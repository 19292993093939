import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
//@ts-ignore
import { Modal } from "modal-react-native-web";
import React, { useContext, useEffect } from "react";
import { Platform } from "react-native";
import { Overlay } from "react-native-elements";
import { themeColor, useTheme } from "react-native-rapi-ui";
import NewMessageModal from "../components/Modals/NewMessageModal";
import TabBarIcon from "../components/utils/TabBarIcon";
import TabBarText from "../components/utils/TabBarText";
import { RouteNames } from "../constants/routeNames";
import useSize from "../hooks/useSize";
import { AuthContext } from "../provider/AuthProvider";
import AccountStack from "../stacks/Account";
import CallStack from "../stacks/Call";
import ChatStack from "../stacks/Chat";
import HomeStackMobile from "../stacks/HomeMobile";
import ProgramStack from "../stacks/Program";
import { MainTabsParamList } from "../types/navigation";

const Tabs = createBottomTabNavigator<MainTabsParamList>();

export default function MainTabsMobile({ navigation }: any) {
  const { isDarkmode } = useTheme();
  const { showModals, userData } = useContext(AuthContext);
  const { isTablet, isWeb, isDesktop } = useSize();
  const [unreadNotification, setUnreadNotification] = React.useState(0);

  useEffect(() => {
    if (userData?.notifications) {
      const unread = userData?.notifications.filter(
        (notification: any) => notification.read === false,
      );
      setUnreadNotification(unread.length);
    }
  }, [userData?.notifications]);

  return (
    <>
      <Tabs.Navigator
        screenOptions={{
          headerShown: false,
          tabBarStyle: {
            borderTopColor: isDarkmode ? themeColor.dark100 : "#c0c0c0",
            backgroundColor: isDarkmode ? themeColor.dark200 : "#ffffff",
            ...(Platform.OS === "web"
              ? { height: isWeb ? 66 : isTablet ? 60 : 56 }
              : { height: 86 }),
          },
        }}
        initialRouteName={RouteNames.HOME}
      >
        <Tabs.Screen
          name={RouteNames.HOME}
          component={HomeStackMobile}
          options={{
            tabBarIcon: ({ focused }) => (
              <TabBarIcon focused={focused} icon={"home"} topRightIcon={null} />
            ),
            tabBarLabelPosition: "below-icon",
            tabBarLabel: ({ focused }) => (
              <TabBarText focused={focused} title={"Home"} />
            ),
          }}
        />
        <Tabs.Screen
          name={RouteNames.CALL_MAIN}
          component={CallStack}
          options={{
            tabBarIcon: ({ focused }) => (
              <TabBarIcon
                focused={focused}
                icon={"video"}
                topRightIcon={null}
              />
            ),
            tabBarLabelPosition: "below-icon",
            tabBarLabel: ({ focused }) => (
              <TabBarText focused={focused} title={"Call"} />
            ),
          }}
        />
        <Tabs.Screen
          name={RouteNames.CHAT_MAIN}
          component={ChatStack}
          options={{
            tabBarIcon: ({ focused }) => (
              <TabBarIcon
                focused={focused}
                icon={"message-circle"}
                topRightIcon={
                  unreadNotification > 0 ? unreadNotification : null
                }
              />
            ),
            tabBarLabelPosition: "below-icon",
            tabBarLabel: ({ focused }) => (
              <TabBarText focused={focused} title={"Chat"} />
            ),
          }}
        />
        <Tabs.Screen
          name={RouteNames.PROGRAM_MAIN}
          component={ProgramStack}
          options={{
            tabBarIcon: ({ focused }) => (
              <TabBarIcon
                focused={focused}
                icon={"search"}
                topRightIcon={null}
              />
            ),
            tabBarLabelPosition: "below-icon",
            tabBarLabel: ({ focused }) => (
              <TabBarText focused={focused} title={"Program"} />
            ),
            lazy: false,
          }}
        />

        <Tabs.Screen
          name={RouteNames.ACCOUNT_MAIN}
          component={AccountStack}
          options={{
            tabBarIcon: ({ focused }) => (
              <TabBarIcon focused={focused} icon={"user"} topRightIcon={null} />
            ),
            tabBarLabelPosition: "below-icon",
            tabBarLabel: ({ focused }) => (
              <TabBarText focused={focused} title={"Account"} />
            ),
          }}
        />
      </Tabs.Navigator>
      <Overlay
        isVisible={showModals as boolean}
        onBackdropPress={() => {}}
        overlayStyle={{
          width: isWeb || isDesktop ? "50%" : "90%",
          height: isWeb || isDesktop ? "90%" : "70%",
          borderRadius: 20,
          padding: 0,
          margin: 0,
        }}
        ModalComponent={Modal}
      >
        <NewMessageModal navigation={navigation} />
      </Overlay>
    </>
  );
}
