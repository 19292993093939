import Container from "typedi";
import { ProgramService } from "../../../services/program/program.service";
import { UserService } from "../../../services/user/user.service";
import { UserData } from "../../provider/AuthProvider";
import {
  CustomWorkouts,
  IProgram,
  ProgramType,
} from "../../types/program.types";

/**
 * Handle removing both custom and non-custom phyx
 * @param item Entire program object
 * @param userData Local userData
 * @param setUserLocalData Callback to set the local userData
 * @returns sets the phyxes array in the userData which triggers the useEffect in the Carousel components to fetch the data again
 */
export const handleRemovePhyx = async (
  item: CustomWorkouts | IProgram,
  userData: UserData,
  setUserLocalData: any,
) => {
  const userServices = Container.get(UserService);

  if (item?.type == ProgramType.Custom) {
    const originalPhyxes = [...userData?.phyxes];
    const phyxIndex = originalPhyxes.indexOf((item as CustomWorkouts)._id);
    if (phyxIndex > -1) {
      originalPhyxes.splice(phyxIndex, 1);
    }
    await userServices.removeUserCustomPhyx(
      userData?.id as string,
      (item as CustomWorkouts)._id as string,
      userData?.email,
    );
    // Remove the phyx from the userData.customPrograms
    userData.customPrograms.splice(
      userData.customPrograms.findIndex(
        (item: any) => item._id === (item as CustomWorkouts)._id,
      ),
      1,
    );

    return setUserLocalData({
      ...userData,
      phyxes: originalPhyxes,
    });
  } else {
    // Remove the phyx from the userData.phyxes
    const originalPhyxes = [...userData?.phyxes];
    const phyxIndex = originalPhyxes.indexOf((item as IProgram).id_);
    if (phyxIndex > -1) {
      originalPhyxes.splice(phyxIndex, 1);
    }
    await userServices.removeUserPhyxes(
      userData?.id as string,
      (item as IProgram).id_ as string,
      userData?.email,
    );
    return setUserLocalData({
      ...userData,
      phyxes: originalPhyxes,
    });
  }
};

/**
 * Get programs information if locally available or fetch from the server
 */
export const getData = async (
  userData: UserData,
  setData: any,
  allPrograms: IProgram[],
  setUserLocalData: any,
) => {
  const programService = Container.get(ProgramService);

  let _data: any[] = [];
  if (!userData) return { _: setData([]), value: [] };
  //If phyxes length is 0 then setData to empty array
  if (userData?.phyxes?.length === 0) {
    //@ts-ignore
    userData.currentProgram = null;
    return { _: setData([]), value: [] };
  }
  let customPrograms: any[] = [...(userData?.customPrograms || [])];

  for (const phyx of userData?.phyxes) {
    // Check locally in the allPrograms or customPrograms
    const _foundPhyx =
      allPrograms?.find((item: any) => item.id_ === phyx) ||
      userData?.customPrograms?.find((item: any) => item._id === phyx);
    if (_foundPhyx) {
      _data.push(_foundPhyx);
      if (_foundPhyx.type === ProgramType.Custom) {
        if (customPrograms.indexOf(_foundPhyx) > -1) {
          customPrograms.splice(customPrograms.indexOf(_foundPhyx), 1);
        }
      }
      continue;
    } else {
      // TODO: check custom program
      const _phyx = await programService.getProgramByid_(phyx);
      if (_phyx.data) {
        _data.push(_phyx.data);
      } else {
        userData.phyxes.splice(userData.phyxes.indexOf(phyx), 1);
      }
    }
  }
  const res = [..._data, ...customPrograms];
  const _firstPhyx = userData?.phyxes[0];
  // set the currentProgram to the first phyx
  if (_firstPhyx && res.length > 0) {
    const indexOfFirstPhyx = res.findIndex(
      (item: any) => item.id_ === _firstPhyx,
    );
    if (indexOfFirstPhyx > -1) {
      userData.currentProgram = res[indexOfFirstPhyx];
    }
  }
  if (!userData?.currentProgram && res.length > 0) {
    setUserLocalData({
      ...userData,
      currentProgram: res[0],
    });
  }

  return { _: setData(res), value: res };
};
