import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React from "react";
import { TouchableOpacity, View } from "react-native";
import { Text } from "react-native-paper";
import { colors } from "../../../constants/colors";
import { STRIPE_REDIRECT_URI } from "@env";

export default function PaymentCardWeb({
  client_secret,
  submitCallBack,
  navigation,
}: any) {
  const [loading, setLoading] = React.useState(true);
  const stripe = useStripe();
  const elements = useElements();

  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        margin: 20,
      }}
    >
      <PaymentElement onReady={() => setLoading(false)} />
      {!loading && (
        <TouchableOpacity
          onPress={async (e) => {
            e.preventDefault();
            if (!stripe || !elements) return;
            const res = await stripe.confirmSetup({
              //`Elements` instance that was used to create the Payment Element
              elements,
              confirmParams: {
                return_url: STRIPE_REDIRECT_URI,
              },
              redirect: "if_required",
            });
            submitCallBack(res);
          }}
          style={{
            backgroundColor: colors.primaryColor,
            padding: 10,
            borderRadius: 5,
            margin: 10,
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: 16,
            }}
          >
            Submit
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
}
