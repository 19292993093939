import React, { useEffect, useRef } from "react";

export default function DataTrack({ navigation, data, track }: any) {
  const ref = useRef();

  useEffect(() => {
    if (track) {
      switch (track.kind) {
        case "data":
          track.on("message", handleDataTrack);
          break;
      }
    }
  }, [track]);

  const handleDataTrack = (_data: any) => {
    const message = JSON.parse(_data);
    const { type, data } = message;
    switch (type) {
      case "chat":
        break;
      case "whiteboard":
        break;
      case "draw":
        const { x, y, color, width } = data;

        break;
    }
  };

  return (
    <canvas
      //@ts-ignore
      ref={ref}
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
        position: "absolute",
        zIndex: 100,
      }}
    />
  );
}
