import { NavigationContainer } from "@react-navigation/native";
// import * as Linking from "expo-linking";
import React, { useContext, useState } from "react";
import { WithPendoReactNavigation } from "rn-pendo-sdk";
import { AuthContext } from "../provider/AuthProvider";
import Loader from "../screens/utils/Loading";
import Auth from "./AuthStack";
import Main from "./MainStack";

const PendoNavigationContainer = WithPendoReactNavigation(NavigationContainer);

export default function Navigation() {
  const { user } = useContext(AuthContext);

  const [route, setRoute] = useState<string | null>(null);

  // @ Vital Signs Screen unauthorized access
  // useEffect(() => {
  //   // const getRoute = async () => {
  //   //   const route = await Linking.getInitialURL();
  //   //   if (route) {
  //   //     const { path, queryParams } = Linking.parse(route);
  //   //     setRoute(path);
  //   //   }
  //   // };
  //   // getRoute();
  // }, [userData?.id]);

  return (
    <PendoNavigationContainer
      linking={{
        enabled: true,
        prefixes: [""],
      }}
      fallback={<Loader />}
    >
      {user == null && <Loader />}
      {user == false && <Auth />}
      {user == true && <Main />}
    </PendoNavigationContainer>
  );
}

const linking = {
  enabled: true,
};
