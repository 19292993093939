import { Ionicons } from "@expo/vector-icons";
import React, { useEffect } from "react";
import { FlatList, TouchableOpacity, View } from "react-native";
import { Surface, Text } from "react-native-paper";
import Container from "typedi";
import { UserService } from "../../../services/user/user.service";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { AuthContext } from "../../provider/AuthProvider";
import { GetFileNameFromURL } from "../../webScreens/helper";

export default function NotificationMenu({ navigation }: any) {
  const {
    userData,
    setUserLocalData,
    showNotificationMenu,
    setShowNotificationMenu,
  } = React.useContext(AuthContext) as any;
  const [popUps, setPopUps] = React.useState(userData?.notifications || []);
  const userServices = Container.get(UserService);
  if (popUps.length > 0) {
    popUps.reverse();
  }

  useEffect(() => {
    //move all the unread notifications to the top
    const _unreadNotification = userData?.notifications?.filter(
      (item: any) => item.read === false,
    );
    const _readNotification = userData?.notifications?.filter(
      (item: any) => item.read === true,
    );
    const _sortedNotification = [..._readNotification, ..._unreadNotification];
    setPopUps(_sortedNotification);
  }, [userData?.notifications]);

  const handleDeleteById = (id: string) => {
    const newPopUps = popUps.filter((item: any) => item.id !== id);
    userData.notifications = newPopUps;
    userServices.deleteNotification(userData.id, id).then((res) => {
      setPopUps(newPopUps);
      if (setUserLocalData) {
        setUserLocalData({
          ...userData,
          notifications: newPopUps,
        });
      }
    });
  };

  const handleDeleteAll = () => {
    userData.notifications = [];
    userServices.deleteAllNotifications(userData.id).then((res) => {
      setPopUps([]);
      if (setUserLocalData) {
        setUserLocalData({
          ...userData,
          notifications: [],
        });
      }
    });
  };

  const notificationCard = (item: any) => {
    return (
      <TouchableOpacity
        onPress={() => {
          userServices.markNotificationAsRead(userData.id, item.id).then(() => {
            setUserLocalData({
              ...userData,
              notifications: userData.notifications.map((notification: any) =>
                notification.id === item.id
                  ? { ...notification, read: true }
                  : notification,
              ),
            });
            navigation.navigate(RouteNames.CHAT_MAIN, {
              screen: RouteNames.INBOX,
              params: {
                loadChatRoomId: item?.data?.chatRoomId || null,
              },
            });
            setShowNotificationMenu(false);
          });
        }}
      >
        <Surface
          style={{
            width: "100%",
            height: 100,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: 5,
            backgroundColor: "white",
            elevation: 5,
          }}
        >
          <Ionicons
            name="close"
            size={24}
            color="black"
            style={{
              position: "absolute",
              right: 10,
              top: "40%",
              zIndex: 100000,
            }}
            onPress={() => handleDeleteById(item.id)}
          />
          {item.read === false && (
            <View
              style={{
                width: 5,
                height: "100%",
                backgroundColor: colors.primaryColor,
              }}
            />
          )}
          <View
            style={{
              width: "100%",
              height: 100,
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: 10,
              marginTop: 15,
            }}
          >
            <Text
              style={{
                fontSize: 20,
                fontWeight: "bold",
                color: colors.textColor,
              }}
            >
              {item.title}
            </Text>
            <Text
              style={{
                fontSize: 15,
                marginTop: 5,
                color: colors.textColor,
                width: "80%",
              }}
            >
              {item.body.includes("data:audio/*")
                ? "Voice Message"
                : item.body.includes(
                      "phyxable-practitioner-portal.s3.amazonaws.com",
                    ) || item.body.includes("firebasestorage.googleapis.com")
                  ? GetFileNameFromURL(item.body || "File")
                  : item.body}
            </Text>
          </View>
        </Surface>
      </TouchableOpacity>
    );
  };

  return (
    <View
      style={{
        flex: 1,
        padding: 10,
        width: "100%",
        backgroundColor: "white",
      }}
    >
      <FlatList
        data={popUps}
        renderItem={({ item }) => notificationCard(item)}
        keyExtractor={(item) => item.id?.toString()}
        ListEmptyComponent={() => (
          <View
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Text>No Notifications</Text>
          </View>
        )}
        ListHeaderComponent={() => {
          return (
            <View
              style={{
                width: "100%",
                height: 50,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: "700",
                  color: colors.blueColor,
                  marginLeft: 10,
                  padding: 5,
                }}
              >
                Notifications
              </Text>
              {popUps.filter((item: any) => item.read == false)?.length > 0 && (
                <TouchableOpacity
                  style={{
                    borderRadius: 100,
                    width: 30,
                    height: 30,
                    borderWidth: 1,
                    borderColor: colors.primaryColor,
                    padding: 5,
                    backgroundColor: colors.primaryColor,
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 11,
                      fontWeight: "700",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    {popUps.filter((item: any) => item.read === false)?.length
                      ? popUps.filter((item: any) => item.read === false)
                        ?.length
                      : ""}
                  </Text>
                </TouchableOpacity>
              )}
              <TouchableOpacity
                style={{
                  padding: 5,
                  borderColor: colors.blueColor,
                  borderWidth: 1,
                  borderRadius: 100,
                  marginLeft: 30,
                }}
                onPress={() => handleDeleteAll()}
              >
                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: "500",
                    color: colors.blueColor,
                  }}
                >
                  Clear Notifications
                </Text>
              </TouchableOpacity>
            </View>
          );
        }}
      />
    </View>
  );
}
