import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { ActivityIndicator, View } from "react-native";
import { colors } from "../../constants/colors";

export default function Loader() {
  return (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      colors={[colors.buttonWhite, colors.buttonWhite]}
      style={{
        flex: 1,
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: 1,
      }}
    >
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <ActivityIndicator size="large" color={colors.primaryColor} />
      </View>
    </LinearGradient>
  );
}
