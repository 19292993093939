import { MOBILE_PENDO_APP_KEY, WEB_PENDO_APP_KEY } from "@env";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as Notifications from "expo-notifications";
import React from "react";
import { LogBox, Platform } from "react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { ThemeProvider } from "react-native-rapi-ui";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { NavigationLibraryType, PendoSDK } from "rn-pendo-sdk";
import Container from "typedi";
import "./ignoreWarnings";
import { PaymentSubscriptionService } from "./services/paymentSubscription/paymentSubscription";
import { ProgramService } from "./services/program/program.service";
import { UserService } from "./services/user/user.service";
import Navigation from "./src/navigation";
import { AuthProvider } from "./src/provider/AuthProvider";

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

initPendo();
export default function App() {
  const queryClient = new QueryClient();
  Container.set("queryClient", queryClient);
  React.useEffect(() => {
    LogBox.ignoreLogs([
      "AsyncStorage has been extracted from react-native core and will be removed in a future release. It can now be installed and imported from '@react-native-async-storage/async-storage' instead of 'react-native'. See https://github.com/react-native-async-storage/async-storage",
    ]);
    new UserService();
    new ProgramService();
    new PaymentSubscriptionService();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <GestureHandlerRootView style={{ flex: 1 }}>
        <ThemeProvider theme="light">
          <AuthProvider>
            <SafeAreaProvider>
              <Navigation />
            </SafeAreaProvider>
          </AuthProvider>
        </ThemeProvider>
      </GestureHandlerRootView>
    </QueryClientProvider>
  );
}

function initPendo() {
  if (Platform.OS === "web") {
    (function (apiKey) {
      (function (p, e, n, d, o) {
        // @ts-ignore
        var v, w, x, y, z;
        // @ts-ignore
        o = p[d] = p[d] || {};
        // @ts-ignore
        o._q = o._q || [];
        v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
        for (w = 0, x = v.length; w < x; ++w)
          (function (m) {
            // @ts-ignore
            o[m] =
              // @ts-ignore
              o[m] ||
              function () {
                // @ts-ignore
                o._q[m === v[0] ? "unshift" : "push"](
                  [m].concat([].slice.call(arguments, 0)),
                );
              };
          })(v[w]);
        // @ts-ignore
        y = e.createElement(n);
        // @ts-ignore
        y.async = !0;
        // @ts-ignore
        y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
        // @ts-ignore
        z = e.getElementsByTagName(n)[0];
        // @ts-ignore
        z.parentNode.insertBefore(y, z);
      })(window, document, "script", "pendo");
    })(WEB_PENDO_APP_KEY);
    // tslint:enable-next-line
  } else {
    const navigationOptions = {
      library: NavigationLibraryType.ReactNavigation,
    };
    PendoSDK.setDebugMode(true);
    PendoSDK.setup(MOBILE_PENDO_APP_KEY, navigationOptions);
  }
}
