import { MaterialCommunityIcons } from "@expo/vector-icons";
import React from "react";
import { FlatList, Text, TouchableOpacity, View } from "react-native";
import { Surface } from "react-native-paper";
import { colors } from "../../constants/colors";

export default function ActiveSubscription({
  subscription,
  period,
  handleUpgrade,
}: any) {
  return (
    <>
      <Surface
        style={{
          borderRadius: 24,
          marginTop: 20,
          elevation: 4,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
          padding: 20,
          alignSelf: "center",
        }}
      >
        <Text
          style={{
            marginTop: 5,
            fontWeight: "700",
            fontSize: 24,
          }}
        >
          {subscription.type}
        </Text>
        <Text
          style={{
            fontWeight: "700",
            fontSize: 24,
          }}
        >
          ${subscription.price} / {period}
        </Text>
        <View
          style={{
            marginLeft: 24,
            marginTop: 24,
            width: 250,
          }}
        >
          <FlatList
            data={subscription.points}
            renderItem={({ item }) => (
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  paddingBottom: 10,
                }}
              >
                <MaterialCommunityIcons
                  name="checkbox-blank-circle"
                  size={15}
                  color="#3EA65C"
                  style={{ marginVertical: "auto" }}
                />
                <Text style={{ marginLeft: 30 }}>{item}</Text>
              </View>
            )}
          />
        </View>
        <TouchableOpacity
          style={[
            {
              borderRadius: 24,
              backgroundColor: colors.primaryColor,
              alignSelf: "center",
              marginTop: 20,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              paddingVertical: 10,
              marginBottom: 20,
            },
            ,
            { width: 280 },
          ]}
          onPress={() => handleUpgrade()}
        >
          <Text
            style={{
              color: "white",
              fontSize: 12,
              fontWeight: "700",
            }}
          >
            UPGRADE
          </Text>
        </TouchableOpacity>
      </Surface>
      <Text
        style={{
          color: colors.primaryColor,
          alignSelf: "center",
          marginTop: 20,
        }}
      >
        {" "}
        It may take up to 24 hours for your subscription to update in the app
      </Text>
    </>
  );
}
