import { PRAC_PORTAL_URL_FOR_MESSAGES } from "@env";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import axios from "axios";
import { Audio } from "expo-av";
import * as DocumentPicker from "expo-document-picker";
import * as ImagePicker from "expo-image-picker";
import { getApp } from "firebase/app";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import moment from "moment";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Alert,
  FlatList,
  Platform,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { Avatar } from "react-native-paper";
import { colors } from "../../constants/colors";
import { AuthContext } from "../../provider/AuthProvider";
import Loader from "../../screens/utils/Loading";
import RenderFileMessage from "./components/RenderFileMessage";
import RenderTextMessage from "./components/RenderTextMessage";
import RenderVoiceMessage from "./components/RenderVoiceMessage";

let firebaseApp: any = null;
let storage: any = null;
if (Platform.OS != "web") {
  firebaseApp = require("@react-native-firebase/app");
  storage = require("@react-native-firebase/storage");
}

export default function Messages({ chatRoom, participants, patient_id }: any) {
  const audioType = "audio/*";
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;

  const [messageToSend, setMessageToSend] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [textInputValue, setTextInputValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState<any>("");
  const [audioURL, setAudioURL] = useState<any>(null);
  const scrollViewRef = useRef<any>();
  const [recording, setRecording] = React.useState<any>(null);
  const { userData } = useContext(AuthContext);

  useEffect(() => {
    getChatMessages().then((data) => {
      setChatMessages(data);
      setLoading(false);
    });
    const intervalId = setInterval(getChatMessages, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const getChatMessages = async () => {
    try {
      const result = await axios.get(
        `${PRAC_PORTAL_URL_FOR_MESSAGES}/chat/messages?chat_room_id=${chatRoom._id}`,
      );
      if (result && result?.data && result?.data?.length > 0) {
        const reversedData = result.data;
        setChatMessages(reversedData);
        return reversedData;
      }
    } catch (error) {
      console.error("error:", error);
    }
  };

  useEffect(() => {
    setChatMessages(chatMessages);
  }, [chatMessages, setChatMessages]);

  const sendChatMessage = async (
    messageData: any,
    messageType: string,
    fileName: string,
  ) => {
    try {
      const result = await axios.post(
        `${PRAC_PORTAL_URL_FOR_MESSAGES}/chat/message`,
        {
          chat_room_id: chatRoom._id,
          sender_id: patient_id,
          data: messageData,
          type: messageType,
          name: messageType === "file" ? fileName : null,
          timestamp: new Date(),
        },
      );
      getChatMessages();
      return result;
    } catch (error) {
      console.error("error:", error);
    }
  };

  const sendMessage = (messageData: string, messageType: string) => {
    if (messageData === "") return;
    sendChatMessage(messageData, messageType, "").then((data) => {});
    setMessageToSend("");
  };

  //================================================================================
  // File Upload
  //================================================================================
  const sendFile = (file: any, fileName: string) => {
    sendChatMessage(file, "file", fileName).then((data) => {});
    addMedicalLog(
      `Chat Message From Patient at ${moment(new Date()).format("llll")}`,
      fileName,
      file,
    );
    setImage("");
    setMessageToSend("");
    setLoading(false);
  };

  const { showActionSheetWithOptions } = useActionSheet();

  const handleFileSelect = async () => {
    if (Platform.OS === "web") {
      handlePickDocument();
      return;
    }
    const options = ["Document", "Image", "Cancel"];
    showActionSheetWithOptions(
      {
        options,
      },
      (selectedIndex) => {
        switch (selectedIndex) {
          case 0:
            handlePickDocument();
            break;
          case 1:
            handlePickImage();
            break;
          default:
            break;
        }
      },
    );
    return;
  };

  const handlePickDocument = async () => {
    setLoading(true);
    const result = await DocumentPicker.getDocumentAsync({
      type: "*/*",
      copyToCacheDirectory: true,
    });
    if (result.type === "success") {
      const { uri, name } = result;
      try {
        const downloadUrl = await handleFileUpload(result);
        if (!downloadUrl) {
          setLoading(false);
          return;
        }
        setImage(downloadUrl);
        sendFile(downloadUrl, name);
      } catch (err) {
        Alert.alert("Uploading Failed", "Failed uploading an document to S3");
      }
    }
    setLoading(false);
  };

  const handlePickImage = async () => {
    setLoading(true);
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
    });
    if (!result.canceled) {
      const [file] = result.assets;
      const { uri } = file;
      let name = file.fileName;
      if (!name) {
        name = uri.split("/").pop();
      }
      try {
        const downloadUrl = await handleFileUpload({ uri, name });
        if (!downloadUrl) {
          setLoading(false);
          return;
        }
        setImage(downloadUrl);
        sendFile(downloadUrl, name ?? "");
      } catch (err) {
        Alert.alert("Uploading Failed", "Failed uploading an image to S3");
      }
    }
    setLoading(false);
  };

  async function handleFileUpload(file: any) {
    try {
      const folder = "chatRoomFiles";
      file.name = file.name + "_" + new Date().getTime();
      const fullPath = `${folder}/${chatRoom._id}/${userData?.id ? userData?.id + "_" + file.name : file.name}`;
      if (Platform.OS === "web") {
        const app = getApp();
        const storage = getStorage(app);
        const storageRef = ref(storage, fullPath);
        const uploadStringType = file.uri.includes("data:")
          ? "data_url"
          : "base64url";
        let res;
        if (uploadStringType === "data_url") {
          res = await uploadString(storageRef, file.uri, "data_url");
        } else {
          // sound file
          const blob_to_upload = await (await fetch(file.uri)).blob();
          res = await uploadBytes(storageRef, blob_to_upload);
        }
        if (res) {
          return await getDownloadURL(ref(storage, fullPath));
        } else {
          return null;
        }
      } else {
        const rnStorageRef = firebaseApp.default.storage().ref(fullPath);
        await rnStorageRef.putFile(file.uri);
        return await rnStorageRef.getDownloadURL();
      }
    } catch (error) {
      console.error("error:", error);
      if (Platform.OS === "web") {
        window.alert("Error uploading file, please try again");
      } else {
        Alert.alert("Error uploading file, please try again");
      }
      return null;
    }
  }

  const addMedicalLog = async (
    topic: string,
    description: string,
    file: any,
  ) => {
    try {
      let result = await axios.post(
        `${PRAC_PORTAL_URL_FOR_MESSAGES}/cpp/patient/log?patient_id=${patient_id}`,
        {
          practitioner_id: patient_id,
          topic: topic,
          description: description,
          files: [{ name: description, data: file }],
          date: new Date(),
        },
      );
      return result;
    } catch (error) {
      console.error("error:", error);
    }
  };
  //================================================================================
  // Voice Chat
  //================================================================================
  const sendVoice = (file: any, fileName: string) => {
    sendChatMessage(file, "voice", fileName).then((data) => {});
  };

  async function startRecording() {
    try {
      await Audio.requestPermissionsAsync();
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        playsInSilentModeIOS: true,
      });

      const { recording } = await Audio.Recording.createAsync(
        Audio.RecordingOptionsPresets.HIGH_QUALITY,
      );
      setRecording(recording);
    } catch (err) {
      console.error("Failed to start recording", err);
    }
  }

  async function stopRecording() {
    setRecording(null);
    await recording.stopAndUnloadAsync();
    await Audio.setAudioModeAsync({
      allowsRecordingIOS: false,
    });
    const uri = recording.getURI();
    const downloadUrl = await handleFileUpload({ uri: uri, name: "voice" });
    if (!downloadUrl) {
      return;
    }
    sendVoice(downloadUrl, "voice");
    setAudioURL(downloadUrl);
  }

  const GetMessageComponent = (
    message: any,
    index: number,
    isPatient: boolean,
  ) => {
    switch (message.type) {
      case "message":
        return RenderTextMessage(message, index, isPatient);
      case "file":
        return RenderFileMessage(message, index, isPatient);
      case "voice":
        return RenderVoiceMessage(message, index, isPatient);
    }
  };

  function RenderPatientMessage(message: any, index: number) {
    return (
      <Fragment key={`patient_message_${index}`}>
        <View style={styles.patientMessage}>
          {GetMessageComponent(message, index, true)}
          <Avatar.Image
            source={require("../../../assets/images/logo.png")}
            size={isTablet ? 40 : 20}
            style={{
              height: isTablet ? 40 : 20,
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              backgroundColor: "transparent",
            }}
          />
        </View>
      </Fragment>
    );
  }

  function RenderPracMessage(message: any, index: number) {
    return (
      <Fragment key={`prac_message_${index}`}>
        <View style={styles.pracMessage}>
          <Avatar.Image
            source={require("../../../assets/images/logo.png")}
            size={isTablet ? 40 : 20}
            style={{
              height: isTablet ? 40 : 20,
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              backgroundColor: "transparent",
            }}
          />
          {GetMessageComponent(message, index, false)}
        </View>
      </Fragment>
    );
  }

  return !loading ? (
    <View style={[styles.container, { marginBottom: isTablet ? 60 : 10 }]}>
      <FlatList
        data={chatMessages}
        renderItem={({ item, index }: any) => {
          if (!item || !item?.data) return null;
          if (item.sender_id === patient_id) {
            return RenderPatientMessage(item, index);
          } else {
            return RenderPracMessage(item, index);
          }
        }}
        keyExtractor={(item: any, index: number) => {
          return item._id;
        }}
        onBegan={() => {
          setLoading(true);
        }}
        onEnded={() => {
          setLoading(false);
        }}
        style={{
          flex: 1,
          height: "100%",
          width: "100%",
        }}
        inverted={true}
        initialNumToRender={chatMessages?.length}
      />
      <View style={styles.messaginginputContainer}>
        <View style={styles.messagingTextContainer}>
          <TextInput
            style={styles.messaginginput}
            placeholder="Enter Message"
            value={textInputValue || ""}
            onChangeText={(value) => {
              setMessageToSend(value);
              setTextInputValue(value);
            }}
            onSubmitEditing={(value) => {
              setMessageToSend(value.nativeEvent.text);
              sendMessage(messageToSend, "message");
              setTextInputValue("");
            }}
            //onPressIn={handlePressIn}
          />
          <TouchableOpacity
            style={styles.messagingbuttonContainer}
            onPress={() => {
              if (image) {
                sendFile(image, messageToSend);
              } else {
                sendMessage(messageToSend, "message");
              }
              setTextInputValue("");
            }}
          >
            <Ionicons name="arrow-up-circle" size={30} color="#18214D" />
          </TouchableOpacity>
        </View>

        <View style={styles.messagingOptions}>
          <View>
            <Ionicons
              name="attach"
              size={20}
              color="#18214D"
              onPress={() => handleFileSelect()}
            />
          </View>
          <TouchableOpacity
            style={styles.circleIcon}
            onPress={recording ? stopRecording : startRecording}
          >
            {!recording ? (
              <Ionicons name="mic-outline" size={20} color="#18214D" />
            ) : (
              <MaterialIcons
                name="record-voice-over"
                size={20}
                color="#18214D"
              />
            )}
          </TouchableOpacity>
        </View>
      </View>
    </View>
  ) : (
    <Loader />
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#FFF",
    flexGrow: 1,
  },
  messages: {
    flex: 1,
    flexDirection: "column",
    margin: 10,
  },
  patientMessage: {
    flexDirection: "row",
    justifyContent: "flex-end",
    // marginVertical: 10,
    marginRight: 10,
  },
  pracMessage: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginLeft: 10,
    // marginVertical: 10,
  },
  messaginginputContainer: {
    width: "100%",
    height: 60,
    backgroundColor: "white",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  messagingTextContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "80%",
    borderWidth: 1,
    borderColor: "#D6D7D9",
    marginLeft: 5,
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 8,
  },
  messaginginput: {
    height: "100%",
    width: "85%",
    padding: 10,
  },
  messagingbuttonContainer: {
    width: "15%",
    justifyContent: "center",
    alignItems: "center",
  },
  messagingOptions: {
    width: "20%",
    height: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    paddingRight: 5,
  },
  circleIcon: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: "rgba(24, 33, 77, 0.1)",
    justifyContent: "center",
    alignItems: "center",
  },
});
