import React from "react";
import { Platform } from "react-native";
import { SvgUri } from "react-native-svg";
import { ReactSVG } from "react-svg";
export default function RenderSVGImage({
  uri,
  size,
  style,
}: {
  uri: string;
  size?: { width: any; height: any };
  style?: any;
}) {
  switch (Platform.OS) {
    case "ios":
    case "android":
      return (
        <SvgUri
          style={
            style
              ? style
              : {
                  width: size?.width,
                  height: size?.height,
                  marginLeft: -30,
                  marginTop: 10,
                }
          }
          uri={uri}
          height={style?.height || size?.height}
          width={style?.width || size?.width}
        />
      );
    default:
      return (
        <ReactSVG
          src={uri}
          style={
            style
              ? style
              : {
                  width: size?.width,
                  height: size?.height,
                  marginLeft: -30,
                  marginTop: 10,
                }
          }
        />
      );
  }
}
