//@ts-nocheck
import React, { Fragment, useEffect, useRef, useState } from "react";
import { View, useWindowDimensions } from "react-native";
import { LineChart } from "react-native-chart-kit";
import { Card } from "react-native-paper";
import { Text, themeColor, useTheme } from "react-native-rapi-ui";
import Container from "typedi";
import { UserService } from "../../../services/user/user.service";
import { IopcVitalSignFhir } from "../../types/types";
import Loader from "../utils/Loading";

export default function UserVitals({ navigation, route }: any) {
  const { isDarkmode, setTheme } = useTheme();
  const { width, height } = useWindowDimensions();
  // const { token, demo } = route.params;
  const isTablet = width >= 768;
  const demo = true;

  const [vitalData, setData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [inAlarm, setInAlarm] = useState(new Set());
  const [connectedDevices, setConnectedDevices] = useState([]);
  const [webSocketConnection, setWebSocketConnectionStatus] = useState(false);

  const originalData = useRef<any>([]);
  const [ws, setWs] = useState<WebSocket>(
    new WebSocket(`wss://devappv3.phyxable.com/api/v3`, "echo-protocol"),
  );

  useEffect(() => {
    setTheme("dark");
    if (Boolean(demo)) {
      const userServices = Container.get(UserService);
      const startDemoStream = async () => {
        const demoData = await userServices.startVitalStream("test");
        parseIOPCData(
          demoData.data,
          setData,
          setConnectedDevices,
          setInAlarm,
          inAlarm,
          connectedDevices,
        );
      };
      startDemoStream().then(() => {
        setLoading(false);
        ws.onopen = () => {
          setWebSocketConnectionStatus(true);
        };
        ws.onmessage = (e) => {
          setWebSocketConnectionStatus(false);
          const data = JSON.parse(e.data);
          originalData.current.push(data);
          parseIOPCData(
            originalData.current,
            setData,
            setConnectedDevices,
            setInAlarm,
            inAlarm,
            connectedDevices,
          );
          setWebSocketConnectionStatus(true);
        };
      });
      ws.onclose = () => {
        setWebSocketConnectionStatus(false);
      };

      return () => {
        ws.close();
      };
    }
  }, []);

  const VitalCardData = ({ _data, showChart }: any) => {
    let textColor = VitalSigsColorMap[_data[0].display];
    return (
      <View
        style={{
          flexDirection: "row",
          backgroundColor: "black",
          width: "95%",
          height: "95%",
          borderRadius: 10,
          padding: 5,
          alignSelf: "center",
        }}
      >
        <View
          style={{
            flex: 0.5,
          }}
        >
          <Text
            style={{
              color: textColor,
              fontSize: 18,
              fontWeight: "300",
              fontFamily: "Roboto",
            }}
          >
            {
              //@ts-ignore
              VitalSigCodeMap[_data[0].display]
            }
          </Text>
          <Text
            style={{
              color: textColor,
              fontSize: 15,
            }}
          >
            {
              //@ts-ignore
              _data[0].unit
            }
          </Text>
          {/* <Text
            style={{
              color: textColor,
              fontSize: 15,
              marginTop: 60,
            }}
          >
            AVG.{" "}
            {
              //@ts-ignore
              _data[0].display === VitalSigEnum.BLOOD_PRESSURE
                ? Math.round(
                    _data.reduce(
                      (a, b) => a + parseInt(b.value.split("/")[0]),
                      0
                    ) / _data.length
                  ) +
                  "/" +
                  Math.round(
                    _data.reduce(
                      (a, b) => a + parseInt(b.value.split("/")[1]),
                      0
                    ) / _data.length
                  )
                : Math.round(
                    _data.reduce((a, b) => a + parseInt(b.value), 0) /
                      _data.length
                  )
            }
          </Text>
          {showChart && (
            <ChartComponent
              _data={_data}
              _height={(height / Object.keys(vitalData).length) * 0.5}
              _width={width * 0.95}
            />
          )} */}
        </View>
        <View
          style={{
            flex: 1,
            // alignItems: "flex-end",
            // borderRadius: 10,
            alignContent: "flex-end",
            justifyContent: "space-evenly",
          }}
        >
          {/* <Text
          style={{
            alignSelf: "flex-end",
          }}
          >
            {connectedDevices.includes(_data[0].display) ? (
              <ConnectedSVG />
            ) : (
              <DisconnectedSVG />
            )}
          </Text> */}
          {_data[0].display === VitalSigEnum.BLOOD_PRESSURE ? (
            <>
              <Text
                style={{
                  color: textColor,
                  fontSize: 55,
                  fontWeight: "800",
                  fontFamily: "Roboto",
                  width: "95%",
                }}
              >
                {
                  //@ts-ignore
                  _data[_data.length - 1].value.split("/")[0]
                }
              </Text>
              <Text
                style={{
                  color: textColor,
                  fontSize: 55,
                  fontWeight: "800",
                  fontFamily: "Roboto",
                  width: "95%",
                  flexWrap: "wrap",
                }}
              >
                /
                {
                  //@ts-ignore
                  _data[_data.length - 1].value.split("/")[1]
                }
              </Text>
            </>
          ) : (
            <Text
              style={{
                color: textColor,
                fontSize: 100,
                fontWeight: "800",
                fontFamily: "Roboto",
                width: "95%",
              }}
            >
              {
                //@ts-ignore
                _data[_data.length - 1].value
              }
            </Text>
          )}
        </View>
      </View>
    );
  };
  const VitalCardMobile = ({ _data }: any) => {
    const activeColorBackground = inAlarm.has(_data[0].display)
      ? VitalSigsColorMap[_data[0].display]
      : themeColor.dark;
    return (
      <Card
        style={{
          margin: 5,
          backgroundColor: activeColorBackground,
          width: width * 0.9,
          height: height / Object.keys(vitalData).length,
          alignSelf: "center",
          justifyContent: "center",
          elevation: 0,
        }}
      >
        <Card.Content>
          <VitalCardData _data={_data} showChart={true} />
        </Card.Content>
      </Card>
    );
  };

  const VitalCardDesktop = ({ _data }: any) => {
    const activeColorBackground = "black";
    inAlarm.has(_data[0].display);
    // ? VitalSigsColorMap[_data[0].display]
    // : "black";
    return (
      <Card
        style={{
          margin: 1,
          backgroundColor: activeColorBackground,
          width: "100%",
          height: (height * 0.9) / Object.keys(vitalData).length,
          elevation: 0,
          alignSelf: "center",
        }}
      >
        <Card.Content>
          <View
            style={{
              flexDirection: "row",
            }}
          >
            {inAlarm && inAlarm.has(_data[0].display) && (
              <View
                style={{
                  backgroundColor: "white", //VitalSigsColorMap[_data[0].display],
                  width: 10,
                  height: "100%",
                }}
              ></View>
            )}
            <View>
              <Text
                style={{
                  color: VitalSigsColorMap[_data[0].display],
                  fontSize: 30,
                  fontWeight: "700",
                  fontFamily: "Roboto-mono",
                }}
              >
                <ChartComponent
                  _data={_data}
                  _height={(height / Object.keys(vitalData).length) * 0.74}
                  _width={width * 0.65}
                />
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                alignItems: "flex-end",
                width: width * 0.4,
              }}
            >
              <VitalCardData _data={_data} showChart={false} />
            </View>
          </View>
        </Card.Content>
      </Card>
    );
  };

  const ChartComponent = ({ _data, _height, _width }: any) => {
    let lineColor = VitalSigsColorMap[_data[0].display];
    if (
      _data[0].display === VitalSigEnum.TEMPERATURE &&
      inAlarm.has(_data[0].display)
    ) {
      lineColor = "white";
    }
    var data = {};
    if (_data[0]?.display === VitalSigEnum.BLOOD_PRESSURE) {
      data = {
        labels: ["Systolic", "Diastolic"],
        datasets: [
          {
            data: _data.map((d: any) => d.value.split("/")[0]),
            color: (opacity = 1) => lineColor,
            strokeWidth: 2, // optional
          },
          {
            data: _data.map((d: any) => d.value.split("/")[1]),
            color: (opacity = 1) => lineColor,
            strokeWidth: 2, // optional
          },
        ],
      };
    } else {
      data = {
        labels: _data.map((d: any) => d.display),
        datasets: [
          {
            data: _data.map((d: any) => d.value),
            color: (opacity = 1) => lineColor,
            strokeWidth: 2, // optional
          },
        ],
      };
    }
    return (
      <LineChart
        //@ts-ignore
        data={data}
        width={_width || 100} // from react-native
        height={_height || 100}
        yAxisLabel=""
        yAxisSuffix=""
        yAxisInterval={1} // optional, defaults to 1
        chartConfig={{
          backgroundColor: themeColor.dark,
          strokeWidth: 0.5,
          decimalPlaces: 2, // optional, defaults to 2dp
          color: (opacity = 1) => lineColor,
          style: {
            borderRadius: 10,
          },
        }}
        bezier
        style={{
          // marginVertical: 8,
          borderRadius: 10,
        }}
        withHorizontalLabels={false}
        withVerticalLabels={false}
        withInnerLines={false}
        withOuterLines={false}
        withDots={false}
        withShadow={false}
      />
    );
  };

  return (
    <Fragment>
      {/* <Header navigation={navigation} /> */}
      {loading ? (
        <Loader />
      ) : (
        <View
          style={{
            width: "100%",
            justifyContent: "center",
            height: "100%",
            backgroundColor: "black",
          }}
        >
          {vitalData != null ? (
            Object.keys(vitalData).map((key, index) => {
              return isTablet ? (
                <VitalCardDesktop _data={vitalData[key]} key={index} />
              ) : (
                <VitalCardDesktop _data={vitalData[key]} key={index} />
              );
            })
          ) : (
            <Text>No Data</Text>
          )}
        </View>
      )}
    </Fragment>
  );
}

export function parseIOPCData(
  data: any[],
  setData: any,
  setConnectedDevices?: any,
  setInAlarm?: any,
  inAlarm?: any[],
  connectedDevices?: any[],
) {
  if (data === null) return;
  let fhir: IopcVitalSignFhir[] = [];
  data?.length
    ? data.forEach((element) => {
        fhir.push(element.fhir);
      })
    : fhir.push(data?.fhir);

  let _data: { display: string; value: number; unit: string }[] = [];
  fhir.forEach((element) => {
    element.component.forEach((component) => {
      _data.push({
        display: component.code.text,
        value: component.valueQuantity.value,
        unit: component.valueQuantity.unit,
        time: element.effectiveDateTime,
      });
    });
  });
  var groupData = _data.reduce(function (r, a) {
    r[a.display] = r[a.display] || [];
    r[a.display].push(a);
    return r;
  }, Object.create(null));
  // Merge the diastolic and systolic pressure
  groupData[VitalSigEnum.BLOOD_PRESSURE] = groupData[
    VitalSigEnum.SYSTOLIC_PRESSURE
  ].map((item: any, index: number) => {
    return {
      display: VitalSigEnum.BLOOD_PRESSURE,
      value:
        item.value +
        "/" +
        groupData[VitalSigEnum.DIASTOLIC_PRESSURE][index].value,
      unit: item.unit,
    };
  });
  delete groupData[VitalSigEnum.DIASTOLIC_PRESSURE];
  delete groupData[VitalSigEnum.SYSTOLIC_PRESSURE];

  // Put blood pressure on second position
  let bp = groupData[VitalSigEnum.BLOOD_PRESSURE];
  delete groupData[VitalSigEnum.BLOOD_PRESSURE];
  groupData = { [VitalSigEnum.BLOOD_PRESSURE]: bp, ...groupData };

  // Check for LimitMap
  if (setInAlarm)
    Object.keys(groupData).forEach((key) => {
      if (!connectedDevices?.includes(key)) {
        connectedDevices.push(key);
        setConnectedDevices([...connectedDevices]);
      }
      if (LimitMap[key]) {
        const { min, max } = LimitMap[key];
        const lastData = groupData[key][groupData[key].length - 1];

        if (
          (lastData.value > max || lastData.value < min) &&
          key !== VitalSigEnum.BLOOD_PRESSURE
        ) {
          setInAlarm((prevInAlarm) => new Set(prevInAlarm).add(key));
        } else if (inAlarm.has(key)) {
          inAlarm.delete(key);
          setInAlarm(new Set(inAlarm));
        }

        if (key === VitalSigEnum.BLOOD_PRESSURE) {
          const [systolic, diastolic] = lastData.value.split("/");
          if (systolic < min || diastolic > max) {
            setInAlarm((prevInAlarm) => new Set(prevInAlarm).add(key));
          }
        }
      }
    });

  setData(groupData);
}

export enum VitalSigEnum {
  BLOOD_OXYGEN = "BLOOD OXYGEN",
  HEART_RATE = "HEART RATE",
  BLOOD_PRESSURE = "BLOOD PRESSURE",
  RESPIRATORY_RATE = "RESPIRATORY RATE",
  DIASTOLIC_PRESSURE = "DIASTOLIC PRESSURE",
  SYSTOLIC_PRESSURE = "SYSTOLIC PRESSURE",
  TEMPERATURE = "TEMPERATURE",

  // Derived
}

const VitalSigsColorMap = {
  [VitalSigEnum.BLOOD_OXYGEN]: "#00ABCB",
  [VitalSigEnum.HEART_RATE]: "#1EDF32",
  [VitalSigEnum.RESPIRATORY_RATE]: "#AA23AD",
  [VitalSigEnum.TEMPERATURE]: "#FAFAFA",
  [VitalSigEnum.BLOOD_PRESSURE]: "#D93133",
};

const VitalSigCodeMap = {
  [VitalSigEnum.BLOOD_OXYGEN]: "SPO2",
  [VitalSigEnum.HEART_RATE]: "HR",
  [VitalSigEnum.RESPIRATORY_RATE]: "RESP",
  [VitalSigEnum.TEMPERATURE]: "TEMP",
  [VitalSigEnum.BLOOD_PRESSURE]: "NIBP",
};

const LimitMap = {
  [VitalSigEnum.BLOOD_OXYGEN]: {
    min: 95,
    max: 100,
  },
  [VitalSigEnum.HEART_RATE]: {
    min: 60,
    max: 100,
  },
  [VitalSigEnum.RESPIRATORY_RATE]: {
    min: 12,
    max: 20,
  },
  [VitalSigEnum.TEMPERATURE]: {
    min: 36.5,
    max: 40.5,
  },
  [VitalSigEnum.BLOOD_PRESSURE]: {
    min: 60,
    max: 120,
  },
};
