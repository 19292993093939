import React, { useEffect, useRef, useState } from "react";

export default function AudioTrack({ track, navigation, data }: any) {
  const ref = useRef();
  const [muted, setMuted] = useState(false);

  useEffect(() => {
    if (track) {
      const el = ref.current;
      if (track?.track) {
        track.track.attach(el);
        setMuted(!track.track.isEnabled);
        track.track.on("disabled", () => {
          setMuted(true);
        });
        track.track.on("enabled", () => {
          setMuted(false);
        });
      } else {
        track?.attach(el);
        setMuted(!track.isEnabled);
        track.on("disabled", () => {
          setMuted(true);
        });
        track.on("enabled", () => {
          setMuted(false);
        });
      }
      return () => {
        if (track?.track) track.track.detach(el);
        else {
          track?.detach(el);
        }
      };
    }
  }, [track]);

  return (
    <audio
      // @ts-ignore
      ref={ref}
      autoPlay={true}
      muted={muted}
    />
  );
}
