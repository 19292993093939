import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React, { useContext, useEffect, useState } from "react";
import {
  FlatList,
  Platform,
  SafeAreaView,
  StyleSheet,
  View,
} from "react-native";
import { Text } from "react-native-rapi-ui";
import Button from "../../../components/Home/button";
import CustomVideoItem from "../../../components/Program/customProgram/customViedoItem";
import MiniProgramBanner from "../../../components/Program/miniProgramBanner";
import { colors } from "../../../constants/colors";
import { RouteNames } from "../../../constants/routeNames";
import {
  AuthContext,
  AuthContextProps,
  IOutcomeFormResult,
} from "../../../provider/AuthProvider";
import { RootStackParamList } from "../../../types/navigation";
import {
  CustomVideoReps,
  CustomVideoTimer,
} from "../../../types/program.types";
import Loading from "../../utils/Loading";

export type CustomPlayListParams = {
  id: string;
  outcome_forms: IOutcomeFormResult[];
  image: any;
  todaySession: any;
};

type Props = NativeStackScreenProps<
  RootStackParamList,
  RouteNames.CUSTOM_PLAYLIST
>;

export default function CustomPlaylist({ navigation, route }: Props) {
  const { customPrograms } = useContext(AuthContext) as AuthContextProps;
  const { id, outcome_forms, image, todaySession } = route.params;
  const [programData, setProgramData] = useState(
    customPrograms.find((p: any) => p.id === id),
  );
  const [videos, setVideos] = useState([]);
  const [playlist, setPlaylist] =
    useState<(CustomVideoTimer | CustomVideoReps)[]>();

  const HandleGoBack = () => {
    navigation.goBack();
  };

  useEffect(() => {
    const program = customPrograms.find((p: any) => p._id === id);
    if (!program) {
      if (Platform.OS === "web") {
        window.location.href = RouteNames.PROGRAM_ALL;
        return;
      }
    }
    setProgramData(program as any);
    const playlist = program?.playlist[todaySession - 1];
    setPlaylist(playlist);
    const videos: any = [];

    playlist?.map((video: any) => {
      let object;
      if (video.reps) {
        object = {
          videoUrl: `https://cdn.jwplayer.com/v2/media/${video.mediaId}`,
          sets: video.sets,
          reps: video.reps,
          session_title: video.title,
          comment: video.comment ? video.comment : null,
        };
      } else {
        object = {
          videoUrl: `https://cdn.jwplayer.com/v2/media/${video.mediaId}`,
          sets: video.sets,
          time: video.time,
          session_title: video.title,
          comment: video.comment ? video.comment : null,
        };
      }

      videos.push(object);
    });
    setVideos(videos);
  }, []);

  const handleStartSession = () => {
    if (!programData) {
      console.error("No program data");
      return;
    }
    navigation.navigate(RouteNames.CUSTOM_EXERCISE, {
      program: {
        title: programData.program_name || "",
        created_by: programData.created_by,
        created_by_name: programData.created_by_name || "",
        id: programData._id,
        image: image,
        session: todaySession,
        level: 1,
      },
      video: videos,
      outcome_forms: outcome_forms,
    });
  };
  const Header = () => {
    return (
      <View>
        <MiniProgramBanner
          title={programData?.program_name}
          goBackCallback={HandleGoBack}
          image={image}
        />
        <Footer />
        <View style={styles.container}>
          <Text style={styles.subHeading}>Session {todaySession}</Text>
        </View>
      </View>
    );
  };

  const Footer = () => {
    return (
      <View>
        <Button
          text="START SESSION"
          backgroundColor={colors.primaryColor}
          customStyle={{
            alignSelf: "center",
            marginTop: 30,
            marginBottom: 20,
            paddingHorizontal: 30,
          }}
          onPressCallback={handleStartSession}
        />
      </View>
    );
  };

  return (
    <SafeAreaView
      style={{
        backgroundColor: "white",
        height: "100%",
      }}
    >
      {programData ? (
        <FlatList
          showsVerticalScrollIndicator={false}
          data={playlist}
          renderItem={({ item }) => <CustomVideoItem video={item} />}
          keyExtractor={(item) => item.title}
          ListEmptyComponent={<Loading />}
          ListHeaderComponent={<Header />}
          style={{ height: "100%", backgroundColor: "white" }}
        />
      ) : null}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingTop: 20,
  },
  title: {
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 16,
  },
  subHeading: {
    textTransform: "capitalize",
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 12,
    marginTop: 5,
    marginBottom: 15,
  },
});
