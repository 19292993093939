import { MaterialIcons } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import React, { useContext } from "react";
import {
  Alert,
  Dimensions,
  FlatList,
  ImageBackground,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Snackbar } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Container from "typedi";
import { UserService } from "../../../services/user/user.service";
import Button from "../../components/Home/button";
import EquipmentItem from "../../components/Program/equipmentItem";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { AuthContext } from "../../provider/AuthProvider";
import { default as Loader, default as Loading } from "../utils/Loading";

//Calculating height for image view
const HEIGHT = Dimensions.get("window").width / 4;
export default function ProgramConfirmation({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) {
  const { equipment, title, id_ } = route.params;
  const user = useContext(AuthContext);

  const [snackbarVisible, setSnackbarVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const _userServices = Container.get(UserService);
  const insets = useSafeAreaInsets();

  const handleJoinProgram = async () => {
    setLoading(true);
    if (user.userData?.id === undefined) return;
    //@ts-ignore
    user.setUserLocalData({
      ...user.userData,
      //@ts-ignore
      phyxes: [id_, ...user.userData?.phyxes],
    });
    const res = await _userServices.updateUserPhyxes(
      user.userData?.id as string,
      id_,
      user.userData?.email as string,
    );
    if (res.status !== 200) {
      // Attempt to join program failed try again
      Alert.alert(
        "Error",
        "Something went wrong while joining the program. Please try again later.",
        [
          {
            text: "OK",
            onPress: async () => {
              //@ts-ignore
              var _resTry2 = await _userServices.updateUserPhyxes(
                user.userData?.id as string,
                id_,
              );
              if (_resTry2.status == 200) {
                //@ts-ignore
                user.setUserLocalData({
                  ...user.userData,
                  //@ts-ignore
                  phyxes: [...user.userData?.phyxes, id_],
                });
              }
            },
          },
          {
            text: "Cancel",
            onPress: () => navigation.navigate(RouteNames.HOME),
          },
        ],
      );
    } else {
      //@ts-ignore
      user.setUserLocalData({
        ...user.userData,
        //@ts-ignore
        phyxes: [id_, ...user.userData?.phyxes],
      });
    }

    setSnackbarVisible(true);
    setLoading(false);
    await navigation.reset({
      index: 0,
      routes: [{ name: RouteNames.HOME }],
    });
  };

  const handleGoBack = () => {
    navigation.goBack();
  };

  return (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      colors={[colors.buttonWhite, colors.buttonWhite]}
      style={{
        flex: 1,
        width: "100%",
        height: "100%",
        top: insets.top,
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <ScrollView style={{ backgroundColor: "white", height: "100%" }}>
          <ImageBackground
            source={{
              uri: "https://phyxableprograms.s3.amazonaws.com/mindfulness_phyx.jpg",
            }}
            resizeMode="cover"
            style={styles.image}
          >
            <TouchableOpacity style={styles.arrow} onPress={handleGoBack}>
              <MaterialIcons
                name="arrow-back-ios"
                size={20}
                color={colors.secondaryColor}
                style={{ width: 10 }}
              />
            </TouchableOpacity>
          </ImageBackground>
          <View style={styles.container}>
            <Text style={styles.title}>Join the Program?</Text>
            <Text style={styles.text}>
              Program: <Text style={styles.boldText}>{title}</Text>
            </Text>
            <Text style={styles.text}>
              Difficulty: <Text style={styles.boldText}>Beginner</Text>
            </Text>
            <Text style={styles.text}>
              Duration: <Text style={styles.boldText}>10 weeks</Text>
            </Text>

            {/* Equipment */}
            <Text style={styles.sectionHeading}>Equipment</Text>
            <FlatList
              showsVerticalScrollIndicator={false}
              data={equipment}
              numColumns={2}
              renderItem={({ item }) => (
                <EquipmentItem name={item.name} image={item.image} />
              )}
              keyExtractor={(item) => item}
              ListEmptyComponent={<Loading />}
            />
          </View>
          {/* Button view */}
          <View style={styles.button}>
            <Button
              text="CONFIRM"
              backgroundColor={colors.primaryColor}
              customStyle={{
                alignSelf: "center",
              }}
              onPressCallback={handleJoinProgram}
            />
          </View>
          <Snackbar
            visible={snackbarVisible}
            onDismiss={() => setSnackbarVisible(false)}
            duration={5000}
          >
            <Text>{title} added to your library</Text>
          </Snackbar>
        </ScrollView>
      )}
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  image: {
    width: "100%",
    height: HEIGHT,
  },
  arrow: {
    borderRadius: 8,
    width: 40,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    marginLeft: 20,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 5,
    backgroundColor: "white",
  },
  container: {
    paddingHorizontal: 20,
    paddingTop: 10,
  },
  title: {
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 28,
    marginBottom: 20,
  },
  text: {
    color: colors.secondaryColor,
    fontSize: 16,
    marginBottom: 10,
  },
  boldText: {
    color: colors.secondaryColor,
    fontSize: 16,
    fontWeight: "700",
  },
  sectionHeading: {
    fontWeight: "400",
    fontSize: 16,
    color: colors.secondaryColor,
    marginTop: 20,
    marginBottom: 10,
  },
  button: {
    position: "relative",
    bottom: 0,
    justifyContent: "center",
    width: "100%",
    marginBottom: 20,
  },
});
