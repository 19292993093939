import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { RouteNames } from "../constants/routeNames";
import { Chat } from "../screens";
import ComposeMessage from "../screens/chat/ComposeMessage";
import Inbox from "../screens/chat/Inbox";
import Media from "../screens/chat/Media";

const chatStack = createNativeStackNavigator();

export default function ChatStack() {
  return (
    <>
      <chatStack.Navigator
        screenOptions={{
          headerShown: true,
          headerBackButtonMenuEnabled: true,
          headerTitle: "",
        }}
        initialRouteName={RouteNames.INBOX}
      >
        <chatStack.Screen
          name={RouteNames.MESSAGES}
          component={Chat}
          options={{
            headerShown: false,
          }}
        />
        <chatStack.Screen
          name={RouteNames.INBOX}
          component={Inbox}
          options={{
            headerShown: false,
          }}
        />
        <chatStack.Screen
          name={RouteNames.MEDIA}
          component={Media}
          options={{
            headerShown: false,
          }}
        />
        <chatStack.Screen
          name={RouteNames.COMPOSE_MESSAGE}
          component={ComposeMessage}
          options={{
            headerShown: false,
          }}
        />
      </chatStack.Navigator>
    </>
  );
}
