import { CommonActions } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import React, { useContext, useState } from "react";
import {
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { RadioButton, Snackbar, Surface, Text } from "react-native-paper";
import { Layout, themeColor, useTheme } from "react-native-rapi-ui";
import Header from "../../components/Common/HeaderComponent";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { AuthContext } from "../../provider/AuthProvider";

export default function FeedbackPage({ navigation, route }: any) {
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;
  const { isDarkmode, setTheme } = useTheme();

  const [snackbarVisible, setSnackbarVisible] = useState(false);

  const { roomName, participants } = route.params;

  const handleFeedbackSubmit = () => {
    console.log("Feedback submitted", roomName, participants);
    setSnackbarVisible(true);
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [
          {
            name: RouteNames.HOME,
          },
        ],
      }),
    );
  };

  const RadioGroup = ({ layout, radioButtons, onPress }: any) => {
    const [checked, setChecked] = useState(radioButtons[0].value);
    return (
      <RadioButton.Group onValueChange={setChecked} value={checked}>
        <View style={{ flexDirection: layout }}>
          {radioButtons.map((data: any) => (
            <View key={data.value} style={{ alignItems: "center" }}>
              <RadioButton value={data.value} />
              <Text
                style={{
                  color: "black",
                }}
              >
                {data.label}
              </Text>
            </View>
          ))}
        </View>
      </RadioButton.Group>
    );
  };

  const { userData } = useContext(AuthContext);
  return (
    <Layout>
      <LinearGradient
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        colors={[colors.buttonWhite, colors.buttonWhite]}
        style={{ flex: 1 }}
      >
        <Header navigation={navigation} />
        <ScrollView>
          <View style={[styles.mainContainer]}>
            <Surface
              style={[
                styles.CardContainer,
                { backgroundColor: isDarkmode ? "black" : "white" },
              ]}
            >
              <View>
                <Text
                  style={{
                    fontSize: 25,
                    fontWeight: "700",
                    alignSelf: "center",
                    marginBottom: "5%",
                    marginTop: "5%",
                    color: isDarkmode ? themeColor.white : themeColor.black,
                  }}
                >
                  Share Your Feedback!
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: "500",
                    alignSelf: "center",
                    marginBottom: "3%",
                    color: isDarkmode ? themeColor.white : themeColor.black,
                  }}
                >
                  How was the Audio quality during this call? (1 = poor, 5 =
                  excellent)
                </Text>
                <RadioGroup
                  layout={"row"}
                  radioButtons={radioButtons}
                  onPress={(e: any) => {
                    console.log(e);
                  }}
                />
                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: "500",
                    alignSelf: "center",
                    marginBottom: "5%",
                    marginTop: "3%",
                    color: isDarkmode ? themeColor.white : themeColor.black,
                  }}
                >
                  How was the Video quality during this call? (1 = poor, 5 =
                  excellent)
                </Text>
                <RadioGroup
                  layout={"row"}
                  radioButtons={radioButtons}
                  onPress={(e: any) => {
                    console.log(e);
                  }}
                />

                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: "500",
                    alignSelf: "center",
                    marginBottom: "5%",
                    marginTop: "3%",
                    color: isDarkmode ? themeColor.white : themeColor.black,
                  }}
                >
                  Were there any issues connecting with the participant?
                </Text>
                <RadioGroup
                  layout={"row"}
                  radioButtons={[
                    {
                      id: "1", // acts as primary key, should be unique and non-empty string
                      label: "Yes",
                      value: "1",
                    },
                    {
                      id: "2",
                      label: "No",
                      value: "0",
                    },
                  ]}
                  onPress={(e: any) => {
                    console.log(e);
                  }}
                />

                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: "500",
                    marginBottom: "5%",
                    marginTop: "3%",
                    color: isDarkmode ? themeColor.white : themeColor.black,
                  }}
                >
                  Are you satisfied with the practitioner?
                </Text>
                <RadioGroup
                  layout={"row"}
                  radioButtons={[
                    {
                      id: "1", // acts as primary key, should be unique and non-empty string
                      label: "Yes",
                      value: "1",
                    },
                    {
                      id: "2",
                      label: "No",
                      value: "0",
                    },
                  ]}
                  onPress={(e: any) => {
                    console.log(e);
                  }}
                />
                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: "500",
                    marginTop: "5%",
                    color: isDarkmode ? themeColor.white : themeColor.black,
                  }}
                >
                  Any Comments on how the session went?
                </Text>
                <TextInput
                  style={{
                    borderColor: "black",
                    borderWidth: 1,
                    borderRadius: 5,
                    marginBottom: "5%",
                    marginTop: "2%",
                  }}
                ></TextInput>
                <TouchableOpacity
                  style={styles.button}
                  onPress={() => {
                    setSnackbarVisible(true);
                    navigation.navigate("Home", {
                      screen: "Home",
                    });
                    handleFeedbackSubmit();
                  }}
                >
                  <Text
                    style={{
                      fontSize: 15,
                      fontWeight: "700",
                      color: "white",
                      alignSelf: "center",
                      padding: "5%",
                    }}
                  >
                    Submit
                  </Text>
                </TouchableOpacity>
              </View>
            </Surface>
            <Snackbar
              visible={snackbarVisible}
              onDismiss={() => setSnackbarVisible(false)}
              duration={1000}
            >
              Thank you!
            </Snackbar>
          </View>
        </ScrollView>
      </LinearGradient>
    </Layout>
  );
}

const radioButtons = [
  {
    id: "1", // acts as primary key, should be unique and non-empty string
    label: "1",
    value: "1",
  },
  {
    id: "2",
    label: "2",
    value: "2",
  },
  {
    id: "3", // acts as primary key, should be unique and non-empty string
    label: "3",
    value: "3",
  },
  {
    id: "4", // acts as primary key, should be unique and non-empty string
    label: "4",
    value: "4",
  },
  {
    id: "5", // acts as primary key, should be unique and non-empty string
    label: "5",
    value: "5",
  },
];

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    height: "100%",
    // backgroundColor: colors.primaryColor,
    alignItems: "center",
  },
  CardContainer: {
    borderStyle: "solid",
    // backgroundColor: colors.accountCard,
    borderRadius: 10,
    borderColor: "black",
    height: "50%",
    width: "95%",
    // justifyContent: 'space-evenly',
    alignItems: "center",
    marginTop: "5%",
    marginBottom: "10%",
    flex: 1,
  },
  button: {
    alignSelf: "center",
    backgroundColor: colors.primaryColor,
    width: "60%",
    borderRadius: 50,
    marginTop: "5%",
    marginBottom: "5%",
  },
});
