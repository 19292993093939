import { LOGO_URI } from "@env";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import React, { useContext, useEffect, useState } from "react";
import {
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { Text } from "react-native-paper";
import Container from "typedi";
import { PaymentSubscriptionService } from "../../services/paymentSubscription/paymentSubscription";
import CustomSwitch from "../components/Account/payment/CustomSwitch";
import BackArrow from "../components/Chat/backArrow";
import RenderImage from "../components/Common/renderImage";
import { colors } from "../constants/colors";
import { RouteNames } from "../constants/routeNames";
import { AuthContext } from "../provider/AuthProvider";
import ActiveSubscription from "../screens/account/ActiveSubscriptionPage";
import Subscription from "../screens/account/Subscription";
import Loader from "../screens/utils/Loading";
import { getSubscriptions } from "./helper";

export default function UpgradePlan({ navigation, route }: any) {
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;
  const [isRegistered, setIsRegistered] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userData } = useContext(AuthContext);
  const { refresh } = route?.params;

  const onSelectSwitch = (index: any) => {
    if (index === 1) {
      setPlansInView(monthlySubscriptions);
      setPeriod("month");
    } else {
      setPlansInView(yearlySubscriptions);
      setPeriod("year");
    }
  };
  const [monthlySubscriptions, setMonthlySubscriptions] = useState([]);
  const [yearlySubscriptions, setYearlySubscriptions] = useState([]);
  const [plansInView, setPlansInView] = useState<any>([]);
  const [period, setPeriod] = useState("month");
  const [registeredCompany, setRegisteredCompany] = useState("");
  const [allCompanies, setAllCompanies] = useState<any>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<any>(null);
  const [selectedCoupon, setSelectedCoupon] = useState<any>(null);
  const [activeSubscription, setActiveSubscription] = useState<any>(null);

  const paymentService = Container.get(PaymentSubscriptionService);

  useEffect(() => {
    getSubscriptions(
      userData,
      paymentService,
      setMonthlySubscriptions,
      setYearlySubscriptions,
      setPlansInView,
      setActiveSubscription,
      setAllCompanies,
    ).then(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (refresh) {
      setYearlySubscriptions([]);
      setMonthlySubscriptions([]);
      setPlansInView([]);
      setActiveSubscription(null);
      setLoading(true);
      getSubscriptions(
        userData,
        paymentService,
        setMonthlySubscriptions,
        setYearlySubscriptions,
        setPlansInView,
        setActiveSubscription,
        setAllCompanies,
      ).then(() => setLoading(false));
    }
  }, [refresh]);

  const handleSelectPlan = (plan: any) => {
    navigation.navigate(RouteNames.SUBSCRIPTION_CONFIRMATION, {
      subscription: plan,
      period: period,
      registeredCompany: registeredCompany,
      selectedCoupon: selectedCoupon,
    });
  };

  const handleGoBack = () => {
    navigation.reset({
      index: 0,
      routes: [{ name: RouteNames.ACCOUNT_MAIN }],
    });
  };
  const handleUpgrade = () => {
    setActiveSubscription(null);
  };
  return loading ? (
    <Loader />
  ) : !isTablet ? (
    <Subscription navigation={navigation} />
  ) : (
    <View
      style={{
        flex: 1,
        alignContent: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
      }}
    >
      <View
        style={{
          backgroundColor: "#fff",
          flex: 1,
          width: "100%",
          height: "95%",
          overflow: "hidden",
          position: "absolute",
        }}
      >
        <LinearGradient
          style={{
            borderRadius: 50,
            width: "100%",
            height: "100%",
            backgroundColor: "transparent",
            overflow: "scroll",
            flex: 1,
            flexDirection: "row",
            paddingRight: 24,
            paddingBottom: 24,
          }}
          locations={[0, 1]}
          colors={["#F7F3F9", "#F7F3F9"]}
        >
          {activeSubscription ? (
            <View
              style={{
                marginHorizontal: "auto",
                width: "100%",
              }}
            >
              <BackArrow
                handleGoBackCallback={handleGoBack}
                title={"Account"}
              />
              <ActiveSubscription
                subscription={activeSubscription}
                period={period}
                handleUpgrade={handleUpgrade}
              />
            </View>
          ) : (
            <>
              <View>
                <BackArrow
                  handleGoBackCallback={handleGoBack}
                  title={"Account"}
                />
                <RenderImage
                  uri={LOGO_URI}
                  style={{
                    width: 150,
                    height: 100,
                  }}
                />
                <View
                  style={{
                    width: 370,
                    marginLeft: 30,
                  }}
                >
                  <Text style={{ fontSize: 25, fontWeight: "700" }}>
                    Phyxable Plus Experience
                  </Text>
                  <Text
                    style={{
                      paddingTop: 36,
                      fontSize: 16,
                      fontWeight: "400",
                    }}
                  >
                    Phyxable brings high quality guided video solutions onto
                    your mobile device for you to tackle at your own pace, in
                    the comfort of your own space.
                  </Text>
                </View>

                {/* <View>
                  <Text
                    style={{
                      fontWeight: "600",
                      fontSize: 16,
                      color: "#41455D",
                      marginTop: 16,
                      paddingTop: 16,
                      marginLeft: 32,
                    }}
                  >
                    Is your company registered with Phyxable?
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      alignSelf: "flex-start",
                      marginLeft: 32,
                      marginTop: 16,
                      justifyContent: "space-evenly",
                    }}
                  >
                    <RadioButton
                      value={isRegistered === (true as any)}
                      onValueChange={(val) => setIsRegistered(true)}
                    />
                    <Text style={{ marginLeft: 10, color: "gray" }}>Yes</Text>
                    <RadioButton
                      value={isRegistered === (false as any)}
                      onValueChange={(val) => setIsRegistered(false)}
                      style={{ marginLeft: 20 }}
                    />
                    <Text style={{ marginLeft: 10, color: "gray" }}>No</Text>
                  </View>
                  <View
                    style={
                      isRegistered
                        ? { display: "flex", marginTop: 15, marginLeft: 32 }
                        : { display: "none" }
                    }
                  >
                    <Searchbar
                      placeholder="Search by company name"
                      onChangeText={(_string) => {
                        setFilteredCompanies(_string);
                      }}
                      value={filteredCompanies || registeredCompany}
                      style={{
                        width: 300,
                        backgroundColor: "#fff",
                        borderRadius: 16,
                      }}
                    />
                    <FlatList
                      data={
                        !filteredCompanies
                          ? null
                          : allCompanies.filter((item: any) =>
                              item.code
                                .toLowerCase()
                                .includes(filteredCompanies.toLowerCase())
                            )
                      }
                      renderItem={({ item }) => (
                        <List.Item
                          title={item.code}
                          onPress={() => {
                            setFilteredCompanies(null);
                            setRegisteredCompany(item.code);
                          }}
                        />
                      )}
                    />
                  </View>
                </View> */}
              </View>
              <View style={{ width: "auto", marginLeft: "auto" }}>
                {/* Switch */}
                <View
                  style={{
                    marginHorizontal: "auto",
                    marginTop: "5%",
                  }}
                >
                  <CustomSwitch
                    selectionMode={1}
                    roundCorner={true}
                    option1={"MONTHLY"}
                    option2={"ANNUALLY"}
                    onSelectSwitch={onSelectSwitch}
                    selectionColor={"#18214D"}
                    navigation={undefined}
                  />
                </View>

                <View
                  style={{
                    width: "100%",
                    flex: 1,
                    flexDirection: "row",
                    marginTop: 39,
                    minHeight: "auto",
                  }}
                >
                  {/* left card */}
                  <View
                    style={{
                      backgroundColor: "#fff",
                      width: 328,
                      height: "auto",
                      borderRadius: 20,
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        marginTop: 44,
                        fontWeight: "700",
                        fontSize: 24,
                      }}
                    >
                      Self Guided
                    </Text>
                    <Text
                      style={{
                        textAlign: "center",
                        fontWeight: "700",
                        fontSize: 24,
                      }}
                    >
                      $
                      {
                        plansInView.plans?.filter((item: any) =>
                          item.type.toLowerCase().includes("self"),
                        )[0]?.price
                      }{" "}
                      / {period}
                    </Text>
                    <View
                      style={{
                        marginLeft: 24,
                        marginTop: 24,
                        width: 250,
                      }}
                    >
                      <FlatList
                        data={
                          plansInView.plans?.filter((item: any) =>
                            item.type.toLowerCase().includes("self"),
                          )[0]?.points
                        }
                        renderItem={({ item }) => (
                          <View
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              paddingBottom: 10,
                            }}
                          >
                            <MaterialCommunityIcons
                              name="checkbox-blank-circle"
                              size={15}
                              color="#3EA65C"
                              style={{ marginVertical: "auto" }}
                            />
                            <Text style={{ marginLeft: 30 }}>{item}</Text>
                          </View>
                        )}
                      />
                    </View>
                    <View style={{ flex: 1, justifyContent: "flex-end" }}>
                      <TouchableOpacity
                        style={[styles.button, { width: 280 }]}
                        onPress={() =>
                          handleSelectPlan(
                            plansInView.plans?.filter((item: any) =>
                              item.type.toLowerCase().includes("self"),
                            )[0],
                          )
                        }
                      >
                        <Text
                          style={{
                            color: "white",
                            fontSize: 12,
                            fontWeight: "700",
                          }}
                        >
                          Choose Plan
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>

                  {/* right card */}
                  <View
                    style={{
                      backgroundColor: "#fff",
                      width: 328,
                      height: "auto",
                      borderRadius: 20,
                      marginLeft: 16,
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <TouchableOpacity
                      style={[styles.buttonLabel, { width: 150 }]}
                    >
                      <Text
                        style={{
                          color: "white",
                          fontSize: 16,
                          fontWeight: "700",
                        }}
                      >
                        BEST VALUE
                      </Text>
                    </TouchableOpacity>
                    <Text
                      style={{
                        textAlign: "center",
                        marginTop: 44,
                        fontWeight: "700",
                        fontSize: 24,
                      }}
                    >
                      Practitioner Guided
                    </Text>
                    <Text
                      style={{
                        textAlign: "center",
                        fontWeight: "700",
                        fontSize: 24,
                      }}
                    >
                      $
                      {
                        plansInView.plans?.filter((item: any) =>
                          item.type.toLowerCase().includes("practitioner"),
                        )[0]?.price
                      }{" "}
                      / {period}
                    </Text>
                    <View
                      style={{
                        marginLeft: 24,
                        marginTop: 24,
                        width: 250,
                      }}
                    >
                      <FlatList
                        data={
                          plansInView.plans?.filter((item: any) =>
                            item.type.toLowerCase().includes("practitioner"),
                          )[0]?.points
                        }
                        renderItem={({ item }) => (
                          <View
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              paddingBottom: 10,
                            }}
                          >
                            <MaterialCommunityIcons
                              name="checkbox-blank-circle"
                              size={15}
                              color="#3EA65C"
                              style={{ marginVertical: "auto" }}
                            />
                            <Text style={{ marginLeft: 30 }}>{item}</Text>
                          </View>
                        )}
                      />
                    </View>
                    <View style={{ flex: 1, justifyContent: "flex-end" }}>
                      <TouchableOpacity
                        style={[styles.button, { width: 280 }]}
                        onPress={() =>
                          handleSelectPlan(
                            plansInView.plans?.filter((item: any) =>
                              item.type.toLowerCase().includes("practitioner"),
                            )[0],
                          )
                        }
                      >
                        <Text
                          style={{
                            color: "white",
                            fontSize: 12,
                            fontWeight: "700",
                          }}
                        >
                          Choose Plan
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    marginTop: 24,
                  }}
                >
                  <TextInput
                    placeholder="Enter Promo code"
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: 16,
                      marginHorizontal: "auto",
                      width: 328,
                      paddingVertical: 12,
                      paddingLeft: 16,
                      paddingRight: 86,
                      height: 48,
                    }}
                    value={selectedCoupon || ""}
                    onChangeText={(text) => setSelectedCoupon(text)}
                  ></TextInput>
                  <View style={{ marginBottom: 16 }}>
                    <TouchableOpacity
                      style={{
                        position: "relative",
                        right: 212,
                        bottom: 0,
                        backgroundColor: "#18214D",
                        borderRadius: 10,
                        width: 67,
                        height: 32,
                        marginTop: 10,
                      }}
                    >
                      <Text
                        style={{
                          color: "white",
                          fontSize: 12,
                          fontWeight: "600",
                          marginHorizontal: "auto",
                          marginVertical: "auto",
                        }}
                      >
                        APPLY
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </>
          )}
        </LinearGradient>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    borderRadius: 24,
    backgroundColor: colors.primaryColor,
    alignSelf: "center",
    marginTop: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    paddingVertical: 10,
    marginBottom: 20,
  },
  buttonLabel: {
    backgroundColor: colors.orange,
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    paddingVertical: 10,
    marginBottom: 20,
    position: "absolute",
    right: 89,
    zIndex: 1,
    width: 150,
    height: 42,
    top: "-3%",
    borderRadius: 5,
  },
});
