import { NO_CAMERA } from "@env";
import { Ionicons } from "@expo/vector-icons";
import { useIsFocused } from "@react-navigation/native";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Image, PermissionsAndroid, Platform, View } from "react-native";
import { Text } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import {
  TwilioVideo,
  TwilioVideoLocalView,
} from "react-native-twilio-video-webrtc";
import { RouteNames } from "../../constants/routeNames";
import { AuthContext } from "../../provider/AuthProvider";
import Loader from "../../screens/utils/Loading";
import ParticipantMobile from "./components/ParticipantMobile";

export default function MobileVideoCall({ navigation, data, token }: any) {
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [videoTracks, setVideoTracks] = useState(new Map());
  const [_token, setToken] = useState(token);
  const [connectedStatus, setConnectedStatus] = useState<ConnectionStatus>();
  const twilioRef = useRef(null) as any;
  const isFocused = useIsFocused();
  const { userData } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [expandedView, setExpandedView] = useState<string | null>(null);

  useEffect(() => {
    if (connectedStatus === ConnectionStatus.DISCONNECTED && isFocused) {
      handleConnect().then(() => {
        setConnectedStatus(ConnectionStatus.CONNECTED);
        setLoading(false);
      });
    }
  }, [isFocused]);

  useEffect(() => {
    setToken(token);
    handleConnect().then(() => {
      twilioRef.current.setLocalVideoEnabled(true);
      twilioRef.current.setLocalAudioEnabled(true);
      setConnectedStatus(ConnectionStatus.CONNECTED);
      setLoading(false);
    });
  }, []);

  const handleConnect = async () => {
    if (Platform.OS === "android") {
      await _requestAudioPermission();
      await _requestCameraPermission();
    }
    twilioRef.current.connect({
      accessToken: token,
      dominantSpeakerEnabled: true,
      enableAudio: true,
      enableVideo: true,
    });
    setConnectedStatus(ConnectionStatus.CONNECTING);
  };

  const _onEndButtonPress = () => {
    twilioRef.current.unpublishLocalAudio();
    twilioRef.current.unpublishLocalVideo();
    twilioRef.current.disconnect();
    navigation.reset({
      index: 0,
      routes: [
        {
          name: RouteNames.CALL_FEEDBACK,
          params: { roomName: data.room_id, participants: videoTracks },
        },
      ],
    });
  };

  const _onMuteButtonPress = () => {
    twilioRef.current
      .setLocalAudioEnabled(!isAudioEnabled)
      .then((isEnabled: boolean) => setIsAudioEnabled(isEnabled));
  };

  const _onVideoHideButtonPress = () => {
    twilioRef.current
      .setLocalVideoEnabled(!isVideoEnabled)
      .then((isEnabled: boolean) => setIsVideoEnabled(isEnabled));
  };

  const _onFlipButtonPress = () => {
    twilioRef.current.flipCamera();
  };

  const _onRoomDidConnect = ({ roomName, error }: any) => {
    console.log("onRoomDidConnect: ", roomName);
    setConnectedStatus(ConnectionStatus.CONNECTED);
  };

  const _onRoomDidDisconnect = ({ roomName, error }: any) => {
    console.log("[Disconnect]ERROR: ", error);
    setConnectedStatus(ConnectionStatus.DISCONNECTED);
    navigation.goBack();
  };

  const _onRoomDidFailToConnect = (error: any) => {
    console.log("[FailToConnect]ERROR: ", error);
    setConnectedStatus(ConnectionStatus.FAILED);
  };

  const _onParticipantAddedVideoTrack = ({ participant, track }: any) => {
    console.log("onParticipantAddedVideoTrack: ", participant, track);
    setVideoTracks(
      new Map([
        ...videoTracks,
        [
          track.trackSid,
          {
            participantSid: participant.sid,
            videoTrackSid: track.trackSid,
            participantIdentity: participant.identity,
          },
        ],
      ]),
    );
  };
  const insets = useSafeAreaInsets();

  const _onParticipantRemovedVideoTrack = ({ participant, track }: any) => {
    videoTracks.delete(track.trackSid);
    setVideoTracks(new Map(videoTracks));
  };

  const totalParticipants = videoTracks.size === 0 ? 2 : videoTracks.size + 1; // +1 for the local participant

  return (
    <>
      <View style={{ flex: 0.94, height: "100%" }}>
        {/* Local Participant */}
        {connectedStatus == ConnectionStatus.CONNECTED && !loading ? (
          isVideoEnabled ? (
            <View
              style={{
                flex:
                  expandedView === ParticipantType.LOCAL
                    ? 1
                    : 1 / totalParticipants,
                width: "100%",
                alignItems: "center",
                height:
                  expandedView === ParticipantType.LOCAL
                    ? "100%"
                    : `${100 / totalParticipants}%`,
                position:
                  expandedView === ParticipantType.LOCAL
                    ? "absolute"
                    : "relative",
                zIndex: expandedView === ParticipantType.LOCAL ? 1000 : 1,
              }}
            >
              <View
                style={{
                  position: "absolute",
                  top: insets.top + 10,
                  right: 10,
                  borderRadius: 100,
                  backgroundColor: "#1c1e21",
                  zIndex: 100,
                }}
              >
                <View
                  style={{
                    borderRadius: 100,
                    padding: 8,
                    backgroundColor: "#1c1e21",
                  }}
                >
                  <Ionicons
                    name={
                      expandedView === "local" ? "md-contract" : "md-expand"
                    }
                    size={16}
                    color="white"
                    onPress={() => {
                      if (expandedView === "local") {
                        setExpandedView(null);
                      } else {
                        setExpandedView(ParticipantType.LOCAL);
                      }
                    }}
                  />
                </View>
              </View>
              <TwilioVideoLocalView
                enabled={true}
                scaleType={"fill"}
                style={{
                  flex: 1,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "black",
                }}
              />
              <Text
                style={{
                  position: "absolute",
                  bottom: 5,
                  right: 5,
                  color: "white",
                  backgroundColor: "#1c1e21",
                  padding: 10,
                  zIndex: 100,
                  borderRadius: 100,
                  minWidth: 50,
                  textAlign: "center",
                }}
              >
                Me
              </Text>
            </View>
          ) : (
            <View
              style={{
                flex: 1 / totalParticipants,
                width: "100%",
                height: `${100 / totalParticipants}%`,
                backgroundColor: "#1c1e21",
              }}
            >
              <Image
                resizeMethod="scale"
                resizeMode="contain"
                style={{
                  width: "100%",
                  height: "100%",
                }}
                source={{ uri: userData?.photoUrl || NO_CAMERA }}
              />
            </View>
          )
        ) : (
          <Loader />
        )}
        {videoTracks.size === 0 && (
          <View
            style={{
              flex: 1 / totalParticipants,
              width: "100%",
              alignItems: "center",
              height: `${100 / totalParticipants}%`,
              position: "relative",
              zIndex: 1,
            }}
          >
            <Text
              style={{
                position: "absolute",
                bottom: 5,
                right: 5,
                color: "white",
                backgroundColor: "#1c1e21",
                padding: 10,
                zIndex: 100,
                borderRadius: 100,
                minWidth: 50,
                textAlign: "center",
              }}
            >
              Waiting for participant...
            </Text>
          </View>
        )}
        {/* Remote Participants */}
        {Array.from(videoTracks.keys()).map((remoteTrackSid) => {
          return loading ? (
            <Loader />
          ) : (
            <ParticipantMobile
              participantSid={videoTracks.get(remoteTrackSid)?.participantSid}
              videoTrackSid={remoteTrackSid}
              uuid={videoTracks.get(remoteTrackSid)?.participantIdentity || ""}
              style={{
                flex:
                  expandedView === remoteTrackSid ? 1 : 1 / totalParticipants,
                width: "100%",
                position:
                  expandedView === remoteTrackSid ? "absolute" : "relative",
                height:
                  expandedView === remoteTrackSid
                    ? "100%"
                    : `${100 / totalParticipants}%`,
                zIndex: expandedView === remoteTrackSid ? 1000 : 1,
              }}
              extendButton={
                <View
                  style={{
                    position: "absolute",
                    top: expandedView === remoteTrackSid ? insets.top + 10 : 10,
                    right: 10,
                    zIndex: 100,
                    flexDirection: "row",
                    gap: 20,
                  }}
                >
                  <View
                    style={{
                      borderRadius: 100,
                      padding: 8,
                      backgroundColor: "#1c1e21",
                    }}
                  >
                    <Ionicons
                      name={
                        expandedView === remoteTrackSid
                          ? "md-contract"
                          : "md-expand"
                      }
                      size={16}
                      color="white"
                      onPress={() => {
                        if (expandedView === remoteTrackSid) {
                          setExpandedView(null);
                          setIsVideoEnabled(true);
                        } else {
                          setIsVideoEnabled(false);
                          setExpandedView(remoteTrackSid);
                        }
                      }}
                    />
                  </View>
                </View>
              }
            />
          );
        })}

        <TwilioVideo
          ref={twilioRef}
          onRoomDidConnect={_onRoomDidConnect}
          onRoomDidDisconnect={_onRoomDidDisconnect}
          onRoomDidFailToConnect={_onRoomDidFailToConnect}
          onParticipantAddedVideoTrack={_onParticipantAddedVideoTrack}
          onParticipantRemovedVideoTrack={_onParticipantRemovedVideoTrack}
          onDominantSpeakerDidChange={(e) => {
            console.log(e);
          }}
          autoInitializeCamera={true}
        />
      </View>
      {/* Buttons */}
      <View
        style={{
          position: "absolute",
          top: "94%",
          bottom: 0,
          alignItems: "center",
          left: 0,
          right: 0,
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <Ionicons
          name="call"
          size={30}
          color="red"
          onPress={() => {
            _onEndButtonPress();
          }}
        />
        <Ionicons
          name={isAudioEnabled ? "mic" : "mic-off"}
          size={30}
          color={"#fff"}
          onPress={() => {
            _onMuteButtonPress();
          }}
        />
        <Ionicons
          name={isVideoEnabled ? "videocam" : "videocam-outline"}
          size={30}
          color={"#fff"}
          onPress={() => {
            _onVideoHideButtonPress();
          }}
        />
        <Ionicons
          name="camera-reverse"
          size={30}
          color={"#fff"}
          onPress={() => {
            _onFlipButtonPress();
          }}
        />
      </View>
    </>
  );
}

export enum ConnectionStatus {
  CONNECTED = "connected",
  CONNECTING = "connecting",
  DISCONNECTED = "disconnected",
  RECONNECTING = "reconnecting",
  RECONNECTED = "reconnected",
  FAILED = "failed",
}

export enum ParticipantType {
  LOCAL = "local",
  REMOTE = "remote",
}

const _requestAudioPermission = () => {
  return PermissionsAndroid.request(
    PermissionsAndroid.PERMISSIONS.RECORD_AUDIO,
    {
      title: "Need permission to access microphone",
      message: "To run this App we need permission to access your microphone",
      buttonNegative: "Cancel",
      buttonPositive: "OK",
    },
  );
};

const _requestCameraPermission = () => {
  return PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.CAMERA, {
    title: "Need permission to access camera",
    message: "To run this App we need permission to access your camera",
    buttonNegative: "Cancel",
    buttonPositive: "OK",
  });
};
