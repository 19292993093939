import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import { Feather, FontAwesome5 } from "@expo/vector-icons";
import React, { useContext } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
import { Avatar } from "react-native-paper";
import { useTheme } from "react-native-rapi-ui";

import BackArrow from "../../components/Chat/backArrow";
import Messages from "../../components/Chat/Messages";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { AuthContext } from "../../provider/AuthProvider";

export default function Chat({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) {
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;
  const { isDarkmode, setTheme } = useTheme();
  const { chatRoom, participants, patient_id } = route.params;
  const handleGoBack = () => {
    navigation.navigate("Inbox");
  };
  const { userData, setUserLocalData } = useContext(AuthContext);

  if (!chatRoom?.participants?.length) {
    navigation.navigate("Inbox");
  }

  return (
    <ActionSheetProvider>
      <SafeAreaView style={styles.container}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={{ flex: 1 }}
        >
          <BackArrow
            handleGoBackCallback={handleGoBack}
            title="Back To Inbox"
          />
          <View style={styles.chatHeader}>
            <View style={styles.rowLayout}>
              <View style={styles.headerLeft}>
                <View style={styles.profilePic}>
                  <Avatar.Image
                    source={require("../../../assets/images/logo.png")}
                    size={40}
                  />
                </View>
                <View style={styles.pracColumn}>
                  {chatRoom?.participants?.length && (
                    <>
                      <Text
                        style={{
                          color: "#2E3940",
                          fontWeight: "600",
                          fontSize: 16,
                          //fontFamily: "Proxima Nova",
                        }}
                      >
                        {participants.filter((p: any) => p.id !== patient_id)
                          .length
                          ? participants.filter(
                            (p: any) => p.id !== patient_id,
                          )[0].userProfile.name
                          : ""}
                      </Text>
                      <Text
                        style={{
                          color: "#2E3940",
                          fontWeight: "600",
                          fontSize: 12,
                          //fontFamily: "Proxima Nova",
                        }}
                      >
                        {participants.filter((p: any) => p.id !== patient_id)
                          .length
                          ? participants.filter(
                            (p: any) => p.id !== patient_id,
                          )[0].userProfile.email
                          : ""}
                      </Text>
                    </>
                  )}
                </View>
              </View>
              <View style={styles.pracOptions}>
                <View
                  style={[styles.iconContainer, { gap: isTablet ? 64 : 16 }]}
                >
                  <TouchableOpacity
                    style={[
                      isTablet ? styles.iconSm : styles.iconLg,
                      styles.imagesIcon,
                    ]}
                    onPress={() => {
                      navigation.navigate("Media", {
                        chatRoom: chatRoom,
                        participants: participants,
                        patient_id: patient_id,
                      });
                    }}
                  >
                    <FontAwesome5
                      name="folder-open"
                      size={isTablet ? 24 : 32}
                      color="#18214D"
                    />
                    {isTablet && (
                      <Text style={[styles.iconText, { color: "#18214D" }]}>
                        SHARED MEDIA
                      </Text>
                    )}
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[
                      isTablet ? styles.iconSm : styles.iconLg,
                      styles.videoIcon,
                    ]}
                    onPress={() => {
                      navigation.navigate(RouteNames.CALL_MAIN, {
                        screen: RouteNames.VIDEO_CALL,
                        params: {
                          roomName: chatRoom._id,
                          userName: userData?.userProfile?.name,
                        },
                      });
                    }}
                  >
                    <Feather
                      name="video"
                      size={isTablet ? 24 : 32}
                      color="white"
                    />
                    {isTablet && (
                      <Text style={[styles.iconText, { color: "white" }]}>
                        VIDEO CALL
                      </Text>
                    )}
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.messages}>
            <Messages
              chatRoom={chatRoom}
              participants={participants}
              patient_id={patient_id}
            />
          </View>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </ActionSheetProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "#FFF",
  },
  chatHeader: {
    top: 0,
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    width: "100%",
    height: 100,
    borderTopWidth: 2,
    borderTopColor: "rgba(108, 121, 147, 0.1)",
    borderBottomWidth: 2,
    borderBottomColor: "rgba(108, 121, 147, 0.1)",
  },
  rowLayout: {
    flex: 2,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerLeft: {
    flexDirection: "row",
    height: "100%",
    marginHorizontal: 5,
  },
  profilePic: {
    marginHorizontal: 3,
    justifyContent: "center",
    alignItems: "center",
  },
  pracColumn: {
    height: "100%",
    width: "auto",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    padding: 3,
  },
  pracOptions: {
    width: "auto",
    height: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  iconContainer: {
    height: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginHorizontal: 5,
  },
  iconLg: {
    width: 52,
    height: 52,
    borderRadius: 26,
  },
  iconSm: {
    width: "auto",
    height: 40,
    borderRadius: 20,
    flexDirection: "row",
    paddingHorizontal: 20,
  },
  imagesIcon: {
    backgroundColor: "rgba(24, 33, 77, 0.1)",
    justifyContent: "center",
    alignItems: "center",
    margin: 5,
  },
  videoIcon: {
    flexDirection: "row",
    backgroundColor: "#74D173",
    justifyContent: "center",
    alignItems: "center",
    margin: 5,
  },
  iconText: {
    marginLeft: 8,
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 16,
  },
  messages: {
    flexGrow: 1,
    backgroundColor: "#FFF",
  },
});
