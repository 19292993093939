import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import UserVitals from "../screens/home/UserVitals";
import { HomeStackParamList } from "../types/navigation";
import HomeScreenWeb from "../webScreens/screens/HomeScreenWeb";

const homeStack = createNativeStackNavigator<HomeStackParamList>();

export default function HomeStackWeb() {
  return (
    <homeStack.Navigator
      screenOptions={{
        headerShown: true,
        headerBackButtonMenuEnabled: true,
        headerTitle: "",
      }}
      initialRouteName="Phyxable"
    >
      <homeStack.Screen
        name="Phyxable"
        component={HomeScreenWeb}
        options={{
          headerShown: false,
        }}
      />
      <homeStack.Screen
        name="Vitals"
        component={UserVitals}
        options={{
          headerShown: false,
        }}
      />
    </homeStack.Navigator>
  );
}
