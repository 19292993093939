import React from "react";
import { View } from "react-native";
import { Text } from "react-native-rapi-ui";

export default function EmptyParticipant({ identity, width, height }: any) {
  return (
    <View
      key={Math.random()}
      style={{
        flex: 1,
        width: width,
        height: height,
        marginLeft: 5,
        marginRight: 5,
        marginTop: 5,
        borderRadius: 12,
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      }}
    >
      <Text
        style={{
          position: "absolute",
          bottom: 5,
          right: 5,
          color: "white",
          backgroundColor: "#1c1e21",
          padding: 10,
          zIndex: 10000,
          borderRadius: 100,
          minWidth: 50,
          textAlign: "center",
        }}
      >
        {identity}
      </Text>
    </View>
  );
}
