export enum RouteNames {
  DEBUG = "Debug",
  HOME = "Home",
  USER_VITALS = "User Vitals",
  USER_VITALS_DETAIL = "User Vitals Detail",
  USER_VITALS_ADD = "User Vitals Add",
  USER_VITALS_EDIT = "User Vitals Edit",
  USER_AUTH_TOKEN = "User Auth Token",
  ACCOMPLISHMENTS = "Accomplishments",
  UPGRADE_PLAN = "Upgrade Plan",
  LOGOUT = "Logout",
  NOTIFICATIONS = "Notifications",

  // Auth
  Login = "Login",
  Register = "Register",
  ForgotPassword = "ForgotPassword",
  ResetPassword = "ResetPassword",

  //Program
  PROGRAM_MAIN = "Programs",
  PROGRAM_DETAIL = "Program Detail",
  CUSTOMPHYX_DETAIL = "Custom Program Details",
  VAS = "VAS",
  VAS_DETAIL = "VAS Detail",
  VAS_DETAIL_2 = "VAS Detail 2",
  INTERSTITIAL = "Interstitial",
  PROGRAM_CONFIRMATION = "Program Confirmation",

  // Custom
  CUSTOMPHYX_CONFIRMATION = "Custom Program Confirmation",
  PROGRAM_MAIN_DASHBOARD = "Program",
  PROGRAM_ALL = "All Programs",
  PROGRAM_OVERVIEW = "Program Overview",
  CUSTOMPHYX_OVERVIEW = "Custom Program Overview",
  CUSTOM_EXERCISE = "Custom Exercise",
  CUSTOM_STOP_INTERSTITIAL = "CustomStopInterstitial",
  CUSTOM_PLAYLIST = "Custom Playlist",
  CUSTOM_VAS = "Custom VAS",
  CUSTOM_PSFS = "Custom PSFS",
  CUSTOM_QUESTIONNAIRE = "Custom Questionnaire",

  // Common
  STOP_INTERSTITIAL = "Stop Interstitial",
  PSFS = "PSFS",
  PLAYLIST = "Playlist",
  EXERCISE = "Exercise",
  QUESTIONNAIRE = "Questionnaire",
  CONGRATULATIONS = "Congratulations",

  //Account
  ACCOUNT_MAIN = "Account",
  ACCOUNT_DETAIL = "Account Detail",
  PAYMENT_INFO = "Payment Info",
  PAYMENT = "Payment",
  PAYMENT_SUCCESS = "Payment Success",
  PAYMENT_FAILURE = "Payment Failure",
  SUBSCRIPTION = "Subscription",
  SUBSCRIPTION_CONFIRMATION = "Subscription Confirmation",
  NOTIFICATION_SETTINGS_MAIN = "Notification Settings",
  NOTIFICATION_SETTINGS = "Notification Setting",
  ADD_CARD = "Add Card",
  REDIRECT = "redirect",
  PROGRAM_REMINDERS = "Program Reminders",
  ADD_EDIT_PROGRAM_REMINDER = "Add Edit Program Reminder",
  GIVE_FEEDBACK = "Give Feedback",

  //Call
  CALL_MAIN = "Calls",
  CALL_FEEDBACK = "Call Feedback",
  VIDEO_CALL = "Video Call",
  CALL_INITIATE = "Call Initiate",
  CALL_INCOMING = "Call Incoming",
  CALL_OUTGOING = "Call Outgoing",
  CALL_IN_PROGRESS = "Call In Progress",
  CALL_END = "Call End",
  CALL_JOIN = "Room",

  //Messages
  CHAT_MAIN = "Chat",
  INBOX = "Inbox",
  MEDIA = "Media",
  MESSAGES = "Messages",
  COMPOSE_MESSAGE = "Compose Message",
}
