import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import React, { useContext, useEffect, useState } from "react";
import { Platform, TouchableOpacity, View } from "react-native";
import { FlatList, ScrollView } from "react-native-gesture-handler";
import { ActivityIndicator, Surface, Text } from "react-native-paper";
import Container from "typedi";
import { PaymentSubscriptionService } from "../../../../services/paymentSubscription/paymentSubscription";
import { UserService } from "../../../../services/user/user.service";
import ApplePay from "../../../components/Account/payment/ApplePay";
import PaymentCard from "../../../components/Account/payment/paymentCard";
import { colors } from "../../../constants/colors";
import { RouteNames } from "../../../constants/routeNames";
import { AuthContext } from "../../../provider/AuthProvider";
import Loader from "../../utils/Loading";

export default function SubscriptionConfirmation({ navigation, route }: any) {
  const { subscription, period, refresh, registeredCompany, selectedCoupon } =
    route.params;
  const { userData } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<any>(
    []
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const userService = Container.get(UserService);
  const paymentService = Container.get(PaymentSubscriptionService);

  useEffect(() => {
    const getPaymentMethods = async () => {
      const paymentMethods = await paymentService.getPaymentMethods(
        userData?.id as string
      );
      setAvailablePaymentMethods(paymentMethods?.data?.data);
      return paymentMethods?.data?.data;
    };
    getPaymentMethods().then((data) => {
      data.map((paymentMethod: any) => {
        if (paymentMethod.default) {
          setSelectedPaymentMethod(paymentMethod);
        }
      });
      if (data.length == 0) {
        navigation.navigate(RouteNames.ADD_CARD, {
          redirect: {
            subscription: subscription,
            period: period,
          },
        });
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (refresh) {
      const getPaymentMethods = async () => {
        const paymentMethods = await paymentService.getPaymentMethods(
          userData?.id as string
        );
        setAvailablePaymentMethods(paymentMethods?.data?.data);
        return paymentMethods?.data?.data;
      };
      getPaymentMethods().then((data) => {
        data.map((paymentMethod: any) => {
          if (paymentMethod.default) {
            setSelectedPaymentMethod(paymentMethod);
          }
        });
        setLoading(false);
      });
    }
  }, [refresh]);

  const ApplePayCallBack = async (paymentMethod: any) => {
    console.log(paymentMethod);
    if (paymentMethod != null) {
      setSelectedPaymentMethod(paymentMethod);
      handleStartSubscription(paymentMethod);
    }
  };

  const handlePickedPaymentMethod = (paymentMethod: any) => {
    setSelectedPaymentMethod(paymentMethod);
  };
  const handleStartSubscription = async (useSelectedPaymentMethod?: any) => {
    console.log(useSelectedPaymentMethod);
    setError(null);
    setLoadingButton(true);
    if (selectedPaymentMethod == null && useSelectedPaymentMethod == null) {
      setError("Please select a payment method or add a new one");
      setLoadingButton(false);
      return;
    }
    const res = await paymentService.createSubscription(
      userData?.id as string,
      selectedPaymentMethod?.id || useSelectedPaymentMethod?.id,
      subscription.priceId,
      subscription.product,
      selectedCoupon,
      registeredCompany
    );
    if (res?.status === 200) {
      setLoadingButton(false);
      navigation.navigate(RouteNames.SUBSCRIPTION, {
        refresh: true,
      });
    } else {
      setLoadingButton(false);
      setError("Please try again later");
    }
  };
  return loading ? (
    <Loader />
  ) : (
    <LinearGradient
      style={{
        flex: 1,
        width: "100%",
        height: "100%",
        alignItems: "center",
        paddingLeft: 20,
        paddingBottom: 20,
      }}
      locations={[0, 1]}
      colors={[colors.buttonWhite, colors.buttonWhite]}
    >
      <ScrollView>
        <View
          style={{
            padding: 10,
          }}
        >
          <Text
            style={[
              {
                fontSize: 24,
                fontWeight: "600",
                margin: 10,
              },
              {
                color: colors.blueColor,
              },
            ]}
          >
            Available Payment Methods
          </Text>
          <FlatList
            horizontal={true}
            ListEmptyComponent={() => (
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 16,
                  fontWeight: "700",
                  margin: 20,
                  color: colors.blueColor,
                }}
              >
                No Payment Methods Available
              </Text>
            )}
            data={availablePaymentMethods}
            renderItem={({ item }) => (
              <TouchableOpacity onPress={() => handlePickedPaymentMethod(item)}>
                <PaymentCard
                  paymentMethodId={item.id}
                  cardNumber={item.card.last4}
                  cardBrand={item.card.brand}
                  expirationDate={
                    item.card.exp_month + "/" + item.card.exp_year
                  }
                  handleDelete={null}
                  handleDefault={null}
                  defaultCard={item.default}
                  selected={selectedPaymentMethod?.id === item.id}
                />
              </TouchableOpacity>
            )}
            ListFooterComponent={() => (
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate(RouteNames.ADD_CARD, {
                      redirect: {
                        subscription: subscription,
                        period: period,
                      },
                    })
                  }
                  style={{
                    backgroundColor: colors.primaryColor,
                    padding: 10,
                    borderRadius: 5,
                    margin: 10,
                    width: 10,
                    height: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Ionicons
                    name="add"
                    size={16}
                    color="white"
                    style={{ alignSelf: "center" }}
                  />
                </TouchableOpacity>
              </View>
            )}
          />
        </View>

        <Surface
          style={{
            borderRadius: 24,
            marginTop: 20,
            elevation: 4,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            padding: 20,
          }}
        >
          <Text
            style={{
              marginTop: 5,
              fontWeight: "700",
              fontSize: 24,
            }}
          >
            {subscription.type}
          </Text>
          <Text
            style={{
              fontWeight: "700",
              fontSize: 24,
            }}
          >
            ${subscription.price} / {period}
          </Text>
          <View
            style={{
              marginLeft: 24,
              marginTop: 24,
              width: 250,
            }}
          >
            <FlatList
              data={subscription.points}
              renderItem={({ item }) => (
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    paddingBottom: 10,
                  }}
                >
                  <MaterialCommunityIcons
                    name="checkbox-blank-circle"
                    size={15}
                    color="#3EA65C"
                    style={{ marginVertical: "auto" }}
                  />
                  <Text style={{ marginLeft: 30 }}>{item}</Text>
                </View>
              )}
            />
          </View>
        </Surface>
        <View style={{ marginTop: 20 }}>
          <TouchableOpacity
            style={[
              {
                borderRadius: 24,
                backgroundColor: colors.primaryColor,
                alignSelf: "center",
                marginTop: 20,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
                paddingVertical: 10,
                marginBottom: 20,
              },
              ,
              { width: 280 },
            ]}
            onPress={() => handleStartSubscription()}
          >
            <Text
              style={{
                color: "white",
                fontSize: 12,
                fontWeight: "700",
                paddingBottom: 5,
              }}
            >
              {!loadingButton ? (
                "START SUBSCRIPTION"
              ) : (
                <ActivityIndicator color="white" />
              )}
            </Text>
          </TouchableOpacity>
          {Platform.OS === "ios" && (
            <ApplePay
              submitCallBack={ApplePayCallBack}
              subscriptionType={subscription.type}
              price={subscription.price}
              frequency={period}
            />
          )}
        </View>
        {error && (
          <Text
            style={{
              color: "red",
              fontSize: 12,
              fontWeight: "700",
            }}
          >
            {error}
          </Text>
        )}
      </ScrollView>
    </LinearGradient>
  );
}
