import { MaterialIcons } from "@expo/vector-icons";
import React, { useContext, useState } from "react";
import {
  Dimensions,
  ImageBackground,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Snackbar } from "react-native-paper";
import Container from "typedi";
import { UserService } from "../../../../services/user/user.service";
import Button from "../../../components/Home/button";
import { colors } from "../../../constants/colors";
import { RouteNames } from "../../../constants/routeNames";
import { AuthContext } from "../../../provider/AuthProvider";

const HEIGHT = Dimensions.get("window").width * (9 / 16);
export default function CustomPhyxConfirmation({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) {
  const [snackbarVisible, setSnackbarVisible] = useState(false);

  const { title, id, created_by } = route.params;
  const [loading, setLoading] = useState(true);

  const user = useContext(AuthContext);
  const userServices = Container.get(UserService);

  const handleGoBack = () => {
    navigation.goBack();
  };
  const handleJoinProgram = async () => {
    if (user.userData?.id === undefined) return;
    // await userServices.updateUserPhyxes(user.userData?.id as string, id);

    setSnackbarVisible(true);

    navigation.navigate(RouteNames.PROGRAM_MAIN);
  };

  return (
    <ScrollView style={{ backgroundColor: "white", height: "100%" }}>
      <ImageBackground
        source={{
          uri: "https://phyxableprograms.s3.amazonaws.com/mindfulness_phyx.jpg",
        }}
        resizeMode="cover"
        style={styles.image}
      >
        <TouchableOpacity style={styles.arrow} onPress={handleGoBack}>
          <MaterialIcons
            name="arrow-back-ios"
            size={20}
            color={colors.secondaryColor}
            style={{ width: 10 }}
          />
        </TouchableOpacity>
      </ImageBackground>
      <View style={styles.container}>
        <Text style={styles.title}>Join the Program?</Text>
        <Text style={styles.text}>
          Program: <Text style={styles.boldText}>{title}</Text>
        </Text>
        <Text style={styles.text}>
          Created by: <Text style={styles.boldText}>{created_by}</Text>
        </Text>
      </View>
      {/* Button view */}
      <View style={styles.button}>
        <Button
          text="CONFIRM"
          backgroundColor={colors.primaryColor}
          customStyle={{
            alignSelf: "center",
          }}
          onPressCallback={handleJoinProgram}
        />
      </View>
      <Snackbar
        visible={snackbarVisible}
        onDismiss={() => setSnackbarVisible(false)}
        duration={5000}
      >
        {title} added to your library
      </Snackbar>
    </ScrollView>
  );
}
const styles = StyleSheet.create({
  image: {
    width: "100%",
    height: HEIGHT,
  },
  arrow: {
    borderRadius: 8,
    width: 40,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    marginLeft: 20,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 5,
    backgroundColor: "white",
  },
  container: {
    paddingHorizontal: 20,
    paddingTop: 10,
  },
  title: {
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 28,
    marginBottom: 20,
  },
  text: {
    color: colors.secondaryColor,
    fontSize: 16,
    marginBottom: 10,
  },
  boldText: {
    color: colors.secondaryColor,
    fontSize: 16,
    fontWeight: "700",
  },
  sectionHeading: {
    fontWeight: "400",
    fontSize: 16,
    color: colors.secondaryColor,
    marginTop: 20,
    marginBottom: 10,
  },
  button: {
    justifyContent: "center",
    width: "100%",
    marginBottom: 20,
    marginTop: 10,
  },
});
