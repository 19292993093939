import React from "react";
import { Platform } from "react-native";
let PaymentComponent: any = null;
if (Platform.OS === "web") {
  PaymentComponent = require("./StripeWebCard").default;
} else {
  PaymentComponent = require("./StripeMobileCard").default;
}

export default function PaymentModule({
  client_secret,
  submitCallBack,
  navigation,
}: any) {
  return (
    <>
      <PaymentComponent
        client_secret={client_secret}
        submitCallBack={submitCallBack}
        navigation={navigation}
      />
    </>
  );
}
