export interface IProgram {
  id: string;
  categories: string[];
  equipment: string[];
  equipment_image: string[];
  id_: string;
  images: PhyxDataImages;
  level: number[];
  level1Blurb: string;
  level1BlurbB: string[];
  level1BlurbBTitle: string | null;
  level2Blurb: string | null;
  level2BlurbB: string[];
  level2BlurbBTitle: string | null;
  level3Blurb: string | null;
  level3BlurbB: string[];
  level3BlurbBTitle: string | null;
  often: string | null;
  overview: string;
  phyxes: Record<string, IPhyx[]>;
  priority: number | null;
  subtitle: string;
  title: string;
  type: ProgramType;
}

export type PhyxDataImages = {
  banner: string | null;
  continue: string;
  explore: string;
  exploredtop: string;
  home: string;
  nextSession: Record<
    string,
    {
      unlocked: string;
      locked: string;
    }
  > | null;
};

export interface VideoReps {
  type: "videoReps";
  session_title: string;
  videoUrl: string;
  buttonOneText: string;
  buttonOneType: string;
  sets: number;
  reps: number;
  category: string;
}

export interface VideoTimer {
  type: "videoTimers";
  videoUrl: string;
  buttonOneText: string;
  buttonOneType: string;
  session_title: string;
  sets: number;
  time: number;
  category: string;
}

export interface VideoIntro {
  type: "videoIntro";
  session: string[];
  blurb: string[];
  focustitle: string[];
  focustime: string[];
  focussets: number[];
  videoUrl: string;
  buttonOneText: string;
  buttonOneType: string;
  program: string;
}

interface Equipment {
  type: "equipment";
  image: string[];
  equipment: string[];
}

export interface Interstitial {
  type: "interstitial";
  blurb: string[];
  image: string;
  buttonOneText: string;
  buttonOneType: string;
}

interface Vas {
  type: "vas";
  blurb: string[];
  buttonOneType: string;
}

interface Questions {
  type: "questions";
  blurb: string[];
  answers: string[];
  image: string;
}

export enum ProgramType {
  Program = "Program",
  Challenge = "Challenge",
  Custom = "Custom",
}

export interface IPhyx {
  free: boolean;
  length: number;
  pages: ExerciseType[];
  session: number;
}

export type ExerciseType =
  | VideoReps
  | VideoIntro
  | Equipment
  | Interstitial
  | Vas
  | Questions;

export type CustomWorkouts = {
  _id: string;
  created_by: string;
  created_by_name: string | null;
  date: string | null;
  interstitial: string[];
  lastEdit: string | null;
  outcome_forms: IOutcomeForm[][] | null; // [session][]
  playlist: (CustomVideoReps | CustomVideoTimer)[][];
  program_name: string | null;
  program_overview: string | null;
  selected_users: CustomWorkoutsSelectedUsers[];
  showTemplate: boolean | null;
  timestamp: number | null;
  nSessions: number | null;
  type: ProgramType;
  isActiveForUser?: boolean | null;
};

export type IOutcomeForm = {
  questions: Array<any>;
  name: string;
  type: "VAS" | "PSFS";
  details: string;
  min: number;
  max: number;
  min_label: string;
  max_label: string;
};

export type CustomWorkoutsSelectedUsers = {
  dataVisible: boolean | null;
  email: string;
  name: string | null;
  patKey: string | null;
  requestedData: Object[] | null;
  uid: string | null;
};

export type PatientsPopup = {
  dateOpened?: String;
  dateSent?: String;
  popupOpened?: Boolean;
  popupPreAccept: Boolean;
  pracId: String;
  pracName: String;
  type: String;
  viaSessionComp?: Boolean;
};

export interface IVideo {
  title: string;
  description: string;
  kind: string;
  feedid: string;
  playlist: IPlayList[];
  feed_instance_id: string;
}

interface IImage {
  src: string; //"https://cdn.jwplayer.com/v2/media/MVOUOGNd/poster.jpg?width=320",
  width: number;
  type: "image/jpeg";
}

export interface IMp4 {
  file: string;
  type: "video/mp4";
  height: number;
  width: number;
  label: string;
  bitrate: number;
  filesize: number;
  framerate: number;
}

interface IM3u8 {
  file: string;
  type: "application/vnd.apple.mpegurl";
}

interface IPlayList {
  title: string;
  mediaid: string;
  link: string; // "https://cdn.jwplayer.com/previews/MVOUOGNd";
  image: string; //"https://cdn.jwplayer.com/v2/media/MVOUOGNd/poster.jpg?width=720";
  images: IImage[];
  feedid: string; //"2ITYTqIu";
  duration: number;
  pubdate: number;
  description: string;
  tags: string; //"posture,neck,Whiplash,levelintro";
  sources: (IMp4 | IM3u8)[];
  // "tracks": [
  //   {
  //     "file": "https://cdn.jwplayer.com/strips/MVOUOGNd-120.vtt",
  //     "kind": "thumbnails"
  //   }
  // ],
  variations: {};
}

export interface CustomVideoReps {
  type: "reps";
  comment: string;
  mediaId: string;
  reps: number;
  sets: number;
  title: string;
}

export interface CustomVideoTimer {
  type: "time";
  comment: string;
  mediaId: string;
  time: number;
  sets: number;
  title: string;
}
