import { HAPPY_FACE_URI } from "@env";
import { LinearGradient } from "expo-linear-gradient";
import * as WebBrowser from "expo-web-browser";
import React, { useContext } from "react";
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from "react-native";
import { themeColor, useTheme } from "react-native-rapi-ui";
import { colors } from "../../constants/colors";
import useSize from "../../hooks/useSize";
import { AuthContext } from "../../provider/AuthProvider";
import Button from "./button";

export default function WelcomeCard({
  name,
  copilot,
}: {
  name: string;
  copilot: any;
}): JSX.Element {
  const bookNowPressed = () => {
    WebBrowser.openBrowserAsync("https://phyxable.janeapp.com/");
  };
  const { isDarkmode, setTheme } = useTheme();
  const { width, height } = useWindowDimensions();
  const { isMiniMobile } = useSize();

  const { userData, setUserLocalData } = useContext(AuthContext);

  return (
    <View {...copilot}>
      <LinearGradient
        style={[
          styles.cardContainer,
          { marginVertical: isMiniMobile ? 10 : 20 },
        ]}
        locations={[0, 1]}
        colors={[`${colors.primaryColor}0D`, `${colors.secondaryColor}0D`]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
      >
        <View style={styles.cardTextContainer}>
          <Text
            style={[
              {
                color: !isDarkmode
                  ? colors.secondaryColor
                  : themeColor.white100,
                fontSize: Math.min(
                  (width / height) * 40,
                  isMiniMobile ? 16 : 28,
                ),
              },
            ]}
          >
            Hello, {userData?.userProfile?.name || ""}
          </Text>
          <Text
            style={[
              {
                color: !isDarkmode ? colors.textColor : themeColor.white100,
                fontSize: Math.min(
                  (width / height) * 30,
                  isMiniMobile ? 12 : 20,
                ),
              },
            ]}
          >
            Need more guidance? Talk to a practitioner now.
          </Text>
          <Button
            text="BOOK NOW"
            color="white"
            backgroundColor={colors.primaryColor}
            onPressCallback={bookNowPressed}
            customStyle={{
              alignSelf: "flex-start",
              marginTop: 10,
              paddingHorizontal: 10,
              paddingVertical: 5,
              height: 30,
            }}
          />
        </View>
        <View style={styles.cardImageContainer}>
          <Image
            source={{
              uri: HAPPY_FACE_URI,
            }}
            style={{
              width: width * 0.2,
              height: 70,
              resizeMode: "contain",
            }}
          />
        </View>
      </LinearGradient>
    </View>
  );
}

const styles = StyleSheet.create({
  cardContainer: {
    borderRadius: 10,
    flexDirection: "row",
    padding: 16,
    marginHorizontal: 10,
    justifyContent: "space-between",
  },
  cardTextContainer: {
    flexShrink: 1,
    height: Platform.OS === "web" ? "20%" : "auto",
  },
  cardTitle: {
    fontWeight: "500",
  },
  cardText: {
    marginBottom: 15,
  },
  cardImageContainer: {},
});
