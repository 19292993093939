import { LinearGradient } from "expo-linear-gradient";
import React, { useContext, useEffect, useState } from "react";
import { PaymentSubscriptionService } from "../../../../services/paymentSubscription/paymentSubscription";
import PaymentModule from "../../../components/Account/payment/payment";
import { colors } from "../../../constants/colors";
import { RouteNames } from "../../../constants/routeNames";
import { AuthContext } from "../../../provider/AuthProvider";
import Loader from "../../utils/Loading";

export default function AddPaymentMethod({ navigation, route }: any) {
  const { userData } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [client_secret, setClientSecret] = useState<string>("");
  const paymentService = new PaymentSubscriptionService();
  useEffect(() => {
    const getClientSecret = async () => {
      const clientSecret = await paymentService.createSetupIntent(
        userData?.id as string,
      );
      setClientSecret(clientSecret.data);
    };
    getClientSecret().then(() => setLoading(false));
  }, []);

  const onSubmitCallBack = async (paymentMethod: any) => {
    setLoading(true);
    if (paymentMethod.error) {
      alert(paymentMethod.error.message);
      return;
    }
    const result = await paymentService.createPaymentMethod(
      paymentMethod,
      userData?.id as string,
    );
    if (result.status == 200) {
      if (route.params && route.params.redirect) {
        await navigation.navigate(RouteNames.SUBSCRIPTION_CONFIRMATION, {
          subscription: route.params.redirect.subscription,
          period: route.params.redirect.period,
          refresh: true,
        });
        setLoading(false);
        return;
      }
      await navigation.navigate(RouteNames.PAYMENT_INFO, {
        refresh: true,
      });
      setLoading(false);
    } else {
      alert(result.data.message);
      setLoading(false);
    }
  };
  return (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      colors={[colors.buttonWhite, colors.buttonWhite]}
      style={{
        flex: 1,
        width: "100%",
        height: "100%",
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <PaymentModule
          client_secret={client_secret}
          submitCallBack={onSubmitCallBack}
          navigation={navigation}
        />
      )}
    </LinearGradient>
  );
}
