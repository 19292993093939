import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import Accomplishments from "../screens/accomplishments/Accomplishments";

const accomplishmentsStack = createNativeStackNavigator();

export default function AccomplishmentsStack() {
  return (
    <accomplishmentsStack.Navigator
      screenOptions={{
        headerShown: true,
        headerBackButtonMenuEnabled: true,
        headerTitle: "",
      }}
    >
      <accomplishmentsStack.Screen
        name="Accomplishment"
        component={Accomplishments}
        options={{
          headerShown: false,
        }}
      />
    </accomplishmentsStack.Navigator>
  );
}
