import { LOGO_URI } from "@env";
import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { Platform, TouchableOpacity, View } from "react-native";
import { Badge } from "react-native-paper";
import { TopNav, themeColor, useTheme } from "react-native-rapi-ui";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { AuthContext } from "../../provider/AuthProvider";
import RenderImage from "./renderImage";

export default function HeaderMobile({ navigation }: any) {
  const { isDarkmode, setTheme } = useTheme();
  const { userData } = React.useContext(AuthContext);
  const [notifications, setNotifications] = React.useState<any>([]);
  React.useEffect(() => {
    setNotifications(userData?.notifications);
  }, [userData?.notifications]);

  const handleNotificationPress = () => {
    navigation.navigate(RouteNames.HOME, { screen: RouteNames.NOTIFICATIONS });
  };

  return (
    <View
      style={{
        borderBottomColor: "white",
        borderWidth: 0,
      }}
    >
      <TopNav
        style={{
          borderBottomColor: "white",
          borderWidth: 0,
        }}
        rightContent={
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-end",
              width: 45,
              justifyContent: "space-between",
              borderRadius: 10,
              backgroundColor: colors.secondaryColor,
              padding: 1,
            }}
          >
            <Ionicons
              name="notifications"
              size={30}
              color={themeColor.white100}
              style={{
                borderRadius: 10,
                alignSelf: "flex-end",
                padding: 5,
              }}
              onPress={handleNotificationPress}
            >
              {Platform.OS === "web" &&
                notifications?.filter((item: any) => item.read === false)
                  ?.length > 0 && (
                  <Badge style={{ position: "absolute", top: -5, right: -10 }}>
                    {notifications?.filter((item: any) => item.read === false)
                      ?.length > 0
                      ? notifications?.filter(
                          (item: any) => item.read === false,
                        )?.length
                      : 0}
                  </Badge>
                )}
            </Ionicons>
            {Platform.OS != "web" &&
              notifications?.filter((item: any) => item.read === false)
                ?.length > 0 && (
                <Badge style={{ position: "absolute", bottom: 30, right: -10 }}>
                  {notifications?.filter((item: any) => item.read === false)
                    ?.length > 0
                    ? notifications?.filter((item: any) => item.read === false)
                        ?.length
                    : 0}
                </Badge>
              )}
          </View>
        }
        leftContent={
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
              alignContent: "flex-start",
              justifyContent: "space-between",
            }}
            onPress={() => {
              navigation.reset({
                index: 0,
                routes: [{ name: RouteNames.HOME }],
              });
            }}
          >
            <RenderImage uri={LOGO_URI} size={{ width: 150, height: 100 }} />
          </TouchableOpacity>
        }
      />
    </View>
  );
}
