import axios from "axios";
import * as FileSystem from "expo-file-system";
import React, { useContext, useEffect, useState } from "react";
import { ImageBackground, StyleSheet, Text, View } from "react-native";
import { colors } from "../../../constants/colors";
import { AuthContext, AuthContextProps } from "../../../provider/AuthProvider";
import {
  CustomVideoReps,
  CustomVideoTimer,
  IVideo,
} from "../../../types/program.types";
import { getCacheUri } from "../../../utils/cache";

interface Props {
  video: CustomVideoReps | CustomVideoTimer;
}
export default function CustomVideoItem({ video }: Props) {
  const BASE_VIDEO_URL = "https://cdn.jwplayer.com/v2/media/";
  const [image, setImage] = useState<string>();
  const { videoCache } = useContext(AuthContext) as AuthContextProps;

  useEffect(() => {
    if (video) {
      fetchVideo();
    }
  }, [video]);

  const fetchVideo = async () => {
    try {
      const videoUrl = `${BASE_VIDEO_URL}${video.mediaId}`;
      const { playlist } =
        videoCache?.[videoUrl] || ((await axios.get(videoUrl)).data as IVideo);
      setImage(playlist[0].image);
      const imageUrl = playlist[0].image;

      const cacheUri = getCacheUri(imageUrl);
      if (cacheUri) {
        try {
          const { exists } = await FileSystem.getInfoAsync(cacheUri);
          if (exists) {
            return setImage(cacheUri);
          }
        } catch {}
        setImage(imageUrl);
      }
    } catch (e) {
      console.log("CustomVideoItem: fetchVideo failed. Error:", e);
    }
  };

  return (
    <View style={styles.container}>
      {image ? (
        <ImageBackground
          source={{
            uri: image,
          }}
          style={styles.image}
          imageStyle={{ borderRadius: 7 }}
        >
          {/* <View style={styles.placeholder}>
            <MaterialIcons name="play-circle-outline" size={20} color="white" />
          </View> */}
        </ImageBackground>
      ) : null}
      <View style={styles.textContent}>
        <Text style={styles.title} numberOfLines={1}>
          {video.title}
        </Text>
        <Text style={styles.subTitle}>
          {(video as CustomVideoReps).reps
            ? `${(video as CustomVideoReps).reps} reps`
            : (video as CustomVideoTimer).time
              ? `${(video as CustomVideoTimer).time} secs ${video.sets} sets`
              : null}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
    paddingHorizontal: 20,
    flexDirection: "row",
  },
  image: {
    width: 80,
    height: 60,
    backgroundColor: "white",
  },
  textContent: {
    flexDirection: "column",
    paddingHorizontal: 5,
    flex: 1,
  },
  title: {
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 16,
    overflow: "hidden",
  },
  subTitle: {
    color: colors.textColor,
    fontWeight: "400",
    fontSize: 14,
  },
  placeholder: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.30)",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 7,
  },
});
