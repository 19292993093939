import {
  STRIPE_MERCHANT_ID,
  STRIPE_PUBLISHABLE_KEY,
  STRIPE_REDIRECT_URI,
} from "@env";
import {
  PlatformPay,
  PlatformPayButton,
  StripeProvider,
  createPlatformPayPaymentMethod,
  isPlatformPaySupported,
} from "@stripe/stripe-react-native";

import React, { useEffect, useState } from "react";
import { Alert } from "react-native";

export default function ApplePay({
  submitCallBack,
  subscriptionType,
  price,
  frequency,
}: any) {
  const [isApplePaySupported, setIsApplePaySupported] = useState(false);

  const pay = async (paymentMethod: any) => {
    submitCallBack(paymentMethod);
  };

  const presentApplePay = async () => {
    const { error, paymentMethod } = await createPlatformPayPaymentMethod({
      applePay: {
        cartItems: [
          {
            label: subscriptionType,
            amount: price,
            paymentType: PlatformPay.PaymentType.Recurring,
            intervalUnit:
              frequency === "month"
                ? PlatformPay.IntervalUnit.Month
                : PlatformPay.IntervalUnit.Year,
            intervalCount: 1,
          },
        ],
        merchantCountryCode: "CA",
        currencyCode: "CAD",
      },
    });

    if (error) {
      Alert.alert(error.code, error.message);
      return;
    } else if (paymentMethod) {
      await pay(paymentMethod);
    }
  };

  useEffect(() => {
    (async function () {
      setIsApplePaySupported(await isPlatformPaySupported());
    })();
  }, [isPlatformPaySupported]);

  return (
    <StripeProvider
      publishableKey={STRIPE_PUBLISHABLE_KEY}
      merchantIdentifier={STRIPE_MERCHANT_ID}
      urlScheme={STRIPE_REDIRECT_URI}
    >
      {isApplePaySupported ? (
        <PlatformPayButton
          onPress={presentApplePay}
          type={PlatformPay.ButtonType.Pay}
          appearance={PlatformPay.ButtonStyle.Black}
          borderRadius={4}
          style={{
            width: "70%",
            height: 50,
            alignSelf: "center",
          }}
        />
      ) : (
        <></>
      )}
    </StripeProvider>
  );
}
