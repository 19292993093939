import Container, { Service } from "typedi";
import { ApiService } from "../api/api.service";

@Service()
export class PaymentSubscriptionService extends ApiService {
  private readonly PAYMENT_SUBSCRIPTION_BASE_URL = "/payments";
  constructor() {
    super();
    Container.set(PaymentSubscriptionService, this);
  }

  /**
   * Get All Subscriptions data , used to populate the subscription cards in the account page
   * @returns SubScriptionData
   */
  async getAvailableSubscriptions(id: string) {
    return await this.get(
      `${this.PAYMENT_SUBSCRIPTION_BASE_URL}/getSubscriptionData/${id}`,
    );
  }

  /**
   * Get the stripe customer for the user , if none exists api creates one
   * @param id user id
   * @returns Stripe relevant data for the user
   */
  async createOrGetStripeCustomerByPatientId(
    email: string,
    name: string,
    patientId: string,
  ) {
    return await this.post(
      `${this.PAYMENT_SUBSCRIPTION_BASE_URL}/createOrGetStripeCustomerByPatientId`,
      {
        email: email,
        name: name,
        id: patientId,
      },
    );
  }

  async createPaymentMethod(card: any, patientId: string) {
    return await this.post(
      `${this.PAYMENT_SUBSCRIPTION_BASE_URL}/createPaymentMethod`,
      {
        card: card,
        patientId: patientId,
      },
    );
  }

  /**
   * Create a subscription for the user
   * @param email user email
   * @param customerId user stripe customerId
   * @param patientId user id
   * @param paymentMethodId payment method id
   * @param priceId price id
   * @returns subscription data
   */
  async createSubscription(
    patientId: string,
    paymentMethodId: string,
    priceId: string,
    productId: string,
    couponId: string,
    companyCode: string,
  ) {
    return await this.post(
      `${this.PAYMENT_SUBSCRIPTION_BASE_URL}/createSubscription`,
      {
        id: patientId,
        paymentMethodId: paymentMethodId,
        priceId: priceId,
        productId: productId,
        couponId: couponId,
        companyCode: companyCode,
      },
    );
  }

  /**
   * Create Payment Intent for the user
   */
  async createPaymentIntent(
    amount: number,
    currency: string,
    customerId: string,
  ) {
    return await this.post(
      `${this.PAYMENT_SUBSCRIPTION_BASE_URL}/createPaymentIntent`,
      {
        amount: amount,
        currency: currency,
        customerId: customerId,
      },
    );
  }

  /**
   * Get All payment methods for the user
   * @param patientId user id
   */
  async getPaymentMethods(patientId: string) {
    return await this.get(
      `${this.PAYMENT_SUBSCRIPTION_BASE_URL}/getAllPaymentMethods/${patientId}`,
    );
  }

  /**
   * Get the payment intent for the user
   * @param patientId user id
   */
  async getPaymentIntent(patientId: string) {
    return await this.get(
      `${this.PAYMENT_SUBSCRIPTION_BASE_URL}/getPaymentIntent/${patientId}`,
    );
  }

  /**
   * Create setup intent for the user
   * @param patientId user id
   */
  async createSetupIntent(patientId: string) {
    return await this.post(
      `${this.PAYMENT_SUBSCRIPTION_BASE_URL}/createSetupIntent`,
      {
        patientId: patientId,
      },
    );
  }

  /**
   * Delete the payment method for the user
   * @param paymentMethodId payment method id
   * @param patientId user id
   */
  async deletePaymentMethod(paymentMethodId: string, patientId: string) {
    return await this.delete(
      `${this.PAYMENT_SUBSCRIPTION_BASE_URL}/deletePaymentMethod/${paymentMethodId}/${patientId}`,
    );
  }

  /**
   * Update default payment method for the user
   * @param paymentMethodId payment method id
   * @param patientId user id
   * @returns updated payment method
   * @throws error if payment method is not found
   */
  async updateDefaultPaymentMethod(paymentMethodId: string, patientId: string) {
    return await this.put(
      `${this.PAYMENT_SUBSCRIPTION_BASE_URL}/updateDefaultPaymentMethod`,
      {
        paymentMethodId: paymentMethodId,
        patientId: patientId,
      },
    );
  }

  async getAllCompanyCodes() {
    return await this.get(
      `${this.PAYMENT_SUBSCRIPTION_BASE_URL}/getAllCompanyCodes`,
    );
  }

  async getAllCoupons() {
    return await this.get(
      `${this.PAYMENT_SUBSCRIPTION_BASE_URL}/getAllCoupons`,
    );
  }
}
