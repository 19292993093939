export const colors = {
  primaryColor: "#FF5A66",
  secondaryColor: "#18214D",
  primaryLightColor: "#ED9894",
  textColor: "#495057",
  LinearGradient: ["rgba(255, 80, 102, 0.05)", "rgba(24, 33, 77, 0.05)"],
  grey: "#DFDFE2",
  cardBackground: "#F7F8FC",
  green: "#34A853",
  black: "#41455D",
  orange: "#FCA727",
  lightBlue: "rgba(174, 216, 229, 0.8)",
  sliderGradient: ["rgba(174, 216, 229, 0.8)", "rgba(255, 90, 123, 0.8)"],
  accountCard: "#F0F2F6",
  categoryFinished: "rgba(226, 255, 229, 0.7)",
  categoryNotFinished: "rgba(255, 255, 255, 0.45)",
  tabletSize: 650 + 450,
  ScreenPercentageNonTablet: "80%",
  blueColor: "#18214D",
  playCircle: "#FF5A66",
  blueSettings: "#18214D",
  buttonWhite: "#F7F3F9",
  backGroundIcons: "#CECECE",
  error: "#e71725",
};
