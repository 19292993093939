import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { themeColor, useTheme } from "react-native-rapi-ui";
import { colors } from "../../constants/colors";

export default function BackButton(navigation: any) {
  const { isDarkmode, setTheme, theme } = useTheme();
  return (
    <Ionicons
      name="arrow-back"
      size={20}
      color={isDarkmode ? themeColor.white100 : colors.textColor}
      style={{
        borderRadius: 5,
        padding: 5,
        alignSelf: "flex-start",
        margin: 10,
        width: 40,
      }}
      onPress={() => {
        navigation.navigation.goBack();
      }}
    />
  );
}
