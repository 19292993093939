import { NativeStackScreenProps } from "@react-navigation/native-stack";
import moment from "moment";
import React, { useContext } from "react";
import { SafeAreaView, StyleSheet, View } from "react-native";
import { Text } from "react-native-rapi-ui";
import Container from "typedi";
import { UserService } from "../../../../services/user/user.service";
import Button from "../../../components/Home/button";
import { BackButton } from "../../../components/Program/backButton";
import { colors } from "../../../constants/colors";
import { RouteNames } from "../../../constants/routeNames";
import {
  AuthContext,
  AuthContextProps,
  IOutcomeFormResult,
} from "../../../provider/AuthProvider";
import { RootStackParamList } from "../../../types/navigation";
import Loader from "../../utils/Loading";
import { CustomExerciseProgram } from "./CustomExercise";

export type CustomQuestionnaireParams = {
  program: CustomExerciseProgram;
  outcome_forms: IOutcomeFormResult[];
};

type Props = NativeStackScreenProps<
  RootStackParamList,
  RouteNames.CUSTOM_QUESTIONNAIRE
>;

export default function CustomQuestionnaire({ navigation, route }: Props) {
  const { program, outcome_forms } = route.params;
  const [loading, setLoading] = React.useState(false);
  const { userData, setUserLocalData } = useContext(
    AuthContext,
  ) as AuthContextProps;
  const userServices = Container.get(UserService);

  const handleGoBack = () => {
    navigation.goBack();
  };

  const handleButton = async (difficulty: number) => {
    if (!userData) {
      return;
    }
    setLoading(true);
    let user = userData;
    try {
      [user] = (await userServices.getUserById()).data;
    } catch {}
    const progress = user.userProfile?.progress;
    if (!progress.customPhyx || Array.isArray(progress.customPhyx)) {
      progress.customPhyx = {};
    }
    const currentPhyxProgress = progress.customPhyx[program.id]?.progress;
    if (!currentPhyxProgress) {
      progress.customPhyx[program.id] = {
        created_by: program.created_by,
        created_by_name: program.created_by_name,
        program: program.title,
        progress: {},
      };
    }
    const newProgress = progress.customPhyx[program.id].progress;
    if (!newProgress[program.session - 1]) {
      newProgress[program.session - 1] = [];
    }
    newProgress[program.session - 1].push({
      date: moment().format("M/D/YYYY, h:mm:ss A"),
      timestamp: Date.parse(new Date().toString()),
      session: program.session - 1,
      difficulty: difficulty,
      outcome_forms: outcome_forms,
    });
    progress.customPhyx[program.id].progress = newProgress;
    user.userProfile.progress = progress;
    try {
      await userServices.updateUserProgress(
        userData?.id as string,
        user?.userProfile.progress,
        userData?.email,
      );
    } catch {}
    setUserLocalData({
      ...userData,
      userProfile: user.userProfile,
    });
    setLoading(false);
    await navigation.reset({
      index: 0,
      routes: [{ name: RouteNames.HOME }],
    });
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.backButton}>
        <BackButton handleGoBackCallback={handleGoBack} />
      </View>
      <View style={styles.content}>
        {!loading ? (
          <>
            <Text style={styles.title}>
              How did you find{"\n"}this session?
            </Text>
            <View style={styles.buttons}>
              <Button
                text="Easy"
                backgroundColor="white"
                color={colors.secondaryColor}
                customStyle={styles.button}
                onPressCallback={() => handleButton(1)}
              />
              <Button
                text="Just Right"
                backgroundColor="white"
                color={colors.secondaryColor}
                customStyle={styles.button}
                onPressCallback={() => handleButton(0)}
              />
              <Button
                text="Challenging"
                backgroundColor="white"
                color={colors.secondaryColor}
                customStyle={styles.button}
                onPressCallback={() => handleButton(-1)}
              />
            </View>
          </>
        ) : (
          <Loader />
        )}
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    backgroundColor: "white",
  },
  content: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 20,
    paddingHorizontal: 10,
  },
  backButton: {
    marginLeft: 10,
    marginTop: 10,
  },
  title: {
    alignSelf: "center",
    textAlign: "center",
    fontSize: 28,
    color: colors.secondaryColor,
    fontWeight: "700",
  },
  buttons: {
    flex: 1,
    width: "100%",
    marginTop: "15%",
    gap: 15,
  },
  button: {
    borderWidth: 2,
    borderColor: colors.secondaryColor,
    alignSelf: "center",
    width: "70%",
  },
});
