import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { TouchableOpacity, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { Text } from "react-native-paper";
import { colors } from "../../../constants/colors";
import TimePicker from "./TimePicker";

export default function CalendarComponent({
  selectedDay,
  timeList,
  handleCalendar,
  handleDayChange,
  handleCalendarDateDelete,
}: {
  selectedDay: any;
  timeList: any[];
  handleCalendar: any;
  handleDayChange: any;
  handleCalendarDateDelete: any;
}) {
  const [_selectedDay, setSelectedDay] = React.useState(selectedDay);
  const [_timeList, setTimeList] = React.useState(timeList);
  const [selectedHour, setSelectedHour] = React.useState(0);
  const [selectedMinute, setSelectedMinute] = React.useState(0);
  const [selectedAM_PM, setSelectedAM_PM] = React.useState("AM");
  const dayLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dayFullLabels = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [showTimePicker, setShowTimePicker] = React.useState(false);

  React.useEffect(() => {
    setSelectedDay(selectedDay);
    setTimeList(timeList);
  }, [selectedDay, timeList]);

  const handleTimePicker = (hour: any, minute: any) => {
    setSelectedHour(hour);
    setSelectedMinute(minute);
    setShowTimePicker(false);
    const time = new Date();
    time.setHours(hour);
    time.setMinutes(minute);
    time.setSeconds(0);
    time.setMilliseconds(0);

    setTimeList([...timeList, time]);
    handleCalendar({ day: _selectedDay, time: time });
  };
  const handleTimeDelete = (time: any) => {
    const newTimeList = timeList.filter((e) => {
      return e !== time;
    });
    setTimeList([...newTimeList] || []);
    handleCalendarDateDelete({
      day: _selectedDay,
      time: time,
    });
  };
  return (
    <>
      <Text
        style={{
          fontWeight: "500",
          color: colors.blueSettings,
          fontSize: 18,
          marginBottom: 10,
          marginLeft: 20,
          alignSelf: "flex-start",
        }}
      >
        Choose Day(s)
      </Text>
      <FlatList
        horizontal={true}
        data={dayLabels}
        renderItem={({ item, index }) => {
          return (
            <>
              <TouchableOpacity
                style={{
                  backgroundColor:
                    _selectedDay === item ? colors.primaryColor : "transparent",
                  width: 40,
                  height: 40,
                  borderRadius: 100,
                  justifyContent: "center",
                  alignItems: "center",
                  marginHorizontal: 5,
                }}
                onPress={() => {
                  setSelectedDay(item);
                  handleDayChange(item);
                }}
              >
                <Text
                  style={{
                    opacity: _selectedDay === item ? 1 : 0.5,
                    color: colors.blueColor,
                    fontSize: 18,
                  }}
                >
                  {item}
                </Text>
              </TouchableOpacity>
            </>
          );
        }}
      />
      <View
        style={{
          backgroundColor: "#D9D9D9",
          borderRadius: 15,
          width: "90%",
          height: "auto",
          padding: 10,
          margin: 10,
          justifyContent: "center",
          alignItems: "center",
          borderColor: colors.blueColor,
          borderWidth: 2,
        }}
      >
        <Text
          style={{
            color: colors.primaryColor,
            fontSize: 18,
            alignSelf: "flex-start",
            fontWeight: "500",
            zIndex: 1,
          }}
        >
          {dayFullLabels[dayLabels.indexOf(_selectedDay)]}
        </Text>
        <Text
          style={{
            color: colors.blueColor,
            fontSize: 18,
            alignSelf: "flex-start",
            fontWeight: "500",
            marginTop: 10,
          }}
        >
          Time(s)
        </Text>
        <FlatList
          style={{ flex: 1 }}
          contentContainerStyle={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
          data={timeList}
          renderItem={({ item, index }) => {
            return (
              <View
                style={{
                  flexDirection: "row",
                  margin: 5,
                }}
              >
                <Text
                  style={{
                    color: colors.blueColor,
                    fontSize: 18,
                    alignSelf: "flex-start",
                    fontWeight: "500",
                  }}
                >
                  {new Date(item).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </Text>
                <Ionicons
                  name="close-circle"
                  style={{ marginLeft: 10 }}
                  size={20}
                  onPress={() => handleTimeDelete(item)}
                />
              </View>
            );
          }}
        />
        <Ionicons
          name={showTimePicker ? "close-circle" : "add-circle"}
          size={20}
          color={colors.primaryColor}
          style={{
            borderRadius: 5,
            alignContent: "center",
            justifyContent: "center",
            margin: 10,
          }}
          onPress={() => {
            setShowTimePicker(!showTimePicker);
          }}
        >
          <Text
            style={{
              color: colors.primaryColor,
              fontSize: 18,
              alignSelf: "flex-start",
              fontWeight: "500",
              paddingBottom: 20,
            }}
          >
            {showTimePicker ? "Hide" : "Add Time"}
          </Text>
        </Ionicons>
        {showTimePicker && (
          <View>
            <TimePicker
              hour={selectedHour || 0}
              minute={selectedMinute || 0}
              AM_PM={selectedAM_PM || "AM"}
              handleTimePicker={handleTimePicker}
              setShowTimePicker={setShowTimePicker}
            />
          </View>
        )}
      </View>
    </>
  );
}
