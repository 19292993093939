import { Image, Platform } from "react-native";
import { SvgCssUri } from "react-native-svg";

/**
 * iOS and Android have different ways of rendering SVGs. This component
 * @param param0
 * @returns
 */
export default function RenderImage({
  uri,
  size,
  style,
}: {
  uri: string;
  size?: { width: any; height: any };
  style?: any;
}) {
  switch (Platform.OS) {
    // case "ios":
    //   return (
    //     <SvgCssUri
    //       style={{
    //         width: size?.width  || 40,
    //         height: size?.height || 40,
    //         marginLeft: -35,
    //         marginTop: 10,
    //       }}
    //       uri={uri}
    //     />
    //   );
    case "android":
    case "ios":
      // If uri is svg then use SvgCssUri else use Image
      if (uri.includes(".svg")) {
        return (
          <SvgCssUri
            style={
              style
                ? style
                : {
                    width: size?.width || 100,
                    height: size?.height || 100,
                    marginLeft: -30,
                    marginTop: 10,
                  }
            }
            uri={uri}
          />
        );
      } else {
        return (
          <Image
            resizeMethod="resize"
            resizeMode="contain"
            style={
              style
                ? style
                : {
                    width: size?.width || 100,
                    height: size?.height || 100,
                    marginLeft: -30,
                    marginTop: 10,
                  }
            }
            source={{ uri: uri }}
          />
        );
      }
    default:
      return (
        <Image
          resizeMethod="resize"
          resizeMode="contain"
          style={
            style
              ? style
              : {
                  width: size?.width || 100,
                  height: size?.height || 100,
                  marginLeft: -30,
                  marginTop: 10,
                }
          }
          source={{ uri: uri }}
        />
      );
  }
}
