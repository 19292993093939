import React, { useContext, useEffect, useState } from "react";
import {
  FlatList,
  GestureResponderEvent,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { Divider, List } from "react-native-paper";
import { ProgramBanner } from "../../../components/Program/ProgramBanner";
import { colors } from "../../../constants/colors";
import { AuthContext } from "../../../provider/AuthProvider";
//@ts-ignore
import ReadMore from "react-native-read-more-text";
import Container from "typedi";
import { UserService } from "../../../../services/user/user.service";
import Button from "../../../components/Home/button";
import { RouteNames } from "../../../constants/routeNames";
import Loading from "../../utils/Loading";

export default function CustomPhyxDetails({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) {
  const { programData, id, image } = route.params;
  const [expanded, setExpanded] = useState(true);
  const [phyxExists, setPhyxExists] = useState(false);
  const [loading, setLoading] = useState(true);

  const user = useContext(AuthContext);

  const userService = Container.get(UserService);

  useEffect(() => {
    if (user.userData?.id === undefined) return;
    const init = async () => {
      const _user = await userService.getUserById();
      if (_user.data.customPhyxes.includes(id)) {
        setPhyxExists(true);
      }
    };
    init().then(() => setLoading(false));
  }, []);

  const handleJoinProgram = () => {
    navigation.navigate(RouteNames.CUSTOMPHYX_CONFIRMATION, {
      id: id,
      title: programData.program_name,
      created_by: programData.created_by_name,
    });
  };

  //Style and view for 'Read more' button
  const renderTruncatedFooter = (
    handlePress: ((event: GestureResponderEvent) => void) | undefined,
  ) => {
    return (
      <Text style={{ color: colors.primaryColor }} onPress={handlePress}>
        Read more
      </Text>
    );
  };

  //Style and view for 'Show less' button
  const renderRevealedFooter = (
    handlePress: ((event: GestureResponderEvent) => void) | undefined,
  ) => {
    return (
      <Text style={{ color: colors.primaryColor }} onPress={handlePress}>
        Show less
      </Text>
    );
  };

  const handleGoBack = () => {
    navigation.goBack();
  };

  //Rendering each video content within sessions
  const ProgramVideos = ({ item }: any): JSX.Element => {
    return (
      <View>
        <Text numberOfLines={1} style={styles.videoTitle}>
          {item.title}
        </Text>
        <Text style={styles.videoSubHeading}>
          {item?.time
            ? `${item?.time} secs`
            : item?.reps
              ? `${item?.reps} reps x ${item.sets} sets`
              : null}
        </Text>
        <Divider style={styles.divider} />
      </View>
    );
  };

  //Rendering each session
  const ProgramSessions = ({ item }: any): JSX.Element => {
    const sessionNumber = programData.playlist.indexOf(item) + 1;

    return (
      <View style={styles.sessionContainer}>
        <View style={styles.sessionHeading}>
          <Text style={styles.sessionTitle}>Session {sessionNumber}</Text>
        </View>
        <View style={styles.videoContainer}>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={item}
            renderItem={({ item }) => <ProgramVideos item={item} />}
            keyExtractor={(i) => item.indexOf(i)}
            ListEmptyComponent={<Loading />}
          />
        </View>
      </View>
    );
  };

  const Header = () => {
    return (
      <View>
        {/* Image background heading view */}
        <ProgramBanner
          title={programData.program_name}
          image={image}
          handleGoBack={handleGoBack}
        />

        {/* Content view */}
        <View style={styles.contentView}>
          {/* OverView */}
          <Text style={styles.sectionHeading}>Overview</Text>
          <ReadMore
            numberOfLines={5}
            renderTruncatedFooter={renderTruncatedFooter}
            renderRevealedFooter={renderRevealedFooter}
          >
            <Text>{programData.program_overview}</Text>
          </ReadMore>
          <Text style={styles.sectionHeading}>Program Overview</Text>
        </View>
      </View>
    );
  };

  const Footer = () => {
    return (
      <View style={styles.button}>
        <Button
          text={phyxExists ? "ALREADY JOINED" : "JOIN PROGRAM"}
          backgroundColor={phyxExists ? colors.textColor : colors.primaryColor}
          disabled={phyxExists}
          customStyle={{
            alignSelf: "center",
          }}
          onPressCallback={handleJoinProgram}
        />
      </View>
    );
  };

  return (
    <View style={{ height: "100%", backgroundColor: "white" }}>
      {programData ? (
        <View>
          <List.AccordionGroup>
            <FlatList
              showsVerticalScrollIndicator={false}
              data={programData.playlist}
              renderItem={({ item }) => <ProgramSessions item={item} />}
              keyExtractor={(item) => programData.playlist.indexOf(item)}
              ListEmptyComponent={<Loading />}
              ListHeaderComponent={<Header />}
              ListFooterComponent={<Footer />}
            />
          </List.AccordionGroup>
        </View>
      ) : (
        <Loading />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  contentView: {
    paddingHorizontal: 20,
  },
  sectionHeading: {
    fontWeight: "400",
    fontSize: 16,
    color: colors.secondaryColor,
    marginTop: 20,
    marginBottom: 10,
  },
  list: {
    backgroundColor: "white",
    paddingHorizontal: 0,
  },
  sessionContainer: {
    flexDirection: "row",
    paddingHorizontal: 20,
  },
  sessionHeading: {
    width: "40%",
  },
  videoContainer: {
    width: "60%",
  },
  videoTitle: {
    fontSize: 15,
    fontWeight: "700",
    color: colors.secondaryColor,
    marginBottom: 5,
  },
  videoSubHeading: {
    fontSize: 13,
    color: colors.textColor,
  },
  divider: {
    marginBottom: 5,
    marginTop: 5,
  },
  sessionTitle: {
    color: colors.secondaryColor,
    fontWeight: "700",
    marginLeft: 7,
  },
  button: {
    position: "relative",
    bottom: 0,
    justifyContent: "center",
    width: "100%",
    marginBottom: 20,
    marginTop: 20,
  },
});
