import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { RouteNames } from "../constants/routeNames";
import { Account } from "../screens";
import DebugScreen from "../screens/account/Debug";
import GiveFeedback from "../screens/account/GiveFeedback";
import ProgramReminders from "../screens/account/NotificationSettings/ProgramReminders";
import AddPaymentMethod from "../screens/account/Payment/AddPaymentMethod";
import PaymentInfo from "../screens/account/Payment/PaymentInfo";
import SubscriptionConfirmation from "../screens/account/Payment/SubscriptionConfirmation";
import UpgradePlan from "../webScreens/UpgradePlan";
import NotificationSettingsStack from "./Notifications";

const accountStack = createNativeStackNavigator();

export default function AccountStack() {
  return (
    <accountStack.Navigator
      screenOptions={{
        headerShown: true,
        headerBackButtonMenuEnabled: true,
        headerTitle: "",
      }}
    >
      <accountStack.Screen
        name={RouteNames.ACCOUNT_DETAIL}
        component={Account}
        options={{
          headerShown: false,
        }}
      />
      <accountStack.Screen
        name={RouteNames.SUBSCRIPTION}
        options={{
          headerShown: false,
        }}
        initialParams={{ refresh: null }}
        component={UpgradePlan}
      />

      <accountStack.Screen
        name={RouteNames.NOTIFICATION_SETTINGS_MAIN}
        options={{
          headerShown: false,
        }}
        component={NotificationSettingsStack}
      />
      <accountStack.Screen
        name={RouteNames.PROGRAM_REMINDERS}
        component={ProgramReminders}
      />
      <accountStack.Screen
        name={RouteNames.PAYMENT_INFO}
        component={PaymentInfo}
      />
      <accountStack.Screen
        name={RouteNames.ADD_CARD}
        component={AddPaymentMethod}
      />
      <accountStack.Screen
        name={RouteNames.REDIRECT}
        component={PaymentInfo}
        initialParams={{ refresh: true }}
      />
      <accountStack.Screen
        name={RouteNames.SUBSCRIPTION_CONFIRMATION}
        component={SubscriptionConfirmation}
      />
      <accountStack.Screen
        name={RouteNames.GIVE_FEEDBACK}
        component={GiveFeedback}
      />
      <accountStack.Screen name={RouteNames.DEBUG} component={DebugScreen} />
    </accountStack.Navigator>
  );
}
