import React from "react";
import {
  Dimensions,
  ImageBackground,
  StyleSheet,
  Text,
  View,
} from "react-native";

import { colors } from "../../constants/colors";
import { BackButton } from "./backButton";

const HEIGHT = Dimensions.get("window").height;
const isTablet = HEIGHT > colors.tabletSize;

export default function MiniProgramBanner({
  title,
  image,
  goBackCallback,
}: any) {
  const handleGoBack = () => {
    goBackCallback();
  };

  return (
    <View>
      <ImageBackground
        source={{
          uri: image,
        }}
        style={styles.image}
      >
        <View style={styles.headingView}>
          <BackButton handleGoBackCallback={handleGoBack} />
          <View style={styles.titleView}>
            <Text style={styles.title}>{title}</Text>
            {/* <Text style={styles.subHeading}>3 Days/Weeks, 10 Weeks</Text> */}
          </View>
        </View>
      </ImageBackground>
    </View>
  );
}

const styles = StyleSheet.create({
  image: {
    // width: "100%",
    // height: isTablet ? HEIGHT * 0.2 : HEIGHT * 0.15,
  },
  headingView: {
    // width: isTablet ? "80%" : "90%",
    // height: isTablet ? HEIGHT * 0.2 : HEIGHT * 0.15,
    backgroundColor: "rgba(255, 255, 255, 0.45)",
    // flexDirection: "row",
    // alignItems: "center",
    // paddingHorizontal: 15,
  },
  title: {
    fontSize: 28,
    color: colors.secondaryColor,
    fontWeight: "700",
  },
  subHeading: {
    fontSize: 10,
    color: colors.secondaryColor,
    fontWeight: "700",
  },
  titleView: {
    flexDirection: "column",
    marginLeft: 15,
  },
});
