import { Ionicons } from "@expo/vector-icons";
import React from "react";

import { Audio } from "expo-av";
import { Alert, View } from "react-native";
import { ProgressBar } from "react-native-paper";
export default function PhyxableMobileAudioPlayer({ message }: any) {
  const [sound, setSound] = React.useState<any>();
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
  const [progress, setProgress] = React.useState<number>(0);

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        // justifyContent: "center",
        width: "100%",
        height: 25,
        backgroundColor: "grey",
        borderRadius: 50,
        padding: 5,
        minHeight: 25,
        minWidth: 100,
      }}
    >
      <Ionicons
        name={isPlaying ? "pause" : "play"}
        size={15}
        color="black"
        onPress={async () => {
          try {
            if (isPlaying && sound) {
              await sound.pauseAsync();
              setIsPlaying(false);
            } else {
              setIsPlaying(true);
              await Audio.requestPermissionsAsync();
              await Audio.setAudioModeAsync({
                allowsRecordingIOS: true,
                playsInSilentModeIOS: true,
              });
              const tempFileUri = message.data.data.includes("https")
                ? message.data.data
                : `data:audio/mpeg;base64,${message.data.data.split(",")[1]}`;
              const { sound } = await Audio.Sound.createAsync(
                { uri: tempFileUri },
                {
                  shouldPlay: true,
                  isLooping: false,
                  volume: 1.0,
                  positionMillis: 0,
                },
              );
              setSound(sound);
              await sound.playAsync();
              sound.setOnPlaybackStatusUpdate((status: any) => {
                if (status.didJustFinish) {
                  setIsPlaying(false);
                }
                setProgress(status.positionMillis / status.durationMillis);
              });
            }
          } catch (error) {
            console.error(error);
            Alert.alert("File Expired", "This file has expired.");
            setIsPlaying(false);
          }
        }}
      />
      <ProgressBar
        progress={progress}
        color="white"
        style={{
          width: "80%",
          height: 5,
          borderRadius: 50,
          zIndex: 1000,
          minWidth: 100,
        }}
      />
    </View>
  );
}
