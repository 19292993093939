import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { Text, View } from "react-native";
import { Card, Checkbox } from "react-native-paper";
import { colors } from "../../../constants/colors";

export default function PaymentCard({
  paymentMethodId,
  cardNumber,
  cardBrand,
  expirationDate,
  handleDelete,
  handleDefault,
  defaultCard,
  selected,
}: any) {
  return (
    <>
      <Card
        key={paymentMethodId}
        elevation={5}
        style={{
          flex: 1,
          margin: 10,
          padding: 10,
          backgroundColor: "#f5f5f5",
          borderWidth: selected ? 2 : 0,
          borderColor: selected ? colors.primaryColor : "transparent",
        }}
      >
        {handleDelete && (
          <Ionicons
            name="trash-outline"
            size={20}
            color="black"
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 100,
            }}
            onPress={() => handleDelete(paymentMethodId)}
          />
        )}
        <View
          style={{
            flexDirection: "row",
          }}
        >
          {handleDefault && (
            <>
              <Checkbox
                status={defaultCard ? "checked" : "unchecked"}
                onPress={() => handleDefault(paymentMethodId)}
              />
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  marginTop: 5,
                }}
              >
                Default
              </Text>
            </>
          )}
        </View>
        <Card.Content>
          <View>
            <Text>{cardBrand}</Text>
            <Text>Ending in {cardNumber}</Text>
            <Text>Exp: {expirationDate}</Text>
          </View>
        </Card.Content>
      </Card>
    </>
  );
}
