import { HAPPY_FACE_URI } from "@env";
import * as WebBrowser from "expo-web-browser";
import React, { useContext } from "react";
import { Image, StyleSheet, View, useWindowDimensions } from "react-native";
import { Text } from "react-native-paper";
import { themeColor, useTheme } from "react-native-rapi-ui";
import { colors } from "../../constants/colors";
import { AuthContext } from "../../provider/AuthProvider";
import Button from "./button";

export default function WelcomeCardWeb({
  name,
  copilot,
}: {
  name: string;
  copilot: any;
}): JSX.Element {
  const bookNowPressed = () => {
    WebBrowser.openBrowserAsync("https://phyxable.janeapp.com/");
  };
  const { isDarkmode, setTheme } = useTheme();
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;

  const { userData } = useContext(AuthContext);

  return (
    <View
      style={{
        width: "100%",
        backgroundColor: colors.buttonWhite,
        borderRadius: 10,
        padding: 20,
        borderWidth: 1,
        borderColor: colors.secondaryColor,
      }}
      {...copilot}
    >
      <View style={styles.cardTextContainer}>
        <Text
          style={[
            {
              color: !isDarkmode ? colors.textColor : themeColor.white100,
              fontSize: isTablet ? 28 : 12,
              fontWeight: "700",
            },
            { color: !isDarkmode ? colors.textColor : themeColor.white100 },
          ]}
        >
          Hello, {userData?.userProfile?.name || ""}
        </Text>
        <Text
          style={[
            {
              color: !isDarkmode ? colors.textColor : themeColor.white100,
              fontSize: isTablet ? 20 : 12,
              marginTop: 5,
            },
          ]}
        >
          Need more guidance? Talk to a practitioner now.
        </Text>
        <Button
          text="BOOK NOW"
          color="white"
          backgroundColor={colors.primaryColor}
          onPressCallback={bookNowPressed}
          customStyle={{
            alignSelf: "flex-start",
            marginTop: 10,
            paddingHorizontal: 10,
            paddingVertical: 5,
            height: 30,
          }}
        />
      </View>
      <View style={styles.cardImageContainer}>
        <Image
          source={{
            uri: HAPPY_FACE_URI,
          }}
          style={{
            width: 150,
            height: 150,
            resizeMode: "contain",
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  cardContainer: {
    width: "100%",
    borderRadius: 20,
    flexDirection: "row",
    padding: 10,
  },
  cardTextContainer: {
    flex: 2,
    height: "20%",
  },
  cardTitle: {
    fontWeight: "700",
  },
  cardText: {
    marginBottom: 15,
  },
  cardImageContainer: {
    alignItems: "center",
    justifyContent: "center",
    top: "0",
    right: "0",
    flex: 1,
    position: "absolute",
  },
});
