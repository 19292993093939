import React from "react";
import { SafeAreaView, StyleSheet, Text, View } from "react-native";
import Button from "../../components/Home/button";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import useSize from "../../hooks/useSize";

export default function Questionnaire({ navigation, route }: any) {
  const { program, vas, psfs } = route.params;
  const { isDesktop } = useSize();

  const handleGoBack = () => {
    navigation.goBack();
  };

  const handleButton = (difficulty: number) => {
    navigation.navigate(RouteNames.CONGRATULATIONS, {
      program: program,
      vas: vas,
      psfs: psfs,
      difficulty: difficulty,
    });
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.backButton}>
        {/* <BackButton handleGoBackCallback={handleGoBack} /> */}
      </View>
      <View style={[styles.content, { marginTop: isDesktop ? 50 : 20 }]}>
        <Text style={styles.title}>How did you find{"\n"}this session?</Text>
        <View style={styles.buttons}>
          <Button
            text="Easy"
            backgroundColor="white"
            color={colors.secondaryColor}
            customStyle={styles.button}
            onPressCallback={() => handleButton(1)}
          />
          <Button
            text="Just Right"
            backgroundColor="white"
            color={colors.secondaryColor}
            customStyle={styles.button}
            onPressCallback={() => handleButton(0)}
          />
          <Button
            text="Challenging"
            backgroundColor="white"
            color={colors.secondaryColor}
            customStyle={styles.button}
            onPressCallback={() => handleButton(-1)}
          />
        </View>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    backgroundColor: "white",
  },
  content: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 20,
    paddingHorizontal: 10,
  },
  backButton: {
    marginLeft: 10,
    marginTop: 10,
  },
  title: {
    alignSelf: "center",
    textAlign: "center",
    fontSize: 28,
    color: colors.secondaryColor,
    fontWeight: "700",
  },
  buttons: {
    flex: 1,
    width: "100%",
    marginTop: "15%",
    gap: 15,
  },
  button: {
    borderWidth: 2,
    borderColor: colors.secondaryColor,
    alignSelf: "center",
    width: "70%",
  },
});
