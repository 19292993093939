import { CustomWorkouts, IProgram } from "../types/program.types";

export function isPhyxablePlus(subscription: any) {
  if (subscription?.paid) return true;
  else if (subscription?.plan) {
    if (subscription.status === "active") return true;
    else if (subscription.current_period_end * 1000 > Date.now()) return true;
    else return false;
  } else return false;
}

export function getTodaysSession(
  userProfile: any,
  phyx: IProgram,
): {
  level: number;
  session: number;
  totalSessions: number;
  completed?: boolean;
} {
  const phyxesCount = phyx.phyxes[1].length || 0;
  if (!userProfile) {
    return {
      level: 1,
      session: 0,
      totalSessions: phyxesCount,
    };
  }
  if (userProfile?.progress) {
    let progress = userProfile.progress;
    let MAX_LEVEL = 0;

    for (const lvl in phyx.phyxes) {
      MAX_LEVEL += 1;
    }

    let levels = progress[phyx.id_];
    if (!levels || !Array.isArray(levels) || levels.length <= 1) {
      return {
        level: 1,
        session: 0,
        totalSessions: phyxesCount,
      };
    }
    // First value is always null
    let latestLevel = levels.length - 1;

    const sessions = progress[phyx.id_][latestLevel];
    let sessionsCompleted = 0;
    for (const sess in sessions) {
      sessionsCompleted += 1;
    }

    const actualNoOfSessions = phyx.phyxes[latestLevel]?.length;

    if (sessionsCompleted === actualNoOfSessions) {
      //If undefined, means there are no further levels
      if (phyx.phyxes[latestLevel + 1] === undefined) {
        // find last level and session by timestamp
        let lastLevel = 0;
        let lastSession = 0;
        let latestTimestamp = 0;
        for (let level = 1; level <= latestLevel; level += 1) {
          const sessions = progress[phyx.id_][level];
          for (const key in sessions) {
            for (const session of sessions[key]) {
              if (latestTimestamp < session.timestamp) {
                latestTimestamp = session.timestamp;
                lastLevel = level;
                lastSession = parseInt(key, 10);
              }
            }
          }
        }
        if (
          lastLevel === latestLevel &&
          lastSession + 1 === actualNoOfSessions
        ) {
          return {
            level: 1,
            session: 0,
            totalSessions: phyxesCount,
            completed: true,
          };
        }
        if (lastSession + 1 === phyx.phyxes[lastLevel]?.length) {
          return {
            level: lastLevel + 1,
            session: 0,
            totalSessions: phyxesCount,
            completed: true,
          };
        }
        return {
          level: lastLevel,
          session: lastSession + 1,
          totalSessions: actualNoOfSessions,
          completed: true,
        };
      } else {
        //Return new level number and first session i.e. 0
        return {
          level: latestLevel + 1,
          session: 0,
          totalSessions: actualNoOfSessions,
        };
      }
    } else {
      return {
        level: latestLevel,
        session: sessionsCompleted,
        totalSessions: actualNoOfSessions,
      };
    }
  }
  return {
    // Add the progress to the userProfile
    level: 1,
    session: 0,
    totalSessions: phyxesCount,
  };
}

export function getTodaysCustomSession(
  userProfile: any,
  customPhyx: CustomWorkouts,
): {
  _todaySession: number;
  _sessionCompleted: number;
  _totalSessions: number;
} {
  const userProgress =
    userProfile.progress.customPhyx &&
    userProfile.progress.customPhyx[customPhyx._id];
  const totalSessions = customPhyx?.playlist?.length || 1;
  if (!userProgress) {
    return {
      _todaySession: 1,
      _sessionCompleted: 0,
      _totalSessions: totalSessions,
    };
  }
  let completedSessions = 0;
  let todaySession = 1;
  let latestTimestamp = 0;

  for (const sess in userProgress.progress) {
    completedSessions += 1;
    const progress = userProgress.progress[sess];
    for (const item of progress) {
      if (latestTimestamp < item.timestamp) {
        latestTimestamp = item.timestamp;
        todaySession = parseInt(sess, 10) + 2;
      }
    }
  }

  if (todaySession <= totalSessions) {
    return {
      _todaySession: todaySession,
      _totalSessions: totalSessions,
      _sessionCompleted: completedSessions,
    };
  } else {
    return {
      _todaySession: 1,
      _totalSessions: totalSessions,
      _sessionCompleted: completedSessions,
    };
  }
}

interface SessionInfo {
  date: string;
  timestamp: number;
  VAS: [];
  PSFS: [];
  difficulty: number;
}

type SessionProgress = Record<number, SessionInfo[]>;
type ProcessMap = Record<string, SessionProgress[]>;

export function mergeTwoProgress(
  cloudProgress: ProcessMap,
  localProgress?: ProcessMap,
  programId?: string,
) {
  if (!localProgress || !programId) {
    return cloudProgress;
  }
  const localLevels = localProgress[programId];
  const cloudLevels = cloudProgress[programId];
  const [lastLocalSession] = localLevels
    .filter((level) => !!level)
    .map((level) => Object.values(level))
    .flat()
    .flat()
    .flat()
    .sort((a, b) => b.timestamp - a.timestamp);
  const [lastCloudSession] = cloudLevels
    .filter((level) => !!level)
    .map((level) => Object.values(level))
    .flat()
    .flat()
    .flat()
    .sort((a, b) => b.timestamp - a.timestamp);
  return lastLocalSession?.timestamp > lastCloudSession?.timestamp
    ? localProgress
    : cloudProgress;
}
