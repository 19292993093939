import { LOGO_URI } from "@env";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  Image,
  Text,
  View,
  useWindowDimensions,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { useTheme } from "react-native-rapi-ui";
import Video, { ConnectOptions } from "twilio-video";
import { colors } from "../../constants/colors";
import VideoBottomBar from "./components/BottomControlBar";
import EmptyParticipant from "./components/EmptyParticipant";
import Participant from "./components/Participant";

export default function WebVideoCall({ navigation, data, token }: any) {
  const { isDarkmode, setTheme } = useTheme();
  const { width, height } = useWindowDimensions();
  const isTablet = width > colors.tabletSize;

  const [roomName, setRoomName] = useState(data.room_id);
  const [userName, setUserName] = useState(data.user_id);

  const [room, setRoom] = useState<any>(null);
  const [participants, setParticipants] = useState<any>([]);
  const [error, setError] = useState<any>(null);

  const participantConnected = useCallback((participant: any) => {
    setParticipants((prevParticipants: any) => [
      ...prevParticipants,
      participant,
    ]);
  }, []);

  const participantDisconnected = useCallback((participant: any) => {
    setParticipants((prevParticipants: any) =>
      prevParticipants.filter((p: any) => p !== participant),
    );
  }, []);

  const initializeRoom = useCallback(() => {
    const connectOptions: ConnectOptions = {
      name: roomName,
      audio: true,
      video: {
        name:
          "webcam" ||
          "screen" ||
          "window" ||
          "application" ||
          "browser" ||
          "camera",
      },
      automaticSubscription: true,
    };

    Video.connect(token, connectOptions)
      .then((room: any) => {
        setRoom(room);
        room.on("participantConnected", participantConnected);
        room.on("participantDisconnected", participantDisconnected);
        room.participants.forEach(participantConnected);
        // // enable the local participant's audio tracks
        room.localParticipant.audioTracks.forEach((publication: any) => {
          publication.track.enable();
        });
      })
      .catch((err: any) => {
        setError(err);
        alert(err);
        navigation.goBack();
      });
  }, [
    navigation,
    participantConnected,
    participantDisconnected,
    roomName,
    token,
  ]);

  useEffect(() => {
    initializeRoom();

    return () => {
      setRoom((currentRoom: any) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication: any,
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [initializeRoom]);

  const formattedParticipants = useMemo(() => {
    let result = [...participants];
    if (result.length === 0) {
      result.push("Waiting");
    }
    if (!room) {
      result.push("Empty");
    } else {
      result.push(room.localParticipant);
    }
    return result;
  }, [participants, room]);

  const participantsMemo = useMemo(() => {
    let _height;
    switch (formattedParticipants.length) {
      case 1:
      case 2:
        _height = height * 0.8;
        break;
      case 3:
      case 4:
        _height = (height * 0.8) / 2;
        break;
      case 5:
      case 6:
        _height = (height * 0.8) / 3;
        break;
      case 7:
      default:
        _height = (height * 0.8) / 4;
    }
    return _height;
  }, [height, formattedParticipants.length]);

  return (
    <>
      {room && (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: "rgba(42, 46, 53, 1)",
            height: "5%",
          }}
        >
          <Image
            resizeMethod="resize"
            resizeMode="contain"
            style={{
              top: 0,
              left: 10,
              width: isTablet ? 200 : 100,
              height: isTablet ? 50 : 25,
            }}
            source={{ uri: LOGO_URI }}
          />
          <Text
            style={{
              backgroundColor: "#1c1e21",
              color: "white",
              padding: 10,
              fontWeight: "bold",
              borderRadius: 10,
              fontSize: isTablet ? 15 : 8,
            }}
          >
            Room : {roomName}
            {"  "}
            {participants.length > 1 ? (
              <Text
                style={{
                  backgroundColor: "#1c1e21",
                  color: "white",
                  padding: 10,
                  fontWeight: "bold",
                  borderRadius: 10,
                  fontSize: isTablet ? 15 : 8,
                }}
              >
                Participants : {participants?.length ?? ""}
              </Text>
            ) : null}
          </Text>
        </View>
      )}
      <View
        style={{
          flex: 1,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(42, 46, 53, 1)",
          justifyContent: "space-evenly",
        }}
      >
        {room ? (
          <ScrollView
            style={{
              flex: 1,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(42, 46, 53, 1)",
            }}
          >
            <FlatList
              data={formattedParticipants}
              renderItem={({ item, index }) => {
                if (item === "Waiting" || item === "Empty") {
                  return (
                    <EmptyParticipant
                      key={item}
                      identity="Waiting for participant..."
                      width={width / Math.max(formattedParticipants.length, 1)}
                      height={participantsMemo}
                    />
                  );
                }
                return (
                  <Participant
                    key={item.sid}
                    participant={item}
                    width={width / Math.max(formattedParticipants.length, 1)}
                    height={participantsMemo}
                  />
                );
              }}
              keyExtractor={(item) => item.sid?.toString()}
              numColumns={2}
              horizontal={false}
              style={{
                flex: 1,
                flexBasis: "100%",
                width: "99%",
                height: "100%",
                backgroundColor: "rgba(42, 46, 53, 1)",
                marginBottom: 10,
                alignSelf: "center",
              }}
            />
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
                backgroundColor: "rgba(42, 46, 53, 1)",
                bottom: 0,
                left: 0,
                flex: 3,
                width: "100%",
                height: "5%",
              }}
            >
              <VideoBottomBar
                room={room}
                participants={participants}
                navigation={navigation}
              />
            </View>
          </ScrollView>
        ) : (
          <View
            style={{
              backgroundColor: "rgba(42, 46, 53, 1)",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              width: "100%",
              height: "100%",
            }}
          >
            <ActivityIndicator size="large" color={colors.primaryColor} />
          </View>
        )}
      </View>
    </>
  );
}
