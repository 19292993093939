import { CUSTOM_PHYX_BANNER_URI, PLACEHOLDER_URI } from "@env";
import { Ionicons } from "@expo/vector-icons";
import React from "react";
import {
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { Card } from "react-native-paper";
import { themeColor, useTheme } from "react-native-rapi-ui";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { IProgram } from "../../types/program.types";

export default function ProgramCard({
  program,
  navigation,
  phyxExists,
}: {
  program: IProgram;
  navigation: any;
  phyxExists: boolean;
}) {
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;
  const { isDarkmode, setTheme } = useTheme();

  let handleItemPress = (data: IProgram) => {
    if (phyxExists) {
      navigation.navigate(RouteNames.PROGRAM_OVERVIEW, {
        id: data.id,
        image: data.images.banner || CUSTOM_PHYX_BANNER_URI,
        data: data,
      });
    } else {
      navigation.push(RouteNames.PROGRAM_DETAIL, {
        programId: data.id_,
      });
    }
  };

  return (
    <Card
      style={[
        styles.container,
        {
          backgroundColor: isDarkmode ? themeColor.dark100 : themeColor.white,
          flex: 1,
          width: isTablet ? "50%" : "86%",
        },
      ]}
      onPress={() => handleItemPress(program)}
    >
      <View
        style={{
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
      >
        <Image
          source={{
            uri: program.images.banner || PLACEHOLDER_URI,
          }}
          style={{
            width: "100%",
            aspectRatio: 16 / 9,
          }}
        />
      </View>
      <Card.Title
        title={program.title}
        titleStyle={[
          styles.title,
          {
            color: isDarkmode ? themeColor.white : themeColor.primary900,
          },
        ]}
      />
      <Card.Content>
        <Text style={styles.overview} numberOfLines={5}>
          {program.overview}
        </Text>
        <View style={styles.rowLayout}>
          <Text style={styles.dailyCount}>{program.often}</Text>
          <TouchableOpacity
            style={[styles.button]}
            onPress={() => {
              handleItemPress(program);
            }}
          >
            <Ionicons
              name={phyxExists ? "play" : "add"}
              size={15}
              color={"white"}
            />
          </TouchableOpacity>
        </View>
      </Card.Content>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 15,
    marginHorizontal: 5,
    marginBottom: 20,
    backgroundColor: "white",
    overflow: "hidden",
  },
  image: {
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    resizeMode: "contain",
    alignSelf: "center",
  },
  info: {
    paddingHorizontal: 10,
    flexDirection: "column",
  },
  title: {
    textAlign: "center",
    fontSize: 18,
    marginTop: 5,
    marginBottom: 5,
    fontWeight: "700",
  },
  overview: {
    fontSize: 15,
  },
  rowLayout: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    marginRight: 10,
    marginLeft: 10,
    width: "100%",
    alignSelf: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  dailyCount: {
    fontSize: 12,
  },
  button: {
    borderRadius: 100,
    padding: 10,
    backgroundColor: colors.primaryColor,
  },
});
