import { CUSTOM_PHYX_BANNER_URI, PRAC_PORTAL_URL_FOR_MESSAGES } from "@env";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-rapi-ui";

import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import {
  LocalAudioTrack,
  LocalDataTrack,
  LocalParticipant,
  LocalTrack,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteDataTrack,
  RemoteParticipant,
  RemoteTrack,
  RemoteVideoTrack,
} from "twilio-video";
import RenderImage from "../../Common/renderImage";
import AudioTrack from "./AudioTrack";
import DataTrack from "./DataTrack";
import ScreenTracks from "./SceeenTracks";
import VideoTrack from "./VideoTrack";

interface Props {
  participant: LocalParticipant | RemoteParticipant;
  width: number;
  height: number;
}

export default function Participant({ participant, width, height }: Props) {
  const [videoTracks, setVideoTracks] = useState<
    (LocalVideoTrack | RemoteVideoTrack)[]
  >([]);
  const [screenTracks, setScreenTracks] = useState<
    (LocalVideoTrack | RemoteVideoTrack)[]
  >([]);
  const [audioTracks, setAudioTracks] = useState<
    (LocalAudioTrack | RemoteAudioTrack)[]
  >([]);
  const [dataTracks, setDataTracks] = useState<
    (LocalDataTrack | RemoteDataTrack)[]
  >([]);
  const [isMuted, setIsMuted] = useState(false);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isScreenShareOn, setIsScreenShareOn] = useState(false);

  useEffect(() => {
    const trackSubscribed = (track: LocalTrack | RemoteTrack) => {
      if (track == null) return;
      switch (track.kind) {
        case "audio":
          // enable audio track
          setAudioTracks([...audioTracks, track]);
          setIsMuted(track.isEnabled);
          break;
        case "video":
          track.name === "screen" || track.name === "window"
            ? setScreenTracks([...screenTracks, track])
            : setVideoTracks([...videoTracks, track]);
          if (track.name === "camera" || track.name === "webcam") {
            setIsCameraOn(track.isEnabled);
          }
          break;
        case "data":
          setDataTracks([...dataTracks, track]);
          break;
      }
    };

    const trackUnsubscribed = (track: any) => {
      if (track == null) return;
      switch (track.kind) {
        case "audio":
          setAudioTracks((track: any) =>
            audioTracks.filter((t: any) => t !== track),
          );
          break;
        case "video":
          track.name === "screen" ||
          track.name === "window" ||
          track.trackName === "screen" ||
          track.trackName === "window"
            ? setScreenTracks((track: any) =>
                screenTracks.filter((t: any) => t !== track),
              )
            : setVideoTracks((track: any) =>
                videoTracks.filter(
                  (t: any) => t !== track && t.name === track.name,
                ),
              );
          break;
        case "data":
          setDataTracks((track: any) =>
            dataTracks.filter((t: any) => t !== track),
          );
          break;
      }
    };

    const trackEnabled = (track: any) => {
      if (track == null) return;
      switch (track.kind) {
        case "audio":
          setIsMuted(false);
          break;
        case "video":
          track.name === "screen" ||
          track.name === "window" ||
          track.trackName === "screen" ||
          track.trackName === "window"
            ? setIsScreenShareOn(true)
            : setIsCameraOn(true);
          break;
      }
    };

    const trackDisabled = (track: any) => {
      if (track == null) return;
      switch (track.kind) {
        case "audio":
          setIsMuted(true);
          break;
        case "video":
          track.name === "screen" ||
          track.name === "window" ||
          track.trackName === "screen" ||
          track.trackName === "window"
            ? setIsScreenShareOn(false)
            : setIsCameraOn(false);
          // Remove screen track if camera is on
          if (
            track.name === "screen" ||
            track.name === "window" ||
            track.trackName === "screen" ||
            track.trackName === "window"
          ) {
            setScreenTracks((track: any) =>
              screenTracks.filter((t: any) => t !== track),
            );
          }
          break;
      }
    };

    if (participant) {
      participant.on("trackSubscribed", trackSubscribed);
      participant.on("trackUnsubscribed", trackUnsubscribed);
      participant.on("trackPublished", trackSubscribed);
      participant.on("trackUnpublished", trackUnsubscribed);

      participant.on("trackEnabled", trackEnabled);
      participant.on("trackDisabled", trackDisabled);
      participant.on("trackStarted", trackEnabled);
      participant.on("trackStopped", trackDisabled);
    }

    participant.tracks.forEach((publication: any) => {
      if (publication.isTrackEnabled) {
        trackSubscribed(publication.track);
      } else {
        trackDisabled(publication.track);
      }
      publication.on("enabled", trackEnabled);
      publication.on("disabled", trackDisabled);
    });

    return () => {
      if (participant) {
        setVideoTracks([]);
        setAudioTracks([]);
        setDataTracks([]);
        setScreenTracks([]);
        participant.removeAllListeners();
      }
    };
  }, [participant]);

  const [myDetail, setMyDetail] = useState<any>(null);

  const fetchMyDetail = async (id: string) => {
    try {
      let result = await axios.get(
        `${PRAC_PORTAL_URL_FOR_MESSAGES}/chat/my/detail?id=${id}`,
      );
      setMyDetail(result?.data);
    } catch (error) {
      console.error("error:", error);
    }
  };
  useEffect(() => {
    fetchMyDetail(participant.identity);
  }, [participant]);

  return (
    <View
      key={participant.sid}
      style={{
        flex: 1,
        width: width,
        height: height,
        marginLeft: 5,
        marginRight: 5,
        marginTop: 5,
        borderRadius: 100,
      }}
    >
      {/* <Card.Content> */}
      {videoTracks.length > 0 ? (
        videoTracks.map((track) => (
          <VideoTrack
            track={track}
            key={`${
              (track as LocalVideoTrack).id || (track as RemoteVideoTrack).sid
            } video`}
          />
        ))
      ) : (
        <RenderImage
          uri={CUSTOM_PHYX_BANNER_URI}
          size={{ width: "100%", height: "100%" }}
        />
      )}
      {screenTracks.length > 0 &&
        screenTracks.map((track: LocalVideoTrack | RemoteVideoTrack) => (
          <ScreenTracks
            track={track}
            key={`${
              (track as LocalVideoTrack).id || (track as RemoteVideoTrack).sid
            } screen`}
          />
        ))}
      {audioTracks.length > 0
        ? audioTracks.map((track: LocalAudioTrack | RemoteAudioTrack) => (
            <AudioTrack
              track={track}
              key={`${
                (track as LocalAudioTrack).id || (track as RemoteAudioTrack).sid
              } audio`}
            />
          ))
        : null}
      {dataTracks.length > 0
        ? dataTracks.map((track: LocalDataTrack | RemoteDataTrack) => (
            <DataTrack
              track={track}
              key={`${
                (track as LocalDataTrack).id || (track as RemoteDataTrack).sid
              } data`}
            />
          ))
        : null}
      <Text
        style={{
          position: "absolute",
          bottom: 5,
          right: 5,
          color: "white",
          backgroundColor: "#1c1e21",
          padding: 10,
          zIndex: 10000,
          borderRadius: 100,
          minWidth: 50,
          textAlign: "center",
        }}
      >
        {myDetail ? myDetail?.userProfile?.name : participant?.identity}
      </Text>
      <View
        style={{
          position: "absolute",
          top: 5,
          right: 5,
          zIndex: 10000,
          flexDirection: "row",
          gap: 20,
        }}
      >
        {!isCameraOn && (
          <MaterialCommunityIcons
            name="camera-off"
            size={24}
            style={{
              backgroundColor: "#1c1e21",
              padding: 5,
              borderRadius: 100,
            }}
            color="white"
          />
        )}
        {isMuted && (
          <Ionicons
            name="mic-off"
            size={24}
            style={{
              backgroundColor: "#1c1e21",
              padding: 5,
              borderRadius: 100,
            }}
            color="white"
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  participantInfo: {
    width: "100%",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
});

// import { CUSTOM_PHYX_BANNER_URI } from "@env";
// import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
// import React, { useEffect, useState } from "react";
// import { StyleSheet, View } from "react-native";
// import { Text } from "react-native-rapi-ui";
// import {
//   LocalAudioTrack,
//   LocalDataTrack,
//   LocalParticipant,
//   LocalVideoTrack,
//   RemoteAudioTrack,
//   RemoteDataTrack,
//   RemoteParticipant,
//   RemoteVideoTrack,
// } from "twilio-video";
// import RenderImage from "../../Common/renderImage";
// import AudioTrack from "./AudioTrack";
// import DataTrack from "./DataTrack";
// import ScreenTracks from "./SceeenTracks";
// import VideoTrack from "./VideoTrack";

// interface Props {
//   participant: LocalParticipant | RemoteParticipant;
//   width: number;
//   height: number;
// }

// interface TrackTypes {
//   audioTracks: (LocalAudioTrack | RemoteAudioTrack)[];
//   videoTracks: (LocalVideoTrack | RemoteVideoTrack)[];
//   screenTracks: (LocalVideoTrack | RemoteVideoTrack)[];
//   dataTracks: (LocalDataTrack | RemoteDataTrack)[];
// }

// export default function Participant({ participant, width, height }: Props) {
//   const [tracks, setTracks] = useState<TrackTypes>({
//     audioTracks: [],
//     videoTracks: [],
//     screenTracks: [],
//     dataTracks: [],
//   });

//   const [isMuted, setIsMuted] = useState(false);
//   const [isCameraOn, setIsCameraOn] = useState(false);
//   const [isScreenShareOn, setIsScreenShareOn] = useState(false);
//   const [myDetail, setMyDetail] = useState<any>(null);

//   const trackSubscribed = (track: LocalVideoTrack | RemoteVideoTrack | LocalAudioTrack | RemoteAudioTrack | LocalDataTrack | RemoteDataTrack) => {
//     if (!track) return;

//     setTracks((prevTracks) => {
//       const newTracks = { ...prevTracks };

//       switch (track.kind) {
//         case "audio":
//           newTracks.audioTracks = [...prevTracks.audioTracks, track as LocalAudioTrack | RemoteAudioTrack];
//           setIsMuted(!track.isEnabled);
//           break;
//         case "video":
//           if (track.name === "screen" || track.name === "window") {
//             newTracks.screenTracks = [...prevTracks.screenTracks, track as LocalVideoTrack | RemoteVideoTrack];
//             setIsScreenShareOn(track.isEnabled);
//           } else {
//             newTracks.videoTracks = [...prevTracks.videoTracks, track as LocalVideoTrack | RemoteVideoTrack];
//             setIsCameraOn(track.isEnabled);
//           }
//           break;
//         case "data":
//           newTracks.dataTracks = [...prevTracks.dataTracks, track as LocalDataTrack | RemoteDataTrack];
//           break;
//       }

//       return newTracks;
//     });
//   };

//   const trackUnsubscribed = (track: LocalVideoTrack | RemoteVideoTrack | LocalAudioTrack | RemoteAudioTrack | LocalDataTrack | RemoteDataTrack) => {
//     if (!track) return;

//     setTracks((prevTracks) => {
//       const newTracks = { ...prevTracks };

//       const isScreen = track.name === "screen" || track.name === "window";
//       const trackList = isScreen ? 'screenTracks' : `${track.kind}Tracks`;

//       newTracks[trackList as keyof TrackTypes] = prevTracks[trackList as keyof TrackTypes].filter(t => {
//         if ('name' in t) {
//           return t.name !== track.name;
//         } else if ('sid' in t) {
//           return t.sid !== track.sid;
//         }
//         return false;
//       });

//       return newTracks;
//     });
//   };

//   const trackEnabled = (track: any) => {
//     if (!track) return;
//     const isScreen = track.name === "screen" || track.name === "window";
//     if (track.kind === "audio") {
//       setIsMuted(false);
//     } else if (track.kind === "video") {
//       if (isScreen) {
//         setIsScreenShareOn(true);
//       } else {
//         setIsCameraOn(true);
//       }
//     }
//   };

//   const trackDisabled = (track: any) => {
//     if (!track) return;
//     const isScreen = track.name === "screen" || track.name === "window";
//     if (track.kind === "audio") {
//       setIsMuted(true);
//     } else if (track.kind === "video") {
//       if (isScreen) {
//         setIsScreenShareOn(false);
//       } else {
//         setIsCameraOn(false);
//       }
//     }
//   };

//   useEffect(() => {
//     // Set up event listeners on the participant when the component mounts and clean them up on unmount.
//     const setupTrackListeners = () => {
//       participant.on("trackSubscribed", trackSubscribed);
//       participant.on("trackUnsubscribed", trackUnsubscribed);
//       participant.on("trackPublished", trackSubscribed);
//       participant.on("trackUnpublished", trackUnsubscribed);
//       participant.on("trackEnabled", trackEnabled);
//       participant.on("trackDisabled", trackDisabled);
//       participant.on("trackStarted", trackEnabled);
//       participant.on("trackStopped", trackDisabled);

//       participant.tracks.forEach(publication => {
//         if (publication.track) {
//           trackSubscribed(publication.track);
//           publication.on("enabled", trackEnabled);
//           publication.on("disabled", trackDisabled);
//         }
//       });
//     };

//     setupTrackListeners();

//     return () => {
//       // Clean up all track listeners.
//       participant.removeAllListeners();
//       setTracks({
//         audioTracks: [],
//         videoTracks: [],
//         screenTracks: [],
//         dataTracks: [],
//       });
//     };
//   }, [participant]);

//   const renderIconWithBackground = (name: string, Lib: any) => (
//     <Lib
//       name={name}
//       size={24}
//       style={styles.iconBackground}
//       color="white"
//     />
//   );

//   return (
//     <View key={participant.sid} style={[styles.participantContainer, { width: width, height: height }]}>
//       {tracks.videoTracks.length > 0 ? (
//         tracks.videoTracks.map((track: any) => (
//           <VideoTrack key={`video_${track.sid}`} track={track} />
//         ))
//       ) : (
//         <RenderImage uri={CUSTOM_PHYX_BANNER_URI} size={{ width: '100%', height: '100%' }} />
//       )}

//       {tracks.screenTracks.map((track: any) => (
//         <ScreenTracks key={`screen_${track.sid}`} track={track} />
//       ))}

//       {tracks.audioTracks.map((track: any) => (
//         <AudioTrack key={`audio_${track.sid}`} track={track} />
//       ))}

//       {tracks.dataTracks.map((track: any) => (
//         <DataTrack key={`data_${track.sid}`} track={track} />
//       ))}

//       <Text style={styles.detailText}>
//         {myDetail ? myDetail?.userProfile.name : participant.identity}
//       </Text>

//       <View style={styles.statusIcons}>
//         {!isCameraOn && renderIconWithBackground("camera-off", MaterialCommunityIcons)}
//         {isMuted && renderIconWithBackground("mic-off", Ionicons)}
//       </View>
//     </View>
//   );
// }

// const styles = StyleSheet.create({
//   participantContainer: {
//     flex: 1,
//     marginLeft: 5,
//     marginRight: 5,
//     marginTop: 5,
//     borderRadius: 100,
//   },
//   iconBackground: {
//     backgroundColor: "#1c1e21",
//     padding: 5,
//     borderRadius: 100,
//   },
//   statusIcons: {
//     position: "absolute",
//     top: 5,
//     right: 5,
//     flexDirection: "row",
//     gap: 20,
//     zIndex: 10000,
//   },
//   detailText: {
//     position: "absolute",
//     bottom: 5,
//     right: 5,
//     color: "white",
//     backgroundColor: "#1c1e21",
//     padding: 10,
//     minWidth: 50,
//     textAlign: "center",
//     borderRadius: 100,
//     zIndex: 10000,
//   },
// });

// import { CUSTOM_PHYX_BANNER_URI } from "@env";
// import React, { useEffect, useState } from "react";
// import { StyleSheet, View } from "react-native";
// import { Text } from "react-native-rapi-ui";

// import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
// import {
//   LocalAudioTrack,
//   LocalDataTrack,
//   LocalParticipant,
//   LocalTrack,
//   LocalVideoTrack,
//   RemoteAudioTrack,
//   RemoteDataTrack,
//   RemoteParticipant,
//   RemoteTrack,
//   RemoteVideoTrack,
// } from "twilio-video";
// import RenderImage from "../../Common/renderImage";
// import AudioTrack from "./AudioTrack";
// import DataTrack from "./DataTrack";
// import ScreenTracks from "./SceeenTracks";
// import VideoTrack from "./VideoTrack";

// interface Props {
//   participant: LocalParticipant | RemoteParticipant;
//   width: number;
//   height: number;
// }

// export default function Participant({ participant, width, height }: Props) {
//   const [videoTracks, setVideoTracks] = useState<
//     (LocalVideoTrack | RemoteVideoTrack)[]
//   >([]);
//   const [screenTracks, setScreenTracks] = useState<
//     (LocalVideoTrack | RemoteVideoTrack)[]
//   >([]);
//   const [audioTracks, setAudioTracks] = useState<
//     (LocalAudioTrack | RemoteAudioTrack)[]
//   >([]);
//   const [dataTracks, setDataTracks] = useState<
//     (LocalDataTrack | RemoteDataTrack)[]
//   >([]);
//   const [isMuted, setIsMuted] = useState(false);
//   const [isCameraOn, setIsCameraOn] = useState(false);
//   const [isScreenShareOn, setIsScreenShareOn] = useState(false);

//   useEffect(() => {
//     const trackSubscribed = (track: LocalTrack | RemoteTrack) => {
//       if (track == null) return;
//       switch (track.kind) {
//         case "audio":
//           // enable audio track
//           setAudioTracks([...audioTracks, track]);
//           setIsMuted(track.isEnabled);
//           break;
//         case "video":
//           track.name === "screen" || track.name === "window"
//             ? setScreenTracks([...screenTracks, track])
//             : setVideoTracks([...videoTracks, track]);
//           if (track.name === "camera" || track.name === "webcam") {
//             setIsCameraOn(track.isEnabled);
//           }
//           break;
//         case "data":
//           setDataTracks([...dataTracks, track]);
//           break;
//       }
//     };

//     const trackUnsubscribed = (track: any) => {
//       if (track == null) return;
//       switch (track.kind) {
//         case "audio":
//           setAudioTracks((track: any) =>
//             audioTracks.filter((t: any) => t !== track)
//           );
//           break;
//         case "video":
//           track.name === "screen" ||
//           track.name === "window" ||
//           track.trackName === "screen" ||
//           track.trackName === "window"
//             ? setScreenTracks((track: any) =>
//                 screenTracks.filter((t: any) => t !== track)
//               )
//             : setVideoTracks((track: any) =>
//                 videoTracks.filter(
//                   (t: any) => t !== track && t.name === track.name
//                 )
//               );
//           break;
//         case "data":
//           setDataTracks((track: any) =>
//             dataTracks.filter((t: any) => t !== track)
//           );
//           break;
//       }
//     };

//     const trackEnabled = (track: any) => {
//       if (track == null) return;
//       switch (track.kind) {
//         case "audio":
//           setIsMuted(false);
//           break;
//         case "video":
//           track.name === "screen" ||
//           track.name === "window" ||
//           track.trackName === "screen" ||
//           track.trackName === "window"
//             ? setIsScreenShareOn(true)
//             : setIsCameraOn(true);
//           break;
//       }
//     };

//     const trackDisabled = (track: any) => {
//       if (track == null) return;
//       switch (track.kind) {
//         case "audio":
//           setIsMuted(true);
//           break;
//         case "video":
//           track.name === "screen" ||
//           track.name === "window" ||
//           track.trackName === "screen" ||
//           track.trackName === "window"
//             ? setIsScreenShareOn(false)
//             : setIsCameraOn(false);
//           // Remove screen track if camera is on
//           if (
//             track.name === "screen" ||
//             track.name === "window" ||
//             track.trackName === "screen" ||
//             track.trackName === "window"
//           ) {
//             setScreenTracks((track: any) =>
//               screenTracks.filter((t: any) => t !== track)
//             );
//           }
//           break;
//       }
//     };

//     if (participant) {
//       participant.on("trackSubscribed", trackSubscribed);
//       participant.on("trackUnsubscribed", trackUnsubscribed);
//       participant.on("trackPublished", trackSubscribed);
//       participant.on("trackUnpublished", trackUnsubscribed);

//       participant.on("trackEnabled", trackEnabled);
//       participant.on("trackDisabled", trackDisabled);
//       participant.on("trackStarted", trackEnabled);
//       participant.on("trackStopped", trackDisabled);
//     }

//     participant.tracks.forEach((publication: any) => {
//       if (publication.isTrackEnabled) {
//         trackSubscribed(publication.track);
//       } else {
//         trackDisabled(publication.track);
//       }
//       publication.on("enabled", trackEnabled);
//       publication.on("disabled", trackDisabled);
//     });

//     return () => {
//       if (participant) {
//         setVideoTracks([]);
//         setAudioTracks([]);
//         setDataTracks([]);
//         setScreenTracks([]);
//         participant.removeAllListeners();
//       }
//     };
//   }, [participant]);

//   const [myDetail, setMyDetail] = useState<any>(null);

//   // const fetchMyDetail = async (id: string) => {
//   //   try {
//   //     let result = await axios.get(`${PRAC_PORTAL_URL_FOR_MESSAGES}/chat/my/detail?id=${id}`);
//   //     setMyDetail(result?.data);
//   //   } catch (error) {
//   //     console.error("error:", error);
//   //   }
//   // };
//   // useEffect(() => {
//   //   fetchMyDetail(participant.identity);
//   // }, [participant]);

//   return (
//     <View
//       key={participant.sid}
//       style={{
//         flex: 1,
//         width: width,
//         height: height,
//         marginLeft: 5,
//         marginRight: 5,
//         marginTop: 5,
//         borderRadius: 100,
//       }}
//     >
//       {/* <Card.Content> */}
//       {videoTracks.length > 0 ? (
//         videoTracks.map((track) => (
//           console.log("Videotrack", track),
//           <VideoTrack
//             track={track}
//             key={`${
//               (track as LocalVideoTrack).id || (track as RemoteVideoTrack).sid
//             } video`}
//           />
//         ))
//       ) : (
//         <RenderImage
//           uri={CUSTOM_PHYX_BANNER_URI}
//           size={{ width: "100%", height: "100%" }}
//         />
//       )}
//       {screenTracks.length > 0 &&
//         screenTracks.map((track: LocalVideoTrack | RemoteVideoTrack) => (
//           <ScreenTracks
//             track={track}
//             key={`${
//               (track as LocalVideoTrack).id || (track as RemoteVideoTrack).sid
//             } screen`}
//           />
//         ))}
//       {audioTracks.length > 0
//         ? audioTracks.map((track: LocalAudioTrack | RemoteAudioTrack) => (
//             <AudioTrack
//               track={track}
//               key={`${
//                 (track as LocalAudioTrack).id || (track as RemoteAudioTrack).sid
//               } audio`}
//             />
//           ))
//         : null}
//       {dataTracks.length > 0
//         ? dataTracks.map((track: LocalDataTrack | RemoteDataTrack) => (
//             <DataTrack
//               track={track}
//               key={`${
//                 (track as LocalDataTrack).id || (track as RemoteDataTrack).sid
//               } data`}
//             />
//           ))
//         : null}
//       <Text
//         style={{
//           position: "absolute",
//           bottom: 5,
//           right: 5,
//           color: "white",
//           backgroundColor: "#1c1e21",
//           padding: 10,
//           zIndex: 10000,
//           borderRadius: 100,
//           minWidth: 50,
//           textAlign: "center",
//         }}
//       >
//         {myDetail ? myDetail?.userProfile.name : participant.identity}
//       </Text>
//       <View
//         style={{
//           position: "absolute",
//           top: 5,
//           right: 5,
//           zIndex: 10000,
//           flexDirection: "row",
//           gap: 20,
//         }}
//       >
//         {!isCameraOn && (
//           <MaterialCommunityIcons
//             name="camera-off"
//             size={24}
//             style={{
//               backgroundColor: "#1c1e21",
//               padding: 5,
//               borderRadius: 100,
//             }}
//             color="white"
//           />
//         )}
//         {isMuted && (
//           <Ionicons
//             name="mic-off"
//             size={24}
//             style={{
//               backgroundColor: "#1c1e21",
//               padding: 5,
//               borderRadius: 100,
//             }}
//             color="white"
//           />
//         )}
//       </View>
//     </View>
//   );
// }

// const styles = StyleSheet.create({
//   participantInfo: {
//     width: "100%",
//     height: "auto",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "flex-start",
//   },
// });
