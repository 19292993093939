import { CUSTOM_PHYX_BANNER } from "@env";
import * as WebBrowser from "expo-web-browser";
import React, { useContext } from "react";
import {
  Image,
  SafeAreaView,
  ScrollView,
  useWindowDimensions,
  View,
} from "react-native";
import { Card } from "react-native-paper";
import { Text } from "react-native-rapi-ui";

import { LinearGradient } from "expo-linear-gradient";
import BackArrow from "../../components/Chat/backArrow";
import Button from "../../components/Common/Button";
import Header from "../../components/Common/HeaderComponent";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { AuthContext } from "../../provider/AuthProvider";

export default function InitiateCall({ navigation }: any) {
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;

  const handleGoBack = () => {
    navigation.goBack();
  };

  const { userData, showNotificationMenu } = useContext(AuthContext);
  return (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      colors={[colors.buttonWhite, colors.buttonWhite]}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <SafeAreaView>
        <Header navigation={navigation} />
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
          }}
        >
          {isTablet && (
            <BackArrow handleGoBackCallback={handleGoBack} title={"Home"} />
          )}
          <Card
            style={{
              marginTop: isTablet ? 20 : 0,
              width: isTablet ? "80%" : "100%",
              alignSelf: "center",
              zIndex: showNotificationMenu ? -1 : 0,
              flex: 1,
              marginBottom: isTablet ? 8 : 0,
              borderRadius: isTablet ? 10 : 0,
            }}
            contentStyle={{ height: "100%" }}
          >
            <Card.Content style={{ flex: 1, justifyContent: "space-between" }}>
              <View style={{ justifyContent: "center", alignItems: "center" }}>
                <Text
                  style={{
                    fontSize: 25,
                    fontWeight: "900",
                    color: "#2f2f2e",
                  }}
                >
                  Virtual Rehab 1-on-1
                </Text>
                <Text
                  style={{
                    fontSize: 15,
                    color: "#2f2f2e",
                    fontWeight: "800",
                  }}
                >
                  Book Virtual Rehab 1-on-1 with a practitioner,
                </Text>
                <Button
                  mode="text"
                  textColor={colors.primaryColor}
                  onPress={() => {
                    WebBrowser.openBrowserAsync(
                      "https://phyxable.janeapp.com/",
                    );
                  }}
                >
                  Book Now
                </Button>
              </View>
              <View style={{ flexGrow: 1, width: "100%" }}>
                <Image
                  resizeMethod="resize"
                  resizeMode="contain"
                  style={{
                    width: "100%",
                    height: 400,
                  }}
                  source={{ uri: CUSTOM_PHYX_BANNER }}
                />
              </View>
              <View style={{ width: "100%" }}>
                <Button
                  mode="contained"
                  buttonColor={colors.primaryColor}
                  style={{
                    marginBottom: 24,
                    width: isTablet ? 456 : "100%",
                    maxWidth: "100%",
                    alignSelf: "center",
                    borderRadius: 30,
                  }}
                  contentStyle={{ height: 60 }}
                  labelStyle={{
                    fontSize: 40,
                    fontWeight: "700",
                    lineHeight: 40,
                  }}
                  onPress={() => {
                    navigation.navigate(RouteNames.VIDEO_CALL, {
                      roomName: userData?.email,
                      userName: userData?.id,
                    });
                  }}
                >
                  Start Call
                </Button>
                <Button
                  mode="contained"
                  buttonColor={colors.primaryColor}
                  style={{ width: 214, alignSelf: "center" }}
                  onPress={() => {
                    navigation.navigate(RouteNames.CALL_JOIN);
                  }}
                >
                  Join Group Call
                </Button>
              </View>
            </Card.Content>
          </Card>
        </ScrollView>
      </SafeAreaView>
    </LinearGradient>
  );
}
