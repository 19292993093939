import React from "react";
import { useWindowDimensions } from "react-native";
import { Text } from "react-native-paper";
import { themeColor, useTheme } from "react-native-rapi-ui";
import { colors } from "../../constants/colors";
export default function TabBarTextWeb({
  title,
  focused,
}: {
  title: string;
  focused: boolean;
}) {
  const { isDarkmode } = useTheme();
  const { width, height } = useWindowDimensions();
  return (
    <Text
      style={{
        color: focused
          ? isDarkmode
            ? themeColor.white100
            : colors.primaryColor
          : "18214D",
        fontSize: 25,
        fontWeight: "400",
        marginLeft: -25,
        paddingRight: 0,
      }}
    >
      {title}
    </Text>
  );
}
