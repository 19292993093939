import { MaterialIcons } from "@expo/vector-icons";
import React, { useContext, useEffect, useState } from "react";
import {
  FlatList,
  GestureResponderEvent,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from "react-native";
import { Divider, List } from "react-native-paper";
import { useTheme } from "react-native-rapi-ui";
//@ts-ignore
//@ts-ignore
import ReadMore from "react-native-read-more-text";
import { Container } from "typedi";
import { UserService } from "../../../services/user/user.service";
import Button from "../../components/Home/button";
import { ProgramBanner } from "../../components/Program/ProgramBanner";
import EquipmentItem from "../../components/Program/equipmentItem";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { AuthContext } from "../../provider/AuthProvider";
import { IProgram } from "../../types/program.types";
import Loading from "../utils/Loading";

export default function ProgramDetails({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) {
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;
  const { isDarkmode, setTheme } = useTheme();

  const { userData, allPrograms } = useContext(AuthContext);

  const { programId } = route.params;
  const handleGoBack = () => {
    navigation.goBack();
  };

  if (!programId) {
    navigation.goBack(console.log("ProgramDetails.tsx: program is undefined"));
  }

  const [programData, setProgramData] = useState<IProgram>(
    allPrograms?.find((p) => p.id_ === route.params.programId) as IProgram,
  );
  const [equipment, setEquipment] = useState(null);
  const [phyxExists, setPhyxExists] = useState<boolean>(false);
  const [isUserPaid, setIsUserPaid] = useState(false);
  const [loading, setLoading] = useState(true);

  const userServices = Container.get(UserService);

  useEffect(() => {
    if (!userData || !programData) {
      handleGoBack();
    }
    const getUserData = async () => {
      const [_user, paidStatus] = await Promise.all([
        userServices.getUserById(),
        userServices.isPhyxablePlus(userData?.id as string),
      ]);
      setIsUserPaid(paidStatus.data);
      const _phyxExists =
        userData?.phyxes?.find((p) => p === programData.id_) !== undefined;
      setPhyxExists(_phyxExists);
    };
    getUserData().then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!programData) return;

    let equipment = [];
    for (let i = 0; i < programData.equipment.length; i++) {
      equipment.push({
        image: programData.equipment_image[i],
        name: programData.equipment[i],
      });
    }
    //@ts-ignore
    setEquipment(equipment);
  }, [programData]);

  const handleJoinProgram = () => {
    navigation.navigate(RouteNames.PROGRAM_CONFIRMATION, {
      equipment: equipment,
      title: programData.title,
      id_: programData.id_,
    });
  };

  //Style and view for 'Read more' button
  const renderTruncatedFooter = (
    handlePress: ((event: GestureResponderEvent) => void) | undefined,
  ) => {
    return (
      <Text style={{ color: colors.primaryColor }} onPress={handlePress}>
        Read more
      </Text>
    );
  };

  //Style and view for 'Show less' button
  const renderRevealedFooter = (
    handlePress: ((event: GestureResponderEvent) => void) | undefined,
  ) => {
    return (
      <Text style={{ color: colors.primaryColor }} onPress={handlePress}>
        Show less
      </Text>
    );
  };

  //Rendering each video content within sessions
  const ProgramVideos = ({ item, showLock }: { item: any; showLock: any }) => {
    return (
      <View>
        {item.session_title ? (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "80%",
              }}
            >
              <Text numberOfLines={1} style={styles.videoTitle}>
                {item.session_title}
              </Text>
              <Text style={styles.videoSubHeading}>
                {item?.time
                  ? `${item?.time} secs`
                  : item?.reps
                    ? `${item?.reps} reps x ${item.sets} sets`
                    : null}
              </Text>
            </View>
            {showLock ? (
              <View
                style={{
                  width: "20%",
                  alignItems: "flex-end",
                }}
              >
                <MaterialIcons name="lock-outline" size={20} color="black" />
              </View>
            ) : null}
          </View>
        ) : null}
        {item.session_title ? <Divider style={styles.divider} /> : null}
      </View>
    );
  };

  //Rendering each session within levels
  const ProgramSessions = ({ item, level }: { item: any; level: any }) => {
    let showLock = true;

    if (!isUserPaid) {
      if (level === 1 && item.session === 1) {
        showLock = false;
      }
    } else {
      showLock = false;
    }

    return (
      <View style={styles.sessionContainer}>
        <View style={styles.sessionHeading}>
          <Text style={styles.sessionTitle}>Session {item.session}</Text>
        </View>
        <View style={styles.videoContainer}>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={item.pages}
            renderItem={({ item }) => (
              <ProgramVideos item={item} showLock={showLock} />
            )}
            keyExtractor={(i) => item.pages.indexOf(i)}
            ListEmptyComponent={<Loading />}
          />
        </View>
      </View>
    );
  };

  //Rendering level within program
  const ProgramLevels = ({ level }: { level: any }) => {
    return (
      <List.Accordion
        title={`Level ${level}`}
        id={level}
        style={styles.list}
        theme={{ colors: { text: colors.black } }}
      >
        <FlatList
          showsVerticalScrollIndicator={false}
          //@ts-ignore
          data={programData.phyxes?.[level] || []}
          renderItem={({ item }) => (
            <ProgramSessions item={item} level={level} />
          )}
          keyExtractor={(item) => item.session}
          ListEmptyComponent={<Loading />}
        />
      </List.Accordion>
    );
  };

  return (
    <SafeAreaView>
      <ScrollView style={{ height: "100%", backgroundColor: "#fff" }}>
        {!loading ? (
          <View>
            {/* Image background heading view */}
            <ProgramBanner
              title={programData.title}
              image={programData.images.banner}
              handleGoBack={handleGoBack}
            />

            {/* Content view */}
            <View style={styles.contentView}>
              {/* OverView */}
              <Text style={styles.sectionHeading}>Overview</Text>
              <ReadMore
                numberOfLines={5}
                renderTruncatedFooter={renderTruncatedFooter}
                renderRevealedFooter={renderRevealedFooter}
              >
                <Text>{programData.overview}</Text>
              </ReadMore>

              {/* Equipment */}
              <View>
                <Text style={styles.sectionHeading}>Equipment</Text>
                <FlatList
                  showsVerticalScrollIndicator={false}
                  data={equipment}
                  numColumns={2}
                  renderItem={({ item }: { item: any }) => (
                    <EquipmentItem name={item.name} image={item.image} />
                  )}
                  keyExtractor={(item) => item}
                  ListEmptyComponent={<Loading />}
                />
              </View>

              {/* Program overview */}
              <View style={{ marginBottom: 20 }}>
                <Text style={styles.sectionHeading}>Program Overview</Text>
                <List.AccordionGroup>
                  <FlatList
                    showsVerticalScrollIndicator={false}
                    data={programData.level}
                    renderItem={({ item }) => <ProgramLevels level={item} />}
                    //@ts-ignore
                    keyExtractor={(item) => programData.level.indexOf(item)}
                    ListEmptyComponent={<Loading />}
                  />
                </List.AccordionGroup>
              </View>

              {/* Button view */}
              <View style={styles.button}>
                <Button
                  text={phyxExists ? "Already joined" : "JOIN PROGRAM"}
                  disabled={phyxExists ? true : false}
                  backgroundColor={
                    phyxExists ? colors.textColor : colors.primaryColor
                  }
                  customStyle={{
                    alignSelf: "center",
                  }}
                  onPressCallback={handleJoinProgram}
                />
              </View>
            </View>
          </View>
        ) : (
          <Loading />
        )}
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  contentView: {
    paddingHorizontal: 20,
  },
  sectionHeading: {
    fontWeight: "400",
    fontSize: 16,
    color: colors.secondaryColor,
    marginTop: 20,
    marginBottom: 10,
  },
  list: {
    backgroundColor: "white",
    paddingHorizontal: 0,
  },
  sessionContainer: {
    flexDirection: "row",
  },
  sessionHeading: {
    width: "35%",
  },
  videoContainer: {
    width: "65%",
  },
  videoTitle: {
    fontSize: 15,
    fontWeight: "700",
    color: colors.secondaryColor,
    marginBottom: 5,
  },
  videoSubHeading: {
    fontSize: 13,
    color: colors.textColor,
  },
  divider: {
    marginBottom: 5,
    marginTop: 5,
  },
  sessionTitle: {
    color: colors.secondaryColor,
    fontWeight: "700",
    marginLeft: 7,
  },
  button: {
    position: "relative",
    justifyContent: "center",
    width: "100%",
    marginBottom: 20,
  },
});
