import { Ionicons } from "@expo/vector-icons";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import {
  Button,
  Text,
  TextInput,
  themeColor,
  useTheme,
} from "react-native-rapi-ui";

import { LinearGradient } from "expo-linear-gradient";
import AuthHeader from "../../components/Auth/AuthHeader";
import { colors } from "../../constants/colors";
import { AuthStackParamList } from "../../types/navigation";

let firebaseAuth: any = null;
let firebaseApp: any = null;
if (Platform.OS == "web") {
  firebaseAuth = require("firebase/auth");
} else {
  firebaseAuth = require("@react-native-firebase/auth");
  firebaseApp = require("@react-native-firebase/app");
}

export default function ({
  navigation,
}: NativeStackScreenProps<AuthStackParamList, "Login">) {
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;
  const { isDarkmode: isDarkMode, setTheme } = useTheme();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  async function login() {
    setLoading(true);
    if (Platform.OS == "web") {
      try {
        const auth = getAuth();
        const res = await signInWithEmailAndPassword(auth, email, password);
        if (res) {
          setLoading(false);
        }
      } catch (error: any) {
        switch (error?.code) {
          case "auth/user-not-found":
          case "auth/wrong-password":
            setError("Incorrect username or password");
            break;
          default:
            setError("Something went wrong please try again");
        }
        setLoading(false);
      }
    } else {
      try {
        const auth = firebaseApp.default.auth();
        const res = await firebaseAuth.signInWithEmailAndPassword(
          auth,
          email,
          password,
        );
        if (res) {
          setLoading(false);
        }
      } catch (error) {
        setError("Incorrect email or password");
        setLoading(false);
      }
    }
  }
  return (
    <KeyboardAvoidingView behavior="height" enabled style={{ flex: 1 }}>
      <>
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
          colors={[colors.buttonWhite, colors.buttonWhite]}
          style={{
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          <ScrollView
            contentContainerStyle={{
              flexGrow: 1,
              width: isTablet ? 500 : "100%",
              alignSelf: "center",
            }}
          >
            <AuthHeader />
            <View
              style={{
                flex: 3,
                paddingHorizontal: 20,
                paddingBottom: 20,
              }}
            >
              <Text
                fontWeight="bold"
                style={{
                  alignSelf: "center",
                  padding: 30,
                  fontFamily: "Roboto-Bold",
                }}
                size="h3"
              >
                Login
              </Text>
              <TextInput
                containerStyle={{ marginTop: 15 }}
                placeholder="Email"
                value={email}
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect={false}
                keyboardType="email-address"
                onChangeText={(text) => setEmail(text)}
                leftContent={
                  <Ionicons
                    name="mail-outline"
                    size={20}
                    color={colors.primaryColor}
                    style={{ marginRight: 10 }}
                  />
                }
              />
              <TextInput
                containerStyle={{ marginTop: 15 }}
                placeholder="Password"
                value={password}
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect={false}
                secureTextEntry={!showPassword}
                onChangeText={(text) => setPassword(text)}
                onSubmitEditing={() => login()}
                leftContent={
                  <Ionicons
                    name="lock-closed-outline"
                    size={20}
                    color={colors.primaryColor}
                    style={{ marginRight: 10 }}
                  />
                }
                rightContent={
                  <Ionicons
                    name={showPassword ? "eye-outline" : "eye-off-outline"}
                    size={20}
                    color={themeColor.black}
                    style={{ marginRight: 10 }}
                    onPress={() => {
                      setShowPassword(!showPassword);
                    }}
                  />
                }
              />
              <Button
                text={loading ? "Loading" : "Sign in"}
                textStyle={{ fontWeight: "100" }}
                onPress={() => {
                  login();
                }}
                style={{
                  marginTop: 20,
                }}
                color={colors.primaryColor}
                disabled={loading || email == "" || password == ""}
                size="md"
              />
              {error && (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 15,
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ color: themeColor.danger }}>{error}</Text>
                </View>
              )}
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 10,
                  justifyContent: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate("ForgetPassword");
                  }}
                >
                  <Text size="md">Forgot Password ?</Text>
                </TouchableOpacity>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 15,
                  justifyContent: "center",
                }}
              >
                <Text size="md">Don't have an account?</Text>
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate("Register");
                  }}
                >
                  <Text
                    size="md"
                    style={{
                      marginLeft: 5,
                    }}
                  >
                    Sign up
                  </Text>
                </TouchableOpacity>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 30,
                  justifyContent: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    isDarkMode ? setTheme("light") : setTheme("dark");
                  }}
                >
                  <Text
                    size="md"
                    fontWeight="bold"
                    style={{
                      marginLeft: 5,
                    }}
                  >
                    {/* {isDarkMode ? "☀️ light theme" : "🌑 dark theme"} */}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </ScrollView>
        </LinearGradient>
      </>
    </KeyboardAvoidingView>
  );
}
