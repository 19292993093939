import { Ionicons } from "@expo/vector-icons";
import React, { useEffect } from "react";
import { Image, Platform, TouchableOpacity, View } from "react-native";
import { Badge } from "react-native-paper";
import { themeColor, useTheme } from "react-native-rapi-ui";
import { APP_IMAGES } from "../../../assets/images";
import NotificationMenu from "../../components/Modals/NotificationMenu";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { AuthContext } from "../../provider/AuthProvider";

export default function WebHeader({ navigation, width }: any) {
  const { isDarkmode, setTheme } = useTheme();
  const { userData, showNotificationMenu, setShowNotificationMenu } =
    React.useContext(AuthContext) as any;
  const [notifications, setNotifications] = React.useState<any>([]);

  React.useEffect(() => {
    setNotifications(userData?.notifications);
  }, [userData?.notifications]);

  const handleNotificationPress = () => {
    //@ts-ignore
    setShowNotificationMenu(!showNotificationMenu);
  };

  useEffect(() => {
    if (showNotificationMenu && Platform.OS === "web") {
      window.addEventListener("click", (e) => {
        if (showNotificationMenu) {
          setShowNotificationMenu(false);
        }
      });
    }
  }, [showNotificationMenu]);

  return (
    <View
      style={{
        flexDirection: "row",
        height: 100,
        width: width,
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 100,
      }}
    >
      <TouchableOpacity
        onPress={() => {
          navigation.reset({
            index: 0,
            routes: [{ name: RouteNames.HOME }],
          });
        }}
      >
        {/* <BackArrow navigation={navigation} /> */}
        <Image
          resizeMethod="resize"
          resizeMode="contain"
          style={{
            width: 200,
            height: 60,
          }}
          source={APP_IMAGES.logoWebinar}
        />
      </TouchableOpacity>
      {/* <Ionicons
              name={isDarkmode ? "sunny" : "moon"}
              size={20}
              color={themeColor.white100}
              style={{
                borderRadius: 10,
                backgroundColor: isDarkmode
                  ? themeColor.dark
                  : colors.textColor,
                padding: 3,
              }}
              onPress={() => {
                setTheme(isDarkmode ? "light" : "dark");
              }}
            /> */}
      <Ionicons
        name="notifications"
        size={36}
        color={themeColor.white100}
        style={{
          borderRadius: 10,
          backgroundColor: !showNotificationMenu
            ? colors.blueColor
            : colors.primaryColor,
          paddingHorizontal: 12,
          paddingVertical: 10,
        }}
        onPress={handleNotificationPress}
      >
        {notifications?.filter((item: any) => item.read === false)?.length >
          0 && (
          <Badge
            style={{
              position: "absolute",
              top: 8,
              right: 40,
              width: 25,
              height: 25,
              borderRadius: 100,
              fontSize: 15,
              lineHeight: 24,
            }}
          >
            {notifications?.filter((item: any) => item.read === false)?.length >
            0
              ? notifications?.filter((item: any) => item.read === false)
                  ?.length
              : 0}
          </Badge>
        )}
      </Ionicons>
      {showNotificationMenu && (
        <View
          style={{
            position: "absolute",
            top: "100%",
            right: 0,
            width: "50%",
            height: "600%",
          }}
        >
          <NotificationMenu navigation={navigation} />
        </View>
      )}
    </View>
  );
}
