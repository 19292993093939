import React from "react";
import { ActivityIndicator, View } from "react-native";
import { colors } from "../../constants/colors";

export default function LoadingOver() {
  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: "white",
        opacity: 0.5,
      }}
    >
      <ActivityIndicator size="large" color={colors.primaryColor} />
    </View>
  );
}
