import { useEffect, useState } from "react";
import { useWindowDimensions } from "react-native";

const breakpoints = {
  xs: 430,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1100,
};

export default function useSize() {
  const [currentBreakpoint, setCurrentBreakpoint] = useState("");
  const { width } = useWindowDimensions();

  useEffect(() => {
    let activeBreakpoint = "";
    if (width < breakpoints.xs) {
      activeBreakpoint = "xs";
    } else if (width < breakpoints.sm) {
      activeBreakpoint = "sm";
    } else if (width < breakpoints.md) {
      activeBreakpoint = "md";
    } else if (width < breakpoints.lg) {
      activeBreakpoint = "lg";
    } else if (width < breakpoints.xl) {
      activeBreakpoint = "xl";
    } else {
      activeBreakpoint = "xxl";
    }
    setCurrentBreakpoint(activeBreakpoint);
  }, [width]);

  const isDesktop = currentBreakpoint === "xxl";
  const isWeb = currentBreakpoint === "lg" || currentBreakpoint === "xl";
  const isTablet = currentBreakpoint === "md";
  const isMobile = currentBreakpoint === "sm" || currentBreakpoint === "xs";
  const isMiniMobile = currentBreakpoint === "xs";

  return {
    currentBreakpoint,
    isDesktop,
    isWeb,
    isTablet,
    isMobile,
    isMiniMobile,
  };
}
