import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";

export default function Button({
  text = "Button",
  backgroundColor,
  onPressCallback,
  boxShadow = false,
  color = "white",
  customStyle,
  disabled = false,
  fontSize = 14,
  fontWeight = "normal",
}) {
  const shadowProp = {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.1,
    shadowRadius: 9,
    elevation: 2,
  };
  const boxShadowStyles = boxShadow ? shadowProp : null;

  return (
    <TouchableOpacity
      disabled={disabled}
      style={[
        styles.container,
        { backgroundColor: backgroundColor },
        boxShadowStyles,
        customStyle,
      ]}
      onPress={onPressCallback}
    >
      <Text
        style={{
          color: color,
          textAlign: "center",
          fontSize: fontSize,
          fontWeight: fontWeight,
        }}
      >
        {text}
      </Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    alignSelf: "flex-start",
    justifyContent: "center",
  },
});
