import { MaterialIcons } from "@expo/vector-icons";
import React from "react";
import { Dimensions, StyleSheet, TouchableOpacity } from "react-native";
import { colors } from "../../constants/colors";

export const BackButton = ({ handleGoBackCallback }: any) => {
  const handleGoBack = () => {
    handleGoBackCallback();
  };
  const { width } = Dimensions.get("window");
  const isTablet = width >= colors.tabletSize;

  return (
    <TouchableOpacity
      style={[
        styles.arrow,
        { width: isTablet ? 35 : 25, height: isTablet ? 35 : 25 },
      ]}
      onPress={handleGoBack}
    >
      <MaterialIcons
        name="arrow-back-ios"
        size={isTablet ? 20 : 15}
        color={colors.secondaryColor}
        style={{ width: 10 }}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  arrow: {
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 5,
    backgroundColor: "white",
    margin: 5,
  },
});
