import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
} from "@env";
import { initializeApp } from "firebase/app";
import { getAuth, onIdTokenChanged } from "firebase/auth";

import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { createContext, useEffect, useState } from "react";
import { Platform } from "react-native";
import { PendoSDK } from "rn-pendo-sdk";
import Container from "typedi";
import { ProgramService } from "../../services/program/program.service";
import { UserService } from "../../services/user/user.service";
import {
  CustomWorkouts,
  IProgram,
  IVideo,
  ProgramType,
} from "../types/program.types";
import { PatientsPopup } from "../types/types";
import { mergeTwoProgress } from "../utils/userProfiles";

let firebaseApp: any = null;
let firebaseAuth: any = null;

if (Platform.OS !== "web") {
  firebaseApp = require("@react-native-firebase/app");
  firebaseAuth = require("@react-native-firebase/auth");
}

export type AuthContextProps = {
  user: null | boolean;
  userData: UserData | null;
  allPrograms: IProgram[] | [];
  customPrograms: CustomWorkouts[] | [];
  showModals: boolean;
  modalData: ModalData;
  showNotificationMenu: boolean;
  allReminders: any[] | [];
  videoCache: VideoCache;
  setAllReminders: (reminders: any[]) => void;
  setShowNotificationMenu: (show: boolean) => void;
  setUserLocalData: (userData: UserData) => void;
  setLocalPrograms: (programs: IProgram[]) => void;
  setLocalCustomPrograms: (customPrograms: CustomWorkouts[]) => void;
  setUser: (user: boolean) => void;
  setModalData: (data: ModalData) => void;
  setShowModals: (show: boolean) => void;
  setLocalVideoCache: (cache: VideoCache) => void;
};

const AuthContext = createContext<Partial<AuthContextProps>>({
  user: null,
  userData: null,
  allPrograms: [],
  showModals: false,
  showNotificationMenu: false,
  modalData: {
    headerImage: "",
    bodyImage: "",
    footerImage: "",
    title: "",
    bodyTitle: "",
    bodyList: [],
    type: "",
    button1: "",
    button2: "",
    button1Action: () => {},
    button2Action: () => {},
  },
  videoCache: {},
  setUserLocalData: (userData: UserData) => {},
  setLocalPrograms: (programs: any) => {},
  setLocalCustomPrograms: (customPrograms: any) => {},
  setUser: (user: any) => {},
  setModalData: (data: any) => {},
  setShowModals: (show: any) => {},
  setShowNotificationMenu: (show: any) => {},
  setLocalVideoCache: (cache: VideoCache) => {},
});

interface Props {
  children: React.ReactNode;
}

export type VideoCache = Record<string, IVideo>;

export interface ChatData {
  type: "chat";
  timestamp: string;
  userId: string;
  chatRoomId: string;
}

export interface NotificationData {
  body: string;
  data: ChatData;
  date: string;
  id: string;
  read: boolean;
  title: string;
}

export interface UserData {
  id: string | null;
  name: string | null;
  email: string | null;
  photoUrl: string | null;
  currentProgram: IProgram | CustomWorkouts | null;
  userProfile: IUserProfile;
  phyxes: string[];
  customPrograms: CustomWorkouts[] | [];
  popup: PatientsPopup[] | [];
  notifications: NotificationData[];
  offlineProgram: string | undefined;
}

export interface IUserProfile {
  email: string;
  name: string;
  lastName: string;
  progress: {
    [key: string]: Array<null | any>;
  } & {
    customPhyx: {
      [programId: string]: ICustomProgress;
    };
  };
  about: string;
  stripe_customer_id: string | null;
  subscription: {
    paid: boolean;
    plan: any;
    status: any;
    current_period_end: any;
  };
}

export interface ICustomProgress {
  created_by: string;
  created_by_name: string;
  program: string;
  progress: ICustomProgressData;
}

export type ICustomProgressData = {
  [level: number]: {
    date: string;
    timestamp: number;
    session: number;
    difficulty: number;
    outcome_forms?: IOutcomeFormResult[];
  }[];
};

export type IOutcomeFormResult = {
  type: "VAS" | "PSFS";
  question?: string;
  answer: number;
};

export interface ModalData {
  headerImage?: string;
  bodyImage?: string;
  footerImage?: string;
  title?: string;
  bodyTitle?: string;
  bodyList?: string[];
  type?: string;
  button1?: string;
  button2?: string;
  button1Action?: () => void;
  button2Action?: () => void;
}

const dayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
//Initialize Firebase
const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
};

const authApp = initializeApp(firebaseConfig);

const AuthProvider = (props: Props) => {
  const auth = getAuth(authApp);

  // user null = loading
  const [user, setUser] = useState<null | boolean>(null);
  const [userData, setUserData] = useState<null | UserData>(null);
  const [allPrograms, setAllPrograms] = useState<IProgram[] | []>([]);
  const [videoCache, setVideoCache] = useState<VideoCache>();
  const [customPrograms, setCustomPrograms] = useState<CustomWorkouts[] | []>(
    [],
  );
  const [allReminders, setAllReminders] = useState<any[] | []>([]);
  const [showModals, setShowModals] = useState<boolean>(false);
  const [showNotificationMenu, setShowNotificationMenu] =
    useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalData>({
    title: "",
    headerImage: "",
    bodyImage: "",
    footerImage: "",
    bodyTitle: "",
    bodyList: [],
    type: "",
    button1: "",
    button2: "",
    button1Action: () => {},
    button2Action: () => {},
  });
  const userService = Container.get(UserService);
  const programService = Container.get(ProgramService);

  const getUserData = async (id: string, email: string) => {
    const [customPhyxes, [data]] = await Promise.all([
      await (await programService.getCustomProgramsByUserId(id)).data,
      await (
        await userService.getUserById()
      ).data,
      // await (await userService.getUserByEmail(email)).data,
    ]);
    customPhyxes.forEach((program: IProgram) => {
      program["type"] = ProgramType.Custom;
    });
    const customPhyxesIds = customPhyxes.map((program: CustomWorkouts) => {
      return program._id;
    });
    const _useableData = data;
    if (_useableData) {
      const userProfile = _useableData?.userProfile || null;
      const phyxes = _useableData?.phyxes ? _useableData?.phyxes : ["posture"];
      const progress = _useableData?.progress || null;
      const _userData = {
        id: _useableData.id,
        name: _useableData.name,
        email: _useableData.email,
        photoUrl: _useableData.photoUrl,
        currentProgram: _useableData?.currentProgram || null,
        //currentCustomPhyx: customPhyxes.length > 0 ? customPhyxes[0].id : null,
        userProfile: userProfile,
        phyxes: [...phyxes, ...customPhyxesIds],
        customPhyxes: customPhyxes,
        progress: progress,
        popup: _useableData.popup || [],
        notifications: _useableData.notifications || [],
      };
      // remove duplicate phyxes
      _userData.phyxes = _userData.phyxes.filter(
        (item: string, index: number) => {
          return _userData.phyxes.indexOf(item) === index;
        },
      );
      return _userData;
    } else {
      return null;
    }
  };

  function handleWebAuthChange(u: any) {
    if (u) {
      if (u.metadata.creationTime === u.metadata.lastSignInTime) {
        checkIfUserExists(u.uid).then((exists) => {
          if (!exists) {
            setUser(false);
            setUserData(null);

            return;
          }
        });
      }
      if (auth?.currentUser === null) {
        setUser(false);
        setUserData(null);
        return;
      }
      setVideoCache({});
      getUserData(u.uid, u.email as string)
        .then((data) => {
          if (data === undefined || data === null) {
            setUser(false);
            setUserData(null);
            return;
          }
          if (Platform.OS === "web") {
            // @ts-ignore
            pendo.initialize({
              visitor: { id: u.uid },
              account: { id: u.email },
            });
          } else {
            PendoSDK.startSession(u.uid, u.email);
          }
          //@ts-ignore
          setUserData({
            //currentProgram: data.currentProgram,
            userProfile: data.userProfile,
            phyxes: data.phyxes,
            customPrograms: data.customPhyxes,
            id: u.uid,
            name: u.displayName,
            email: u.email,
            photoUrl: u.photoURL,
            popup: data.popup,
            notifications: data.notifications,
          });
          if (userData?.userProfile === null) {
            setUser(false);
          } else {
            setUser(true);
            // handleNotificationPreferences(u.uid);
          }
        })
        .catch((err) => {
          console.log(err);
          setUser(false);
          setUserData(null);
        });
    } else {
      setUser(false);
      setUserData(null);
    }
  }

  const handleAuthChangeMobile = async (u: any) => {
    const auth = u?._auth;
    if (u && auth) {
      if (u.metadata.creationTime === u.metadata.lastSignInTime) {
        checkIfUserExists(u.uid).then((exists) => {
          if (!exists) {
            setUser(false);
            setUserData(null);
            return;
          }
        });
      }
      if (auth?.currentUser === null) {
        setUser(false);
        setUserData(null);
        return;
      }
      const userDataStr = await AsyncStorage.getItem("userData");
      let userData = userDataStr ? (JSON.parse(userDataStr) as UserData) : null;
      if (userData?.id !== u.uid || userData?.email !== u.email) {
        userData = null;
      } else {
        const videoCacheData = await AsyncStorage.getItem("videoCache");
        if (videoCacheData) {
          setVideoCache(JSON.parse(videoCacheData));
        } else {
          setVideoCache({});
        }
      }
      try {
        const data = await getUserData(u.uid, u.email as string);
        if (data === undefined || data === null) {
          setUser(false);
          setUserData(null);
          return;
        }
        if (Platform.OS === "web") {
          // @ts-ignore
          pendo.initialize({
            visitor: { id: u.uid },
            account: { id: u.email },
          });
        } else {
          PendoSDK.startSession(u.uid, u.email);
        }
        data.userProfile.progress = mergeTwoProgress(
          data?.userProfile?.progress,
          userData?.userProfile?.progress,
          userData?.offlineProgram,
        );
        //@ts-ignore
        setUserData({
          offlineProgram: userData?.offlineProgram,
          //currentProgram: data.currentProgram,
          userProfile: data.userProfile,
          phyxes: data.phyxes,
          customPrograms: data.customPhyxes,
          id: u.uid,
          name: u.displayName,
          email: u.email,
          photoUrl: u.photoURL,
          popup: data.popup,
          notifications: data.notifications,
        });
        if (userData?.userProfile === null) {
          setUser(false);
        } else {
          setUser(true);
          // handleNotificationPreferences(u.uid);
        }
      } catch (err) {
        console.log("Auth Change Error", err);
        const data = await AsyncStorage.getItem("allPrograms");
        if (data) {
          //setAllPrograms(JSON.parse(data));
        }
        setUserData(userData);
        if (userData) {
          setUser(true);
        } else {
          setUser(false);
        }
      }
    } else {
      setUser(false);
      setUserData(null);
    }
  };

  const checkIfUserExists = async (id: string) => {
    const [data] = await (await userService.getUserById()).data;
    if (data) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (videoCache && Platform.OS !== "web") {
      AsyncStorage.setItem("videoCache", JSON.stringify(videoCache));
    }
  }, [videoCache]);

  useEffect(() => {
    if (userData && Platform.OS !== "web") {
      AsyncStorage.setItem("userData", JSON.stringify(userData));
    }
  }, [userData]);

  useEffect(() => {
    if (allPrograms.length > 0 && Platform.OS !== "web") {
      AsyncStorage.setItem("allPrograms", JSON.stringify(allPrograms));
    }
  }, [allPrograms]);

  useEffect(() => {
    checkLogin();
  }, []);

  function checkLogin() {
    if (Platform.OS === "web") {
      onIdTokenChanged(auth, handleWebAuthChange);
    } else {
      //Check if firebase app is already initialized
      if (!firebaseApp?.default?.apps?.length) {
        firebaseApp.default.initializeApp(firebaseConfig);
        // get all apps initialized
        const apps = firebaseApp.default.apps;
        // get the auth app
        const auth = firebaseApp.default.auth();
        // listen to auth state changes
        firebaseAuth.onIdTokenChanged(auth, handleAuthChangeMobile);
      } else {
        // app is already initialized add the listener
        const auth = firebaseApp.default.auth();
        firebaseAuth.onIdTokenChanged(auth, handleAuthChangeMobile);
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        userData,
        allPrograms,
        customPrograms,
        showModals,
        modalData,
        showNotificationMenu,
        allReminders,
        videoCache,
        setAllReminders: (reminders: any[]) => {
          setAllReminders(reminders);
        },
        setUserLocalData: (data: any) => {
          setUserData(data);
        },
        setLocalPrograms: (programs: IProgram[]) => {
          setAllPrograms(programs as IProgram[]);
        },
        setUser: (user: any) => {
          setUser(user);
        },
        setModalData: (data: any) => {
          setModalData(data);
        },
        setShowModals: (show: any) => {
          setShowModals(show);
        },
        setLocalCustomPrograms: (customPrograms: CustomWorkouts[]) => {
          setCustomPrograms(customPrograms);
        },
        setShowNotificationMenu: (show: any) => {
          setShowNotificationMenu(show);
        },
        setLocalVideoCache: (cache: VideoCache) => {
          setVideoCache(cache);
        },
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
