import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React, { useEffect, useState } from "react";
import { SafeAreaView, StyleSheet, Text, View } from "react-native";

import RenderImage from "../../components/Common/renderImage";
import Button from "../../components/Home/button";
import { BackButton } from "../../components/Program/backButton";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { RootStackParamList } from "../../types/navigation";
import {
  ExerciseType,
  IProgram,
  Interstitial as InterstitialType,
} from "../../types/program.types";

export type InterstitialRouteParams = {
  id: string;
  image: string;
  interstitial: InterstitialType;
  level: number;
  session: number;
  vas: ExerciseType[];
  psfs: ExerciseType[];
  data: IProgram;
};

type Props = NativeStackScreenProps<
  RootStackParamList,
  RouteNames.INTERSTITIAL
>;

export default function Interstitial({ navigation, route }: Props) {
  const { id, image, interstitial, level, session, vas, psfs, data } =
    route.params;

  const [text, setText] = useState("");
  const [interstitialImage, setInterstitialImage] = useState<string | null>(
    null,
  );
  const [isSvg, setIsSvg] = useState(false);

  useEffect(() => {
    //We join because each text line is kept in separate indexes
    const joinAllIndexes = interstitial.blurb.join(" ");

    setText(joinAllIndexes);
    setInterstitialImage(interstitial.image);

    //We check for file type because the svg uses different library
    const fileType = interstitial.image.substr(interstitial.image.length - 3);
    if (fileType === "svg") {
      setIsSvg(true);
    }
  }, [interstitial]);

  const handleButton = () => {
    if (vas.length !== 0) {
      navigation.navigate(RouteNames.VAS, {
        id: id,
        image: image,
        level: level,
        session: session,
        psfs: psfs,
        data: data,
      });
    } else {
      if (psfs.length !== 0) {
        navigation.navigate(RouteNames.PSFS, {
          id: id,
          image: image,
          level: level,
          session: session,
          psfs: psfs,
          data: data,
        });
      } else {
        navigation.navigate(RouteNames.PLAYLIST, {
          id: id,
          image: image,
          level: level,
          session: session,
          vas: vas,
          psfs: [],
          data: data,
        });
      }
    }
  };

  const handleGoBack = () => {
    navigation.goBack();
  };

  return (
    <SafeAreaView style={styles.container}>
      <BackButton handleGoBackCallback={handleGoBack} />
      <View style={styles.content}>
        <Text style={styles.text}>{text}</Text>
        {interstitialImage ? (
          <View>
            <RenderImage
              size={{
                width: 230,
                height: 230,
              }}
              uri={interstitialImage}
              style={styles.image}
            />
          </View>
        ) : null}

        <Button
          text="NEXT"
          backgroundColor={colors.secondaryColor}
          customStyle={{
            alignSelf: "center",
            paddingHorizontal: 60,
            marginBottom: 20,
          }}
          onPressCallback={handleButton}
        />
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    backgroundColor: "white",
  },
  content: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
    paddingHorizontal: 10,
  },
  text: {
    fontSize: 24,
    color: colors.secondaryColor,
    fontWeight: "700",
    textAlign: "center",
  },
  image: {
    width: 230,
    height: 230,
    marginTop: 30,
  },
});
