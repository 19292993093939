import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { View, useWindowDimensions } from "react-native";
import { useTheme } from "react-native-rapi-ui";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import AccomplishmentsStack from "../../stacks/Accomplishments";
import ProgramDashBoard from "./ProgramDashBoard";

const TopTab = createMaterialTopTabNavigator();

export default function HomeTabView() {
  const { isDarkmode, setTheme } = useTheme();
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;

  return (
    <View style={{ height: "100%" }}>
      <TopTab.Navigator
        initialRouteName={RouteNames.PROGRAM_MAIN_DASHBOARD}
        style={{
          marginTop: 2,
          width: isTablet ? width * 0.8 : width * 0.9,
          alignSelf: "center",
          backgroundColor: "white",
          // height: 400,
        }}
        screenOptions={{
          swipeEnabled: true,
          tabBarActiveTintColor: colors.primaryColor,
          tabBarScrollEnabled: false,
          tabBarLabelStyle: {
            fontSize: 16,
            fontWeight: "500",
            textTransform: "none",
          },
          tabBarIndicatorStyle: {
            backgroundColor: colors.primaryColor,
          },
        }}
      >
        <TopTab.Screen
          name={RouteNames.PROGRAM_MAIN_DASHBOARD}
          component={ProgramDashBoard}
        />
        <TopTab.Screen
          name={RouteNames.ACCOMPLISHMENTS}
          component={AccomplishmentsStack}
        />
      </TopTab.Navigator>
    </View>
  );
}
