import { LinearGradient } from "expo-linear-gradient";
import React, { useContext } from "react";
import { Platform, TouchableOpacity, View } from "react-native";
import { AirbnbRating, Input } from "react-native-elements";
import { Text } from "react-native-paper";
import Container from "typedi";
import { UserService } from "../../../services/user/user.service";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { AuthContext } from "../../provider/AuthProvider";

export default function GiveFeedback({ navigation }: any) {
  const { userData } = useContext(AuthContext);
  const userService = Container.get(UserService);
  const [feedback, setFeedback] = React.useState("");
  const [rating, setRating] = React.useState(0);

  const reviewArr = ["Terrible", "Bad", "Okay", "Good", "Great"];

  const handleFeedback = async () => {
    if (feedback) {
      const res = await userService.sendEmailToSupport(userData?.id as string, {
        feedback: `[Platform: ${Platform.OS}]  ${feedback}`,
        rating: `${rating} / ${reviewArr.length}`,
      });
      if (res) {
        navigation.navigate(RouteNames.ACCOUNT_MAIN, {
          screen: RouteNames.ACCOUNT_DETAIL,
        });
      }
    }
  };

  const handleGoBack = () => {
    navigation.goBack();
  };

  return (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      colors={[colors.buttonWhite, colors.buttonWhite]}
      style={{
        flex: 1,
        width: "100%",
        height: "100%",
        position: "absolute",
      }}
    >
      <Text
        style={{
          fontSize: 20,
          fontWeight: "bold",
          margin: 20,
          color: colors.primaryColor,
        }}
      >
        Give Feedback
      </Text>
      <View style={{ flex: 1, alignItems: "center", width: "80%" }}>
        <Input
          placeholder="Comment"
          leftIcon={{ type: "font-awesome", name: "comment" }}
          onChangeText={(value) => setFeedback(value)}
        />
        <Text
          style={{
            color: colors.primaryColor,
            fontSize: 20,
            fontWeight: "bold",
            padding: 10,
          }}
        >
          Rate Us
        </Text>
        <AirbnbRating
          count={5}
          reviews={reviewArr}
          defaultRating={0}
          size={20}
          onFinishRating={(value) => setRating(value)}
          starContainerStyle={{ padding: 10 }}
        />

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-evenly",
            width: "80%",
            marginTop: 20,
          }}
        >
          <TouchableOpacity
            onPress={handleFeedback}
            style={{
              backgroundColor: colors.primaryColor,
              padding: 10,
              borderRadius: 10,
              width: "auto",
              alignItems: "center",
            }}
          >
            <Text style={{ color: "white" }}>Send</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={handleGoBack}
            style={{
              backgroundColor: colors.primaryColor,
              padding: 10,
              borderRadius: 10,
              width: "auto",
              alignItems: "center",
            }}
          >
            <Text style={{ color: "white" }}>Cancel</Text>
          </TouchableOpacity>
        </View>
      </View>
    </LinearGradient>
  );
}
