import React from "react";
import {
  ActivityIndicator,
  Image,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { colors } from "../../constants/colors";

interface Props {
  loading: boolean;
  onClose: () => void;
  onContinue: () => void;
}
export default function OfflineModal({ onClose, onContinue, loading }: Props) {
  const { width } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;

  if (loading) {
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          padding: 20,
        }}
      >
        <ActivityIndicator
          size="large"
          color={colors.primaryColor}
          style={{ marginBottom: 10 }}
        />
        <Image
          source={require("../../webScreens/assets/downloading.png")}
          style={{ width: 130, height: 80, marginBottom: 10 }}
        />
        <Text
          style={{
            fontSize: isTablet ? 20 : 10,
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: 10,
            fontFamily: "futura",
          }}
        >
          Please wait while we prepare your program for offline access...
        </Text>
      </View>
    );
  }
  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        padding: 20,
      }}
    >
      <Image
        source={require("../../webScreens/assets/posture.png")}
        style={{ width: 160, height: 160 }}
      />
      <Text
        style={{
          fontSize: isTablet ? 20 : 10,
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: 10,
          fontFamily: "futura",
        }}
      >
        Please note, you can only access one program offline.
      </Text>
      <Text
        style={{
          fontSize: isTablet ? 20 : 10,
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: 8,
          fontFamily: "futura",
        }}
      >
        If you choose a new one, it replaces any previously selected program.
      </Text>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          paddingHorizontal: 20,
        }}
      >
        <TouchableOpacity
          onPress={onClose}
          style={{
            backgroundColor: colors.blueColor,
            padding: isTablet ? 15 : 10,
            borderRadius: 100,
            marginTop: 10,
            minWidth: isTablet ? 150 : 100,
          }}
        >
          <Text
            style={{
              color: "white",
              fontSize: isTablet ? 20 : 12,
              fontWeight: "400",
              textAlign: "center",
            }}
          >
            Cancel
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={onContinue}
          style={{
            backgroundColor: colors.blueColor,
            padding: isTablet ? 15 : 10,
            borderRadius: 100,
            marginTop: 10,
            minWidth: isTablet ? 150 : 100,
          }}
        >
          <Text
            style={{
              color: "white",
              fontSize: isTablet ? 20 : 12,
              fontWeight: "400",
              textAlign: "center",
            }}
          >
            Continue
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
