import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Button from "../../../components/Home/button";
import { BackButton } from "../../../components/Program/backButton";
import { colors } from "../../../constants/colors";
import { RouteNames } from "../../../constants/routeNames";
import { RootStackParamList } from "../../../types/navigation";
import { IOutcomeForm } from "../../../types/program.types";

export type CustomStopInterstitialParams = {
  id: string;
  image: any;
  session: any;
  outcome_forms: IOutcomeForm[];
};

type Props = NativeStackScreenProps<
  RootStackParamList,
  RouteNames.CUSTOM_STOP_INTERSTITIAL
>;

export default function CustomStopInterstitial({ navigation, route }: Props) {
  const { id, image, session, outcome_forms } = route.params;

  const insets = useSafeAreaInsets();

  const handleButton = () => {
    //Don't show VAS
    if (outcome_forms?.length !== 0) {
      let multipleForm = false;
      if (outcome_forms?.length > 1) multipleForm = true;
      const VAS = outcome_forms?.filter((obj: any) => obj.type === "VAS");
      const PSFS = outcome_forms?.filter((obj: any) => obj.type === "PSFS");
      if (VAS && VAS.length !== 0) {
        navigation.navigate(RouteNames.CUSTOM_VAS, {
          id: id,
          image: image,
          todaySession: session,
          vas: VAS,
          multipleForm: multipleForm,
          psfs: PSFS,
        });
      } else if (PSFS && PSFS.length !== 0) {
        navigation.navigate(RouteNames.CUSTOM_PSFS, {
          id: id,
          image: image,
          todaySession: session,
          psfs: PSFS,
        });
      }
    } else {
      navigation.navigate(RouteNames.CUSTOM_PLAYLIST, {
        id: id,
        image: image,
        todaySession: session,
        outcome_forms: [],
      });
    }
  };

  const handleGoBack = () => {
    navigation.navigate(RouteNames.CUSTOMPHYX_OVERVIEW, {
      id,
    });
  };

  return (
    <View style={[styles.container, { top: insets.top }]}>
      <BackButton handleGoBackCallback={handleGoBack} />
      <View style={styles.content}>
        <Text style={styles.text}>How are you feeling today?</Text>
      </View>
      <ScrollView style={{ height: "100%", backgroundColor: "white" }}>
        <View style={styles.content}>
          <Text style={styles.intText}>
            If you are experiencing any of the following symptoms, please take a
            rest day and return to the Phyx when you are feeling better.
          </Text>
        </View>

        <View style={styles.pointContent}>
          <Text style={styles.points}>Low energy</Text>
          <Text style={styles.points}>Dizziness</Text>
          <Text style={styles.points}>Shortness of breath</Text>
          <Text style={styles.points}>Chest pain</Text>
          <Text style={styles.points}>Fever</Text>
        </View>

        <View style={styles.pointContent}>
          <Text style={styles.subText}>
            If at any time during the session you start to feel unwell or
            experience dizziness, shortness of breath, or chest pain, click the
            STOP button to end the session.
          </Text>
        </View>
        <Button
          text="Proceed to Session"
          backgroundColor={colors.secondaryColor}
          fontSize={20}
          customStyle={{
            alignSelf: "center",
            marginTop: 20,
            width: 250,
            height: 50,
            borderRadius: 50,
          }}
          onPressCallback={handleButton}
        />

        <Button
          text="Stop"
          backgroundColor="#ffffff"
          color={colors.secondaryColor}
          fontSize={20}
          customStyle={{
            alignSelf: "center",
            marginTop: 10,
            width: 250,
            height: 50,
            borderRadius: 50,
            borderWidth: 1,
            borderColor: colors.secondaryColor,
          }}
          onPressCallback={() =>
            navigation.reset({
              index: 0,
              routes: [{ name: RouteNames.HOME }],
            })
          }
        />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    height: "100%",
    paddingHorizontal: 10,
    top: 0,
  },
  pointContent: {
    marginTop: 16,
    marginLeft: 12,
    marginRight: 12,
  },
  content: {
    alignItems: "center",
    marginTop: 10,
    paddingHorizontal: 10,
  },
  text: {
    fontSize: 24,
    color: colors.secondaryColor,
    fontWeight: "700",
    textAlign: "center",
  },
  points: {
    fontSize: 20,
    marginTop: 5,
    color: colors.primaryColor,
    fontWeight: "700",
    textAlign: "center",
  },
  intText: {
    fontSize: 16,
    color: colors.secondaryColor,
    fontWeight: "300",
    textAlign: "center",
    marginTop: 10,
  },
  subText: {
    fontSize: 14,
    color: colors.secondaryColor,
    fontWeight: "200",
    textAlign: "center",
    marginTop: 10,
    paddingBottom: 200,
  },
  image: {
    width: 230,
    height: 230,
    marginTop: 30,
  },
});
