import React from "react";
import { SafeAreaView, View } from "react-native";
import BackArrow from "../components/Chat/backArrow";
import Header from "../components/Common/HeaderComponent";
import NotificationMenu from "../components/Modals/NotificationMenu";

export default function MobileNotifications({ navigation }: any) {
  const handleGoBackCallback = () => {
    navigation.goBack();
  };
  return (
    <SafeAreaView
      style={{
        flex: 1,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
      }}
    >
      <View
        style={{
          width: "100%",
          height: 50,
          backgroundColor: "white",
          borderBottomColor: "white",
          borderWidth: 0,
        }}
      >
        <Header navigation={navigation} />
      </View>
      <BackArrow handleGoBackCallback={handleGoBackCallback} />
      <NotificationMenu navigation={navigation} />
    </SafeAreaView>
  );
}
