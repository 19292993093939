import { StyleSheet, Text, View } from "react-native";
import { styles } from "./commonStyles";

export default function RenderTextMessage(
  message: any,
  index: number,
  isPatient: boolean,
) {
  return (
    <View
      key={`message_${index}+${isPatient ? "patient" : "prac"}`}
      style={isPatient ? styles.patientMessageColumn : styles.pracMessageColumn}
    >
      <View
        style={
          isPatient ? textStyles.patientMessageText : textStyles.pracMessageText
        }
      >
        <Text
          style={{
            color: isPatient ? "#FFF" : "#18214D",
            fontWeight: "400",
            fontSize: 14,
          }}
        >
          {message.data}
        </Text>
      </View>
      <View style={isPatient ? styles.patientTimeStamp : styles.pracTimeStamp}>
        <Text
          style={{
            color: "#6C7993",
            fontWeight: "400",
            fontSize: 12,
            //fontFamily: "Proxima Nova",
          }}
        >
          {new Date(message.timestamp).toLocaleString()}
        </Text>
      </View>
    </View>
  );
}

const textStyles = StyleSheet.create({
  patientMessageText: {
    // width: "auto",
    backgroundColor: "#18214D",
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderBottomLeftRadius: 24,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
  },
  pracMessageText: {
    // width: "auto",
    backgroundColor: "#EBEBEC",
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 24,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
  },
});
