import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FlatList, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import Container from "typedi";
import { UserService } from "../../../services/user/user.service";
import Header from "../../components/Common/HeaderComponent";
import HomeTabView from "../../components/Home/TabView";
import WelcomeCard from "../../components/Home/WelcomeCard";
import { getData } from "../../components/Home/carouselCommon";
import ProgramChip from "../../components/Program/programChip";
import { AuthContext, UserData } from "../../provider/AuthProvider";
import { HomeStackParamList } from "../../types/navigation";
import {
  CustomWorkouts,
  IProgram,
  ProgramType,
} from "../../types/program.types";
import Loader from "../utils/Loading";
import LoadingOver from "../utils/LoadingOver";

type HomeScreenProps = {
  navigation: NativeStackNavigationProp<HomeStackParamList>;
};

export default function HomePortrait({ navigation }: HomeScreenProps) {
  const [programIndex, setProgramIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [changing, setChanging] = useState(false);
  const [data, setData] = useState<(CustomWorkouts | IProgram)[]>([]);
  const { userData, setUserLocalData, allPrograms } = useContext(
    AuthContext,
  ) as any;

  const userService = Container.get(UserService);

  useEffect(() => {
    getData(
      userData as UserData,
      setData,
      allPrograms as IProgram[],
      setUserLocalData,
    )
      .then((data) => {
        setLoading(false);
      })
      .catch((error) => {
        console.log("getData error:", error);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    getData(
      userData as UserData,
      setData,
      allPrograms as IProgram[],
      setUserLocalData,
    ).then((data) => {
      setLoading(false);
    });
  }, [userData?.phyxes]);

  useEffect(() => {
    // Reshuffle data and set the current program to the first phyx
    if (userData?.currentProgram) {
      const originalPhyxes = [...userData?.phyxes];
      const indexofCurrentProgram = originalPhyxes.indexOf(
        (userData?.currentProgram as IProgram).id_ ||
          (userData?.currentProgram as CustomWorkouts)._id,
      );
      if (indexofCurrentProgram > 0) {
        const temp = originalPhyxes[0];
        originalPhyxes[0] = originalPhyxes[indexofCurrentProgram];
        originalPhyxes[indexofCurrentProgram] = temp;
        setUserLocalData({
          ...userData,
          phyxes: originalPhyxes,
          customPrograms: userData.customPrograms.sort((a, b) =>
            originalPhyxes.indexOf(a._id) > originalPhyxes.indexOf(b._id)
              ? 1
              : -1,
          ),
        });
        setProgramIndex(0);
        if (userData?.currentProgram.type !== ProgramType.Custom) {
          userService.updateOrderOfPhyxes(
            userData?.id as string,
            originalPhyxes,
            userData?.email as string,
          );
        }
      }
    }
    if (!userData?.currentProgram && !userData?.phyxes?.length) {
      setData([]);
    }
  }, [userData?.currentProgram]);

  const changeCustomProgram = useCallback(
    (item: IProgram | CustomWorkouts) => {
      setChanging(true);
      setTimeout(() => {
        setChanging(false);
        setUserLocalData({ ...userData, currentProgram: item });
      }, 700);
    },
    [setUserLocalData, userData],
  );

  return (
    <SafeAreaView style={{ height: "100%" }} edges={["top"]}>
      <View style={{ flex: 1 }}>
        <Header navigation={navigation} />
        {!loading ? (
          <View
            style={{
              backgroundColor: "white",
              borderBottomColor: "white",
              borderWidth: 0,
              flex: 1,
            }}
          >
            <WelcomeCard
              name={userData?.userProfile?.name || ""}
              copilot={null}
            />
            <View style={{ paddingHorizontal: 10 }}>
              <FlatList
                horizontal
                data={data}
                contentContainerStyle={{
                  marginVertical: 10,
                  marginHorizontal: 4,
                }}
                renderItem={({
                  item,
                  index,
                }: {
                  item: IProgram | CustomWorkouts;
                  index: number;
                }) => {
                  return item.type === ProgramType.Custom ? (
                    <RenderCustomChip
                      item={item as CustomWorkouts}
                      _index={index}
                      programIndex={programIndex}
                      changeCustomProgram={changeCustomProgram}
                    />
                  ) : (
                    <RenderChip
                      item={item as IProgram}
                      _index={index}
                      programIndex={programIndex}
                      changeCustomProgram={changeCustomProgram}
                    />
                  );
                }}
                keyExtractor={(item) =>
                  (item as IProgram).id || (item as CustomWorkouts)._id
                }
                showsHorizontalScrollIndicator={true}
              />
            </View>
            <View style={{ position: "relative", flex: 1, height: "auto" }}>
              <HomeTabView />
              {changing && <LoadingOver />}
            </View>
          </View>
        ) : (
          <Loader />
        )}
      </View>
    </SafeAreaView>
  );
}

type RenderChipProps = {
  item: IProgram;
  _index: number;
  programIndex: number;
  changeCustomProgram: (item: IProgram | CustomWorkouts) => void;
};

const RenderChip = ({
  item,
  _index,
  programIndex,
  changeCustomProgram,
}: RenderChipProps) => {
  return (
    <ProgramChip
      item={item}
      selected={_index === programIndex}
      onChipPressedCallback={changeCustomProgram}
    >
      {item.title}
    </ProgramChip>
  );
};

type RenderCustomChipProps = {
  item: CustomWorkouts;
  _index: number;
  programIndex: number;
  changeCustomProgram: (item: IProgram | CustomWorkouts) => void;
};

const RenderCustomChip = ({
  item,
  _index,
  programIndex,
  changeCustomProgram,
}: RenderCustomChipProps) => {
  return (
    <ProgramChip
      item={item}
      selected={_index === programIndex}
      onChipPressedCallback={changeCustomProgram}
    >
      {item.program_name}
    </ProgramChip>
  );
};
