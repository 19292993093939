import React, { useCallback, useEffect, useMemo, useState } from "react";
import { LayoutChangeEvent, StyleSheet } from "react-native";
import { Card, Chip, Text } from "react-native-paper";
import { colors } from "../../constants/colors";
import useSize from "../../hooks/useSize";

interface Props {
  title: string | null;
  text: string;
  often?: string | null;
  isCustom?: boolean;
}

export default function CardContent({ title, text, often, isCustom }: Props) {
  const [lines, setLines] = useState(1);
  const [lineHeight, setLineHeight] = useState(-1);
  const [contentHeight, setContentHeight] = useState(-1);
  const { isDesktop, isTablet, isWeb } = useSize();

  const fontSize = useMemo(() => {
    return isDesktop ? 18 : isWeb ? 20 : isTablet ? 18 : 16;
  }, [isDesktop, isWeb, isTablet]);

  const handleDescriptionLayout = useCallback((e: LayoutChangeEvent) => {
    setContentHeight(e.nativeEvent.layout.height);
  }, []);

  const handleTextLayout = useCallback(
    (e: LayoutChangeEvent) => {
      if (lines === 1) {
        setLineHeight(e.nativeEvent.layout.height);
      }
    },
    [lines],
  );

  useEffect(() => {
    if (contentHeight === -1) {
      return;
    }
    const newLines = Math.floor((contentHeight - 8) / fontSize / 1.2);
    setLines(newLines);
  }, [contentHeight, fontSize]);

  return (
    <>
      <Card.Title
        titleStyle={{
          fontSize: fontSize * 1.2,
          fontWeight: "bold",
        }}
        title={title}
      />
      {isCustom && (
        <Chip
          style={{
            alignSelf: "flex-start",
            backgroundColor: colors.primaryColor,
            marginLeft: 16,
            marginBottom: 10,
          }}
          textStyle={{
            color: "white",
          }}
        >
          Practitioner Assigned
        </Chip>
      )}
      <Card.Content
        style={{ flex: 1, flexGrow: 1 }}
        onLayout={handleDescriptionLayout}
      >
        <Text
          onLayout={handleTextLayout}
          style={{
            fontSize,
            lineHeight: fontSize * 1.2,
            marginBottom: 10,
          }}
          numberOfLines={lines}
        >
          {text}
        </Text>
      </Card.Content>
      {often && (
        <Card.Actions>
          <Text style={styles.dailyCount}>{often}</Text>
        </Card.Actions>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  dailyCount: {
    color: colors.secondaryColor,
    marginBottom: 8,
  },
});
