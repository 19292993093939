import React, { useEffect, useRef, useState } from "react";

export default function ScreenTracks({ track, navigation, data }: any) {
  const ref = useRef();

  const [borderWidth, setBorderWidth] = useState(0);
  const [borderColor, setBorderColor] = useState("transparent");
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (track) {
      const el = ref.current;
      if (track.trackName === "screen") {
        if (track.track) {
          track.track.attach(el);
        } else {
          return;
        }
        track.on("disabled", () => {
          setDisabled(true);
        });
        track.on("enabled", () => {
          setDisabled(false);
        });
      }
      if (track.name === "screen") {
        const el = ref.current;
        track.attach(el);
        track.on("disabled", () => {
          setDisabled(true);
        });
        track.on("enabled", () => {
          setDisabled(false);
        });
      }
      return () => {
        if (!track.mediaStreamTrack) {
          track?.track?.detach(el);
        }
      };
    }
  }, [track]);

  return (
    <video
      style={{
        width: "100%",
        height: "100%",
        objectFit: "contain",
        borderWidth: borderWidth,
        borderColor: borderColor,
        borderRadius: 10,
        position: "absolute",
        zIndex: disabled ? 0 : 100,
      }}
      //@ts-ignore
      key={track.trackSid}
      autoPlay={true}
      // @ts-ignore
      ref={ref}
    />
  );
}
