import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { colors } from "../../constants/colors";

export default function BackArrow({ handleGoBackCallback, title }: any) {
  const handleGoBack = () => {
    handleGoBackCallback();
  };

  return (
    <TouchableOpacity
      style={[
        styles.arrow,
        {
          borderBottomColor: "white",
          borderWidth: 0,
          zIndex: 100,
        },
      ]}
      onPress={handleGoBack}
    >
      <Ionicons name="arrow-back" size={20} color={colors.secondaryColor} />
      <Text style={{ color: colors.secondaryColor }}>{title}</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  arrow: {
    flexDirection: "row",
    width: "100%",
    height: "5%",
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: 5,
    marginTop: 10,
  },
});
