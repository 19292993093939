import { LinearGradient } from "expo-linear-gradient";
import React, { useContext, useEffect } from "react";
import { Text, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Picker } from "react-native-rapi-ui";
import Container from "typedi";
import { UserService } from "../../../../services/user/user.service";
import CalendarComponent from "../../../components/Account/notifications/Calendar";
import BackArrow from "../../../components/Chat/backArrow";
import Header from "../../../components/Common/HeaderComponent";
import { colors } from "../../../constants/colors";
import { RouteNames } from "../../../constants/routeNames";
import { AuthContext } from "../../../provider/AuthProvider";
import Loader from "../../utils/Loading";

export default function AddOrEditProgramReminders({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) {
  const dayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const { userData, allReminders, setAllReminders } = useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);
  const [selectedProgram, setSelectedProgram] = React.useState<any>();
  const [selectedProgramReminders, setSelectedProgramReminders] =
    React.useState<any>([]);
  const [selectedDay, setSelectedDay] = React.useState("Sun");
  const [selectedTimeList, setSelectedTimeList] = React.useState([]);
  const userService = Container.get(UserService);
  const [pickerLabels, setPickerLabels] = React.useState<any>([]);

  useEffect(() => {
    setLoading(true);
    const customProgramsNames = userData?.customPrograms?.map(
      (e: any) => e.program_name,
    );
    const _mergedPrograms = [
      ...(userData?.phyxes as any),
      ...(customProgramsNames as any),
    ];
    // Remove the duplicates
    userData?.customPrograms?.forEach((e: any) => {
      const index = _mergedPrograms.findIndex((el: any) => el === e._id);
      if (index > -1) {
        _mergedPrograms.splice(index, 1);
      }
    });
    const _pickerLabels = _mergedPrograms.map((e: any) => ({
      label: e.toUpperCase(),
      value: e,
    }));
    setPickerLabels(_pickerLabels);
    setSelectedProgram(
      userData?.phyxes && userData.phyxes.length ? userData.phyxes[0] : "",
    );
    setLoading(false);
  }, [userData?.phyxes]);

  useEffect(() => {
    if (route.params && route.params?.reminder) {
      setLoading(true);
      setSelectedProgram(route.params?.reminder?.item?.program_id);
      setSelectedProgramReminders(
        (route.params?.reminder?.item?.reminders as any) || [],
      );
      const day = route.params?.reminder?.item?.reminders[0]?.day;
      if (day) {
        setSelectedDay(day);
        const timeArray = route.params?.reminder?.item?.reminders[0]?.time;
        if (timeArray) {
          setSelectedTimeList(timeArray);
        }
      }
      setLoading(false);
    }
    setLoading(false);
  }, [route.params?.reminder]);

  const handleGoBack = async () => {
    navigation.navigate(RouteNames.PROGRAM_REMINDERS);
  };

  const handleCalendar = async (data: any) => {
    setLoading(true);
    if (route.params?.reminder) {
      let identifier: any;
      let payload = {};
      // check if the day is already present
      const index = selectedProgramReminders.findIndex(
        (e: any) => e.day === data.day,
      );
      if (index > -1) {
        // day is present
        identifier = selectedProgramReminders[index].id;
        const timeIndex = selectedProgramReminders[index].time.findIndex(
          (e: any) => e === data.time,
        );
        if (timeIndex > -1) {
          return;
        } else {
          // time is not present
          // add time
          const _timeList = selectedProgramReminders[index].time;
          _timeList.push(data.time);
          const _otherDays = selectedProgramReminders.filter(
            (e: any) => e.day !== data.day,
          );
          payload = {
            id: identifier,
            program_id: selectedProgram,
            reminders: [
              ..._otherDays,
              {
                day: data.day,
                time: _timeList,
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
              },
            ],
          };
        }
      } else {
        // day is not present
        // add day

        payload = {
          id: identifier,
          program_id: selectedProgram,
          reminders: [
            ...selectedProgramReminders,
            {
              day: data.day,
              time: [data.time],
              tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          ],
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
      }
      const res = await userService.updateNotificationPreferencesForProgram(
        userData?.id as string,
        payload,
      );
      if (res.data) {
        if (setAllReminders) {
          if (res.data?.notificationPreferences?.length > 0) {
            setAllReminders(
              res.data?.notificationPreferences[0]?.programs || [],
            );
          }
        }
        navigation.navigate(RouteNames.PROGRAM_REMINDERS);
      }
    } else {
      // add reminder
      const payload = {
        program_id: selectedProgram,
        reminders: [
          {
            day: data.day,
            time: [data.time],
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        ],
        id: Math.random().toString(36).substr(2, 9),
      };
      const res = await userService.addProgramNotification(
        userData?.id as string,
        payload,
      );
      if (res.data) {
        if (setAllReminders) {
          if (res.data?.notificationPreferences?.length > 0) {
            setAllReminders(
              res.data?.notificationPreferences[0]?.programs || [],
            );
          }
        }
        navigation.navigate(RouteNames.PROGRAM_REMINDERS);
      }
    }
    setLoading(false);
  };

  const handleCalendarDateDelete = async (data: any) => {
    if (route.params?.reminder) {
      const index = selectedProgramReminders.findIndex(
        (e: any) => e.day === data.day,
      );
      if (index > -1) {
        const identifier = selectedProgramReminders[index].id;
        const _timeList = selectedProgramReminders[index].time;
        const timeIndex = _timeList.findIndex((e: any) => e === data.time);
        if (timeIndex > -1) {
          _timeList.splice(timeIndex, 1);
          const _otherDays = selectedProgramReminders.filter(
            (e: any) => e.day !== data.day,
          );
          const payload = {
            id: identifier,
            program_id: selectedProgram,
            reminders: [
              ..._otherDays,
              {
                day: data.day,
                time: _timeList,
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
              },
            ],
          };
          const res = await userService.updateNotificationPreferencesForProgram(
            userData?.id as string,
            payload,
          );
          if (res.data) {
            navigation.navigate(RouteNames.PROGRAM_REMINDERS);
          }
        }
      }
    }
  };

  const handleDayChange = (itemValue: any, itemIndex: any) => {
    const _reminderForSelectedDayIndex = selectedProgramReminders.findIndex(
      (e: any) => e.day === itemValue,
    );
    if (_reminderForSelectedDayIndex > -1) {
      setSelectedDay(itemValue);
      setSelectedTimeList(
        selectedProgramReminders[_reminderForSelectedDayIndex]?.time || [],
      );
    } else {
      setSelectedDay(itemValue);
      setSelectedTimeList([]);
    }
  };

  return (
    <>
      <LinearGradient
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        colors={[colors.buttonWhite, colors.buttonWhite]}
        style={{
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <Header navigation={navigation} />
        <>
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              height: "5%",
              alignItems: "center",
              justifyContent: "flex-start",
              marginLeft: 5,
              marginTop: 10,
              zIndex: 10000,
            }}
          >
            <BackArrow handleGoBackCallback={handleGoBack} title={"Back"} />
          </View>
          {loading ? (
            <View style={{ marginTop: "25%" }}>
              <Loader />
            </View>
          ) : (
            <ScrollView>
              <View style={{}}>
                <Text
                  style={{
                    fontWeight: "700",
                    color: colors.blueSettings,
                    fontSize: 24,
                    marginBottom: 10,
                    alignSelf: "center",
                  }}
                >
                  {route.params?.reminder ? "EDIT" : "ADD NEW"} PROGRAM REMINDER
                </Text>
              </View>
              <View
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <Text
                  style={{
                    fontWeight: "500",
                    color: colors.blueSettings,
                    fontSize: 22,
                    marginBottom: 10,
                    marginLeft: 20,
                    alignSelf: "flex-start",
                  }}
                >
                  Select Your Program
                </Text>
                <View
                  style={{
                    width: "auto",
                    alignSelf: "flex-start",
                    marginBottom: 20,
                    marginLeft: 20,
                  }}
                >
                  <Picker
                    placeholder={
                      selectedProgram
                        ? selectedProgram?.toUpperCase()
                        : "Select a program"
                    }
                    items={pickerLabels}
                    value={selectedProgram}
                    onValueChange={(value) => setSelectedProgram(value)}
                    disabled={route.params?.reminder ? true : false}
                  />
                </View>

                <View
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginTop: 20,
                    padding: 10,
                  }}
                >
                  <CalendarComponent
                    selectedDay={selectedDay}
                    timeList={selectedTimeList}
                    handleCalendar={handleCalendar}
                    handleDayChange={handleDayChange}
                    handleCalendarDateDelete={handleCalendarDateDelete}
                  />
                </View>
              </View>
            </ScrollView>
          )}
        </>
      </LinearGradient>
    </>
  );
}
