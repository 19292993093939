import * as FileSystem from "expo-file-system";

export function getCacheUri(url: string): string | undefined {
  const path = url.match(/:\/\/[^/]+(\/[^?#]*)/);
  const extractedPath = path ? path[1].split("/").join("_") : null;
  if (!extractedPath) {
    return;
  }
  const fileUri = FileSystem.cacheDirectory + extractedPath;
  return fileUri;
}

export async function downloadVideo(url: string) {
  const cacheUri = getCacheUri(url);
  if (!cacheUri) {
    return;
  }
  try {
    const { exists } = await FileSystem.getInfoAsync(cacheUri);
    if (exists) {
      return;
    }
  } catch {}
  await FileSystem.downloadAsync(url, cacheUri);
}

export async function clearCache() {
  if (!FileSystem.cacheDirectory) {
    return;
  }
  const files = await FileSystem.readDirectoryAsync(FileSystem.cacheDirectory);
  for (const file of files) {
    await FileSystem.deleteAsync(`${FileSystem.cacheDirectory}/${file}`, {
      idempotent: true,
    });
  }
}
