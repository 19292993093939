import Container, { Service } from "typedi";

import { IPatientCreateInput } from "../../types/patient";
import { ApiService } from "../api/api.service";

@Service()
export class UserService extends ApiService {
  public PATIENT_BASE_URL = "/patients";

  constructor() {
    super();
    Container.set(UserService, this);
  }

  async getUsers() {
    return await this.get(this.PATIENT_BASE_URL);
  }

  async getUserById() {
    return await this.get(`${this.PATIENT_BASE_URL}`);
  }

  async createUser(data: IPatientCreateInput) {
    return await this.post(`${this.PATIENT_BASE_URL}`, data);
  }

  async updateUser(id: string, data: any) {
    return await this.patch(`${this.PATIENT_BASE_URL}/${id}`, data);
  }

  async deleteUser() {
    return await this.delete(`${this.PATIENT_BASE_URL}/deleteUser`);
  }

  async signOut() {
    return await this.get(`${this.PATIENT_BASE_URL}/signOut`);
  }

  async getUserByEmail(email: string) {
    return await this.get(`${this.PATIENT_BASE_URL}/email/${email}`);
  }

  async isPhyxablePlus(id: string) {
    return await this.get(`${this.PATIENT_BASE_URL}/isPhyxablePlus/${id}`);
  }

  async updateUserPhyxes(id: string, phyx: any, email: any) {
    return await this.patch(`${this.PATIENT_BASE_URL}/${id}`, {
      phyx: phyx,
      email: email,
    });
  }

  async updateOrderOfPhyxes(id: string, phyx: any, email: any) {
    return await this.patch(`${this.PATIENT_BASE_URL}/phyx/${id}`, {
      phyx: phyx,
      email: email,
    });
  }

  async updateUserCustomPhyx(id: string, customPhyxId: any) {
    return await this.patch(`${this.PATIENT_BASE_URL}/customPhyx/${id}`, {
      id: customPhyxId,
    });
  }

  // Add the progress to the userProfile
  async updateUserProgress(id: string, progress: any, email: any) {
    return await this.patch(`${this.PATIENT_BASE_URL}/progress/${id}`, {
      progress: progress,
      email: email,
    });
  }

  // Get videoCall token by email and name
  async getVideoCallToken(email: string, name: string) {
    return await this.get(
      `/videoChat/token?roomName=${email}&identity=${name || email.split("@")[0]}`,
    );
  }

  // Start the vital stream
  async startVitalStream(id: string) {
    return await this.get(`${this.PATIENT_BASE_URL}/vitals/${id}`);
  }

  async getPatientsPractitioner(id: string) {
    return await this.get(
      `${this.PATIENT_BASE_URL}/patientsPractitioner/${id}`,
    );
  }

  async createChatroom(practitioner_id: string, patient_id: string) {
    const participants = {
      participants: [
        { id: patient_id, type: "patient" },
        { id: practitioner_id, type: "practitioner" },
      ],
    };
    return await this.postPracPortalAPI(`/chat/room`, participants);
  }

  async sendMessageToChatroom(messagePayload: IChatMessage) {
    const _ = await this.postPracPortalAPI(`/chat/message`, messagePayload);
    return _;
  }

  async removeUserPhyxes(id: string, phyx: any, email: any) {
    return await this.put(`${this.PATIENT_BASE_URL}/phyx/${id}`, {
      phyx: phyx,
      email: email,
    });
  }

  async removeUserCustomPhyx(id: string, customPhyxId: any, email: any) {
    return await this.post(`${this.PATIENT_BASE_URL}/customPhyx/${id}`, {
      customPhyxId: customPhyxId,
      email: email,
    });
  }

  async getNotifications(id: string) {
    return await this.get(`${this.PATIENT_BASE_URL}/notifications/${id}`);
  }
  async deleteNotification(id: string, notificationId: string) {
    return await this.post(
      `${this.PATIENT_BASE_URL}/deleteNotification/${id}`,
      {
        notificationId: notificationId,
      },
    );
  }

  async deleteAllNotifications(id: string) {
    return await this.post(
      `${this.PATIENT_BASE_URL}/deleteAllNotifications/${id}`,
    );
  }

  async markNotificationAsRead(id: string, notificationId: string) {
    return await this.post(
      `${this.PATIENT_BASE_URL}/markNotificationAsRead/${id}`,
      {
        notificationId: notificationId,
      },
    );
  }

  // Notifications Preferences
  async getNotificationsPreferences(id: string) {
    return await this.get(
      `${this.PATIENT_BASE_URL}/notificationPreferences/${id}`,
    );
  }

  async updateNotificationsPreferences(id: string, data: any) {
    return await this.patch(
      `${this.PATIENT_BASE_URL}/notificationPreferences/${id}`,
      { notificationPreferences: data },
    );
  }

  // add a program notification to the user
  async addProgramNotification(id: string, data: any) {
    return await this.post(
      `${this.PATIENT_BASE_URL}/addNotificationPreferencesForProgram/${id}`,
      { notificationPreferences: data },
    );
  }

  async updateNotificationPreferencesForProgram(id: string, data: any) {
    return await this.post(
      `${this.PATIENT_BASE_URL}/updateNotificationPreferencesForProgram/${id}`,
      { notificationPreferences: data },
    );
  }

  async deleteAllNotificationPreferencesForProgram(id: string, data: any) {
    return await this.post(
      `${this.PATIENT_BASE_URL}/deleteAllNotificationPreferencesForProgram/${id}`,
      { programId: data },
    );
  }

  async sendEmailToSupport(id: string, message: any) {
    return await this.post(
      `${this.PATIENT_BASE_URL}/sendEmailToSupport/${id}`,
      { message: message },
    );
  }

  async addFCMToken(id: string, token: string, deviceId?: string) {
    return await this.post(`${this.PATIENT_BASE_URL}/addFCMToken/${id}`, {
      token: token,
      deviceId: deviceId,
    });
  }
}

export interface IChatMessage {
  chat_room_id: string;
  practitioner_id: string;
  data: string;
  messageType: string;
  fileName: string | null;
  sender_id: string;
  name: string | null;
  timestamp: Date;
}
