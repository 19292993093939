import { LinearGradient } from "expo-linear-gradient";
import React, { useState } from "react";
import { Button, Text, TextInput } from "react-native-rapi-ui";
import BackArrow from "../../components/Chat/backArrow";
import Header from "../../components/Common/HeaderComponent";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";

export default function JoinRoom({ navigation }: any) {
  const [roomName, setRoomName] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [error, setError] = useState<string>("");
  const handleGoBack = () => {
    navigation.goBack();
  };
  return (
    <>
      <LinearGradient
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        colors={[colors.buttonWhite, colors.buttonWhite]}
        style={{
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <Header navigation={navigation} />
        <BackArrow handleGoBackCallback={handleGoBack} />
        <Text
          fontWeight="bold"
          style={{
            alignSelf: "center",
            marginTop: 20,
            fontSize: 20,
          }}
        >
          Join A Room
        </Text>
        <TextInput
          containerStyle={{
            marginTop: 20,
            width: 200,
            alignSelf: "center",
          }}
          placeholder="Room Name"
          value={roomName}
          onChangeText={(value) => {
            setRoomName(value);
          }}
        />
        <TextInput
          containerStyle={{
            marginTop: 20,
            width: 200,
            alignSelf: "center",
          }}
          placeholder="User Name"
          value={userName}
          onChangeText={(value) => {
            setUserName(value);
          }}
        />
        <Button
          text="Join Call"
          onPress={() => {
            if (!roomName || !userName) {
              setError("Please enter room name and user name");
              return;
            }
            setError("");
            navigation.navigate(RouteNames.VIDEO_CALL, {
              roomName: roomName,
              userName: userName,
            });
          }}
          width={200}
          style={{
            marginTop: 20,
            alignSelf: "center",
          }}
          size="sm"
          outline={false}
          color={colors.playCircle}
        />
        {error && (
          <Text
            style={{
              alignSelf: "center",
              marginTop: 20,
              color: colors.error,
            }}
          >
            {error}
          </Text>
        )}
      </LinearGradient>
    </>
  );
}
