import axios from "axios";
import {
  AVPlaybackStatus,
  Audio,
  InterruptionModeAndroid,
  InterruptionModeIOS,
} from "expo-av";
import { activateKeepAwakeAsync } from "expo-keep-awake";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from "react-native";
import { CountdownCircleTimer } from "react-native-countdown-circle-timer";
import Button from "../../components/Home/button";
import MiniProgramBanner from "../../components/Program/miniProgramBanner";
import VideoPlayer from "../../components/Program/videoPlayer";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { AuthContext, AuthContextProps } from "../../provider/AuthProvider";
import { IMp4, IVideo, VideoReps, VideoTimer } from "../../types/program.types";
import Loading from "../utils/Loading";

interface Params {
  program: {
    title: string;
    id: string;
    level: number;
    session: number;
    image: string;
  };
  video: (VideoReps | VideoTimer)[];
  vas: any;
  psfs: any;
}

export default function Exercise({ route, navigation }: any) {
  const { videoCache } = useContext(AuthContext) as AuthContextProps;
  const { width, height } = useWindowDimensions();
  const isTablet = width > colors.tabletSize;

  const { program, video, vas, psfs } = route.params as Params;

  const videoIndex = useRef(-1);
  const currentSet = useRef(0);
  const BREAK_TIME = 30;

  const [loading, setLoading] = useState(true);
  const [currentVideo, setCurrentVideo] = useState<VideoReps | VideoTimer>();
  const [currentVideoFile, setCurrentVideoFile] = useState<string>();
  const [timerPlayer, setTimerPlayer] = useState(false);
  const [countDownDuration, setCountDownDuration] = useState(0);
  const [setsCompleted, setSetsCompleted] = useState(false);
  const [setCount, setSetCount] = useState(1);
  const [totalSet, setTotalSet] = useState(0);
  const [countDown, setCountDown] = useState(0);
  const [isRep, setIsRep] = useState(false);
  const [isBreakTime, setIsBreakTime] = useState(false);
  const [breakPlayer, setBreakPlayer] = useState(false);
  const [buttonText, setButtonText] = useState("START");
  const [breakCountDown, setBreakCountDown] = useState(BREAK_TIME);

  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [audioObject, setAudioObject] = useState(new Audio.Sound());
  const [audioStatus, setAudioStatus] = useState<AVPlaybackStatus>();

  const MAX_VIDEO_LENGTH = video.length;

  useEffect(() => {
    if (audioObject === null) return;

    loadAudio();
  }, [audioObject]);

  const loadAudio = async () => {
    try {
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: false,
        interruptionModeIOS: InterruptionModeIOS.DoNotMix,
        playsInSilentModeIOS: true,
        staysActiveInBackground: false,
        interruptionModeAndroid: InterruptionModeAndroid.DoNotMix,
        shouldDuckAndroid: false,
      });
      if (audioObject) {
        const status = await audioObject?.loadAsync(
          require("../../../assets/sound/timer.wav"),
          {
            shouldPlay: false,
          },
        );
        setAudioStatus(status);
      }
    } catch (err) {
      console.error("Sound: ", err);
    }
  };

  const playAudio = async () => {
    await audioObject.playAsync();
    await audioObject.setPositionAsync(0);
    setIsAudioPlaying(true);
  };

  useEffect(() => {
    changeVideo();
  }, [video]);

  useEffect(() => {
    if (currentVideo) {
      setLoading(false);

      //There can be time or reps in the video object
      //We are doubling the reps to give user more time to finish the
      //exercise
      setCountDownDuration(
        (currentVideo as VideoTimer).time ||
          (currentVideo as VideoReps).reps * 2 ||
          0,
      );
    }
  }, [currentVideo]);

  useEffect(() => {
    if (isBreakTime) {
      setBreakPlayer(true);
      setTimerPlayer(false);
      setButtonText("SKIP");
    } else {
      setBreakPlayer(false);
      setButtonText("START");
    }
  }, [isBreakTime]);

  //Moves forward to the next video in the session
  const changeVideo = async () => {
    setSetsCompleted(false);
    setSetCount(1);
    setTimerPlayer(false);
    setBreakPlayer(false);
    setButtonText("START");
    videoIndex.current += 1;
    if (videoIndex.current < MAX_VIDEO_LENGTH) {
      const _currentVideo = video[videoIndex.current];
      setCurrentVideo(_currentVideo);
      currentSet.current = _currentVideo?.sets;
      setTotalSet(_currentVideo?.sets || 1);
      fetchVideo(_currentVideo);
      handleCountDownText();
    } else {
      navigation.navigate(RouteNames.QUESTIONNAIRE, {
        program: program,
        vas: vas,
        psfs: psfs,
      });
    }
  };

  //This function is for exercise time update
  const onIntervalUpdate = (remainingTime: number) => {
    if (videoIndex.current < MAX_VIDEO_LENGTH) {
      if ((video[videoIndex.current] as VideoReps)?.reps) {
        if (remainingTime % 2 === 0) {
          setCountDown(countDown - 1);
        }
      } else {
        setCountDown(countDown - 1);
      }
    }
  };

  //This function is for break time update
  const onBreakIntervalUpdate = (
    remainingTime: React.SetStateAction<number>,
  ) => {
    setBreakCountDown(remainingTime);
  };

  const handleGoBack = () => {
    navigation.goBack();
  };

  const handleVideoFinish = () => {
    activateKeepAwakeAsync();
    if (
      currentSet.current > 0 &&
      timerPlayer === false &&
      breakPlayer === false
    ) {
      handleStart();
    }
  };

  const fetchVideo = async (data: VideoReps | VideoTimer) => {
    try {
      const { playlist } =
        videoCache?.[data.videoUrl] ||
        ((await axios.get(data.videoUrl)).data as IVideo);
      const firstPlay = playlist && playlist.length ? playlist[0] : undefined;
      const videoToUse = (
        firstPlay?.sources?.filter(
          (video) =>
            video.file && video.file !== "" && video.type === "video/mp4",
        ) as IMp4[]
      ).sort((a, b) => {
        if (a.height < b.height) {
          return 1;
        } else if (a.height > b.height) {
          return -1;
        } else {
          return 0;
        }
      });

      setCurrentVideoFile(
        videoToUse && videoToUse.length > 0
          ? videoToUse[0].file
          : (playlist || [])[0].sources[videoIndex.current + 1 || 0].file,
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handleStart = () => {
    activateKeepAwakeAsync();
    if (audioStatus) {
      //Play audio only if it's loaded
      if (audioStatus.isLoaded === true) {
        playAudio();
      }
    }
    //If break time is playing then button will skip the
    //break time and move user to next set
    if (isBreakTime) {
      setIsBreakTime(false);
      changeSet();
    }
    setTimerPlayer(true);
    setBreakPlayer(true);
  };

  const handleStop = () => {
    setTimerPlayer(false);
    setBreakPlayer(false);
    setButtonText("RESUME");
  };

  const handleSkip = () => {
    setTimerPlayer(false);
    changeVideo();
  };

  const handleCountDownText = () => {
    const _currentVideo = video[videoIndex.current];
    if ((_currentVideo as VideoReps).reps) {
      setCountDown((_currentVideo as VideoReps).reps + 1);
      setIsRep(true);
    } else {
      setCountDown((_currentVideo as VideoTimer).time + 1);
      setIsRep(false);
    }
  };

  //Changes the set when the timer is up
  //If all sets are completed then timer won't move ahead
  const changeSet = () => {
    if (audioStatus) {
      //Play audio only if it's loaded
      if (audioStatus.isLoaded === true) {
        playAudio();
      }
    }
    setIsBreakTime(false);
    setTimerPlayer(true);
    currentSet.current -= 1;
    if (currentSet.current > 0) {
      setSetCount(setCount + 1);
      setCountDownDuration(
        (currentVideo as VideoTimer).time
          ? (currentVideo as VideoTimer).time
          : (currentVideo as VideoReps)?.reps * 2 || 0,
      );
      handleCountDownText();
    } else {
      setSetsCompleted(true);
      changeVideo();
    }
  };

  const formatTime = (time: number) => {
    if (time <= 60) {
      return `${time}`;
    }
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;

    return `${minutes}:${seconds}`;
  };

  const TimerText = () => {
    return (
      <View>
        {!setsCompleted ? (
          <Text style={styles.timerText}>
            <Text style={styles.timerText}>
              {!isRep ? formatTime(countDown || 0) : countDown || 0}{" "}
              <Text style={styles.staticText}>{isRep ? "reps" : ""}</Text>
            </Text>
          </Text>
        ) : (
          <Text style={styles.timerText}>Done!</Text>
        )}
      </View>
    );
  };

  const BreakTimerText = () => {
    return (
      <View>
        <Text style={styles.timerText}>00:{breakCountDown}</Text>
      </View>
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <SafeAreaView>
      <ScrollView
        style={styles.container}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <View style={{ flexGrow: 1 }}>
          <MiniProgramBanner
            title={program.title}
            goBackCallback={handleGoBack}
            image={program.image}
          />
          <View style={styles.contentView}>
            <Text style={styles.title}>
              Level {program.level}: Session {program.session + 1}
            </Text>
            <Text style={styles.subHeading}>
              {currentVideo?.session_title ?? ""}
            </Text>
            {currentVideoFile ? (
              <View
                style={{
                  width: "100%",
                  flexGrow: 1,
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <VideoPlayer
                  videoFile={currentVideoFile}
                  videoFinishCallback={handleVideoFinish}
                  navigation={navigation}
                  width={isTablet ? width * 0.6 : width * 0.9}
                  height={isTablet ? height * 0.4 : height * 0.25}
                  autoPlay={true}
                />
              </View>
            ) : null}
            <View style={styles.cardContainer}>
              <Text
                style={[styles.title, { alignSelf: "center", marginTop: 5 }]}
              >
                {isBreakTime ? "Break" : currentVideo?.session_title}
              </Text>
              <Text style={styles.setsText}>
                {setCount}/{totalSet} sets
              </Text>
              <View style={styles.timer}>
                {isBreakTime ? (
                  <CountdownCircleTimer
                    isPlaying={breakPlayer}
                    duration={BREAK_TIME}
                    colors={[colors.primaryColor, colors.green]}
                    colorsTime={[countDownDuration, 0]}
                    size={110}
                    strokeWidth={8}
                    rotation="counterclockwise"
                    onUpdate={onBreakIntervalUpdate}
                    onComplete={() => {
                      // if (changeSet()) {
                      //   return { shouldRepeat: true, delay: 1 };
                      // }
                      changeSet();
                    }}
                  >
                    {({ remainingTime, elapsedTime, color }) => (
                      <BreakTimerText />
                    )}
                  </CountdownCircleTimer>
                ) : (
                  <CountdownCircleTimer
                    isPlaying={timerPlayer}
                    duration={countDownDuration}
                    colors={[colors.primaryColor, colors.green]}
                    colorsTime={[countDownDuration, 0]}
                    size={110}
                    strokeWidth={8}
                    rotation="counterclockwise"
                    key={currentVideo?.session_title}
                    onUpdate={onIntervalUpdate}
                    onComplete={() => {
                      setIsBreakTime(true);
                      setTimerPlayer(false);
                      if (audioStatus) {
                        //Play audio only if it's loaded
                        if (audioStatus.isLoaded === true) {
                          playAudio();
                        }
                      }
                    }}
                  >
                    {({ remainingTime, elapsedTime, color }) => <TimerText />}
                  </CountdownCircleTimer>
                )}
              </View>
              <View style={styles.button}>
                {timerPlayer && !setsCompleted ? (
                  <View style={styles.multiButton}>
                    <Button
                      text="SKIP"
                      color={colors.secondaryColor}
                      backgroundColor={colors.primaryColor}
                      customStyle={{
                        borderColor: colors.secondaryColor,
                        borderWidth: 2,
                        paddingHorizontal: 30,
                      }}
                      onPressCallback={handleSkip}
                    />
                    <Button
                      text="PAUSE"
                      backgroundColor={colors.secondaryColor}
                      onPressCallback={handleStop}
                      customStyle={{
                        borderColor: colors.secondaryColor,
                        borderWidth: 2,
                        paddingHorizontal: 30,
                      }}
                    />
                  </View>
                ) : (
                  //Single button view when timer stops or sets are completed
                  <View>
                    <Button
                      text={setsCompleted ? "NEXT" : buttonText}
                      backgroundColor={colors.primaryColor}
                      customStyle={{
                        alignSelf: "center",
                        paddingHorizontal: 30,
                      }}
                      onPressCallback={() =>
                        setsCompleted ? handleSkip() : handleStart()
                      }
                    />
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
  },
  contentView: {
    paddingHorizontal: 20,
    paddingTop: 20,
    flexGrow: 1,
  },
  title: {
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 16,
  },
  subHeading: {
    textTransform: "capitalize",
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 12,
    marginTop: 5,
    marginBottom: 15,
  },
  timer: {
    marginTop: 10,
    alignItems: "center",
  },
  timerText: {
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 28,
  },
  staticText: {
    color: colors.secondaryColor,
    fontSize: 16,
    fontWeight: "400",
  },
  button: {
    marginTop: 20,
    marginBottom: 15,
  },
  multiButton: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  cardContainer: {
    backgroundColor: "white",
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 20,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 5,
  },
  setsText: {
    fontSize: 14,
    color: colors.textColor,
    alignSelf: "center",
  },
});
