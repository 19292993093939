import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import {
  SafeAreaView,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from "react-native";
import { FlatList, ScrollView } from "react-native-gesture-handler";
import Button from "../../components/Home/button";
import MiniProgramBanner from "../../components/Program/miniProgramBanner";
import VideoItem from "../../components/Program/videoItem";
import VideoPlayer from "../../components/Program/videoPlayer";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { AuthContext, AuthContextProps } from "../../provider/AuthProvider";
import {
  IProgram,
  IVideo,
  VideoIntro,
  VideoReps,
  VideoTimer,
} from "../../types/program.types";
import { getTodaysSession } from "../../utils/userProfiles";
import Loader from "../utils/Loading";

// const WIDTH = Dimensions.get("window").width;
// const HEIGHT = Dimensions.get("window").height;
// const isTablet = WIDTH > colors.tabletSize;

interface Params {
  id: string;
  vas: any; // props drilling
  psfs: any; // props drilling
  image: string;
  level: number;
  session: number;
  data: IProgram;
}

export default function ProgramPlaylist({ navigation, route }: any) {
  const {
    id,
    vas,
    image,
    level,
    session,
    psfs,
    data: programData,
  } = route.params as Params;
  const [videos, setVideos] = useState<any[] | null>(null);
  const [currentLevel, setCurrentLevel] = useState(1);
  const [currentSession, setCurrentSession] = useState(0);
  const [introVideo, setIntroVideo] = useState<string>();
  const [loading, setLoading] = useState(true);

  const { width, height } = useWindowDimensions();
  const isTablet = width > colors.tabletSize;

  const { userData, videoCache } = useContext(AuthContext) as AuthContextProps;

  useEffect(() => {
    if (id) {
      fetchProgram();
    } else {
      console.log("Playlist:", "program id null");
    }

    if (level !== undefined && session != undefined) {
      setCurrentLevel(level);
      setCurrentSession(session);
    }
  }, []);

  //If user clicked on a particular session then directly
  //fetch videos for that level and session else
  //fetch which level and session to show
  useEffect(() => {
    if (!programData) return;

    if (level !== undefined && session != undefined) {
      getVideos({ level: level, session: session });
    } else {
      const result = getTodaysSession(userData?.userProfile, programData);

      console.log("Playlist - todays session: ", result);
      setCurrentLevel(result.level);
      setCurrentSession(result.session);

      getVideos(result);
    }
  }, [programData]);

  const getVideos = (result: {
    level: number;
    session: number;
    totalSessions?: any;
  }) => {
    const _videos = programData.phyxes[result.level]?.[
      result.session
    ]?.pages.filter((page) => (page as VideoReps).session_title) as (
      | VideoTimer
      | VideoReps
    )[];
    setVideos(_videos);
    fetchIntroVideo(result);
    setLoading(false);
  };

  const fetchIntroVideo = async (result: {
    level: number;
    session: number;
  }) => {
    const _introVideo = programData.phyxes[result.level][
      result.session
    ].pages.filter(({ type }) => type === "videoIntro") as VideoIntro[];
    try {
      const videoUrl = _introVideo[0]?.videoUrl;
      const { playlist } =
        videoCache?.[videoUrl] || ((await axios.get(videoUrl)).data as IVideo);
      console.log("Playlist - introVideo: ", playlist[0]?.link);
      const __introVideo = playlist[0]?.sources?.filter(
        (video) =>
          video.file &&
          video.file !== "" &&
          video.type === "video/mp4" &&
          video.label === "1080p",
      );
      setIntroVideo(__introVideo ? __introVideo[0].file : playlist[0]?.link);
    } catch (err) {
      console.error("fetchIntroVideo Error:", err);
    }
  };

  const fetchProgram = () => {
    console.log("Playlist - Getting data: ", programData.title);
  };

  const handleGoBack = () => {
    navigation.goBack();
  };

  const handleStartSession = () => {
    navigation.navigate(RouteNames.EXERCISE, {
      program: {
        title: programData.title,
        id: programData.id_,
        level: currentLevel,
        session: currentSession,
        image: image,
      },
      video: videos,
      vas: vas,
      psfs: psfs,
    });
  };

  const Footer = () => {
    return (
      <View>
        <Button
          text="START SESSION"
          backgroundColor={colors.primaryColor}
          customStyle={{
            alignSelf: "center",
            marginTop: 30,
            marginBottom: 20,
            paddingHorizontal: 30,
          }}
          onPressCallback={handleStartSession}
        />
      </View>
    );
  };

  return (
    <SafeAreaView>
      {loading ? (
        <Loader />
      ) : (
        <ScrollView>
          <MiniProgramBanner
            title={programData.title}
            goBackCallback={handleGoBack}
            image={image}
          />
          <View style={styles.container}>
            <Text style={styles.title}>Level {currentLevel}</Text>
            <Text style={styles.subHeading}>Session {currentSession + 1}</Text>
          </View>
          <ScrollView>
            <View style={{ flex: 1, alignItems: "center" }}>
              {introVideo && (
                <VideoPlayer
                  videoFile={introVideo}
                  videoFinishCallback={() => {
                    console.log("Intro video finished");
                  }}
                  navigation={navigation}
                  width={isTablet ? width * 0.6 : width * 0.9}
                  height={isTablet ? height * 0.4 : height * 0.25}
                  autoPlay={true}
                />
              )}
              <Footer />
              {videos && <Text style={styles.subHeading}>Exercise list</Text>}
              {
                videos && (
                  <FlatList
                    data={videos}
                    renderItem={({ item, index }) => (
                      <VideoItem
                        video={item}
                        index={index}
                        navigation={navigation}
                        width={isTablet ? width * 0.6 : width * 0.9}
                        height={isTablet ? height * 0.4 : height}
                      />
                    )}
                    keyExtractor={(item, index) => item.id}
                    style={{ marginTop: 0, alignSelf: "flex-start" }}
                  />
                )

                // videos?.map((video: any, index: number) => {
                //   return (
                //     <View
                //       key={index}
                //       style={{ marginTop: 0, alignSelf: "flex-start" }}
                //     >
                //       <VideoItem
                //         video={video}
                //         index={index}
                //         navigation={navigation}
                //         width={isTablet ? width * 0.6 : width * 0.9}
                //         height={isTablet ? height * 0.4 : height}
                //       />
                //     </View>
                //);
              }
            </View>
          </ScrollView>
        </ScrollView>
      )}
    </SafeAreaView>
  );
}
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingTop: 20,
  },
  title: {
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 16,
  },
  subHeading: {
    textTransform: "capitalize",
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 12,
    marginTop: 5,
    marginBottom: 15,
    alignSelf: "flex-start",
  },
});
