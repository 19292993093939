import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import Video, { Room } from "twilio-video";
import { RouteNames } from "../../../constants/routeNames";

export default function VideoBottomBar({
  navigation,
  room,
  participants,
}: {
  navigation: any;
  room: Room;
  participants: any;
}) {
  const [isMuted, setIsMuted] = useState(false);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isScreenShareOn, setIsScreenShareOn] = useState(false);

  useEffect(() => {
    if (room) {
      // Check the mute and camera status of the local participant
      room.localParticipant.audioTracks.forEach((publication: any) => {
        setIsMuted(publication.track.isEnabled);
      });
      room.localParticipant.videoTracks.forEach((publication: any) => {
        setIsCameraOn(publication.track.isEnabled);
      });
      // Hack
      handleMute();
      setTimeout(() => {
        handleMute();
      }, 1000);
    }
  }, [room]);

  const handleDisconnect = () => {
    if (room) {
      room.disconnect();
      navigation.navigate(RouteNames.CALL_FEEDBACK, {
        roomName: room.name,
        participants: participants,
      });
    }
  };

  const handleMute = () => {
    if (room) {
      room.localParticipant.audioTracks.forEach((publication: any) => {
        if (publication.track.isEnabled) {
          publication.track.disable();
          setIsMuted(true);
        } else {
          publication.track.enable();
          setIsMuted(false);
        }
      });
    }
  };

  const handleCamera = () => {
    if (room) {
      room.localParticipant.videoTracks.forEach((publication: any) => {
        // Turn off the camera not the screen share
        if (
          publication.track.name === "camera" ||
          publication.track.name === "webcam"
        ) {
          if (publication.track.isEnabled) {
            publication.track.disable();
            setIsCameraOn(false);
          } else {
            publication.track.restart();
            publication.track.enable();

            setIsCameraOn(true);
          }
        }
      });
    }
  };

  const handleShareScreen = () => {
    if (room) {
      let allVideoTracks: any[] = [];
      room.localParticipant.videoTracks.forEach((publication: any) => {
        allVideoTracks.push(publication.track);
      });

      const screenTrack = allVideoTracks.find((track) => {
        return (
          track.name === "screen" ||
          track.name === "window" ||
          track.name === "application" ||
          track.name === "browser"
        );
      });
      if (screenTrack) {
        if (screenTrack.isEnabled) {
          room.localParticipant.videoTracks.forEach((publication: any) => {
            if (
              publication.track.name === "screen" ||
              publication.track.name === "window" ||
              publication.track.name === "application" ||
              publication.track.name === "browser"
            ) {
              publication.track.disable();
              room.localParticipant.unpublishTrack(publication.track);
            }
          });

          setIsScreenShareOn(false);
        } else {
          navigator.mediaDevices.getDisplayMedia().then((stream) => {
            const screenTrack = new Video.LocalVideoTrack(
              stream.getTracks()[0],
              {
                name: "screen",
              },
            );
            room.localParticipant.publishTrack(screenTrack);
            setIsScreenShareOn(true);
          });
        }
      } else {
        navigator.mediaDevices.getDisplayMedia().then((stream) => {
          const screenTrack = new Video.LocalVideoTrack(stream.getTracks()[0], {
            name: "screen",
          });
          room.localParticipant.publishTrack(screenTrack);
          setIsScreenShareOn(true);
        });
      }
    }
  };

  const handleChat = () => {};

  const handleSettings = () => {
    if (room) {
    }
  };
  return (
    <>
      <Ionicons
        name="call"
        size={40}
        color="red"
        onPress={() => {
          handleDisconnect();
        }}
      />
      <Ionicons
        name={isMuted ? "mic-off" : "mic"}
        size={40}
        color={"#fff"}
        onPress={() => {
          handleMute();
        }}
      />
      <Ionicons
        name={isCameraOn ? "videocam" : "videocam-outline"}
        size={40}
        color={"#fff"}
        onPress={() => {
          handleCamera();
        }}
      />
      <MaterialIcons
        name={isScreenShareOn ? "stop-screen-share" : "screen-share"}
        size={40}
        color={"#fff"}
        onPress={() => {
          handleShareScreen();
        }}
      />
    </>
  );
}
