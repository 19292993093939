import React, { useMemo } from "react";
import { Platform, StyleSheet } from "react-native";
import { Chip, ChipProps } from "react-native-paper";
import { colors } from "../../constants/colors";

type Props = ChipProps & {
  item: any;
  selected: boolean;
  onChipPressedCallback: (item: any) => void;
};

export default function ProgramChip({
  item,
  selected,
  onChipPressedCallback,
  children,
  ...props
}: Props) {
  const { textStyle = {}, style = {} } = props;
  //Specify the colors to be used for selected and unselected
  const selectedTextColor = colors.primaryColor;
  const unSelectedTextColor = colors.secondaryColor;
  const textColor = useMemo(
    () => (selected ? selectedTextColor : unSelectedTextColor),
    [selected],
  );

  const onChipPressed = () => {
    onChipPressedCallback(item);
  };

  return (
    <Chip
      textStyle={[
        {
          color: textColor,
          fontSize: 16,
        },
        textStyle,
      ]}
      style={[styles.chip, style]}
      onPress={onChipPressed}
    >
      {children}
    </Chip>
  );
}

const styles = StyleSheet.create({
  chip: {
    borderRadius: 20,
    backgroundColor: "#ffff",
    padding: 4,
    // boxShadow: '0px 1px 10px 0px #18214D1A',
    ...Platform.select({
      ios: {
        shadowColor: "#18214D",
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.1,
        shadowRadius: 10,
      },
      web: {
        shadowColor: "#18214D",
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.1,
        shadowRadius: 10,
      },
      android: {
        elevation: 2,
        borderColor: "#18214D33",
        borderWidth: 1,
        borderStyle: "solid",
      },
    }),
    marginRight: 10,
  },
});
