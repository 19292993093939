import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { Text } from "react-native";
import { Card } from "react-native-paper";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";

export default function EmptyCard({ navigation }: any) {
  return (
    <Card
      style={{
        alignSelf: "center",
        marginTop: "25%",
        backgroundColor: "white",
        borderRadius: 0,
        marginHorizontal: 10,
        padding: 10,
      }}
    >
      <Card.Title title="No Program Found" />
      <Card.Content>
        <Text>Please add a program to your profile</Text>
      </Card.Content>
      <Ionicons
        name="add"
        color={"white"}
        style={{
          alignSelf: "center",
          top: 10,
          right: 10,
          position: "absolute",
          zIndex: 100,
          borderRadius: 100,
          padding: 2,
          backgroundColor: colors.blueColor,
        }}
        size={15}
        onPress={() => {
          navigation.navigate(RouteNames.PROGRAM_MAIN);
        }}
      />
    </Card>
  );
}
