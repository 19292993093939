import Container, { Service } from "typedi";
import { ApiService } from "../api/api.service";

@Service()
export class ProgramService extends ApiService {
  public AllPrograms: any[] = [];
  public AllCustomPrograms: any[] = [];
  constructor() {
    super();
    Container.set(ProgramService, this);
  }

  async getPrograms() {
    var _ = await this.get("/programs");
    this.AllPrograms = _.data;
    return _;
  }

  async getAllCustomPrograms() {
    var _ = await this.get("/programs/customPhyx");
    this.AllCustomPrograms = _.data;
    return _;
  }

  async getCustomProgramsByUserId(id: string) {
    var _ = await this.get(`/patients/customPhyx/${id}`);
    this.AllCustomPrograms = _.data;
    return _;
  }

  async getProgramById(id: string) {
    return await this.get(`/programs/phyx/${id}`);
  }

  async getProgramByid_(id: string) {
    return await this.get(`/programs/name/${id}?id=${id}`);
  }

  async createProgram(data: any) {
    return await this.post("/programs", data);
  }

  async updateProgram(id: string, data: any) {
    return await this.put(`/programs/${id}`, data);
  }

  async deleteProgram(id: string) {
    return await this.delete(`/programs/${id}`);
  }

  async getProgramsByUser(id: string) {
    return await this.get(`/programs/user/${id}`);
  }

  async getProgramsByUserAndDate(id: string, date: string) {
    return await this.get(`/programs/user/${id}/date/${date}`);
  }

  async getProgramsByUserAndDateRange(
    id: string,
    dateStart: string,
    dateEnd: string,
  ) {
    return await this.get(`/programs/user/${id}/date/${dateStart}/${dateEnd}`);
  }
}
