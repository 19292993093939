import {
  STRIPE_MERCHANT_ID,
  STRIPE_PUBLISHABLE_KEY,
  STRIPE_REDIRECT_URI,
} from "@env";
import { StripeProvider } from "@stripe/stripe-react-native";
import React from "react";
import PaymentNative from "./paymentNative";

export default function StripeMobileCard({
  client_secret,
  submitCallBack,
  navigation,
}: any) {
  return (
    <StripeProvider
      publishableKey={STRIPE_PUBLISHABLE_KEY}
      merchantIdentifier={STRIPE_MERCHANT_ID}
      urlScheme={STRIPE_REDIRECT_URI}
    >
      <PaymentNative
        client_secret={client_secret}
        submitCallBack={submitCallBack}
        navigation={navigation}
      />
    </StripeProvider>
  );
}
