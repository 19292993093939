import React from "react";
import {
  Dimensions,
  ImageBackground,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { colors } from "../../constants/colors";
import { BackButton } from "./backButton";

//Calculating height for image view
const HEIGHT = Dimensions.get("window").width * 0.15;

export const ProgramBanner = ({
  title,
  image,
  handleGoBack,
}: {
  title: string;
  image: any;
  handleGoBack: any;
}) => {
  const { width } = Dimensions.get("window");
  const isTablet = width >= colors.tabletSize;

  return (
    <View
      style={{
        backgroundColor: "white",
      }}
    >
      <ImageBackground source={{ uri: image }} style={styles.image}>
        <View style={[styles.backButton, { marginBottom: 10 }]}>
          <BackButton handleGoBackCallback={handleGoBack} />
        </View>
        <View style={styles.headingView}>
          <Text
            style={[
              styles.title,
              { fontWeight: "700", fontSize: isTablet ? 24 : 16 },
            ]}
          >
            {title}
          </Text>
          {/* <Text style={styles.subHeading}>3 Days/Weeks, 10 Weeks</Text> */}
        </View>
      </ImageBackground>
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    width: "100%",
    height: HEIGHT,
  },
  title: {
    color: colors.secondaryColor,
  },
  headingView: {
    flexDirection: "column",
    position: "absolute",
    bottom: 0,
    paddingLeft: 10,
    paddingBottom: 10,
    width: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.45)",
    top: 50,
  },
  subHeading: {
    fontSize: 10,
    color: colors.secondaryColor,
    fontWeight: "700",
  },
  backButton: {
    marginLeft: 10,
    marginTop: 10,
  },
});
