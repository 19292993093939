import { CUSTOM_PHYX_BANNER_URI } from "@env";
import { MaterialIcons } from "@expo/vector-icons";
import React, { useContext, useEffect, useState } from "react";
import {
  FlatList,
  GestureResponderEvent,
  Image,
  Modal,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { Divider, List } from "react-native-paper";
//@ts-ignore
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import axios from "axios";
import { Overlay } from "react-native-elements";
import ReadMore from "react-native-read-more-text";
import Button from "../../../components/Home/button";
import OfflineModal from "../../../components/Program/OfflineModal";
import { ProgramBanner } from "../../../components/Program/ProgramBanner";
import { colors } from "../../../constants/colors";
import { RouteNames } from "../../../constants/routeNames";
import {
  AuthContext,
  AuthContextProps,
  VideoCache,
} from "../../../provider/AuthProvider";
import { RootStackParamList } from "../../../types/navigation";
import { CustomWorkouts, IMp4, IVideo } from "../../../types/program.types";
import { clearCache, downloadVideo } from "../../../utils/cache";
import { getTodaysCustomSession } from "../../../utils/userProfiles";
import Loading from "../../utils/Loading";

const toggleOn = require("../../../webScreens/assets/toggle-on.png");
const toggleOff = require("../../../webScreens/assets/toggle-off.png");

async function downloadMedias(cache: VideoCache, mediaLinks: string[]) {
  const newCache: VideoCache = { ...cache };
  const result = await Promise.all(
    mediaLinks.map(async (link) => {
      if (link && !newCache[link]) {
        try {
          const data = (await axios.get(link)).data as IVideo;
          return { link, data };
        } catch (err) {}
      }
      return { link, data: null };
    }),
  );
  result.forEach(({ link, data }) => {
    if (data) {
      newCache[link] = data;
    }
  });
  if (Object.keys(newCache).length === 0) {
    return newCache;
  }
  try {
    const links = collectLinks(newCache, mediaLinks);
    await Promise.all(links.map((link) => downloadVideo(link)));
  } catch {}
  return newCache;
}

function collectLinks(cache: VideoCache, links: string[]) {
  const linkSet = new Set<string>();
  for (let i = 0; i < links.length; i += 1) {
    const link = links[i];
    const data = cache[link];
    const { playlist } = data;
    const videoToUse = playlist[0]?.sources?.filter(
      (video) => video.file && video.file !== "" && video.type === "video/mp4",
    ) as IMp4[];
    videoToUse.sort((a, b) => {
      if (a.height < b.height) {
        return 1;
      } else if (a.height > b.height) {
        return -1;
      } else {
        return 0;
      }
    });
    const video =
      videoToUse && videoToUse.length > 0
        ? videoToUse[0].file
        : (playlist || [])[0].sources[i + 1 || 0].file;
    linkSet.add(playlist[0].image);
    linkSet.add(video);
  }
  return Array.from(linkSet);
}

export type CustomProgramOverviewParams = {
  id: string;
};

type Props = NativeStackScreenProps<
  RootStackParamList,
  RouteNames.CUSTOMPHYX_OVERVIEW
>;

export default function CustomProgramOverview({ navigation, route }: Props) {
  const { id: customPhyxId } = route.params;
  const [programData, setProgramData] = useState<CustomWorkouts>();
  const [todaySession, setTodaySession] = useState(0);
  const [sessionsCompleted, setSessionsCompleted] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isOfflineAccess, setIsOfflineAccess] = useState(false);
  const [showModals, setShowModals] = useState(false);
  const { width } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;
  const image = CUSTOM_PHYX_BANNER_URI;
  const isMobile = Platform.OS !== "web";
  const {
    userData,
    setUserLocalData,
    videoCache,
    setLocalVideoCache,
    customPrograms,
  } = useContext(AuthContext) as AuthContextProps;
  const handleGoBack = () => {
    navigation.navigate(RouteNames.PROGRAM_ALL);
  };

  useEffect(() => {
    setIsOfflineAccess(userData?.offlineProgram === programData?._id);
  }, [userData?.offlineProgram, programData?._id]);
  //Fetch which level and session to show
  useEffect(() => {
    const _customProgram = customPrograms?.find(
      (program) => program._id === customPhyxId,
    );
    if (_customProgram) {
      setProgramData(_customProgram);
    } else {
      handleGoBack();
      return;
    }

    const { _todaySession, _sessionCompleted, _totalSessions } =
      getTodaysCustomSession(userData?.userProfile, _customProgram as any);
    setTodaySession(_todaySession);
    setSessionsCompleted(_sessionCompleted);
    setIsCompleted(_totalSessions === _sessionCompleted);
  }, []);

  const handleContinue = (_session: any) => {
    const session = _session === null ? todaySession : _session;
    const outcome_forms =
      programData?.outcome_forms?.length &&
      programData?.outcome_forms[session - 1]
        ? programData?.outcome_forms[session - 1]
        : [];

    navigation.navigate(RouteNames.CUSTOM_STOP_INTERSTITIAL, {
      image: image,
      session: session,
      outcome_forms: outcome_forms,
      id: customPhyxId,
    });
  };

  const renderTruncatedFooter = (
    handlePress: ((event: GestureResponderEvent) => void) | undefined,
  ) => {
    return (
      <Text style={{ color: colors.primaryColor }} onPress={handlePress}>
        Read more
      </Text>
    );
  };

  //Style and view for 'Show less' button
  const renderRevealedFooter = (
    handlePress: ((event: GestureResponderEvent) => void) | undefined,
  ) => {
    return (
      <Text style={{ color: colors.primaryColor }} onPress={handlePress}>
        Show less
      </Text>
    );
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    await clearCache();
    const linkSet = new Set<string>();
    for (const playlist of programData?.playlist || []) {
      playlist.forEach((video) =>
        linkSet.add(`https://cdn.jwplayer.com/v2/media/${video.mediaId}`),
      );
    }
    const mediaLinks = Array.from(linkSet);
    try {
      const cache = await downloadMedias(videoCache, mediaLinks);
      if (Object.keys(cache).length > 0) {
        setLocalVideoCache(cache);
        if (programData && userData) {
          setUserLocalData({ ...userData, offlineProgram: programData._id });
        }
        setIsOfflineAccess(true);
      }
    } catch (e) {
      console.log("CustomProgramOverview: downloadMedias failed. Error:", e);
    }
    setShowModals(false);
    setIsDownloading(false);
  };

  const switchDownload = async () => {
    if (!programData || !userData) {
      return;
    }
    if (!isOfflineAccess) {
      setShowModals(true);
    } else {
      await clearCache();
      setIsOfflineAccess(!isOfflineAccess);
      setLocalVideoCache({});
      setUserLocalData({ ...userData, offlineProgram: undefined });
    }
  };

  const handleSessionPressed = (session: number) => {
    console.log("CPO: ", session);
    console.log("CPO: ", todaySession);
    if (session > todaySession) {
      return;
    }

    handleContinue(session);
  };

  //Rendering each video content within sessions
  const ProgramVideos = ({ item, showCheckmark }: any) => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <View
          style={{
            flex: 1,
          }}
        >
          <Text numberOfLines={1} style={styles.videoTitle}>
            {item.title}
          </Text>
          <Text style={styles.videoSubHeading}>
            {item?.time
              ? `${item?.time} secs`
              : item?.reps
                ? `${item?.reps} reps x ${item.sets} sets`
                : null}
          </Text>
        </View>
        {showCheckmark ? (
          <View
            style={{
              marginLeft: 20,
              alignItems: "flex-end",
            }}
          >
            <MaterialIcons
              name="check-circle-outline"
              size={20}
              color={colors.green}
            />
          </View>
        ) : null}
        <Divider style={styles.divider} />
      </View>
    );
  };

  //Rendering each session
  const ProgramSessions = ({ item }: any) => {
    if (!programData) {
      return null;
    }
    const sessionCount = programData.playlist.indexOf(item) + 1;

    let showCheckmark = false;
    if (isCompleted) {
      showCheckmark = true;
    } else if (sessionCount <= sessionsCompleted) {
      showCheckmark = true;
    }

    return (
      <TouchableOpacity
        style={styles.sessionContainer}
        onPress={() => handleSessionPressed(sessionCount)}
      >
        <View style={styles.sessionHeading}>
          <Text style={styles.sessionTitle}>Session {sessionCount}</Text>
        </View>
        <View style={styles.videoContainer}>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={item}
            renderItem={({ item }) => (
              <ProgramVideos item={item} showCheckmark={showCheckmark} />
            )}
            keyExtractor={(i) => item.indexOf(i)}
            ListEmptyComponent={<Loading />}
          />
        </View>
      </TouchableOpacity>
    );
  };

  if (!programData) {
    return <Loading />;
  }

  return (
    <SafeAreaView
      style={{
        backgroundColor: "white",
        height: "100%",
      }}
    >
      <ScrollView style={{ backgroundColor: "white" }}>
        {programData ? (
          <View>
            {/* Image background heading view */}
            <ProgramBanner
              title={programData.program_name as string}
              image={image}
              handleGoBack={handleGoBack}
            />

            {/* Content view */}
            <View style={styles.contentView}>
              {/* OverView */}
              <Text style={styles.sectionHeading}>Overview</Text>
              <ReadMore
                numberOfLines={5}
                renderTruncatedFooter={renderTruncatedFooter}
                renderRevealedFooter={renderRevealedFooter}
              >
                <Text>{programData.program_overview}</Text>
              </ReadMore>

              {/* Button view */}
              <View style={styles.button}>
                <Button
                  text="START SESSION"
                  backgroundColor={colors.primaryColor}
                  customStyle={{
                    alignSelf: "center",
                  }}
                  //disabled={showCustomVAS === null ? true : false}
                  onPressCallback={() => handleContinue(null)}
                />
              </View>
              {isMobile && (
                <View style={styles.offlineAccess}>
                  <Text>Access Program Offline</Text>
                  <TouchableOpacity
                    disabled={isDownloading}
                    onPress={switchDownload}
                  >
                    <Image
                      style={{ width: 48, height: 48 }}
                      source={isOfflineAccess ? toggleOn : toggleOff}
                    />
                  </TouchableOpacity>
                </View>
              )}
              {/* Program overview */}
              <View style={{ marginBottom: 20 }}>
                <Text style={styles.sectionHeading}>Program Overview</Text>
                <List.AccordionGroup>
                  <FlatList
                    showsVerticalScrollIndicator={false}
                    data={programData.playlist}
                    renderItem={({ item }) => <ProgramSessions item={item} />}
                    keyExtractor={(item) => programData?.playlist.indexOf(item)}
                    ListEmptyComponent={<Loading />}
                  />
                </List.AccordionGroup>
              </View>
            </View>
            <Overlay
              isVisible={showModals}
              onBackdropPress={() => {}}
              overlayStyle={{
                width: isTablet ? "50%" : "90%",
                borderRadius: 20,
                padding: 0,
                margin: 0,
              }}
              ModalComponent={Modal}
            >
              <OfflineModal
                onClose={() => setShowModals(false)}
                onContinue={handleDownload}
                loading={isDownloading}
              />
            </Overlay>
          </View>
        ) : (
          <Loading />
        )}
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  contentView: {
    paddingHorizontal: 20,
    position: "relative",
  },
  sectionHeading: {
    fontSize: 16,
    color: colors.secondaryColor,
    marginTop: 10,
    marginBottom: 10,
    fontWeight: "700",
  },
  list: {
    backgroundColor: "white",
    paddingHorizontal: 0,
  },
  sessionContainer: {
    flexDirection: "row",
  },
  sessionHeading: {
    width: "40%",
  },
  videoContainer: {
    width: "60%",
  },
  videoTitle: {
    fontSize: 15,
    fontWeight: "700",
    color: colors.secondaryColor,
    marginBottom: 5,
  },
  videoSubHeading: {
    fontSize: 13,
    color: colors.textColor,
  },
  divider: {
    marginBottom: 5,
    marginTop: 5,
  },
  sessionTitle: {
    color: colors.secondaryColor,
    fontWeight: "700",
    marginLeft: 7,
  },
  button: {
    position: "relative",
    bottom: 0,
    justifyContent: "center",
    width: "100%",
    marginBottom: 20,
    marginTop: 20,
  },
  indicator: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 20,
    top: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  offlineAccess: {
    position: "relative",
    bottom: 0,
    justifyContent: "center",
    width: "100%",
    marginBottom: 20,
    marginTop: 24,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 24,
  },
});
