import React, { Fragment, useContext, useEffect, useState } from "react";
import { Platform, View } from "react-native";

import { LinearGradient } from "expo-linear-gradient";
import { ActivityIndicator } from "react-native-paper";
import { colors } from "../../constants/colors";
import { AuthContext } from "../../provider/AuthProvider";

let VideoCallComponent: any = null;
if (Platform.OS === "web") {
  VideoCallComponent = require("./WebVideoCall").default;
} else {
  VideoCallComponent = require("./MobileVideoCall").default;
}

export default function VideoCall({ navigation, route }: any) {
  const { userName, roomName } = route.params;
  const { userData } = useContext(AuthContext);
  const [token, setToken] = useState<string>("");

  const data: PhyxableVideoCallProps = {
    user_id: userData?.userProfile?.name || userName,
    room_id: roomName,
  };

  const handleGoBack = () => {
    navigation.goBack();
  };

  useEffect(() => {
    const getToken = async () => {
      const res = await fetch(`https://videochat.phyxable.com/video/token`, {
        method: "POST",
        body: JSON.stringify({
          identity: data.user_id,
          room: data.room_id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json());
      return res;
    };
    getToken().then((res) => {
      setToken(res.token);
    });
  }, []);

  return (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      colors={["rgba(42, 46, 53, 1)", "rgba(42, 46, 53, 1)"]}
      style={{
        flex: 1,
        width: "100%",
        height: "100%",
      }}
    >
      <Fragment>
        {token == "" ? (
          <View
            style={{
              backgroundColor: "rgba(42, 46, 53, 1)",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              width: "100%",
              height: "100%",
            }}
          >
            <ActivityIndicator size="large" color={colors.primaryColor} />
          </View>
        ) : (
          <>
            <VideoCallComponent
              navigation={navigation}
              data={data}
              token={token}
            />
          </>
        )}
      </Fragment>
    </LinearGradient>
  );
}

export interface PhyxableVideoCallProps {
  user_id: string;
  room_id: string;
}
