import { LinearGradient } from "expo-linear-gradient";
import React, { useContext, useEffect, useState } from "react";
import { TouchableOpacity, View, useWindowDimensions } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { Text } from "react-native-paper";
import Container from "typedi";
import { PaymentSubscriptionService } from "../../../../services/paymentSubscription/paymentSubscription";
import PaymentCard from "../../../components/Account/payment/paymentCard";
import { colors } from "../../../constants/colors";
import { RouteNames } from "../../../constants/routeNames";
import { AuthContext } from "../../../provider/AuthProvider";
import Loader from "../../utils/Loading";

export default function PaymentInfo({ navigation, route }: any) {
  const { width, height } = useWindowDimensions();
  const [paymentMethod, setPaymentMethod] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const paymentService = Container.get(PaymentSubscriptionService);

  const { userData } = useContext(AuthContext);

  useEffect(() => {
    const getPaymentMethod = async () => {
      const paymentMethod = await paymentService.getPaymentMethods(
        userData?.id as string,
      );
      setPaymentMethod(paymentMethod.data.data);
    };
    getPaymentMethod().then(() => setLoading(false));
  }, [route.params?.refresh]);

  const handleDelete = async (paymentMethodId: string) => {
    const paymentMethod = await paymentService.deletePaymentMethod(
      paymentMethodId,
      userData?.id as string,
    );
    setPaymentMethod(paymentMethod.data.data);
  };

  const handleDefault = async (paymentMethodId: string) => {
    const paymentMethod = await paymentService.updateDefaultPaymentMethod(
      paymentMethodId,
      userData?.id as string,
    );
    setPaymentMethod(paymentMethod.data.data);
  };

  return (
    <>
      <LinearGradient
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        colors={[colors.buttonWhite, colors.buttonWhite]}
        style={{
          flex: 1,
          width: "100%",
          height: "100%",
          alignSelf: "flex-start",
        }}
      >
        {!loading ? (
          <FlatList
            ListEmptyComponent={() => (
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 16,
                  fontWeight: "700",
                  margin: 20,
                  color: colors.blueColor,
                }}
              >
                No Payment Methods Available
              </Text>
            )}
            data={paymentMethod}
            renderItem={({ item }) => (
              <View
                style={{
                  flex: 1,
                  margin: 10,
                  width: width < colors.tabletSize ? "90%" : "30%",
                }}
              >
                <PaymentCard
                  paymentMethodId={item.id}
                  cardNumber={item.card.last4}
                  cardBrand={item.card.brand}
                  expirationDate={
                    item.card.exp_month + "/" + item.card.exp_year
                  }
                  handleDelete={handleDelete}
                  handleDefault={handleDefault}
                  defaultCard={item.default}
                  selected={item.default}
                />
              </View>
            )}
            ListFooterComponent={() => (
              <TouchableOpacity
                onPress={() => navigation.navigate(RouteNames.ADD_CARD)}
                style={{
                  backgroundColor: colors.primaryColor,
                  padding: 10,
                  borderRadius: 5,
                  margin: 10,
                  width: 200,
                }}
              >
                <Text
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: 16,
                  }}
                >
                  Add New Card
                </Text>
              </TouchableOpacity>
            )}
            ListHeaderComponent={() => (
              <Text
                style={[
                  {
                    fontSize: 28,
                    fontWeight: "700",
                    margin: 20,
                  },
                  {
                    color: colors.blueColor,
                  },
                ]}
              >
                Available Payment Methods
              </Text>
            )}
          />
        ) : (
          <Loader />
        )}
      </LinearGradient>
    </>
  );
}
