import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Avatar } from "react-native-paper";
import * as Progress from "react-native-progress";
import { colors } from "../../constants/colors";

interface Props {
  level: number;
  session: number;
  totalSessions: number;
  customPhyx: any;
}
export default function ProgressCard({
  level,
  session,
  totalSessions,
  customPhyx,
}: Props) {
  const [completePercentage, setCompletePercentage] = useState(0);
  const [percentageText, setPercentageText] = useState(0);
  const [progressColor, setProgressColor] = useState("#0000");
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    if (!session || !totalSessions || session === 0) {
      setCompletePercentage(0);
      setPercentageText(0);
      return;
    }

    const perc = session / totalSessions;
    const percText = (session * 100) / totalSessions;
    setCompletePercentage(perc);
    if (percText === 100) {
      setCompleted(true);
    } else {
      setCompleted(false);
    }
    setPercentageText(Math.floor(percText));
    calcProgressColor(Math.floor(percText));
  }, [session, totalSessions]);

  const calcProgressColor = (perc: any) => {
    if (perc >= 0 && perc <= 50) {
      setProgressColor(colors.primaryLightColor);
    } else if (perc > 50 && perc <= 99) {
      setProgressColor(colors.primaryColor);
    } else {
      setProgressColor(colors.green);
    }
  };

  const InnerText = () => {
    return (
      <View>
        {percentageText === 100 ? (
          <Text>All completed!</Text>
        ) : (
          <Text>{percentageText}% completed</Text>
        )}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {/* Progress view */}
      <View style={styles.progressContainer}>
        <Progress.Circle
          size={120}
          indeterminate={false}
          progress={completePercentage}
          color={progressColor}
          formatText={() => <InnerText />}
          showsText={true}
          textStyle={styles.progressText}
          thickness={10}
          borderWidth={1}
        />
      </View>
      {/* Info view */}
      <View style={styles.infoContainer}>
        <Text style={styles.heading}>
          {!customPhyx ? `Level ${level} : ` : null}Session {session}
        </Text>
        <Text style={styles.infoPara}>Complete your sessions to level up.</Text>
      </View>
      {completed && (
        <View style={{ position: "absolute", right: 4, bottom: 4 }}>
          <Avatar.Image
            source={require("../../webScreens/assets/guarantee.png")}
            size={32}
            style={{ backgroundColor: "transparent" }}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "90%",
    borderRadius: 15,
    flexDirection: "row",
    padding: 15,
    backgroundColor: colors.cardBackground,
  },
  progressContainer: {
    width: "50%",
  },
  infoContainer: {
    flexDirection: "column",
    width: "50%",
  },
  progressText: {
    fontSize: 12,
    color: colors.secondaryColor,
    fontWeight: "500",
  },
  heading: {
    fontSize: 21,
    fontWeight: "500",
    color: colors.secondaryColor,
  },
  infoPara: {
    fontSize: 12,
    marginTop: 20,
    lineHeight: 15,
    fontWeight: "300",
  },
  highlight: {
    color: colors.primaryColor,
    textDecorationLine: "underline",
  },
});
