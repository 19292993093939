import React from "react";
import { Text, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Container } from "typedi";
import { UserService } from "../../../services/user/user.service";

export default function DebugScreen() {
  const [allJobs, setAllJobs] = React.useState<any>([]);

  const userService = Container.get(UserService);
  // const { data: jobs, isLoading } = useQuery({
  //   queryKey: ["allJobs"],
  //   queryFn: async () => {
  //     const jobs = await userService?.getAllScheduledJobs();
  //     setAllJobs(jobs?.data?.jobs || []);
  //     return jobs;
  //   },
  //   refetchInterval: 1000,
  // });

  return (
    <ScrollView>
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Text>
          Debug Screen: Only for testing will not be visible in production ,
          depends on NODE_ENV variable in .env file
        </Text>
        <Text>No data to show</Text>
        {/* <DataTable>
          <DataTable.Header>
            <DataTable.Title>Id</DataTable.Title>
            <DataTable.Title>Message</DataTable.Title>
            <DataTable.Title>Email</DataTable.Title>
            <DataTable.Title>Time Zone</DataTable.Title>
            <DataTable.Title>Last Run</DataTable.Title>
            <DataTable.Title>Next Run</DataTable.Title>
            <DataTable.Title>Fail Reason</DataTable.Title>
          </DataTable.Header>
          {allJobs.map((job: any) => {
            return (
              <DataTable.Row key={job?._id}>
                <DataTable.Cell>{job?._id}</DataTable.Cell>
                <DataTable.Cell>{job?.data?.body}</DataTable.Cell>
                <DataTable.Cell>{job?.data?.email}</DataTable.Cell>
                <DataTable.Cell>{job?.data?.tz}</DataTable.Cell>
                <DataTable.Cell>
                  {new Date(job?.lastRunAt).toLocaleString() || ""}
                </DataTable.Cell>
                <DataTable.Cell>
                  {new Date(job?.nextRunAt).toLocaleString() || ""}
                </DataTable.Cell>
                <DataTable.Cell>{job?.failReason || ""}</DataTable.Cell>
              </DataTable.Row>
            );
          })}
        </DataTable> */}

        {/* <TouchableOpacity
          onPress={async () => {
            const res = await userService?.triggerScheduledJobs();
            if (res?.data?.status == "success") {
              alert(JSON.stringify(res?.data?.status));
            } else {
              alert(JSON.stringify(res?.data?.error));
            }
          }}
          style={{
            backgroundColor: colors.primaryColor,
            padding: 10,
            borderRadius: 10,
            marginTop: 10,
          }}
        >
          <Text>Trigger All Jobs</Text>
        </TouchableOpacity> */}
      </View>
    </ScrollView>
  );
}
