import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import MobileNotifications from "../mobileScreen/mobileNotificationScreen";
import HomePortrait from "../screens/home/HomePortrait";
import UserVitals from "../screens/home/UserVitals";
import { HomeStackParamList } from "../types/navigation";

const homeStack = createNativeStackNavigator<HomeStackParamList>();

export default function HomeStackMobile() {
  return (
    <homeStack.Navigator
      screenOptions={{
        headerShown: true,
        headerBackButtonMenuEnabled: true,
        headerTitle: "",
      }}
      initialRouteName="Phyxable"
    >
      <homeStack.Screen
        name="Phyxable"
        component={HomePortrait}
        options={{
          headerShown: false,
        }}
      />
      <homeStack.Screen
        name="Vitals"
        component={UserVitals}
        options={{
          headerShown: false,
        }}
      />
      <homeStack.Screen
        name="Notifications"
        component={MobileNotifications}
        options={{
          headerShown: false,
        }}
      />
    </homeStack.Navigator>
  );
}
