import { CUSTOM_PHYX_BANNER_URI } from "@env";
import React, { useEffect, useRef, useState } from "react";
import RenderImage from "../../Common/renderImage";

export default function VideoTrack({ track, navigation, data }: any) {
  const ref = useRef();

  const [borderWidth, setBorderWidth] = useState(0);
  const [borderColor, setBorderColor] = useState("transparent");
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (track) {
      const el = ref.current;
      if (track.mediaStreamTrack) {
        track.attach(el);
        track.on("disabled", () => {
          setDisabled(true);
        });
        track.on("enabled", () => {
          setDisabled(false);
        });
      }
      return () => {
        if (!track.mediaStreamTrack) {
          track?.track?.detach(el);
        }
      };
    }
  }, [track]);

  if (disabled) {
    return (
      <RenderImage
        style={{
          width: "100%",
          height: "100%",
          objectFit: "fill",
          borderWidth: borderWidth,
          borderColor: borderColor,
          borderRadius: 10,
          position: "absolute",
          zIndex: disabled ? 100 : 0,
        }}
        uri={CUSTOM_PHYX_BANNER_URI}
      />
    );
  }

  return (
    <video
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderWidth: borderWidth,
        borderColor: borderColor,
        borderRadius: 10,
        zIndex: disabled ? 0 : 100,
      }}
      autoPlay={true}
      // @ts-ignore
      ref={ref}
    />
  );
}

// import { CUSTOM_PHYX_BANNER_URI } from "@env";
// import React, { useEffect, useRef, useState } from "react";
// import { StyleSheet, ViewStyle } from "react-native";
// import type { LocalVideoTrack, RemoteVideoTrack } from "twilio-video";
// import RenderImage from "../../Common/renderImage";

// interface VideoTrackProps {
//   track: LocalVideoTrack | RemoteVideoTrack;
//   navigation?: any; // Specify a more precise type for navigation as needed
//   data?: any;      // Provide an appropriate type for `data` based on usage
// }

// export default function VideoTrack({ track, navigation, data }: VideoTrackProps) {
//   const ref = useRef<HTMLVideoElement>(null);

//   const [isDisabled, setIsDisabled] = useState(false);

//   useEffect(() => {
//     const el = ref.current;

//     if (!track || !el) {
//       return;
//     }

//     track.attach(el);

//     const handleDisabled = () => setIsDisabled(true);
//     const handleEnabled = () => setIsDisabled(false);

//     track.on("disabled", handleDisabled);
//     track.on("enabled", handleEnabled);

//     return () => {
//       track.detach(el);
//       track.off("disabled", handleDisabled);
//       track.off("enabled", handleEnabled);
//     };
//   }, [track]);

//   const videoStyles: ViewStyle = {
//     ...styles.video,
//     zIndex: isDisabled ? 0 : 100,
//   };

//   const renderDisabledVideo = (
//     <RenderImage
//       style={styles.disabledVideo}
//       uri={CUSTOM_PHYX_BANNER_URI}
//     />
//   );

//   return isDisabled ? renderDisabledVideo : <video style={videoStyles} autoPlay={true} ref={ref} />;
// }

// const styles = StyleSheet.create({
//   video: {
//     width: '100%',
//     height: '100%',
//     objectFit: 'cover' as 'cover',
//     borderRadius: 10,
//   },
//   disabledVideo: {
//     width: '100%',
//     height: '100%',
//     objectFit: 'fill',
//     borderRadius: 10,
//     position: 'absolute' as 'absolute',
//     zIndex: 100,
//     borderWidth: 0,
//     borderColor: 'transparent'
//   }
// });
