import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { RouteNames } from "../constants/routeNames";
import { Program } from "../screens";
import Congratulations from "../screens/program/Congratulations";
import Exercise from "../screens/program/Exercise";
import Interstitial from "../screens/program/Interstitial";
import ProgramConfirmation from "../screens/program/ProgramConfirmation";
import ProgramDetails from "../screens/program/ProgramDetails";
import ProgramOverView from "../screens/program/ProgramOverview";
import ProgramPSFS from "../screens/program/ProgramPSFS";
import ProgramPlaylist from "../screens/program/ProgramPlaylist";
import ProgramVAS from "../screens/program/ProgramVAS";
import Questionnaire from "../screens/program/Questionnaire";
import StopInterstitial from "../screens/program/StopInterstitial";
import CustomExercise from "../screens/program/customPhyx/CustomExercise";
import CustomPSFS from "../screens/program/customPhyx/CustomPSFS";
import CustomPhyxConfirmation from "../screens/program/customPhyx/CustomPhyxConfirm";
import CustomPhyxDetails from "../screens/program/customPhyx/CustomPhyxDetails";
import CustomPlaylist from "../screens/program/customPhyx/CustomPlaylist";
import CustomProgramOverview from "../screens/program/customPhyx/CustomProgramOverview";
import CustomQuestionnaire from "../screens/program/customPhyx/CustomQuestionnaire";
import CustomStopInterstitial from "../screens/program/customPhyx/CustomStopInterstitial";
import CustomVAS from "../screens/program/customPhyx/CustomVAS";
const programsStack = createNativeStackNavigator();

export default function ProgramStack() {
  return (
    <programsStack.Navigator
      screenOptions={{
        headerShown: true,
        headerBackButtonMenuEnabled: true,
        headerTitle: "",
      }}
    >
      <programsStack.Screen
        name={RouteNames.PROGRAM_ALL}
        component={Program}
        options={{
          headerShown: false,
        }}
      />
      <programsStack.Screen
        name={RouteNames.PROGRAM_DETAIL}
        component={ProgramDetails}
        options={{
          headerShown: false,
        }}
      />
      <programsStack.Screen
        name={RouteNames.PROGRAM_CONFIRMATION}
        component={ProgramConfirmation}
        options={{
          headerShown: false,
        }}
      />
      <programsStack.Screen
        name={RouteNames.PROGRAM_OVERVIEW}
        component={ProgramOverView}
        options={{
          headerShown: false,
        }}
      />

      {/* Custom Phyxes */}
      <programsStack.Screen
        name={RouteNames.CUSTOMPHYX_OVERVIEW}
        component={CustomProgramOverview}
        options={{
          headerShown: false,
        }}
      />
      <programsStack.Screen
        name={RouteNames.CUSTOM_EXERCISE}
        component={CustomExercise}
        options={{
          headerShown: false,
        }}
      />
      <programsStack.Screen
        name={RouteNames.CUSTOM_PLAYLIST}
        component={CustomPlaylist}
        options={{
          headerShown: false,
        }}
      />
      <programsStack.Screen
        name={RouteNames.CUSTOMPHYX_DETAIL}
        component={CustomPhyxDetails}
      />

      <programsStack.Screen
        name={RouteNames.CUSTOMPHYX_CONFIRMATION}
        component={CustomPhyxConfirmation}
      />
      <programsStack.Screen
        name={RouteNames.CUSTOM_STOP_INTERSTITIAL}
        component={CustomStopInterstitial}
        options={{
          headerShown: false,
        }}
      />
      <programsStack.Screen
        name={RouteNames.CUSTOM_VAS}
        component={CustomVAS}
        options={{
          headerShown: false,
        }}
      />
      <programsStack.Screen
        name={RouteNames.CUSTOM_PSFS}
        component={CustomPSFS}
        options={{
          headerShown: false,
        }}
      />
      <programsStack.Screen
        name={RouteNames.CUSTOM_QUESTIONNAIRE}
        component={CustomQuestionnaire}
        options={{
          headerShown: false,
        }}
      />

      {/* Common */}
      <programsStack.Screen
        name={RouteNames.STOP_INTERSTITIAL}
        component={StopInterstitial}
        options={{
          headerShown: false,
        }}
      />
      <programsStack.Screen
        name={RouteNames.VAS}
        component={ProgramVAS}
        options={{
          headerShown: false,
        }}
      />
      <programsStack.Screen
        name={RouteNames.PSFS}
        component={ProgramPSFS}
        options={{
          headerShown: false,
        }}
      />
      <programsStack.Screen
        name={RouteNames.INTERSTITIAL}
        component={Interstitial}
        options={{
          headerShown: false,
        }}
      />
      <programsStack.Screen
        name={RouteNames.PLAYLIST}
        component={ProgramPlaylist}
        options={{
          headerShown: false,
        }}
      />
      <programsStack.Screen
        name={RouteNames.EXERCISE}
        component={Exercise}
        options={{
          headerShown: false,
        }}
      />
      <programsStack.Screen
        name={RouteNames.QUESTIONNAIRE}
        component={Questionnaire}
        options={{
          headerShown: false,
        }}
      />
      <programsStack.Screen
        name={RouteNames.CONGRATULATIONS}
        component={Congratulations}
        options={{
          headerShown: false,
        }}
      />
    </programsStack.Navigator>
  );
}
