import React, { useContext } from "react";
import { TimePickerModal } from "react-native-paper-dates";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { AuthContext, ModalData } from "../../../provider/AuthProvider";

export default function TimePicker({
  hour,
  minute,
  AM_PM,
  handleTimePicker,
  setShowTimePicker,
}: any) {
  /**
   * For simple Time Picker
   */
  const [selectedHour, setSelectedHour] = React.useState(hour || "00");
  const [selectedMinute, setSelectedMinute] = React.useState(minute || "00");
  const [selectedAM_PM, setSelectedAM_PM] = React.useState(AM_PM || "AM");
  //                SIMPLE TIME PICKER

  const [visible, setVisible] = React.useState(true);
  const { setShowModals, setModalData } = useContext(AuthContext) as any;

  // const handleHour = (hour: any) => {
  //   if (hour > 12) {
  //     setSelectedHour("00");
  //   } else if (hour < 0) {
  //     setSelectedHour(12);
  //   } else if (hour < 10) {
  //     setSelectedHour(`0${hour}`);
  //   } else {
  //     setSelectedHour(hour);
  //   }
  // };
  // const handleMinute = (minute: any) => {
  //   if (minute > 59) {
  //     setSelectedMinute(`00`);
  //   } else if (minute < 0) {
  //     setSelectedMinute(59);
  //   } else if (minute < 10) {
  //     setSelectedMinute(`0${minute}`);
  //   } else {
  //     setSelectedMinute(minute);
  //   }
  // };

  // const handleAM_PM = (AM_PM: any) => {
  //   console.log("AM_PM", AM_PM);
  //   if (AM_PM === "AM") {
  //     setSelectedAM_PM("PM");
  //   } else {
  //     setSelectedAM_PM("AM");
  //   }
  // };

  const handleSetData = (hours: number, minutes: number) => {
    const time = new Date();
    time.setHours(hours);
    time.setMinutes(minutes);

    const _modalData: ModalData = {
      title: "Set Time",
      bodyTitle: `You would like to be reminded at ${time.toLocaleTimeString(
        [],
        { hour: "2-digit", minute: "2-digit" },
      )}`,
      button1: "Yes",
      button2: "Cancel",
      button1Action: () => {
        handleTimePicker(hours, minutes);
        setShowModals(false);
        setModalData({} as ModalData);
      },
      button2Action: () => {
        setModalData({} as ModalData);
        setShowModals(false);
      },
    };
    setModalData(_modalData);
    setShowModals(true);
  };

  return (
    <SafeAreaProvider>
      <TimePickerModal
        hours={selectedHour}
        minutes={selectedMinute}
        visible={visible}
        locale="en"
        onDismiss={() => {
          setVisible(false);
          setShowTimePicker(false);
        }}
        onConfirm={({ hours, minutes }) => {
          handleSetData(hours, minutes);
          setVisible(false);
        }}
      />
    </SafeAreaProvider>

    /** Simple our Minutes and AM PM Selector */
    /* <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Ionicons
              name="caret-up"
              size={24}
              color={colors.blueColor}
              onPress={() => {
                handleHour(parseInt(selectedHour) + 1);
              }}
            />
            <Text style={{ color: colors.blueColor, fontSize: 20 }}>
              {selectedHour}
            </Text>
            <Ionicons
              name="caret-down"
              size={24}
              color={colors.blueColor}
              onPress={() => {
                handleHour(parseInt(selectedHour) - 1);
              }}
            />
          </View>
          <Text
            style={{ color: colors.blueColor, fontSize: 20, marginRight: 10 }}
          >
            :
          </Text>
          <View
            style={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Ionicons
              name="caret-up"
              size={24}
              color={colors.blueColor}
              onPress={() => {
                handleMinute(parseInt(selectedMinute) + 1);
              }}
            />
            <Text style={{ color: colors.blueColor, fontSize: 20 }}>
              {selectedMinute}
            </Text>
            <Ionicons
              name="caret-down"
              size={24}
              color={colors.blueColor}
              onPress={() => {
                handleMinute(parseInt(selectedMinute) - 1);
              }}
            />
          </View>
        </View>
        <View
          style={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Ionicons
            name="caret-up"
            size={24}
            color={colors.blueColor}
            onPress={() => handleAM_PM(selectedAM_PM)}
          />
          <Text style={{ color: colors.blueColor, fontSize: 18 }}>
            {selectedAM_PM}
          </Text>
          <Ionicons
            name="caret-down"
            size={24}
            color={colors.blueColor}
            onPress={() => handleAM_PM(selectedAM_PM)}
          />
        </View>
        <TouchableOpacity
          style={{
            backgroundColor: colors.blueColor,
            borderRadius: 5,
            padding: 5,
            marginTop: 5,
            marginLeft: 20,
            zIndex: 100000,
          }}
          onPress={() => handleSetData()}
        >
          <Text
            style={{
              color: "white",
              fontSize: 10,
              alignSelf: "flex-start",
              fontWeight: "500",
            }}
          >
            Set Time
          </Text>
        </TouchableOpacity>
      </View>
    </> */
  );
}
