import { Slider } from "@miblanchard/react-native-slider";
import React, { useState } from "react";
import { Dimensions, ScrollView, StyleSheet, Text, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import RenderSVGImage from "../../components/Common/renderSVGImage";
import Button from "../../components/Home/button";
import { BackButton } from "../../components/Program/backButton";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";

//Height for the image
const HEIGHT = Dimensions.get("window").width / 3;
const WIDTH = Dimensions.get("window").width - 80;

export default function ProgramPSFS({ navigation, route }: any) {
  const { id, image, psfs, level, session, vas, data } = route.params;

  const [sliderValue, setSliderValue] = useState(0);
  const [space, setSpace] = useState(0);
  const [vasText, setVasText] = useState(psfs[0]?.min_label || "");
  const [sliderMoved, setSliderMoved] = useState(false);
  const [psfsData, setPsfsData] = useState<PsfsObject[]>([]);
  const [current, setCurrent] = useState(0);
  const [vasImage, setVasImage] = useState(
    "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/pain-blob-three.svg",
  );

  const insets = useSafeAreaInsets();

  const handleGoBack = () => {
    if (current === 0) navigation.goBack();
    else setCurrent(current - 1);
  };

  const handleSliderChange = (value: any[]) => {
    setSliderMoved(true);
    setSliderValue(value[0]);
    handleVAS(value[0]);
  };

  const handleConfirm = () => {
    let psfsArray: PsfsObject[] = psfsData;
    psfsArray.push({
      answer: sliderValue,
      question: psfs[current]?.blurb && psfs[current].blurb[0],
    });
    setPsfsData(psfsArray);

    setSliderMoved(false);
    setSliderValue(0);
    handleVAS(0);

    if (current < psfs.length - 1) {
      setCurrent(current + 1);
    } else {
      navigation.navigate(RouteNames.PLAYLIST, {
        id: id,
        image: image,
        level: level,
        session: session,
        vas: vas,
        psfs: psfsArray,
        data: data,
      });
    }
  };

  //Change the VAS pain text and VAS image depending on the slider value
  const handleVAS = (value: number) => {
    switch (value) {
      case 0:
        setVasText(psfs[current]?.min_label);
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/pain-blob-three.svg",
        );
        break;
      case 1:
        setVasText("");
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/pain-blob-two.svg",
        );
        break;
      case 2:
        setVasText("");
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/pain-blob-two.svg",
        );
        break;
      case 3:
        setVasText("");
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/pain-blob.svg",
        );
        break;
      case 4:
        setVasText("");
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/ok-blob.svg",
        );
        break;
      case 5:
        setVasText("");
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/ok-blob.svg",
        );
        break;
      case 6:
        setVasText("");
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/ok-blob.svg",
        );
        break;
      case 7:
        setVasText("");
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/good-blob.svg",
        );
        break;
      case 8:
        setVasText("");
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/good-blob.svg",
        );
        break;
      case 9:
        setVasText("");
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/good-blob.svg",
        );
        break;
      case 10:
        setVasText(psfs[current].max_label);
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/great-blob.svg",
        );
        break;
      default:
        setVasText(psfs[current].max_label);
        setVasImage(
          "https://phyxableprograms.s3.amazonaws.com/mobile/VAS/great-blob.svg",
        );
    }
  };

  return (
    <ScrollView style={[styles.container, { top: insets.top }]}>
      <View style={{ padding: 15 }}>
        <BackButton handleGoBackCallback={handleGoBack} />
      </View>
      <View style={styles.contentView}>
        <Text style={styles.title}>{psfs[current]?.blurb}</Text>
        {/* Image view */}
        <View
          style={{
            width: "100%",
          }}
        >
          <View style={{ height: 240, marginTop: 50 }}>
            <RenderSVGImage
              style={{
                alignSelf: "center",
                flex: 1,
              }}
              uri={vasImage}
            />
          </View>
        </View>

        <Text style={styles.vasText}>{vasText}</Text>

        <View style={styles.sliderTitleView}>
          <Text style={styles.sliderTitle}>Unable</Text>
          <Text style={styles.sliderTitle}>Fully able</Text>
        </View>

        {/* Slider View */}
        <View>
          <Slider
            value={[sliderValue]}
            onValueChange={handleSliderChange}
            animateTransitions
            minimumValue={0}
            maximumValue={10}
            step={1}
            trackClickable={true}
            minimumTrackTintColor={colors.primaryLightColor}
            maximumTrackTintColor={colors.primaryColor}
            thumbStyle={styles.thumb}
            trackStyle={styles.track}
          />
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "space-between",
              width: WIDTH,
            }}
          >
            {Array.from(Array(11).keys()).map((key) => {
              const textColor =
                sliderValue === key ? colors.primaryColor : colors.textColor;
              return (
                <Text
                  style={{ color: textColor, marginRight: space - 4 }}
                  key={key}
                >
                  {key}
                </Text>
              );
            })}
          </View>
        </View>

        <Button
          text="CONFIRM"
          backgroundColor={sliderMoved ? colors.primaryColor : colors.grey}
          disabled={sliderMoved ? false : true}
          customStyle={{
            alignSelf: "center",
            marginTop: 30,
            marginBottom: 20,
            paddingHorizontal: 40,
          }}
          onPressCallback={handleConfirm}
        />
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
  },
  contentView: {
    paddingHorizontal: 20,
    alignItems: "center",
  },
  title: {
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 28,
  },
  subTitle: {
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 16,
  },
  image: {
    marginTop: 20,
  },
  sliderTitleView: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    // width: width + 20,
    marginTop: 50,
    marginBottom: 10,
  },
  sliderTitle: {
    color: colors.textColor,
    fontWeight: "700",
    left: 0,
  },
  sliderView: {
    width: WIDTH,
    justifyContent: "center",
    flexDirection: "column",
    height: 15,
    marginBottom: 10,
  },
  dummySliderView: {
    flexDirection: "row",
  },
  realSliderView: {
    position: "absolute",
    width: WIDTH,
  },
  vasText: {
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 21,
    height: 52,
  },
  content: {
    alignItems: "center",
    marginTop: 10,
    paddingHorizontal: 10,
  },
  text: {
    fontSize: 24,
    color: colors.secondaryColor,
    fontWeight: "700",
    textAlign: "center",
    height: 140,
  },
  questionImage: {
    width: 230,
    height: 230,
    marginTop: 30,
  },
  thumb: {
    backgroundColor: colors.primaryColor,
    borderRadius: 5,
    height: 30,
    width: 10,
  },
  track: {
    backgroundColor: colors.grey,
    borderRadius: 5,
    height: 10,
  },
});

export interface PsfsObject {
  answer: number;
  question: string;
}
