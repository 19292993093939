import React from "react";
import { Platform } from "react-native";
import VideoCall from "../../components/VideoCall/VideoCall";
let twilio;
if (Platform.OS === "web") {
  twilio = require("twilio-video");
} else {
  twilio = require("react-native-twilio-video-webrtc");
}

export default function Call({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) {
  return <VideoCall navigation={navigation} route={route} />;
}
