import { useStripe } from "@stripe/stripe-react-native";
import React, { useEffect, useState } from "react";
import { Alert, TouchableOpacity, View } from "react-native";
import { Text } from "react-native-paper";
import { colors } from "../../../constants/colors";
import { RouteNames } from "../../../constants/routeNames";
import Loader from "../../../screens/utils/Loading";

export default function PaymentNative({
  client_secret,
  submitCallBack,
  navigation,
}: any) {
  const { initPaymentSheet, presentPaymentSheet } = useStripe();
  const [loading, setLoading] = useState(true);
  const [reopen, setReopen] = useState(false);

  const initializePaymentSheet = async () => {
    const { error } = await initPaymentSheet({
      merchantDisplayName: "Phyxable",
      customerId: client_secret.customer.id,
      customerEphemeralKeySecret: client_secret.ephemeralKey,
      setupIntentClientSecret: client_secret.client_secret,
    });
    if (!error) {
      setLoading(false);
    }
  };

  const openPaymentSheet = async () => {
    const { error } = await presentPaymentSheet();

    if (error) {
      Alert.alert(`${error.code}`, error.message);
      setReopen(true);
    } else {
      Alert.alert(
        "Success",
        "Your payment method is successfully set up for future payments!",
      );
      await navigation.navigate(RouteNames.PAYMENT_INFO, {
        refresh: true,
      });
    }
  };

  useEffect(() => {
    initializePaymentSheet().then(() => {
      setLoading(false);
      openPaymentSheet();
    });
  }, []);

  return (
    <View>
      {loading ? (
        <Loader />
      ) : (
        reopen && (
          <TouchableOpacity
            onPress={openPaymentSheet}
            style={{
              backgroundColor: colors.primaryColor,
              padding: 10,
              borderRadius: 5,
              margin: 10,
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "center",
                fontSize: 16,
              }}
            >
              Reopen Payment Sheet
            </Text>
          </TouchableOpacity>
        )
      )}
    </View>
  );
}
