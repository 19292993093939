import { Feather, Ionicons } from "@expo/vector-icons";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { styles } from "./commonStyles";
import { handleFileDownload } from "../../../webScreens/helper";

export default function RenderFileMessage(
  message: any,
  index: number,
  isPatient: boolean,
) {
  return (
    <View
      style={isPatient ? styles.patientMessageColumn : styles.pracMessageColumn}
    >
      <View style={fileStyles.fileMessageContainer}>
        <TouchableOpacity
          style={fileStyles.downloadIcon}
          onPress={() => {
            handleFileDownload(message);
          }}
        >
          <Feather name="download" size={15} color="#10111B" />
        </TouchableOpacity>
        <View style={fileStyles.fileMessage}>
          <Ionicons name="newspaper-outline" size={12} color="#18214D" />
          <View style={styles.spacer}></View>
          <Text
            numberOfLines={1}
            style={{
              color: "#18214D",
              fontWeight: "600",
              fontSize: 12,
              //fontFamily: "Proxima Nova",
            }}
          >
            {message.name ?? "File"}
          </Text>
        </View>
      </View>
      <View style={isPatient ? styles.patientTimeStamp : styles.pracTimeStamp}>
        <Text
          style={{
            color: "#6C7993",
            fontWeight: "400",
            fontSize: 12,
            //fontFamily: "Proxima Nova",
          }}
        >
          {new Date(message.timestamp).toLocaleString()}
        </Text>
      </View>
    </View>
  );
}

const fileStyles = StyleSheet.create({
  fileMessageContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignContent: "flex-start",
    // width: "auto",
    alignItems: "center",
  },
  fileMessage: {
    // maxWidth: "80%",
    flexDirection: "row",
    height: "100%",
    borderWidth: 1,
    borderColor: "rgba(24, 33, 77, 0.2)",
    borderRadius: 15,
    paddingVertical: 5,
    paddingHorizontal: 15,
    justifyContent: "center",
    alignItems: "center",
  },
  downloadIcon: {
    width: 30,
    height: 30,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: "#E2E4E9",
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 10,
  },
});
