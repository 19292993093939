import React, { useContext } from "react";
import { View } from "react-native";
import WebCarouselDesktop from "../../components/Home/WebCarouselDesktop";
import WelcomeCardWeb from "../../components/Home/WelComeCardWeb";
import { AuthContext } from "../../provider/AuthProvider";
import AccomplishmentsWeb from "../components/AccomplishmentsWeb";
import WebHeader from "./WebHeader";

export default function HomeScreenWeb({ navigation }: any) {
  const { userData } = useContext(AuthContext);
  return (
    <View
      style={{
        flex: 1,
        alignContent: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
      }}
    >
      <View
        style={{
          backgroundColor: "#fff",
          flex: 1,
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            borderRadius: 50,
            width: "60%",
            background:
              "linear-gradient(-90deg, rgba(24, 33, 77, 0.05) 0%, rgba(255, 90, 102, 0.05) 100%)",
            alignItems: "center",
            paddingHorizontal: 32,
            marginVertical: 10,
          }}
        >
          <WebHeader navigation={navigation} width="100%" />
          <WelcomeCardWeb name={userData?.name || ""} copilot={null} />
          <View
            style={{
              width: "100%",
              flex: 1,
            }}
          >
            <WebCarouselDesktop navigation={navigation} />
          </View>
        </View>
        <View
          style={{
            width: "40%",
            height: "100%",
            paddingTop: "5%",
            backgroundColor: "transparent",
          }}
        >
          <AccomplishmentsWeb />
        </View>
      </View>
    </View>
  );
}
