import React from "react";
import { Platform, View } from "react-native";
let _PhyxableMobileAudioPlayer: any = null;
Platform.OS == "web"
  ? null
  : (_PhyxableMobileAudioPlayer = require("./PhyxableAudioPlayer").default);
export default function PhyxableAudioPlayer(message: any) {
  return (
    <View>
      {Platform.OS == "web" ? (
        <audio key={`voice_${message.sender_id}_${message.timestamp}`} controls>
          <source
            key={`voice_src_${message.sender_id}_${message.timestamp}`}
            src={message.data.data}
            type="audio/mpeg"
          />
        </audio>
      ) : (
        <>
          <_PhyxableMobileAudioPlayer message={message} />
        </>
      )}
    </View>
  );
}
