import { Ionicons } from "@expo/vector-icons";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { LinearGradient } from "expo-linear-gradient";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";
import React, { useContext, useState } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import {
  Button,
  Text,
  TextInput,
  themeColor,
  useTheme,
} from "react-native-rapi-ui";
import Container from "typedi";
import { UserService } from "../../../services/user/user.service";
import AuthHeader from "../../components/Auth/AuthHeader";
import { colors } from "../../constants/colors";
import { AuthContext } from "../../provider/AuthProvider";
import { AuthStackParamList } from "../../types/navigation";

export default function ({
  navigation,
}: NativeStackScreenProps<AuthStackParamList, "Register">) {
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;
  const { isDarkmode, setTheme } = useTheme();
  const auth = getAuth();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const userService = Container.get(UserService);

  const { setUserLocalData, setUser } = useContext(AuthContext) as any;

  async function register() {
    setLoading(true);
    try {
      var _res;
      if (Platform.OS === "web") {
        _res = await createUserWithEmailAndPassword(auth, email, password);
      } else {
        const firebaseAuth = require("@react-native-firebase/auth");
        const firebaseApp = require("@react-native-firebase/app");
        const auth = firebaseApp.default.auth();
        _res = await auth.createUserWithEmailAndPassword(email, password);
      }
    } catch (error: any) {
      setError(
        (error.message.toString().replace("Firebase:", "") as string) ||
          "Something went wrong please try again",
      );
      setLoading(false);
      return;
    }
    if (_res?.user) {
      var _resUser = await userService.createUser({
        email: email,
        firstName: firstName,
        lastName: lastName,
        id: _res.user.uid,
      });
      if (_resUser.status === 200) {
        // @ts-ignore
        setUserLocalData({
          phyxes: _resUser.data.phyxes,
          customPhyxes: _resUser.data.customPhyxes,
          userProfile: _resUser.data.userProfile,
          name: _res.user.displayName,
          email: _resUser.data.userProfile?.email,
          photoUrl: _resUser.data.userProfile?.photoUrl,
          id: _res.user.uid,
          currentProgram: _resUser.data.phyxes[0],
          currentCustomPhyx: _resUser.data.customPhyxes[0],
        });
        if (setUser) setUser(null);
        // add a 5 second delay to allow the user to see the loading screen
        setTimeout(async () => {
          setLoading(false);
          if (Platform.OS === "web") {
            await signInWithEmailAndPassword(auth, email, password);
          } else {
            const firebaseAuth = require("@react-native-firebase/auth");
            const firebaseApp = require("@react-native-firebase/app");
            const auth = firebaseApp.default.auth();
            await auth.signInWithEmailAndPassword(email, password);
          }
          setUser(true);
        }, 5000);
      } else {
        alert(_resUser.data.message);
        setLoading(false);
      }
    }
  }
  return (
    <KeyboardAvoidingView behavior="height" enabled style={{ flex: 1 }}>
      <>
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
          colors={[colors.buttonWhite, colors.buttonWhite]}
          style={{
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          <ScrollView
            contentContainerStyle={{
              flexGrow: 1,
              width: isTablet ? 500 : "100%",
              alignSelf: "center",
            }}
          >
            <AuthHeader />

            <View
              style={{
                flex: 3,
                paddingHorizontal: 20,
                paddingBottom: 20,
              }}
            >
              <Text
                fontWeight="bold"
                size="h3"
                style={{
                  alignSelf: "center",
                  padding: 30,
                }}
              >
                Register
              </Text>
              <TextInput
                containerStyle={{ marginTop: 15 }}
                placeholder="First Name"
                value={firstName}
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect={false}
                secureTextEntry={false}
                keyboardType="default"
                onChangeText={(text) => setFirstName(text)}
                leftContent={
                  <Ionicons
                    name="person-outline"
                    size={20}
                    color={colors.primaryColor}
                    style={{ marginRight: 10 }}
                  />
                }
              />

              <TextInput
                containerStyle={{ marginTop: 15 }}
                placeholder="Last Name"
                value={lastName}
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect={false}
                secureTextEntry={false}
                onChangeText={(text) => setLastName(text)}
                leftContent={
                  <Ionicons
                    name="person-outline"
                    size={20}
                    color={colors.primaryColor}
                    style={{ marginRight: 10 }}
                  />
                }
              />
              <TextInput
                containerStyle={{ marginTop: 15 }}
                placeholder="Email"
                value={email}
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect={false}
                keyboardType="email-address"
                onChangeText={(text) => setEmail(text)}
                leftContent={
                  <Ionicons
                    name="mail-outline"
                    size={20}
                    color={colors.primaryColor}
                    style={{ marginRight: 10 }}
                  />
                }
              />
              <TextInput
                containerStyle={{ marginTop: 15 }}
                placeholder="Password"
                value={password}
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect={false}
                secureTextEntry={showPassword}
                onChangeText={(text) => setPassword(text)}
                onSubmitEditing={() => register()}
                leftContent={
                  <Ionicons
                    name="lock-closed-outline"
                    size={20}
                    color={colors.primaryColor}
                    style={{ marginRight: 10 }}
                  />
                }
                rightContent={
                  <Ionicons
                    name={showPassword ? "eye-outline" : "eye-off-outline"}
                    size={20}
                    color={colors.primaryColor}
                    style={{ marginRight: 10 }}
                    onPress={() => {
                      setShowPassword(!showPassword);
                    }}
                  />
                }
              />
              <Button
                text={loading ? "Loading" : "Sign up"}
                textStyle={{ fontWeight: "100" }}
                onPress={() => {
                  register();
                }}
                style={{
                  marginTop: 20,
                }}
                color={colors.primaryColor}
                disabled={loading}
                size="md"
              />
              {error && (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 15,
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ color: themeColor.danger }}>{error}</Text>
                </View>
              )}

              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 15,
                  justifyContent: "center",
                }}
              >
                <Text size="md">Already have an account?</Text>
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate("Login");
                  }}
                >
                  <Text
                    size="md"
                    fontWeight="bold"
                    style={{
                      marginLeft: 5,
                    }}
                  >
                    Login here
                  </Text>
                </TouchableOpacity>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 30,
                  justifyContent: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    isDarkmode ? setTheme("light") : setTheme("dark");
                  }}
                >
                  <Text
                    size="md"
                    fontWeight="bold"
                    style={{
                      marginLeft: 5,
                    }}
                  >
                    {/* {isDarkmode ? "☀️ light theme" : "🌑 dark theme"} */}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </ScrollView>
        </LinearGradient>
      </>
    </KeyboardAvoidingView>
  );
}
