import { STRIPE_PUBLISHABLE_KEY } from "@env";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import React from "react";
import PaymentCardWeb from "./paymentWeb";

export default function StripeWebCard({
  client_secret,
  submitCallBack,
  navigation,
}: any) {
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
  const options: StripeElementsOptions = {
    currency: "cad",
    fonts: [
      {
        cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
      },
    ],
  };
  if (!client_secret?.client_secret) {
    options.mode = "setup";
  } else {
    options.clientSecret = client_secret?.client_secret;
  }
  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentCardWeb
        client_secret={client_secret}
        submitCallBack={submitCallBack}
        navigation={navigation}
      />
    </Elements>
  );
}
