import { PRAC_PORTAL_URL_FOR_MESSAGES } from "@env";
import axios from "axios";
import React, { ReactNode, useEffect, useState } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { Text } from "react-native-paper";
import { TwilioVideoParticipantView } from "react-native-twilio-video-webrtc";

interface Props {
  style?: StyleProp<ViewStyle>;
  extendButton?: ReactNode;
  participantSid: string;
  videoTrackSid: string;
  uuid: string;
}

export default function ParticipantMobile({
  style,
  extendButton,
  participantSid,
  videoTrackSid,
  uuid,
}: Props) {
  const [myDetail, setMyDetail] = useState<any>(null);

  const fetchMyDetail = async (id: string) => {
    try {
      let result = await axios.get(
        `${PRAC_PORTAL_URL_FOR_MESSAGES}/chat/my/detail?id=${id}`,
      );
      setMyDetail(result?.data);
    } catch (error) {
      console.error("error:", error);
    }
  };

  useEffect(() => {
    fetchMyDetail(uuid);
  }, [uuid]);

  return (
    <View style={style}>
      {extendButton}
      <TwilioVideoParticipantView
        trackIdentifier={{ participantSid, videoTrackSid }}
        scaleType="fill"
        style={{
          flex: 1,
          width: "100%",
          height: "100%",
          overflow: "hidden",
          position: "relative",
        }}
      />
      <Text
        style={{
          position: "absolute",
          bottom: 5,
          right: 5,
          color: "white",
          backgroundColor: "#1c1e21",
          padding: 10,
          zIndex: 100,
          borderRadius: 100,
          minWidth: 50,
          textAlign: "center",
        }}
      >
        {myDetail ? myDetail?.userProfile.name : uuid}
      </Text>
    </View>
  );
}
