import React from "react";
import { Text, themeColor, useTheme } from "react-native-rapi-ui";
import { colors } from "../../constants/colors";

type Props = {
  title: string;
  focused: boolean;
};

export default function TabBarText({ title, focused }: Props) {
  const { isDarkmode } = useTheme();

  return (
    <Text
      fontWeight="bold"
      style={{
        marginBottom: 5,
        color: focused
          ? isDarkmode
            ? themeColor.white100
            : colors.primaryColor
          : "rgb(143, 155, 179)",
        fontSize: 16, // isWeb ? 16 : isTablet ? 14 :  16,
        alignContent: "center",
      }}
    >
      {title}
    </Text>
  );
}
