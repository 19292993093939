import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  patientMessageColumn: {
    flexDirection: "column",
    width: "50%",
    flex: 1,
    alignItems: "flex-end",
  },
  pracMessageColumn: {
    flexDirection: "column",
    // width: "auto",
    flex: 1,
    alignItems: "flex-start",
    // gap: 10,
  },
  patientTimeStamp: {
    marginTop: 5,
    width: "100%",
    alignItems: "flex-end",
  },
  pracTimeStamp: {
    width: "100%",
    alignItems: "flex-start",
  },
  spacer: {
    marginHorizontal: 5,
  },
});
