import { HAPPY_FACE_URI } from "@env";
import { Ionicons } from "@expo/vector-icons";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { LinearGradient } from "expo-linear-gradient";
import * as WebBrowser from "expo-web-browser";
import { getAuth } from "firebase/auth";
import React, { useContext, useState } from "react";
import {
  Platform,
  SafeAreaView,
  StyleSheet,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Divider, Text } from "react-native-paper";
import { Avatar, useTheme } from "react-native-rapi-ui";
import Container from "typedi";
import { UserService } from "../../../services/user/user.service";
import Header from "../../components/Common/HeaderComponent";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { AuthContext } from "../../provider/AuthProvider";
import { AccountStackParamList } from "../../types/navigation";
import { capitalize } from "../../utils/common";

type AccountProps = {
  navigation: NativeStackNavigationProp<AccountStackParamList, "Account">;
};

export default function Account({ navigation }: AccountProps) {
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;
  const { isDarkmode, setTheme } = useTheme();

  const { userData, setShowModals, setModalData } = useContext(AuthContext);

  const [ready, setReady] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState(null);

  const userService = Container.get(UserService);

  const auth = getAuth();

  const logout = async () => {
    const action1 = async () => {
      await userService.signOut();
      if (Platform.OS === "web") {
        await auth.signOut();
      } else {
        const firebaseAuth = require("@react-native-firebase/auth");
        const firebaseApp = require("@react-native-firebase/app");
        const auth = firebaseApp.default.auth();
        await firebaseAuth.signOut(auth);
      }
    };
    const action2 = () => {
      if (setShowModals) {
        setShowModals(false);
      }
    };
    const modalData = getModalData(EnumType.signOut, action1, action2);
    if (setModalData) {
      setModalData(modalData);
    }
    if (setShowModals) {
      setShowModals(true);
    }
  };

  const deleteAccount = async () => {
    const action1 = async () => {
      await userService.deleteUser();
      if (Platform.OS === "web") {
        await auth.signOut();
      } else {
        const firebaseAuth = require("@react-native-firebase/auth");
        const firebaseApp = require("@react-native-firebase/app");
        const auth = firebaseApp.default.auth();
        await firebaseAuth.signOut(auth);
      }
    };

    const action2 = () => {
      if (setShowModals) {
        setShowModals(false);
      }
    };

    const modalData = getModalData(EnumType.deleteAccount, action1, action2);
    if (setModalData) {
      setModalData(modalData);
    }
    if (setShowModals) {
      setShowModals(true);
    }
  };

  const handleSubscriptionPressed = () => {
    navigation.navigate("Subscription");
  };

  return (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      colors={["#fff", "#fff"]}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <SafeAreaView>
        <Header navigation={navigation} />
        <ScrollView>
          <View style={styles.profileContainer}>
            <View style={styles.profile}>
              {subscriptionType ? (
                <View style={styles.subscriptionBanner}>
                  <Text style={styles.subscriptionType}>
                    {subscriptionType}
                  </Text>
                </View>
              ) : null}
              <View style={styles.rowLayout}>
                <View style={styles.profilePic}>
                  <View>
                    <Avatar
                      size={isTablet ? "xl" : "md"}
                      style={{ backgroundColor: colors.primaryColor }}
                      source={{
                        uri: userData?.photoUrl
                          ? userData?.photoUrl
                          : HAPPY_FACE_URI,
                      }}
                    />
                  </View>
                </View>
                <View style={styles.name}>
                  <Text style={styles.userName}>
                    {capitalize(userData?.userProfile?.name) || ""}{" "}
                    {capitalize(userData?.userProfile?.lastName)}
                  </Text>
                  <View style={styles.rowLayout}>
                    {subscriptionType ? (
                      <Text style={styles.subscriptionType}>Plus</Text>
                    ) : null}
                    <Text style={styles.userEmail}>
                      {userData?.userProfile?.email || ""}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            {/* Accounts container view */}
            <View style={styles.accountContainer}>
              <View style={styles.subContainer}>
                <View style={styles.box}>
                  <TouchableOpacity onPress={handleSubscriptionPressed}>
                    <Ionicons
                      style={{ alignSelf: "center" }}
                      name="md-albums"
                      size={30}
                      color="white"
                    />
                    <Text style={styles.boxText}>Subscription</Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.box}>
                  <TouchableOpacity
                    onPress={() => {
                      //@ts-ignore
                      navigation.navigate(RouteNames.PAYMENT_INFO, {
                        userData: userData,
                      });
                    }}
                  >
                    <Ionicons
                      style={{ alignSelf: "center" }}
                      name="card"
                      size={30}
                      color="white"
                    />
                    <Text style={styles.boxText}>Payment Info</Text>
                  </TouchableOpacity>
                </View>
              </View>

              <View style={styles.footer}>
                <Text
                  style={{
                    fontWeight: "700",
                    color: colors.secondaryColor,
                    fontSize: 16,
                    marginBottom: 10,
                  }}
                >
                  Learn More
                </Text>
                <View style={{}}>
                  <View style={styles.linksList}>
                    <TouchableOpacity
                      onPress={() => {
                        WebBrowser.openBrowserAsync(
                          "https://phyxable.com/support",
                        );
                      }}
                    >
                      <Text style={styles.linkText}>Get Support</Text>
                    </TouchableOpacity>
                  </View>
                  <Divider
                    style={{
                      height: 0.5,
                    }}
                  />
                  <View style={styles.linksList}>
                    <TouchableOpacity
                      onPress={() => {
                        navigation.navigate(RouteNames.GIVE_FEEDBACK as never);
                      }}
                    >
                      <Text style={styles.linkText}>Give Feedback</Text>
                    </TouchableOpacity>
                  </View>
                  <Divider
                    style={{
                      height: 0.5,
                    }}
                  />
                  <View style={styles.linksList}>
                    <TouchableOpacity
                      onPress={() => {
                        WebBrowser.openBrowserAsync(
                          "https://phyxable.com/terms-of-use",
                        );
                      }}
                    >
                      <Text style={styles.linkText}>Terms and Conditions</Text>
                    </TouchableOpacity>
                  </View>
                  <Divider
                    style={{
                      height: 0.5,
                    }}
                  />
                  <View style={styles.linksList}>
                    <TouchableOpacity
                      onPress={() => {
                        WebBrowser.openBrowserAsync(
                          "https://phyxable.com/privacy-policy",
                        );
                      }}
                    >
                      <Text style={styles.linkText}>Privacy Policy</Text>
                    </TouchableOpacity>
                  </View>
                  <Divider
                    style={{
                      height: 0.5,
                    }}
                  />
                </View>
                <View style={styles.footer}>
                  <Text
                    style={{
                      fontWeight: "700",
                      color: colors.secondaryColor,
                      fontSize: 16,
                      marginBottom: 10,
                    }}
                  >
                    Settings
                  </Text>
                  <View style={styles.linkText}>
                    <TouchableOpacity
                      onPress={() => {
                        navigation.navigate(
                          RouteNames.NOTIFICATION_SETTINGS_MAIN as never,
                        );
                      }}
                    >
                      <Text
                        style={{
                          color: colors.secondaryColor,
                          fontWeight: "700",
                          fontSize: 12,
                        }}
                      >
                        Notification Settings
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <View>
                  <TouchableOpacity
                    style={[styles.button, { width: isTablet ? "20%" : "50%" }]}
                    onPress={logout}
                  >
                    <Text
                      style={{
                        color: "white",
                        fontSize: 16,
                        fontWeight: "700",
                      }}
                    >
                      Log out
                    </Text>
                    <Ionicons name="md-log-out" color="white" size={25} />
                  </TouchableOpacity>
                </View>
                <View
                  style={[
                    {
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 10,
                    },
                  ]}
                >
                  <TouchableOpacity onPress={deleteAccount}>
                    <Text
                      style={{
                        color: colors.secondaryColor,
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    >
                      Delete Account
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    </LinearGradient>
  );
}
export enum EnumType {
  signOut = "signOut",
  deleteAccount = "deleteAccount",
}
const getModalData = (
  type: EnumType,
  button1Action: any,
  button2Action: any,
) => {
  const ModalData = {
    headerImage: "https://phyxableprograms.s3.amazonaws.com/prevent.svg",
    bodyImage: "",
    footerImage: "",
    title: "Are you sure?",
    bodyTitle:
      type === EnumType.signOut
        ? "Do you want to sign out?"
        : "Do you want to delete your account? all your data will be lost and you will not be able to recover it.",
    bodyList: [],
    button1: "Yes",
    button2: "Close",
    type: "notification",
    button1Action: () => button1Action(),
    button2Action: () => button2Action(),
  };

  return ModalData;
};
const styles = StyleSheet.create({
  profileContainer: {
    width: "100%",
    height: "100%",
    // backgroundColor: "white",
  },
  profile: {
    marginTop: 2,
    // backgroundColor: "white",
    paddingVertical: 15,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 5,
  },
  subscriptionBanner: {
    alignItems: "flex-end",
    marginRight: 10,
  },
  subscriptionType: {
    // backgroundColor: colors.primaryColor,
    color: "white",
    borderRadius: 3,
    paddingVertical: 1,
    paddingHorizontal: 4,
    fontSize: 12,
    fontWeight: "700",
  },
  rowLayout: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  profilePic: {
    marginLeft: 20,
  },
  name: {
    width: "100%",
    marginLeft: 10,
    flexDirection: "column",
  },
  accountContainer: {
    paddingHorizontal: 20,
    width: "100%",
    // backgroundColor: "white",
  },
  box: {
    backgroundColor: colors.secondaryColor,
    borderRadius: 8,
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  subContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginTop: 30,
  },
  footer: {
    marginTop: 15,
    alignContent: "space-between",
  },
  linksList: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 15,
    marginBottom: 5,
    alignItems: "center",
  },
  boxText: {
    fontSize: 12,
    fontWeight: "700",
    color: "#ffffff",
    marginTop: "10%",
  },
  userName: {
    fontSize: 25,
    color: colors.secondaryColor,
    fontWeight: "700",
  },
  userEmail: {
    fontSize: 11,
    color: colors.secondaryColor,
    fontWeight: "700",
    marginLeft: 5,
  },
  linkText: {
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 12,
    marginLeft: 20,
  },
  button: {
    borderRadius: 30,
    backgroundColor: colors.primaryColor,
    alignSelf: "center",
    marginTop: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    paddingVertical: 10,
    marginBottom: 20,
  },
  reminder: {
    marginTop: "10%",
    flexDirection: "row",
  },
});
