import {
  API_BASE_MOBILE,
  API_BASE_WEB,
  PRAC_PORTAL_URL_FOR_MESSAGES,
} from "@env";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { Platform } from "react-native";
import { Service } from "typedi";

@Service()
export class ApiService {
  private readonly API_URL =
    Platform.OS === "web" ? API_BASE_WEB : API_BASE_MOBILE;
  private readonly PRAC_PORTAL_URL_FOR_MESSAGES =
    PRAC_PORTAL_URL_FOR_MESSAGES || "";
  constructor() {}
  async get(url: string, params: any = {}) {
    const token = await this.getTokenFromFirebase();
    return await axios.get(`${this.API_URL}${url}`, {
      params,
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  async post(url: string, data: any = {}) {
    const token = await this.getTokenFromFirebase();
    return await axios.post(`${this.API_URL}${url}`, data, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  async put(url: string, data: any = {}) {
    const token = await this.getTokenFromFirebase();
    return await axios.put(`${this.API_URL}${url}`, data, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  async delete(url: string, data: any = {}) {
    const token = await this.getTokenFromFirebase();
    return await axios.delete(`${this.API_URL}${url}`, {
      data,
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  async patch(url: string, data: any = {}) {
    const token = await this.getTokenFromFirebase();
    return await axios.patch(`${this.API_URL}${url}`, data, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }

  async postPracPortalAPI(url: string, data: any = {}) {
    return await axios.post(`${this.PRAC_PORTAL_URL_FOR_MESSAGES}${url}`, data);
  }

  async getPracPortalAPI(url: string, params: any = {}) {
    return await axios.get(`${this.PRAC_PORTAL_URL_FOR_MESSAGES}${url}`, {
      params,
    });
  }

  async getTokenFromFirebase() {
    if (Platform.OS === "web") {
      const auth = getAuth();
      return auth.currentUser?.getIdToken();
    } else {
      const firebaseAuth = require("@react-native-firebase/auth");
      const firebaseApp = require("@react-native-firebase/app");
      const auth = firebaseApp.default.auth();
      return auth.currentUser?.getIdToken();
    }
  }
}
