import { getAuth } from "firebase/auth";
import React from "react";
import { Text, View } from "react-native";

export default function Logout() {
  const auth = getAuth();
  React.useEffect(() => {
    auth.signOut();
  }, []);

  return (
    <View>
      <Text></Text>
    </View>
  );
}
