import axios from "axios";
import { Audio } from "expo-av";
import { activateKeepAwake, deactivateKeepAwake } from "expo-keep-awake";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Dimensions,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
  useWindowDimensions,
} from "react-native";
import { Text } from "react-native-rapi-ui";
//@ts-ignore
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { CountdownCircleTimer } from "react-native-countdown-circle-timer";
import ReadMore from "react-native-read-more-text";
import Button from "../../../components/Home/button";
import MiniProgramBanner from "../../../components/Program/miniProgramBanner";
import VideoPlayer from "../../../components/Program/videoPlayer";
import { colors } from "../../../constants/colors";
import { RouteNames } from "../../../constants/routeNames";
import {
  AuthContext,
  AuthContextProps,
  IOutcomeFormResult,
} from "../../../provider/AuthProvider";
import { RootStackParamList } from "../../../types/navigation";
import { IMp4, IVideo } from "../../../types/program.types";
import Loading from "../../utils/Loading";

const WIDTH = Dimensions.get("window").width;

export type CustomExerciseProgram = {
  title: string;
  created_by: string;
  created_by_name: string;
  id: string;
  image: any;
  session: any;
  level: number;
};

export type CustomExerciseParams = {
  program: CustomExerciseProgram;
  video: Array<any>;
  outcome_forms: IOutcomeFormResult[];
};

type Props = NativeStackScreenProps<
  RootStackParamList,
  RouteNames.CUSTOM_EXERCISE
>;

export default function CustomExercise({ navigation, route }: Props) {
  const { program, video, outcome_forms } = route.params;
  const { userData } = useContext(AuthContext) as any;
  const { width, height } = useWindowDimensions();
  const isTablet = WIDTH >= colors.tabletSize;
  const { videoCache } = useContext(AuthContext) as AuthContextProps;

  const videoIndex = useRef(-1);
  const currentSet = useRef(0);
  const BREAK_TIME = 3;
  const [loading, setLoading] = useState(true);
  const [currentVideo, setCurrentVideo] = useState<any>(null);
  const [currentVideoFile, setCurrentVideoFile] = useState<any>(null);
  const [timerPlayer, setTimerPlayer] = useState(false);
  const [countDownDuration, setCountDownDuration] = useState(BREAK_TIME);
  const [setsCompleted, setSetsCompleted] = useState(false);
  const [setCount, setSetCount] = useState(1);
  const [totalSet, setTotalSet] = useState(0);
  const [countDown, setCountDown] = useState(0);
  const [isRep, setIsRep] = useState(false);
  const [buttonText, setButtonText] = useState("START");

  const [audioObject, setAudioObject] = useState(new Audio.Sound());
  const [audioStatus, setAudioStatus] = useState();

  const MAX_VIDEO_LENGTH = video.length;

  useEffect(() => {
    setLoading(true);
    if (audioObject === null) {
      setAudioObject(new Audio.Sound());
      setLoading(false);
      return;
    }

    loadAudio().then(() => {
      setLoading(false);
    });
  }, [audioObject]);

  const loadAudio = async () => {
    try {
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: false,
        interruptionModeIOS: 1,
        playsInSilentModeIOS: true,
        staysActiveInBackground: false,
        interruptionModeAndroid: 1,
        shouldDuckAndroid: false,
      });
      const status = await audioObject.loadAsync(
        require("../../../../assets/sound/timer.wav"),
        {
          shouldPlay: false,
        },
      );
      setAudioStatus(status as any);
    } catch (err) {
      console.error("Sound: ", err);
    }
  };

  const playAudio = async () => {
    await audioObject.playAsync();
    await audioObject.setPositionAsync(0);
  };

  const fetchVideo = async (data: any) => {
    try {
      const { playlist } =
        videoCache?.[data.videoUrl] ||
        ((await axios.get(data.videoUrl)).data as IVideo);
      const videoToUse = (
        playlist?.[0]?.sources?.filter(
          (source) =>
            source.file && source.file !== "" && source?.type === "video/mp4",
        ) as IMp4[]
      ).sort((a, b) => {
        if (a.height < b.height) {
          return 1;
        } else if (a.height > b.height) {
          return -1;
        } else {
          return 0;
        }
      });

      setCurrentVideoFile(
        videoToUse && videoToUse.length > 0
          ? videoToUse[0].file
          : (playlist || [])[0].sources[videoIndex.current + 1 || 0].file,
      );
    } catch (err) {
      console.error("CustomExercise: fetchVideo Error:", err);
    }
  };

  const handleStart = () => {
    activateKeepAwake();
    if (audioStatus !== null) {
      //Play audio only if it's loaded
      if (audioStatus?.isLoaded === true) {
        playAudio();
      }
    }

    setTimerPlayer(true);
  };

  const handleStop = () => {
    setTimerPlayer(false);
    setButtonText("RESUME");
  };

  const handleSkip = () => {
    setTimerPlayer(false);
    changeVideo();
  };

  const handleCountDownText = () => {
    if (video[videoIndex.current].reps) {
      console.log("Reps: ", video[videoIndex.current].reps);
      setCountDown(video[videoIndex.current].reps + 1);
      setIsRep(true);
    } else {
      setCountDown(video[videoIndex.current].time + 1);
      setIsRep(false);
    }
  };

  useEffect(() => {
    changeVideo();
  }, [video]);
  //Moves forward to the next video in the session
  const changeVideo = async () => {
    setSetsCompleted(false);
    setSetCount(1);
    setTimerPlayer(false);
    setButtonText("START");
    videoIndex.current += 1;
    if (videoIndex.current < MAX_VIDEO_LENGTH) {
      const _currentVideo = video[videoIndex.current];
      setCurrentVideo(_currentVideo);
      currentSet.current = _currentVideo?.sets;
      setTotalSet(_currentVideo.sets || 1);
      fetchVideo(_currentVideo);
      handleCountDownText();
    } else {
      let user = userData;
      let customPhyxes = [];
      customPhyxes = user.userProfile.progress.customPhyx;
      if (customPhyxes?.length == 0) {
        var data: any = {};
        data[program.id] = 1;
        // user.userProfile.progress.customPhyx.push(data);
      } else {
        // if (user.userProfile.progress.customPhyx)
        //   user.userProfile.progress.customPhyx[program.id] += 1;
      }
      console.log("Exercise: All videos completed!");
      navigation.navigate(RouteNames.CUSTOM_QUESTIONNAIRE, {
        program: program,
        outcome_forms: outcome_forms,
      });
    }
  };

  //Style and view for 'Read more' button
  const renderTruncatedFooter = (handlePress: any) => {
    return (
      <Text style={{ color: colors.primaryColor }} onPress={handlePress}>
        Read more
      </Text>
    );
  };
  const handleVideoFinish = () => {
    deactivateKeepAwake();
    console.log("Video finished");
    if (currentSet.current > 0) {
      handleStart();
    }
  };

  //Style and view for 'Show less' button
  const renderRevealedFooter = (handlePress: any) => {
    return (
      <Text style={{ color: colors.primaryColor }} onPress={handlePress}>
        Show less
      </Text>
    );
  };
  const handleGoBack = () => {
    navigation.goBack();
  };
  const onIntervalUpdate = (remainingTime: number) => {
    if (video[videoIndex.current]?.reps) {
      if (remainingTime / countDownDuration === 0) {
        if (countDown > 0) {
          setCountDown(countDown - 1);
        } else {
          navigation.navigate(RouteNames.CUSTOM_QUESTIONNAIRE, {
            program: program,
            outcome_forms: outcome_forms,
          });
        }
      }
    } else {
      setCountDown(countDown - 1);
    }
  };
  const changeSet = () => {
    if (audioStatus !== null) {
      //Play audio only if it's loaded
      if (audioStatus.isLoaded === true) {
        playAudio();
      }
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.container}>
        <View>
          <MiniProgramBanner
            title={program.title}
            goBackCallback={handleGoBack}
            image={program.image}
          />
          <View style={styles.contentView}>
            <Text style={styles.title}>Session {program.session}</Text>
            <Text style={styles.subHeading} numberOfLines={1}>
              {currentVideo?.session_title}
            </Text>
            {currentVideoFile ? (
              <View
                style={{
                  width: "100%",
                  //height: HEIGHT,
                  alignItems: "center",
                  // justifyContent: "center",
                }}
              >
                <VideoPlayer
                  videoFile={currentVideoFile}
                  videoFinishCallback={handleVideoFinish}
                  navigation={navigation}
                  width={isTablet ? width * 0.6 : width * 0.9}
                  height={isTablet ? height * 0.4 : height * 0.25}
                  autoPlay={true}
                />
              </View>
            ) : null}

            {currentVideo?.comment && (
              <View style={styles.commentView}>
                <Text style={styles.title}>Comments</Text>
                <ReadMore
                  numberOfLines={2}
                  renderTruncatedFooter={renderTruncatedFooter}
                  renderRevealedFooter={renderRevealedFooter}
                >
                  <Text>{currentVideo?.comment || ""}</Text>
                </ReadMore>
              </View>
            )}

            <View style={styles.cardContainer}>
              <Text
                style={{
                  alignSelf: "center",
                  marginTop: 5,
                  color: colors.secondaryColor,
                  fontWeight: "700",
                  fontSize: 16,
                }}
                numberOfLines={1}
              >
                {currentVideo?.session_title}
              </Text>
              <Text style={styles.setsText}>
                {setCount}/{totalSet} sets
              </Text>
              <View style={styles.timer}>
                <CountdownCircleTimer
                  isPlaying={timerPlayer}
                  duration={countDownDuration}
                  colors={[colors.primaryColor, colors.green]}
                  colorsTime={[countDownDuration, 0]}
                  size={110}
                  strokeWidth={8}
                  rotation="counterclockwise"
                  key={currentVideo?.session_title}
                  onUpdate={onIntervalUpdate}
                  onComplete={() => {
                    changeSet();
                    return { shouldRepeat: true, delay: 1 };
                  }}
                >
                  {({ remainingTime, elapsedTime, color }) => (
                    <TimerText
                      setsCompleted={setsCompleted}
                      countDown={countDown}
                      isRep={isRep}
                    />
                  )}
                </CountdownCircleTimer>
              </View>
              <View style={styles.button}>
                {timerPlayer && !setsCompleted ? (
                  /* 2 button view when timer is playing*/
                  <View style={styles.multiButton}>
                    <Button
                      text="SKIP"
                      color={colors.secondaryColor}
                      backgroundColor={"white"}
                      customStyle={{
                        borderColor: colors.secondaryColor,
                        borderWidth: 2,
                        paddingHorizontal: 30,
                      }}
                      onPressCallback={handleSkip}
                    />
                    <Button
                      text="PAUSE"
                      backgroundColor={colors.secondaryColor}
                      onPressCallback={handleStop}
                      customStyle={{
                        borderColor: colors.secondaryColor,
                        borderWidth: 2,
                        paddingHorizontal: 30,
                      }}
                    />
                  </View>
                ) : (
                  //Single button view when timer stops or sets are completed
                  <View>
                    <Button
                      text={setsCompleted ? "NEXT" : buttonText}
                      backgroundColor={colors.primaryColor}
                      customStyle={{
                        alignSelf: "center",
                        paddingHorizontal: 30,
                      }}
                      onPressCallback={() =>
                        setsCompleted ? handleSkip() : handleStart()
                      }
                    />
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

type TimerTextProps = {
  setsCompleted: boolean;
  countDown: number;
  isRep: boolean;
};

const TimerText = ({ setsCompleted, countDown, isRep }: TimerTextProps) => {
  const formatTime = (time: number) => {
    if (time <= 60) {
      return `${time}`;
    }
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;

    return `${minutes}:${seconds}`;
  };

  return (
    <View>
      {!setsCompleted ? (
        <Text style={styles.timerText}>
          <Text style={styles.timerText}>
            {!isRep
              ? formatTime(countDown)
              : countDown < 0
                ? -1 * countDown
                : countDown}{" "}
            <Text style={styles.staticText}>{isRep ? "reps" : ""}</Text>
          </Text>
        </Text>
      ) : (
        <Text style={styles.timerText}>Done!</Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
  },
  contentView: {
    paddingHorizontal: 20,
  },
  commentView: {
    // paddingTop: 10,
  },
  title: {
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 16,
  },
  subHeading: {
    textTransform: "capitalize",
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 12,
    marginTop: 5,
    marginBottom: 15,
  },
  timer: {
    marginTop: 10,
    alignItems: "center",
  },
  timerText: {
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 24,
  },
  staticText: {
    color: colors.secondaryColor,
    fontSize: 16,
    fontWeight: "400",
  },
  button: {
    marginTop: 20,
    marginBottom: 15,
  },
  multiButton: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  cardContainer: {
    backgroundColor: "white",
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 20,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 5,
  },
  setsText: {
    fontSize: 14,
    color: colors.textColor,
    alignSelf: "center",
  },
});
