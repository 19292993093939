import { Feather } from "@expo/vector-icons";
import React from "react";
import { View, useWindowDimensions } from "react-native";
import { useTheme } from "react-native-rapi-ui";
import { colors } from "../../constants/colors";

export default ({
  icon,
  focused,
  topRightIcon,
}: {
  icon: any;
  focused: boolean;
  topRightIcon: any;
}) => {
  const { isDarkmode } = useTheme();
  const { width, height } = useWindowDimensions();
  const isTablet = width >= 768;
  return topRightIcon ? (
    <View>
      <View
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 1,
          opacity: 0.8,
          width: 10,
          height: 10,
          borderRadius: 5,
          backgroundColor: colors.primaryColor,
        }}
      ></View>
      <Feather
        name={icon}
        style={{}}
        size={isTablet ? 32 : 20}
        color={focused ? colors.primaryColor : colors.textColor}
      />
    </View>
  ) : (
    <Feather
      name={icon}
      style={{}}
      size={isTablet ? 32 : 20}
      color={focused ? colors.primaryColor : colors.textColor}
    />
  );
};
