import React from "react";
import { useWindowDimensions } from "react-native";
import { useTheme } from "react-native-rapi-ui";
import { colors } from "../../constants/colors";
import WebHeader from "../../webScreens/screens/WebHeader";
import HeaderMobile from "./HeaderMobile";

export default function Header({ navigation }: any) {
  const { isDarkmode, setTheme } = useTheme();
  const { width, height } = useWindowDimensions();

  const isTablet = width >= colors.tabletSize;

  const HeaderToRender = isTablet ? WebHeader : HeaderMobile;

  return <HeaderToRender navigation={navigation} width={width * 0.85} />;
}
