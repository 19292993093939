import { CONGRATS } from "@env";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React, { useContext } from "react";
import {
  Dimensions,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { SvgFromUri } from "react-native-svg";
import Container from "typedi";
import { UserService } from "../../../services/user/user.service";
import RenderSVGImage from "../../components/Common/renderSVGImage";
import Button from "../../components/Home/button";
import { BackButton } from "../../components/Program/backButton";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { AuthContext } from "../../provider/AuthProvider";
import { RootStackParamList } from "../../types/navigation";

const HEIGHT = Dimensions.get("window").width / 2;

export type CongratulationsRouteParams = {
  program: any;
  vas: any;
  difficulty: number;
  psfs: any;
};

type Props = NativeStackScreenProps<
  RootStackParamList,
  RouteNames.CONGRATULATIONS
>;

export default function Congratulations({ navigation, route }: Props) {
  const { program, vas, difficulty, psfs } = route.params;

  const userService = Container.get(UserService);
  const { userData } = useContext(AuthContext);

  if (!userData) {
    return null;
  }

  const handleGoBack = () => {
    navigation.goBack();
  };

  const handleBackToMain = async () => {
    //We won't move forward unless we get user object

    //Extract progress from user
    const progress = userData.userProfile.progress || {};

    if (!progress[program.id] || !Array.isArray(progress[program.id])) {
      progress[program.id] = [];
    }
    //If level doesn't exists we create an object for it
    //along with session's array
    if (!progress[program.id][program.level]) {
      progress[program.id][program.level] = {};
    }
    //If level exists but session does not then we create only
    //session's array
    if (!progress[program.id][program.level][program.session]) {
      progress[program.id][program.level][program.session] = [];
    }
    //Add the new progress to the data
    progress[program.id][program.level][program.session].push({
      date: new Date().toISOString(),
      timestamp: Date.parse(new Date().toISOString()),
      VAS: vas || null,
      PSFS: psfs,
      difficulty: difficulty,
    });

    //Add the new progress object to user's object
    userData.userProfile.progress = progress;
    //Update the user in mongodb with new progress
    try {
      await userService.updateUserProgress(
        userData?.id as string,
        progress,
        userData.email,
      );
    } catch (error) {
      console.error(
        "Congratulations: updateUserProgress failed. Error:",
        error,
      );
    }

    //This will reset all the current stack so that when screens load
    //the empty useEffect can be called.
    await navigation.reset({
      index: 0,
      routes: [{ name: RouteNames.HOME }],
    });
  };

  return (
    <SafeAreaView>
      <ScrollView style={styles.container}>
        <View style={{ padding: 15 }}>
          <BackButton handleGoBackCallback={handleGoBack} />
        </View>
        <View style={styles.contentView}>
          <Text style={styles.title}>Congratulations!!!</Text>
          <Text style={styles.subTitle}>You’ve completed all tasks today!</Text>
          {Platform.OS == "web" ? (
            <RenderSVGImage
              style={{
                alignSelf: "center",
                flex: 1,
              }}
              uri={CONGRATS}
            />
          ) : (
            <SvgFromUri uri={CONGRATS} width={HEIGHT} height={HEIGHT} />
          )}

          <Button
            text="BACK TO MAIN"
            backgroundColor={colors.primaryColor}
            customStyle={{
              alignSelf: "center",
              marginTop: 50,
              marginBottom: 20,
              paddingHorizontal: 40,
            }}
            onPressCallback={handleBackToMain}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
const styles = StyleSheet.create({
  container: {
    height: "100%",
    backgroundColor: "white",
    width: "100%",
  },
  contentView: {
    paddingHorizontal: 20,
    alignItems: "center",
  },
  title: {
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 28,
  },
  subTitle: {
    color: colors.secondaryColor,
    fontWeight: "700",
    fontSize: 16,
  },
  image: {
    marginTop: 20,
  },
});
