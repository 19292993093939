import { Fragment } from "react";
import { StyleSheet, Text, View } from "react-native";
import PhyxableAudioPlayer from "../PhyxableAudioComponent";
import { styles } from "./commonStyles";

export default function RenderVoiceMessage(
  message: any,
  index: number,
  isPatient: boolean,
) {
  return (
    <Fragment>
      <View
        style={
          isPatient ? styles.patientMessageColumn : styles.pracMessageColumn
        }
      >
        <View style={voiceStyles.voiceMessageContainer}>
          <View style={voiceStyles.voiceMessage}>
            <PhyxableAudioPlayer data={message} />
            <View style={styles.spacer}></View>
            <Text
              numberOfLines={1}
              style={{
                color: "#18214D",
                fontWeight: "600",
                fontSize: 12,
                //fontFamily: "Proxima Nova",
                marginTop: 5,
              }}
            >
              {message.name ?? "File"}
            </Text>
          </View>

          <View
            style={isPatient ? styles.patientTimeStamp : styles.pracTimeStamp}
          >
            <Text
              style={{
                color: "#6C7993",
                fontWeight: "400",
                fontSize: 12,
                //fontFamily: "Proxima Nova",
              }}
            >
              {new Date(message.timestamp).toLocaleString()}
            </Text>
          </View>
        </View>
      </View>
    </Fragment>
  );
}

const voiceStyles = StyleSheet.create({
  voiceMessageContainer: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignContent: "flex-start",
    width: "auto",
    alignItems: "center",
    gap: 10,
  },
  voiceMessage: {
    width: "auto",
    backgroundColor: "transparent",
    borderRadius: 30,
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
  },
});
