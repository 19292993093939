import { ResizeMode, Video } from "expo-av";
import * as FileSystem from "expo-file-system";
import React, { useEffect, useRef, useState } from "react";
import { Platform, View } from "react-native";
import { getCacheUri } from "../../utils/cache";

// const WIDTH = Dimensions.get("window").width;
// const HEIGHT = WIDTH / 2;

export default function VideoPlayer({
  videoFile,
  videoFinishCallback,
  navigation,
  width,
  height,
  _autoPlay,
}: any) {
  const videoRef = useRef(null);
  const [uri, setUri] = useState("");
  const handlePlayback = (status: any) => {
    //Finished playing video
    if (status.didJustFinish) {
      if (videoFinishCallback) videoFinishCallback();
    }
  };

  useEffect(() => {
    //Stop playing video when navigating to other screen
    if (!navigation) return;

    navigation.addListener("blur", () => {
      if (videoRef.current != null) {
        Platform.OS === "web"
          ? videoRef.current?.pause()
          : videoRef.current?.pauseAsync();
      }
    });
  }, []);

  useEffect(() => {
    if (videoRef.current != null) {
      Platform.OS === "web"
        ? videoRef.current?.play()
        : videoRef.current?.playAsync();
    }
  }, [videoRef]);

  useEffect(() => {
    const callback = async () => {
      const cacheUri = getCacheUri(videoFile);
      if (cacheUri) {
        try {
          const { exists } = await FileSystem.getInfoAsync(cacheUri);
          if (exists) {
            return setUri(cacheUri);
          }
        } catch {}
      }
      setUri(videoFile);
    };
    callback();
  }, [videoFile]);

  return (
    videoFile && (
      <View>
        {Platform.OS === "web" ? (
          <video
            ref={videoRef}
            style={{ width: width, height: height }}
            controls
            autoPlay
            onEnded={handlePlayback}
            src={videoFile}
          />
        ) : (
          <Video
            shouldPlay
            ref={videoRef}
            style={{
              width: width,
              height: height,
            }}
            source={{ uri }}
            useNativeControls
            resizeMode={ResizeMode.CONTAIN}
            onPlaybackStatusUpdate={handlePlayback}
            onLoad={() => {
              videoRef.current.setPositionAsync(0);
              videoRef.current.playAsync();
            }}
          />
        )}
      </View>
    )
  );
}
