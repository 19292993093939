import { LOGO_URI } from "@env";
import React from "react";
import { View } from "react-native";
import RenderImage from "../Common/renderImage";

export default function AuthHeader() {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <RenderImage uri={LOGO_URI} size={{ width: 300, height: 300 }} />
    </View>
  );
}
