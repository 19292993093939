import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { RouteNames } from "../constants/routeNames";
import AddOrEditProgramReminders from "../screens/account/NotificationSettings/AddOrEditProgramReminders";
import NotificationSettings from "../screens/account/NotificationSettings/NotificationSettings";
import ProgramReminders from "../screens/account/NotificationSettings/ProgramReminders";
const notificationsSettingsStack = createNativeStackNavigator();
export default function NotificationSettingsStack() {
  return (
    <notificationsSettingsStack.Navigator
      initialRouteName={RouteNames.NOTIFICATION_SETTINGS}
      screenOptions={{
        headerShown: false,
        headerBackButtonMenuEnabled: true,
        headerTitle: "",
      }}
    >
      <notificationsSettingsStack.Screen
        name={RouteNames.NOTIFICATION_SETTINGS}
        component={NotificationSettings}
      />
      <notificationsSettingsStack.Screen
        name={RouteNames.PROGRAM_REMINDERS}
        component={ProgramReminders}
      />
      <notificationsSettingsStack.Screen
        name={RouteNames.ADD_EDIT_PROGRAM_REMINDER}
        component={AddOrEditProgramReminders}
      />
    </notificationsSettingsStack.Navigator>
  );
}
