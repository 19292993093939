import { Ionicons } from "@expo/vector-icons";
import { createDrawerNavigator } from "@react-navigation/drawer";
import React, { Fragment, useContext, useEffect } from "react";
import { Modal, useWindowDimensions } from "react-native";
import { Overlay } from "react-native-elements";
import { themeColor, useTheme } from "react-native-rapi-ui";
import NewMessageModal from "../components/Modals/NewMessageModal";
import TabBarIcon from "../components/utils/TabBarIcon";
import TabBarTextWeb from "../components/utils/TabBarTextWeb";
import { colors } from "../constants/colors";
import { RouteNames } from "../constants/routeNames";
import { AuthContext } from "../provider/AuthProvider";
import AccountStack from "../stacks/Account";
import CallStack from "../stacks/Call";
import ChatStack from "../stacks/Chat";
import HomeStackWeb from "../stacks/HomeStackWeb";
import ProgramStack from "../stacks/Program";
import Logout from "../webScreens/Logut";

const Drawer = createDrawerNavigator();

export default function DrawerStack({ navigation }: any) {
  const { isDarkmode } = useTheme();
  const { showModals, userData } = useContext(AuthContext);
  const { width } = useWindowDimensions();
  const [unreadNotification, setUnreadNotification] = React.useState(0);

  const isTablet = width >= colors.tabletSize;

  useEffect(() => {
    if (userData?.notifications) {
      const unread = userData?.notifications.filter(
        (notification: any) => notification.read === false,
      );
      setUnreadNotification(unread.length);
    }
  }, [userData?.notifications]);

  return (
    <Fragment>
      <Drawer.Navigator
        screenOptions={{
          headerShown: false,
          drawerStyle: {
            backgroundColor: isDarkmode ? themeColor.dark100 : "#ffffff",
            width: "15%",
            elevation: 0,
            borderRightColor: "#ffffff",
          },
          drawerType: isTablet ? "permanent" : "front",
        }}
        initialRouteName={RouteNames.HOME}
      >
        <Drawer.Screen
          options={{
            drawerIcon: ({ focused }) => (
              <TabBarIcon focused={focused} icon={"home"} topRightIcon={null} />
            ),
            drawerLabel: ({ focused }) => (
              <TabBarTextWeb focused={focused} title={"Home"} />
            ),
            drawerItemStyle: {
              marginTop: 50,
            },
          }}
          name={RouteNames.HOME}
          component={HomeStackWeb}
        />
        <Drawer.Screen
          options={{
            drawerIcon: ({ focused }) => (
              <TabBarIcon
                focused={focused}
                icon={"video"}
                topRightIcon={null}
              />
            ),
            drawerLabel: ({ focused }) => (
              <TabBarTextWeb focused={focused} title={"Call"} />
            ),
          }}
          name={RouteNames.CALL_MAIN}
          component={CallStack}
        />

        <Drawer.Screen
          options={{
            drawerIcon: ({ focused }) => (
              <TabBarIcon
                focused={focused}
                icon={"message-circle"}
                topRightIcon={null}
              />
            ),
            drawerLabel: ({ focused }) => (
              <TabBarTextWeb focused={focused} title={"Chat"} />
            ),
          }}
          name={RouteNames.CHAT_MAIN}
          component={ChatStack}
        />
        <Drawer.Screen
          options={{
            drawerIcon: ({ focused }) => (
              <TabBarIcon
                focused={focused}
                icon={"search"}
                topRightIcon={null}
              />
            ),
            drawerLabel: ({ focused }) => (
              <TabBarTextWeb focused={focused} title={"Programs"} />
            ),
            lazy: false,
          }}
          name={RouteNames.PROGRAM_MAIN}
          component={ProgramStack}
        />
        <Drawer.Screen
          options={{
            drawerIcon: ({ focused }) => (
              <TabBarIcon focused={focused} icon={"user"} topRightIcon={null} />
            ),
            drawerLabel: ({ focused }) => (
              <TabBarTextWeb focused={focused} title={"Account"} />
            ),
          }}
          name={RouteNames.ACCOUNT_MAIN}
          component={AccountStack}
        />
        {/* <Drawer.Screen
          options={{
            drawerIcon: ({ focused }) => (
              <MaterialCommunityIcons
                name={"lightning-bolt-outline"}
                style={{ marginBottom: -7 }}
                size={24}
                color={focused ? colors.primaryColor : colors.textColor}
              />
            ),
            drawerLabel: ({ focused }) => (
              <TabBarTextWeb focused={focused} title={"Upgrade plan"} />
            ),
            drawerItemStyle: {
              marginTop: 250,
            },
          }}
          name={RouteNames.UPGRADE_PLAN}
          component={UpgradePlan}
        /> */}
        <Drawer.Screen
          options={{
            drawerIcon: ({ focused }) => (
              <Ionicons
                name={"log-out-outline"}
                style={{ marginBottom: -7 }}
                size={24}
                color={focused ? colors.primaryColor : colors.textColor}
              />
            ),
            drawerLabel: ({ focused }) => (
              <TabBarTextWeb focused={focused} title={"Logout"} />
            ),
            drawerItemStyle: {
              marginTop: 250,
            },
          }}
          name={RouteNames.LOGOUT}
          component={Logout}
        />
      </Drawer.Navigator>
      <Overlay
        isVisible={showModals as boolean}
        onBackdropPress={() => {}}
        overlayStyle={{
          width: isTablet ? "50%" : "90%",
          height: isTablet ? "90%" : "70%",
          borderRadius: 20,
          padding: 0,
          margin: 0,
        }}
        ModalComponent={Modal}
      >
        <NewMessageModal navigation={navigation} />
      </Overlay>
    </Fragment>
  );
}
