import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, Text, View, useWindowDimensions } from "react-native";
import { LineChart } from "react-native-chart-kit";
import { ScrollView } from "react-native-gesture-handler";
import ProgressCard from "../../components/Home/ProgressCard";
import { colors } from "../../constants/colors";
import { AuthContext } from "../../provider/AuthProvider";
import {
  CustomWorkouts,
  IProgram,
  ProgramType,
} from "../../types/program.types";
import {
  getTodaysCustomSession,
  getTodaysSession,
} from "../../utils/userProfiles";
export default function Accomplishments({ navigation }: any) {
  const { userData } = useContext(AuthContext);
  const { width, height } = useWindowDimensions();

  const [level, setLevel] = useState(0);
  const [session, setSession] = useState(0);
  const [totalSessions, setTotalSessions] = useState<number>(0);
  const [completed, setCompleted] = useState(false);
  const [graphData, setGraphData] = useState<any>(null);
  const [program, setProgram] = useState<IProgram | CustomWorkouts | null>(
    userData?.currentProgram as any,
  );

  useEffect(() => {
    if (userData?.currentProgram && userData?.userProfile.progress) {
      if (userData?.currentProgram?.type === ProgramType.Custom) {
        setProgram(userData?.currentProgram as CustomWorkouts);
        const _currentProgress = getTodaysCustomSession(
          userData?.userProfile,
          userData?.currentProgram as CustomWorkouts,
        );
        setCompleted(
          _currentProgress._totalSessions ===
            _currentProgress._sessionCompleted,
        );
        const _graphData = userData?.userProfile?.progress?.customPhyx
          ? createCustomGraphData(
              userData?.userProfile.progress.customPhyx[
                (program as CustomWorkouts)?._id
              ],
            )
          : null;
        setGraphData(_graphData);
        setLevel(_currentProgress._sessionCompleted);
        setSession(_currentProgress._todaySession);
        setTotalSessions(
          _currentProgress._totalSessions ||
            (program as CustomWorkouts)?.playlist?.length ||
            0,
        );
      } else {
        setProgram(userData?.currentProgram as IProgram);
        const _currentProgress = getTodaysSession(
          userData?.userProfile,
          userData?.currentProgram as IProgram,
        );
        setCompleted(!!_currentProgress.completed);
        const _graphData = createSelfGraphData(
          userData?.userProfile.progress[(program as IProgram)?.id_],
          _currentProgress.level,
        );
        setGraphData(_graphData);
        setLevel(_currentProgress.level);
        setSession(_currentProgress.session + 1);
        setTotalSessions(
          _currentProgress.totalSessions ||
            (program as IProgram)?.phyxes?.length ||
            0,
        );
      }
    }
  }, [userData?.phyxes]);

  const createSelfGraphData = (_progress: any, level: number) => {
    if (!_progress) {
      return null;
    }
    //Create an array of VAS data
    let array = [];

    for (const session in _progress[level]) {
      //Get the latest progress for the session
      //If a user completed a session multiple times then data from
      //all the attempts gets stored but we want the latest one only
      const length = _progress[level][session].length - 1;
      array.push(_progress[level][session][length]);
    }

    //Get the latest 5 values only
    array = array.slice(Math.max(array.length - 5, 0));

    //Create arrays for labels and datasets
    const labels: any[] = [];
    const dataSet: any[] = [];
    array.map((obj, key) => {
      labels.push(`Session ${key + 1}`);
      if (obj.VAS && obj.VAS?.length > 0) {
        dataSet.push(obj.VAS[0] || 0);
      } else {
        dataSet.push(obj.VAS || 0);
      }
    });

    //Set the values to data useState for rendering
    if (dataSet.length === 0) {
      return null;
    }
    return {
      labels: labels,
      datasets: [
        {
          data: dataSet,
        },
      ],
    };
  };

  const createCustomGraphData = (_progress: any) => {
    let array = [];
    let label = [];
    if (!_progress) {
      return null;
    }

    for (const sess in _progress.progress) {
      const length = _progress.progress[sess].length;
      const data = _progress.progress[sess][length - 1];

      let vas;
      if (data && data?.outcome_forms && data.outcome_forms.length !== 0) {
        vas = data.outcome_forms.filter((form: any) => form.type === "VAS");
        if (vas.length && vas[0]) {
          array.push(vas[0].answer);
          label.push(`Session ${parseInt(sess) + 1}`);
        }
      }
    }

    if (array.length !== 0) {
      return {
        labels: label,
        datasets: [
          {
            data: array,
          },
        ],
      };
    } else {
      return null;
    }
  };

  return (
    <ScrollView
      style={{
        flex: 1,
        height: "100%",
        width: width,
        backgroundColor: "white",
      }}
    >
      <View style={styles.container}>
        <Text style={styles.heading}>Accomplishments</Text>
        <Text style={styles.headingPara}>
          Your progress with selected program is listed below.
        </Text>
        {/* <ChipView /> */}
        {program ? (
          <Text style={styles.title}>
            {(program as IProgram).title ||
              (program as CustomWorkouts).program_name ||
              ""}
          </Text>
        ) : null}
        {/* Progress view */}
        {level !== null && session !== null && totalSessions !== null && (
          <ProgressCard
            level={level}
            session={session}
            totalSessions={totalSessions}
            customPhyx={
              (program as CustomWorkouts)?.type === ProgramType.Custom
                ? true
                : false
            }
          />
        )}
        <View
          style={{
            flex: 1,
            width: "100%",
            height: "100%",
            marginBottom: 20,
          }}
        >
          <Text style={styles.heading}>Progress chart</Text>
          <Text style={styles.subHeading}>
            Pain scales{"\n"}
            <Text style={{ fontSize: 10 }}>
              ("0" for feel great, "10" for worst pain)
            </Text>
          </Text>

          {graphData ? (
            <LineChart
              data={graphData}
              width={width * 0.8}
              height={height * 0.2}
              yAxisInterval={1}
              fromZero={true}
              chartConfig={{
                backgroundGradientFrom: "white",
                backgroundGradientTo: "white",
                decimalPlaces: 0,
                color: (opacity = 1) => colors.primaryColor,
                labelColor: (opacity = 1) => colors.secondaryColor,
                strokeWidth: 3,
                useShadowColorFromDataset: false,
                fillShadowGradient: colors.primaryColor,
                propsForBackgroundLines: (props: any) => colors.primaryColor,
              }}
              bezier
              style={{
                alignSelf: "flex-start",
                marginTop: 2,
                borderRadius: 10,
              }}
            />
          ) : (
            <Text style={styles.emptyChart}>No data to display right now</Text>
          )}
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100%",
    paddingTop: 20,
  },
  title: {
    fontSize: 16,
    color: colors.primaryColor,
    fontWeight: "700",
    marginBottom: 2,
  },
  heading: {
    fontSize: 18,
    fontWeight: "800",
    color: colors.secondaryColor,
  },
  headingPara: {
    color: colors.secondaryColor,
    fontSize: 16,
    marginTop: 10,
    marginBottom: 20,
  },
  buttonContainer: {
    marginBottom: 20,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  listContainer: {
    marginTop: 10,
    width: "100%",
    marginBottom: 20,
  },
  list: {
    flexDirection: "row",
    alignItems: "center",
  },
  listText: {
    fontSize: 16,
    color: colors.secondaryColor,
  },
  chipContainer: {
    flexDirection: "row",
    marginTop: 10,
  },
  listTextBold: {
    fontWeight: "700",
  },
  // container: {
  //   // flex: 1,
  //   marginTop: 30,
  //   marginBottom: 50,
  // },
  // heading: {
  //   fontSize: 18,
  //   color: colors.secondaryColor,
  //   fontWeight: "700",
  // },
  subHeading: {
    marginTop: 2,
    color: colors.secondaryColor,
    fontWeight: "500",
    fontSize: 16,
  },
  chart: {
    marginTop: 30,
  },
  emptyChart: {
    marginTop: 20,
    color: colors.primaryColor,
    fontWeight: "700",
    fontSize: 16,
  },
});

// export default copilot({
//   overlay: "svg", // or 'view'
//   animated: true, // or false
//   tooltipStyle: {
//     borderRadius: 5,
//   },
// })(Accomplishment);
