import { LinearGradient } from "expo-linear-gradient";
import React, { useContext, useEffect, useState } from "react";
import {
  FlatList,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { themeColor, useTheme } from "react-native-rapi-ui";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Container from "typedi";
import { ProgramService } from "../../../services/program/program.service";
import BookAppointment from "../../components/Common/BookAppointment";
import Header from "../../components/Common/HeaderComponent";
import CustomPhyxCard from "../../components/Program/CustomPhyxCard";
import ProgramCard from "../../components/Program/ProgramCard";
import { colors } from "../../constants/colors";
import { AuthContext } from "../../provider/AuthProvider";
import { CustomWorkouts, IProgram } from "../../types/program.types";
import Loader from "../utils/Loading";

export default function Programs(
  navigation: { navigation: any },
  route: { route: any },
) {
  const { width, height } = useWindowDimensions();
  const isTablet = width >= colors.tabletSize;
  const { isDarkmode, setTheme } = useTheme();
  const [loading, setLoading] = useState<boolean>(false);

  const [programs, setPrograms] = useState<IProgram[]>([]);
  const [custom, setCustom] = useState<CustomWorkouts[]>([]);
  const insets = useSafeAreaInsets();

  const { userData, allPrograms, setLocalPrograms, setLocalCustomPrograms } =
    useContext(AuthContext) as any;
  const programService = Container.get(ProgramService);

  useEffect(() => {
    setLoading(true);
    const getPrograms = async () => {
      if (allPrograms?.length > 0) {
        setPrograms(allPrograms);
        setLocalPrograms(allPrograms);
      } else {
        try {
          const programs = await programService.getPrograms();
          setPrograms(programs.data);
          setLocalPrograms(programs.data);
        } catch (err) {
          console.error(err);
        }
      }
      if (userData?.customPrograms?.length > 0) {
        setCustom(userData?.customPrograms);
        setLocalCustomPrograms(userData?.customPrograms);
      } else {
        try {
          const customPhyx = await programService.getCustomProgramsByUserId(
            userData?.id as string,
          );
          setCustom(customPhyx.data);
          setLocalCustomPrograms(customPhyx.data);
        } catch (err) {
          console.error(err);
        }
      }
    };
    getPrograms().then(() => {
      setLoading(false);
    });
  }, []);

  const renderProgram = ({ item }: { item: IProgram }) => (
    <ProgramCard
      program={item}
      navigation={navigation.navigation}
      phyxExists={userData?.phyxes.find((i: any) => i == item.id_)}
    />
  );
  const renderCustomItem = ({ item }: { item: CustomWorkouts }) => (
    <CustomPhyxCard
      navigation={navigation.navigation}
      customPhyx={item}
      key={item._id}
    />
  );

  return (
    <SafeAreaView>
      {!isTablet && <Header navigation={navigation.navigation} />}
      {loading ? (
        <Loader />
      ) : (
        <ScrollView
          contentContainerStyle={{ paddingBottom: insets.bottom + 10 }}
        >
          <LinearGradient
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            colors={[colors.buttonWhite, colors.buttonWhite]}
            style={styles.cardContainer}
          >
            {isTablet && <Header navigation={navigation.navigation} />}

            {custom.length > 0 ? (
              <>
                <View
                  style={{
                    width: "100%",
                  }}
                >
                  <Text
                    style={[
                      styles.heading,
                      {
                        color: isDarkmode
                          ? themeColor.white100
                          : colors.blueColor,
                      },
                    ]}
                  >
                    Custom Programs
                  </Text>
                  <FlatList
                    style={{
                      flex: 1,
                    }}
                    data={custom}
                    renderItem={renderCustomItem}
                    scrollEnabled={true}
                    showsHorizontalScrollIndicator={true}
                    keyExtractor={(item) => item._id}
                    horizontal={true}
                  />
                </View>
              </>
            ) : (
              <BookAppointment user={userData?.userProfile.firstName} />
            )}
            <View style={{ flex: 1 }}>
              <FlatList
                data={programs}
                renderItem={renderProgram}
                scrollEnabled={true}
                keyExtractor={(item) => item.id}
                key={isTablet ? "h" : "v"}
                numColumns={isTablet ? 4 : 2}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                ListEmptyComponent={() => (
                  <View style={{ flex: 1, justifyContent: "center" }}>
                    <Text style={{ textAlign: "center" }}>
                      No programs found
                    </Text>
                  </View>
                )}
                ListHeaderComponent={() => (
                  <Text
                    style={[
                      styles.heading,
                      {
                        color: isDarkmode
                          ? themeColor.white100
                          : colors.blueColor,
                      },
                    ]}
                  >
                    Programs
                  </Text>
                )}
              />
            </View>
          </LinearGradient>
        </ScrollView>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  heading: {
    fontSize: 28,
    fontWeight: "700",
    marginBottom: 20,
    marginTop: 20,
  },
  cardContainer: {
    borderRadius: 20,
    flexDirection: "column",
    flex: 1,
    width: "100%",
    alignSelf: "center",
    alignContent: "center",
    padding: 10,
  },
});
